import React from "react";

const   IframeComponent = () => {
  return (
    <div style={{ height: "0px", width: "0px" }}>
      <iframe
        title="Embedded Frame"
        src="/iframe-content.html"
      />
    </div>
  );
};

export default IframeComponent;
