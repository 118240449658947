import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";

const QuestionsList = ({ questionsList, setQuestionsList }) => {
  const [listStatus, setListStatus] = useState(true);
  const [addQuestionForm, setAddQuestionForm] = useState();
  const [question, setQuestion] = useState("");
  const [questionAlias, setQuestionAlias] = useState("");

  const removeQuestionFromList = async (questionString) => {
    console.log(questionString);
    console.log(JSON.stringify(questionsList));
    const relationObj = questionsList.find((relation) =>
      relation.questions.some((q) => q.question == questionString)
    );
    console.log(relationObj);
    if (relationObj.questions.length > 1) {
      console.log("remove one question");
      const newList = await questionsList.map((obj) => {
        const newQuestions = obj.questions.filter(
          (question) => question.question !== questionString
        );
        return { relation: obj.relation, questions: newQuestions };
      });
      // console.log(newList);
      setQuestionsList(newList);
    } else {
      console.log("remove completely");
      const newList = questionsList.filter((obj) => obj !== relationObj);
      setQuestionsList(newList);
      // console.log(newList);
    }
  };

  useEffect(() => {
    setAddQuestionForm();
    setQuestion("");
    setQuestionAlias("");
  }, [listStatus, questionsList]);

  const addQuestionInList = async (relation) => {
    const newList = await questionsList.map((object) => {
      if (object == relation) {
        const { peerOrChild, peerOrChildToQuestion } = object.questions[0];
        const newQuestionObject = {
          peerOrChild,
          peerOrChildToQuestion,
          question,
          questionAlias,
        };
        if (object.questions[0].indexInHeadingQuestions) {
          newQuestionObject.indexInHeadingQuestions =
            object.questions[0].indexInHeadingQuestions + 1;
        }
        object.questions.push(newQuestionObject);
        return object;
      } else {
        return object;
      }
    });
    console.log(newList);
    setAddQuestionForm();
    setQuestionsList(newList);
  };
  return (
    <>
      <div>
        <div onClick={() => setListStatus((prev) => !prev)}>
          <h6
            className="d-flex align-items-center text-maroon"
            style={{ cursor: "pointer" }}
          >
            Questions List
            <span
              className="text-secondary fw-light text-decoration-underline ms-2"
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {listStatus ? (
                <i className="bi bi-chevron-up"></i>
              ) : (
                <i className="bi bi-chevron-down"></i>
              )}
            </span>
          </h6>
        </div>
        {questionsList !== [] &&
          listStatus &&
          questionsList?.map((relation) => (
            <ul>
              <li>Relation:{relation.relation}</li>

              {relation.questions?.map((question) => (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span>-{question.questionAlias}:</span>
                      <span> {question.question}</span>
                    </div>
                    <svg
                      width="35px"
                      height="35px"
                      viewBox="-12 -12 48.00 48.00"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#000000"
                      transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeQuestionFromList(question.question)}
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#CCCCCC"
                        strokeWidth="0.192"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M19 5L4.99998 19M5.00001 5L19 19"
                          stroke="#000000"
                          strokeWidth="0.4800000000000001"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                </>
              ))}
              <span
                className="text-secondary fw-light text-decoration-underline"
                style={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => setAddQuestionForm(relation)}
              >
                Add Question
              </span>
              {addQuestionForm == relation && (
                <>
                  <Form.Group as={Row} controlId="question">
                    <Form.Label row>Question:</Form.Label>
                    <Row sm="9">
                      <Form.Control
                        type="text"
                        value={question}
                        onChange={(event) => setQuestion(event.target.value)}
                      />
                    </Row>
                  </Form.Group>

                  <Form.Group as={Row} controlId="questionAlias">
                    <Form.Label row>Question Alias:</Form.Label>
                    <Row sm="9">
                      <Form.Control
                        type="text"
                        value={questionAlias}
                        onChange={(event) =>
                          setQuestionAlias(event.target.value)
                        }
                      />
                    </Row>
                  </Form.Group>
                  <Button
                    style={{
                      width: "150px",
                      backgroundColor: "#3FACCB",
                      border: "none",
                      marginRight: "15px",
                      marginTop: "8px",
                    }}
                    onClick={() => setAddQuestionForm()}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      width: "150px",
                      backgroundColor: "#3FACCB",
                      border: "none",
                      marginRight: "15px",
                      marginTop: "8px",
                    }}
                    onClick={() => addQuestionInList(relation)}
                  >
                    Add
                  </Button>
                </>
              )}
            </ul>
          ))}
      </div>
      <br />
    </>
  );
};

export default QuestionsList;
