import React from "react";
import styles from "./RatingExtension.module.css";

const RatingExtension = ({ ratingExtension }) => {
  const renderStars = (starsCount) => {
    const starsCountToString = starsCount.toString();
    const starsCountParts = starsCountToString.split(".");

    let fullStars = Number(starsCountParts[0]);
    let partialStar = Number(starsCountParts[1]);

    return (
      <span className="rating-stars">
        {[...Array(5)].map((star, starIndex) => {
          if (fullStars > 0 || (fullStars === 0 && partialStar > 7)) {
            if (fullStars === 0 && partialStar > 7) {
              partialStar = 0;
            }
            if (fullStars > 0) {
              fullStars -= 1;
            }

            // render full star icon
            return <i key={starIndex} className={`${styles["rating-star"]} fa-solid fa-star`}></i>;
          }

          if (partialStar > 2) {
            partialStar = 0;

            // render half star icon
            return <i key={starIndex} className={`fa-solid ${styles["rating-star"]} fa-star-half-stroke`}></i>;
          }

          // render empty star icon
          return <i key={starIndex} className={`${styles["rating-star"]} fa-regular fa-star`}></i>;
        })}
      </span>
    );
  };

  return (
    <div>
      {renderStars(ratingExtension[0].title)}
      <span className={`${styles["rating"]} me-1`}>{ratingExtension[0].description}</span>
    </div>
  );
};

export default RatingExtension;
