import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const OpenChatGptPrompt = ({ showPrompt, setShowPrompt }) => {
  return (
    <Modal show={showPrompt} onHide={() => setShowPrompt(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Open ChatGPT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <a
            id="chat-gpt-link-with-chatId"
            href="https://chat.openai.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              setShowPrompt(false);
            }}
          >
            ChatGPT
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OpenChatGptPrompt;
