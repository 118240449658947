import React, { useEffect, useState } from "react";
import "./userCard.css";
import profile_pic from "../../../assets/profile-images/profile_pic.jpg";
import edit_avatar from "../../../assets/profile-images/edit_avatar.png";
import {
  sortSocialLinks,
  updateSocialLinks,
  updateUserData,
} from "../utility/userUtils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { newAlert } from "../../../slices/alertSlice";
import { useDispatch } from "react-redux";
import UserDescription from "../UserDescription/UserDescription";
import { Spinner } from "react-bootstrap";
import leetCodeIcon from "../../../assets/profile-images/leetcode.png";
import higgingFaceIcon from "../../../assets/profile-images/hugging_face.png";
import notionIcon from "../../../assets/profile-images/notion.png";
import tableauIcon from "../../../assets/profile-images/tableau.png";

function addHttpsToUrlIfNotExists(url) {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "https://" + url;
  }
  return url;
}

const modalBody = (
  socialMediaLinks,
  setSocialMediaLinks,
  errors,
  setErrors
) => {
  // console.log(socialMediaLinks);
  return (
    <div>
      {socialMediaLinks?.map((socialLink, index) => {
        return (
          <div key={index}>
            <div className="d-flex">
              <input
                type="text"
                className="form-control mt-3"
                onChange={(e) => {
                  const value = e.target.value;
                  let terror = { ...errors };
                  if (value?.trim() === "") {
                    terror[index] = "field should not be empty";
                  } else {
                    terror[index] = "";
                  }
                  setErrors(terror);
                  const updatedSocialLinks = [...socialMediaLinks];
                  updatedSocialLinks[index] = {
                    ...socialLink,
                    link: value,
                  };
                  setSocialMediaLinks(updatedSocialLinks);
                }}
                placeholder={`Add link for ${socialLink?.platform}`}
                value={socialLink?.link}
              />
              {socialLink?.platform !== "mail" ? (
                <i
                  className="fa fa-close cursor-pointor mt-3"
                  style={{ padding: "10px" }}
                  onClick={(e) => {
                    const updatedSocialLinks = socialMediaLinks.filter(
                      (link, i) => i !== index
                    );
                    setSocialMediaLinks(updatedSocialLinks);
                  }}
                ></i>
              ) : (
                <span style={{ padding: "16px" }}></span>
              )}
            </div>
            {errors[index] && (
              <div className="text-small text-danger">{errors[index]}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
const onModalSubmit = async (
  socialMediaLinks,
  userInfo,
  setUserInfo,
  dispatch,
  userId,
  errors
) => {
  // if (!validateEmail(socialMediaLinks.mail)) {
  //   dispatch(
  //     newAlert({
  //       type: "danger",
  //       message: "Please enter a valid email address",
  //     })
  //   );
  //   return;
  // }
  let hasError = false;
  for (const error in errors) {
    console.log({ error, hasError });
    if (errors[error] != "") {
      hasError = true;
    }
  }
  if (hasError) {
    dispatch(
      newAlert({
        type: "danger",
        message: "Failed to update social media links",
      })
    );
    return;
  }
  let socialMediaLinksWithoutMail = socialMediaLinks.filter(
    (link) => link.platform !== "mail"
  );
  await updateSocialLinks(socialMediaLinksWithoutMail).then((res) => {
    if (res && res.status === "success") {
      dispatch(
        newAlert({ type: "success", message: "Updated Social media links" })
      );
      let updatedUserInfo = { ...userInfo };
      socialMediaLinksWithoutMail = [
        ...socialMediaLinksWithoutMail,
        { platform: "mail", link: userInfo.email },
      ];
      // console.log({ socialMediaLinksWithoutMail, userInfo });
      updatedUserInfo.socialLinks = sortSocialLinks(
        socialMediaLinksWithoutMail
      );
      setUserInfo({ ...updatedUserInfo });
    } else {
      console.log({ res });
      dispatch(
        newAlert({
          type: "danger",
          message: "Failed to update social media links",
        })
      );
    }
  });
  // loadUserData(userId,setUserInfo)
  // console.log(udata);
};

const ShowModal = ({
  bodyContent,
  onModalSubmit,
  onModalClose = false,
  handleModal,
}) => {
  const handleClose = () => {
    if (onModalClose) onModalClose();
    handleModal.setShowModal(false);
  };
  const handleSubmit = () => {
    onModalSubmit();
    handleModal.setShowModal(false);
  };

  return (
    <Modal
      show={handleModal.showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="profile-modal-bg">
          {bodyContent}
          <div className="mt-3 d-flex justify-content-end">
            <Button
              style={{ backgroundColor: "rgb(239, 136, 140)", border: "none" }}
              className="me-2"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ backgroundColor: "rgb(7, 74, 117)", border: "none" }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const UserCard = ({
  userInfo,
  setUserInfo,
  loadingUserData,
  latestEducation,
}) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [location, setLocation] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const { userId } = useParams();
  const dispatch = useDispatch();
  // console.log(socialMediaLinks);

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    const MAX_FILE_SIZE = 2 * 1024;
    if (!file || file.size / 1024 > MAX_FILE_SIZE) {
      console.log(`File size exceeds ${MAX_FILE_SIZE}kb`);
      dispatch(
        newAlert({
          type: "danger",
          message: `File size exceeds ${Math.floor(MAX_FILE_SIZE / 1000)}mb`,
        })
      );
      return;
    }
    // console.log(file.size / 1024, MAX_FILE_SIZE)
    reader.onloadend = () => {
      setProfilePic(reader.result);
      updateUserData(userId, { profilePic: reader.result });
      // console.log(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const saveLocation = async () => {
    await updateUserData(userId, { location }).then((res) => {
      if (res && res.status === 200) {
        dispatch(
          newAlert({ type: "success", message: "Updated User Location" })
        );
        setUserInfo({ ...userInfo, location });
      } else {
        dispatch(
          newAlert({
            type: "danger",
            message: "Failed to update user location",
          })
        );
      }
    });
    // loadUserData(setUserInfo)
  };

  const openModal = () => {
    setShowModal(true);
  };
  const PlaceholderText = ({ value, placeholder, socialLinks = false }) => {
    value = value?.replace(/^(https?:\/\/)?(www\.)?/, "");
    return (
      <span
        className={`col-9 col-xl-10 ps-1 d-md-inline d-none ${
          !value ? "text-muted" : ""
        } ${socialLinks ? "px-0" : ""}`}
        style={{
          wordBreak: "break-word",
          marginLeft: socialLinks ? "-12px" : "0px",
          fontSize: "17px",
        }}
      >
        {value
          ? value.length > 20
            ? `${value?.slice(0, 20)}...`
            : value
          : placeholder}
      </span>
    );
  };

  const getBrandsIconByName = (brandName) => {
    switch (brandName?.toLowerCase()) {
      case "linkedin":
        return <i className="fab fa-linkedin col-1"></i>;
      case "facebook":
        return <i className="fab fa-facebook-f col-1"></i>;
      case "mail":
        return <i className="fa fa-envelope col-1"></i>;
      case "twitter":
        return <i className="fa-brands fa-x-twitter col-1"></i>;
      case "github":
        return <i className="fa-brands fa-github"></i>;
      case "youtube":
        return <i className="fa-brands fa-youtube"></i>;
      case "medium":
        return <i className="fa-brands fa-medium"></i>;
      case "hackerrank":
        return <i className="fa-brands fa-hackerrank"></i>;
      case "kaggle":
        return <i className="fa-brands fa-kaggle"></i>;
      case "leetcode":
        return <img src={leetCodeIcon} alt="leetcode" width={20} />;
      case "tableau":
        return <img src={tableauIcon} alt="leetcode" width={20} />;
      case "notion":
        return <img src={notionIcon} alt="leetcode" width={20} />;
      case "huggingface":
        return <img src={higgingFaceIcon} alt="leetcode" width={20} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    // console.log(userInfo.socialLinks);
    const pic = userInfo.profilePic;
    if (pic) setProfilePic(pic);
    setSocialMediaLinks(userInfo.socialLinks);
    setLocation(userInfo.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  return (
    <>
      <div className="my-4 pt-2 mt-md-5">
        <ShowModal
          bodyContent={modalBody(
            socialMediaLinks,
            setSocialMediaLinks,
            errors,
            setErrors
          )}
          onModalSubmit={() => {
            onModalSubmit(
              socialMediaLinks,
              userInfo,
              setUserInfo,
              dispatch,
              userId,
              errors
            );
          }}
          handleModal={{ showModal, setShowModal }}
        />
        <div className=" profilepic-parent">
          <img
            src={profilePic === "" ? profile_pic : profilePic}
            // style={{ maxWidth: "285px" }}
            alt="profile_pic"
            className="profilepic-bg"
          />
          <div className="d-flex bg-light profilepic-fg">
            <label
              htmlFor="photo-upload"
              style={{ cursor: "pointer", display: "flex" }}
            >
              <img
                src={edit_avatar}
                style={{ maxWidth: "70px" }}
                alt="edit_avatar"
                className=""
              />
              <input
                id="photo-upload"
                type="file"
                accept="image/*"
                onChange={photoUpload}
              />
              <span className="mt-3">Edit Avatar</span>
            </label>
          </div>
        </div>

        <div className="d-md-none" style={{ marginTop: "32px" }}>
          <UserDescription
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            latestEducation={latestEducation}
          />
        </div>
        <div className="d-flex justify-content-between">
          <span className="pt-2 fw-bold mt-5 mt-md-3 mb-1">Location:</span>
          {userInfo.location !== location && (
            <button
              className="btn btn-white border-bottom text-primary"
              onClick={saveLocation}
            >
              Save
            </button>
          )}
        </div>

        <div className="col-12 placeholder-glow">
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className={`profilepic-location w-100 ${
              loadingUserData ? "placeholder" : ""
            }`}
            style={
              loadingUserData ? { opacity: 0.3, borderRadius: "10px" } : {}
            }
            placeholder="Enter your Location"
            aria-label="Username"
          />
        </div>

        <div>
          <div className="d-flex justify-content-between mt-3 mb-2 fw-bold">
            <span>Social:</span>
            <span
              data-tooltip="Edit Social URLS"
              data-tooltip-location="bottom"
            >
              <i className="far fa-edit ms-2" onClick={openModal}></i>
            </span>
          </div>
          {!loadingUserData ? (
            <>
              <ul
                className={`socialmedia-links`}
                style={{ paddingLeft: "0px", listStyle: "none" }}
              >
                {userInfo.socialLinks?.map((socialLink, index) => {
                  return (
                    <li
                      className={`d-md-block d-inline me-3 fs-18`}
                      key={index}
                    >
                      <a
                        href={`${addHttpsToUrlIfNotExists(socialLink?.link)}`}
                        target="_blank"
                        rel="noreferrer"
                        className="row d-md-flex d-inline pe-0 pt-1 text-decoration-none text-black"
                      >
                        <span
                          className="col-3 col-xl-2"
                          style={{ color: "#064B74" }}
                        >
                          {getBrandsIconByName(socialLink?.platform)}
                        </span>
                        <PlaceholderText
                          value={addHttpsToUrlIfNotExists(socialLink?.link)}
                          placeholder={`add ${socialLink?.platform || ""} Link`}
                          socialLinks={true}
                        />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <div className="text-center">
              <Spinner variant="secondary" />
            </div>
          )}
        </div>
      </div>
      <hr className="d-md-none" style={{ marginTop: "38px" }} />
    </>
  );
};
