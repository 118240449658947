import React from "react";
import FacebookAd from "../../Ad/FacebookAd/FacebookAd";
import GoogleSearchAd from "../../Ad/GoogleSearchAd/GoogleSearchAd";
import { limitHeadline_headlineSlug_redirectToAdPage } from "../../../containers/SearchResults/SearchResults";

const AdCard = ({
  ad,
  ads,
  i,
  adMovedIndex,
  setAdMovedIndex,
  updateAdsArray_sendToBackend,
  box,
}) => {
  let isSaved = false;
  let isIrrelevantAd = false;
  let isUpvoted = false;
  let isDownvoted = false;
  const query = "Test Query";
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      className={box}
      onDrop={(_) => updateAdsArray_sendToBackend(ads, adMovedIndex, i)}
      onClick={(e) => {
        if (ad.type == "fbAd")
          limitHeadline_headlineSlug_redirectToAdPage(
            ad.adDetails[0].headline.title,
            ad.organization[0].name,
            ad._id,
            e
          );
        if (ad.type == "searchAd")
          limitHeadline_headlineSlug_redirectToAdPage(
            ad.headline.title,
            ad.organization[0].name,
            ad._id,
            e
          );
        if (ad.type == "displayAd")
          limitHeadline_headlineSlug_redirectToAdPage(
            ad.adDetails[0].headline.title,
            ad.organization[0].name,
            ad._id,
            e
          );
      }}
    >
      <div draggable="true" onDragStart={(_) => setAdMovedIndex(i)}>
        {ad.type === "fbAd" && (
          <FacebookAd
            ad={ad}
            query={query}
            isSaved={isSaved}
            isIrrelevantAd={isIrrelevantAd}
            isUpvoted={isUpvoted}
            isDownvoted={isDownvoted}
          />
        )}
        {ad.type === "searchAd" && (
          <GoogleSearchAd
            ad={ad}
            query={query}
            isSaved={isSaved}
            isIrrelevantAd={isIrrelevantAd}
            isUpvoted={isUpvoted}
            isDownvoted={isDownvoted}
          />
        )}
      </div>
    </div>
  );
};

export default AdCard;
