import React from 'react'
import './Shimmer.css'
import Masonry from "react-masonry-component";

const Shimmer = () => {
    return (
        <div className="shimmer-container">
            <div className="shimmer">
                <Masonry>
                    {
                        Array(20).fill("").map((e, i) => (
                            i % 2 == 0 ? <div key={i} className="shimmer-card card shadow">
                                <div className='shimmer-card-url-btn'>
                                    <p></p>
                                    <div></div>
                                </div>
                                <div className='shimmer-card-title'></div>
                                <div className='shimmer-card-description'>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className='shimmer-card-links'>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                            </div> : <div className="shimmer-fb-card card shadow">
                                <div className='shimmer-card-url-btn'>
                                    <p></p>
                                    <div></div>
                                </div>
                                <div className='shimmer-card-description'>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className='shimmer-card-image'>
                                    <div></div>
                                </div>
                                <div className='shimmer-card-url-btn'>
                                    <p></p>
                                    <div></div>
                                </div>
                            </div>
                        ))
                    }
                </Masonry>
            </div>
        </div>
    )
}

export default Shimmer