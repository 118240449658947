import React from "react";
import styles from "./PriceExtension.module.css";

const PriceExtension = ({ priceExtension }) => {
  const [firstPriceItem, ...morePriceItems] = priceExtension;
  return (
    <div className="d-flex justify-content-between">
      <div className="first-price-item">
        <a href={firstPriceItem.redirectedUrl} target="_blank" rel="noreferrer" className={`${styles["priceItem-title"]}`}>
          {firstPriceItem.title}
        </a>
        <span className={`${styles["priceItem-price"]}`}> - {firstPriceItem.price.price}</span>
        <p className={`${styles["priceItem-description"]} m-0`}>{firstPriceItem.description} </p>
      </div>
      <div className="more-price-items">
        <span className="dropdown-toggle" href="#" id="moreItemsDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          More Items
        </span>
        <div className="dropdown-menu p-4 px-4 shadow-sm mt-1" aria-labelledby="moreItemsDropdownLink">
          {morePriceItems.map((priceItem) => {
            return (
              <div className="mb-2" key={priceItem.title}>
                <a href={priceItem.redirectedUrl} target="_blank" rel="noreferrer" className={`${styles["priceItem-title"]}`}>
                  {priceItem.title}
                </a>
                <span className={`${styles["priceItem-price"]}`}> - {priceItem.price.price}</span>
                <p className={`${styles["priceItem-description"]} m-0`}>{priceItem.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PriceExtension;
