import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import calenderImage from "../../../assets/profile-images/calender.svg";
import "./PaymentPreferenceSave.css";

function PaymentPreferenceSave() {
  return (
    <div style={{ height: "100dvh" }}>
      <Container className="d-flex flex-column align-items-center">
        <div className="getPaid">
          <Row style={{ textAlign: "center", marginTop: "20px" }}>
            <Col>
              <img
                style={{ height: "221px", width: "203px", marginLeft: "40px" }}
                src={calenderImage}
                alt="calenderImage"
              />
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "20px",
                }}
              >
                Thank you for sharing your preference.
              </h1>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              textAlign: "center",
              marginTop: "30px",
              fontSize: "20px",
            }}
          >
            <h5>We will provide you with updates on the test details</h5>
            <h5>within 4-5 days.</h5>
          </Row>
          {/* <Row
            style={{
              textAlign: "center",
              marginTop: "10px",
              position: "relative",
              top: "-30px",
              justifyContent: "center",
            }}
          > */}
          {/* <Button
          style={{ width: "100px" }}
          variant="danger"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </Button> */}
          {/* </Row> */}
        </div>
      </Container>
    </div>
  );
}

export default PaymentPreferenceSave;
