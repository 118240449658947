import React, { useEffect, useState } from "react";
import AdCard from "../AdCard/AdCard";
import "./AdsGrid.css";
import styles from "../../../containers/SearchResults/SearchResults.module.css";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { getSavedAds } from "../../../slices/savedAdsSlice";
import { getIrrelevantAds } from "../../../slices/reportAdsSlice";
import { getVotes } from "../../../slices/votingsSlice";
//! import adsDataTemp from '../config/ads.json';
import Masonry from "react-masonry-component";
import { newAlert } from "../../../slices/alertSlice";

const AdsGrid = ({ ads, setAds }) => {
  const [adMovedIndex, setAdMovedIndex] = useState("");
  const { searchTermId } = useParams();
  const dispatch = useDispatch();

  const updateAdsArray_sendToBackend = async (
    ads,
    adMovedIndex,
    adBelowIndex
  ) => {
    const newAdsArray = Array.from(ads);
    // update rankingScore of adsMoved
    if (adMovedIndex < adBelowIndex) {
      const adBelowRankingScore = newAdsArray[adBelowIndex].rankingScore;
      // Reverse loop from adBelowIndex till adMovedIndex - 1 over newAdsArray
      for (let i = adBelowIndex; i > adMovedIndex; i--) {
        newAdsArray[i].rankingScore = newAdsArray[i - 1].rankingScore;
      }
      newAdsArray[adMovedIndex].rankingScore = adBelowRankingScore;
    } else if (adMovedIndex > adBelowIndex) {
      const adBelowRankingScore = newAdsArray[adBelowIndex].rankingScore;
      // loop from adBelowIndex till adMovedIndex - 1 over newAdsArray
      for (let i = adBelowIndex; i < adMovedIndex; i++) {
        newAdsArray[i].rankingScore = newAdsArray[i + 1].rankingScore;
      }
      newAdsArray[adMovedIndex].rankingScore = adBelowRankingScore;
    } else return;
    // update position of ads moved
    const adMoved = newAdsArray.splice(adMovedIndex, 1);
    newAdsArray.splice(adBelowIndex, 0, ...adMoved);
    const updatedArray = await axiosInstance
      .put(`/api/admin/update-ads-ranking/${parseInt(searchTermId)}`, {
        newAdsArray,
        adMovedIndex,
        adBelowIndex,
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(newAlert({ type: "danger", message: "Ad Move Failed!!" }));
      });
    if (updatedArray.status === 200) {
      setAds(newAdsArray);
      dispatch(newAlert({ type: "success", message: "Ad Move success!!" }));
    } else {
      dispatch(newAlert({ type: "danger", message: "Ad Move Failed!!" }));
    }
  };

  //ONLY FOR TESTING PURPOSE
  const savedAds = useSelector((state) => state.savedAdsSlice.savedAds);
  const reportAds = useSelector((state) => state.reportAdsSlice.reportAds);
  const votes = useSelector((state) => state.votingsSlice.votes);
  const query = "Test Query";

  useEffect(() => {
    dispatch(getSavedAds());
    dispatch(getIrrelevantAds());
    dispatch(getVotes());
  }, []);
  //ONLY FOR TESTING PURPOSE END

  return (
    <div className={styles.grid}>
      <Masonry>
        {ads &&
          ads.map((ad, i) => {
            //ONLY FOR TESTING PURPOSE
            let isSaved = false;
            let isIrrelevantAd = false;
            let isUpvoted = false;
            let isDownvoted = false;

            if (savedAds && savedAds.find((e) => e.ad._id === ad._id)) {
              isSaved = true;
            }

            if (reportAds && reportAds.find((e) => e.ad === ad._id)) {
              isIrrelevantAd = true;
            }

            let checkVote = votes && votes.find((e) => e.ad === ad._id);
            if (checkVote && checkVote.voteType === "upvote") {
              isUpvoted = true;
            } else if (checkVote && checkVote.voteType === "downvote") {
              isDownvoted = true;
            }
            //ONLY FOR TESTING PURPOSE END

            return (
              <AdCard
                ad={ad}
                i={i}
                ads={ads}
                key={i}
                adMovedIndex={adMovedIndex}
                box={styles.box}
                setAdMovedIndex={setAdMovedIndex}
                updateAdsArray_sendToBackend={updateAdsArray_sendToBackend}
              />
            );
          })}
      </Masonry>
    </div>
  );
};

export default AdsGrid;
