import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleSearchAd from "../../components/Ad/GoogleSearchAd/GoogleSearchAd";
import FacebookAd from "../../components/Ad/FacebookAd/FacebookAd";
import DisplayAd from "../../components/Ad/DisplayAd/DisplayAd";
import styles from "./SearchResults.module.css";
// import { getSavedAds } from "../../slices/savedAdsSlice";
// import { getIrrelevantAds } from "../../slices/reportAdsSlice";
// import { getVotes } from "../../slices/votingsSlice";
import InfiniteScroll from "react-infinite-scroller";
import { getAdsForAdvancedSearch, getAdsForSearchTerm } from "../../slices/adSlice";
import { useState } from "react";
import { defaultSearchTerm } from "../../config";
import Masonry from "react-masonry-component";

export const limitHeadline_headlineSlug_redirectToAdPage = (headline, orgName, adId, e) => {
  if (!e?.target?.closest(".exclude-buttons") && !e?.target?.closest(".carousel-control-next-icon") && !e?.target?.closest(".carousel-control-prev-icon")) {
    const headlineSlug = headline?.split(/[\s,./-]+/).slice(0, 20).join(' ').trim().replace(/[^a-zA-Z0-9-]/g, '-').split('-').filter(x => x != '').join('-');
    const orgNameSlug = orgName?.split(/[\s,./-]+/).slice(0, 20).join(' ').trim().replace(/[^a-zA-Z0-9-]/g, '-').split('-').filter(x => x != '').join('-');
    return window.open(`/${headlineSlug ? headlineSlug : " "}/${orgNameSlug ? orgNameSlug : " "}/${adId}`, '_blank');
  }
}

const SearchResults = ({ searchTerm, adType, industryType, isAdvancedSearchOn, pageNumber, setPageNumber, setLoader, setSearchTerm }) => {
  const adsForSearchTerm = useSelector((state) => state.adsSlice.adsForSearchTerm);
  const isLoading = useSelector((state) => state.adsSlice.loading)
  const gotMoreAds = useSelector((state) => state.adsSlice.gotMoreAds)
  const dispatch = useDispatch();

  //ONLY FOR TESTING PURPOSE
  const savedAds = useSelector((state) => state.savedAdsSlice.savedAds);
  const reportAds = useSelector((state) => state.reportAdsSlice.reportAds);
  const votes = useSelector((state) => state.votingsSlice.votes);
  const query = "Test Query";
  useEffect(() => {
    // dispatch(getSavedAds());
    // dispatch(getIrrelevantAds());
    // dispatch(getVotes());
  }, []);
  //ONLY FOR TESTING PURPOSE END

  const loadMoreAds = () => {
    if (!isLoading) {
      if (adType != "All types") {
        // dispatch(getAdsForAdvancedSearch({ searchTerm: (searchTerm ? searchTerm : defaultSearchTerm), adType, industryType, 'pageNumber': (parseInt(pageNumber) + 1) }))
        // window.history.pushState({}, "", `?st=${searchTerm ? searchTerm : defaultSearchTerm}&p=${parseInt(pageNumber) + 1}&ind_t=${industryType}&ad_t=${adType}`);
        window.history.pushState({}, "", `?st=${searchTerm ? searchTerm : defaultSearchTerm}&p=${parseInt(pageNumber) + 1}&ad_t=${adType}`);
        dispatch(getAdsForAdvancedSearch({ searchTerm: (searchTerm ? searchTerm : defaultSearchTerm), adType, 'pageNumber': (parseInt(pageNumber) + 1), setLoader }))
      }
      else if (adType == "All types") {
        window.history.pushState({}, "", `?st=${searchTerm ? searchTerm : defaultSearchTerm}&p=${parseInt(pageNumber) + 1}`);
        dispatch(getAdsForSearchTerm({ searchTerm: (searchTerm ? searchTerm : defaultSearchTerm), 'pageNumber': (parseInt(pageNumber) + 1), setLoader }))
      }
      setPageNumber(prev => parseInt(prev) + 1)
      if (searchTerm === undefined) setSearchTerm(defaultSearchTerm);
    }
  }

  return (
    <>
      <div className={styles.container} id="results">
        <InfiniteScroll
          // loadMore={() => loadMoreAds()}
          // hasMore={gotMoreAds}
          loadMore={() => {}}
          hasMore={false}
          threshold={300}
          loader={<p className="text-center m-0 p-0 fst-italic fw-light">Loading more ads...</p>}
        >
          {adsForSearchTerm && (
            <div className={styles.grid}>
              <Masonry>
                {adsForSearchTerm.map((ad, i) => {
                  //ONLY FOR TESTING PURPOSE
                  let isSaved = false;
                  let isIrrelevantAd = false;
                  let isUpvoted = false;
                  let isDownvoted = false;

                  if (savedAds && savedAds.find((e) => e.ad._id === ad._id)) {
                    isSaved = true;
                  }

                  if (reportAds && reportAds.find((e) => e.ad === ad._id)) {
                    isIrrelevantAd = true;
                  }

                  let checkVote = votes && votes.find((e) => e.ad === ad._id);
                  if (checkVote && checkVote.voteType === "upvote") {
                    isUpvoted = true;
                  } else if (checkVote && checkVote.voteType === "downvote") {
                    isDownvoted = true;
                  }
                  //ONLY FOR TESTING PURPOSE END

                  if (ad.type === "displayAd") {
                    return (
                      <div key={i} className={styles.box} onClick={(e) => limitHeadline_headlineSlug_redirectToAdPage(ad.adDetails[0].headline?.title, ad.organization[0].name, ad._id, e)}>
                        <DisplayAd ad={ad} query={query} isSaved={isSaved} isIrrelevantAd={isIrrelevantAd} isUpvoted={isUpvoted} isDownvoted={isDownvoted} />
                      </div>
                    );
                  } else if (ad.type === "searchAd") {
                    return (
                      <div key={i} className={styles.box} onClick={(e) => limitHeadline_headlineSlug_redirectToAdPage(ad.headline?.title, ad.organization[0].name, ad._id, e)}>
                        <GoogleSearchAd ad={ad} query={query} isSaved={isSaved} isIrrelevantAd={isIrrelevantAd} isUpvoted={isUpvoted} isDownvoted={isDownvoted} />
                      </div>
                    );
                  } else if (ad.type === "fbAd") {
                    return (
                      <div key={i} className={styles.box} onClick={(e) => limitHeadline_headlineSlug_redirectToAdPage(ad.adDetails[0].headline?.title, ad.organization[0].name, ad._id, e)}>
                        <FacebookAd ad={ad} query={query} isSaved={isSaved} isIrrelevantAd={isIrrelevantAd} isUpvoted={isUpvoted} isDownvoted={isDownvoted} />
                      </div>
                    );
                  }
                })}
              </Masonry>
            </div>
          )}
        </InfiniteScroll>
      </div>
      {/* {!gotMoreAds && searchTerm && <p className="text-center m-0 p-0 fst-italic fw-light">No more results</p>} */}
    </>
  );
};

export default SearchResults;
