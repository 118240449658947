import React from "react";

const Arrow = ({ level, type }) => {
  let arrow = "";
  for (let i = 1; i <= level; i++) {
    if (i === level) {
      if (type === "relation") {
        arrow += " ↴";
      } else {
        arrow += "→";
      }
    } else {
      arrow += "→";
    }
  }
  return <p style={{ marginRight: "5px", fontSize: "15px" }}>{arrow}</p>;
};

export default Arrow;
