import React, { useEffect, useState } from "react";
import companylogo from "../../../assets/profile-images/companylogo.png";
import { MySlider } from "./MySlider";
import { useDispatch, useSelector } from "react-redux";
import {
  openEditExp,
  unsetFirstTimeLoad,
} from "../../../slices/profileSlices/openTabSlice";
import WorkPeriod from "./subComp/WorkPeriod";


const getMessageWithFaIcon = (message, faIconPosition) => {
  return (
    <span>
      {message}
      <i
        className={`fa-solid fa-chevron-${
          faIconPosition === "up" ? "up" : "down"
        }`}
      ></i>
    </span>
  );
};

// for displaying data of skills and tools
export const TimeBar = ({
  timebarData,
  index,
  showAllTB,
  setShowAllTB,
  maxSkillsToShow = 3,
  type,
}) => {
  const showTB = type === 1 ? showAllTB.skills : showAllTB.tools;
  let showLessMsg = type === 1 ? "Show Less Skills " : "Show Less Tools ";
  showLessMsg = getMessageWithFaIcon(showLessMsg, "up");
  let showMoreMsg = type === 1 ? "Show More Skills " : "Show More Tools ";
  showMoreMsg = getMessageWithFaIcon(showMoreMsg, "down");
  const setShowTB = (value) => {
    // for showing all timebars and few timebar
    type === 1
      ? setShowAllTB({ ...showAllTB, skills: value })
      : setShowAllTB({ ...showAllTB, tools: value });
  };
  return (
    <div className="row">
      <div className={`col-lg-3 col-md-8 col-3 pe-0 my-auto fs-responsive`}>
        <div
          className={`fw-lite d-flex justify-content-end ${
            timebarData.length > maxSkillsToShow &&
            index === maxSkillsToShow - 1 &&
            !showTB
              ? "opac-text"
              : ""
          }`}
        >
          <span
            data-tooltip={timebarData[index].name?.label}
            data-tooltip-location="left"
            style={{ fontSize: "14px" }}
            className="text-line-epipsis"
          >
            {timebarData[index].name?.label}
          </span>
        </div>
      </div>
      <div className="col-lg-7 col-md-11 col-7 my-auto">
        {/* {console.log(timebarData.length , index, showTB)} */}
        {timebarData.length > maxSkillsToShow &&
        index === maxSkillsToShow - 1 &&
        !showTB ? (
          <button
            className={`show-btn-close fs-responsive`}
            onClick={(e) => {
              setShowTB(true);
            }}
          >
            {showTB ? showLessMsg : showMoreMsg}
          </button>
        ) : (
          <MySlider timebarData={timebarData[index]} count={index} />
        )}
      </div>
      <div
        className={`col-lg-2 col-md-4 col-2 px-0  my-auto ${
          timebarData.length > maxSkillsToShow &&
          index === maxSkillsToShow - 1 &&
          !showTB
            ? "opac-text"
            : ""
        } fs-responsive`}
      >
        <span
          className={`fw-lite`}
          style={{ fontSize: "13px", marginLeft: "2px" }}
        >{`${parseFloat(timebarData[index].value).toFixed(2)}`}</span>
      </div>
    </div>
  );
};
// for displaying data of skills and tools
export const ShowBtnData = ({ workData, showAllTB, setShowAllTB, type }) => {
  const showTB = type === 1 ? showAllTB.skills : showAllTB.tools;
  let showLessMsg = type === 1 ? "Show Less Skills " : "Show Less Tools ";
  showLessMsg = getMessageWithFaIcon(showLessMsg, "up");
  let showMoreMsg = type === 1 ? "Show More Skills " : "Show More Tools ";
  showMoreMsg = getMessageWithFaIcon(showMoreMsg, "down");
  const setShowTB = (value) => {
    type === 1
      ? setShowAllTB({ ...showAllTB, skills: value })
      : setShowAllTB({ ...showAllTB, tools: value });
  };
  // console.log(showAllTB,type);
  return (
    <div className="row">
      <div className="col-lg-3 col-md-8 col-3 pe-0"></div>
      <div className="col-lg-7 col-md-11 col-7">
        <div className="text-center">
          <button
            className="show-btn-open"
            onClick={(e) => {
              setShowTB(false);
            }}
          >
            {showTB ? showLessMsg : showMoreMsg}
          </button>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-2 px-0">
        <span className=""></span>
      </div>
    </div>
  );
};
export const SavedExperience = ({ workData, index, fFirstTimeLoad }) => {
  // to show only top 3 skills and tools
  // console.log(workData);
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const [showAllTB, setShowAllTB] = useState({ skills: false, tools: false });
  const fProjectWithSkills =
    sessionUser.user?.profileType === "mern" &&
    sessionUser.user?.experienceLevel === "fresher";
  useEffect(() => {
    if (workData && index === 0 && fFirstTimeLoad && !fProjectWithSkills) {
      dispatch(unsetFirstTimeLoad());
      dispatch(
        openEditExp({ value1: false, value2: true, mode: "update", index })
      );
    }
  }, []);
  const dispatch = useDispatch();
  if (!workData) {
    return <></>;
  }
  const timePeriod = workData.timePeriod;
  const currWorking = workData.currWorking;
  const skills = [...workData?.skills]?.sort((a, b) => {
    return b.value - a.value;
  });
  // const tools = [...workData?.tools].sort((a, b) => {
  //   return b.value - a.value;
  // });

  const verticalDashedBorderStyle = {
    opacity: "0.1",
    borderLeft: "2px dashed black",
    height: "15px",
  };
  const Labels = () => {
    return (
      <div className="row mt-3" style={{ fontSize: "13px" }}>
        <div className="col-lg-3 col-md-8 col-3" />
        <div className="d-flex justify-content-between col-lg-7 col-md-11 col-7">
          <span className={``} style={{ position: "relative", left: "-26px" }}>
            Very Low
          </span>
          <span className={`pt-3 pt-md-0`}>Moderate</span>
          <span
            className={`text-label-right`}
            // style={{ position: "relative", left: "26px" }}
          >
            Very High
          </span>
        </div>
        <div className="col-lg-2 col-md-4" />
        <div className="col-lg-3 col-md-8 col-3" />
        <div className="d-flex justify-content-between col-lg-7 col-md-11 col-7">
          <span style={verticalDashedBorderStyle}></span>
          <span className={``}>Low</span>
          <span style={verticalDashedBorderStyle}></span>
          <span className={``}>High</span>
          <span style={verticalDashedBorderStyle}></span>
        </div>
        <div className="col-lg-2 col-md-4" />
      </div>
    );
  };

  const HorizontalBar = ({ teamContribution }) => {
    // console.log(teamContribution);
    return (
      <>
        <div className="row mt-4">
          <span className="col-1"></span>
          <div className="d-flex contribution-label justify-content-between col-lg-10 col-md-11 col-7">
            <span
              className="label-text ps-2"
              // style={{ position: "relative", left: "-56px" }}
            >
              Individual Contribution
            </span>
            <span className="label-text"></span>
            <span
              className="label-text"
              // style={{ position: "relative", left: "56px" }}
            >
              Team Management
            </span>
          </div>
          <span className="col-1"></span>
          <div className="col-lg-3 col-md-8 col-3" />
          <div className="d-flex justify-content-between col-lg-7 col-md-11 col-7">
            <span style={verticalDashedBorderStyle}></span>
            <span style={verticalDashedBorderStyle}></span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-8 col-3 text-end fw-500">
            {teamContribution.value}%
          </div>
          <div className="col-lg-7 col-md-11 col-7">
            <MySlider
              timebarData={teamContribution}
              isContributionSlider={true}
            />
          </div>
          <span className="col-lg-2 col-md-4 col-2 p-0 w-auto fw-500">
            {100 - teamContribution.value + "%"}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="card p-4 bg-light">
      <div className="row">
        <div className="col-lg-6 col-md-11 col-12">
          <div className="d-flex">
            <div className="orgpic-parent">
              <img
                src={workData.orgPic ? workData.orgPic : companylogo}
                style={{ maxHeight: "80px" }}
                alt="companylogo"
                className="orgpic-work orgpic-bg"
              />
            </div>

            <div className="ms-2">
              <div className="h4">
                {workData.orgInfo && workData.orgInfo.name}
              </div>
              <div className="h4" style={{fontSize:"18px"}}>
                {workData.orgInfo && workData.orgInfo.position}
              </div>
            </div>
          </div>
          {workData.timePeriod.startYear && (
            <WorkPeriod timePeriod={timePeriod} currWorking={currWorking} />
          )}
          {workData.workDescription && (
            <div
              className="mt-2 pt-2"
              style={{ textAlign: "justify", fontSize: "15px" }}
            >
              {workData.workDescription}
            </div>
          )}
        </div>

        <div className="col-lg-6 col-md-11 col-12">
          <div className="row">
            <div className="text-center ps-3 mb-3" style={{ fontSize: "13px" }}>
              <div className="fw-bold mt-2 mt-0">
                Average Time Spent per Week
              </div>
              <div className="fw-bold">
                (Assume you work for 45 hrs per week)
              </div>
            </div>
            <div className="col-12">
              {/* {skills && skills.length > 0 && <Labels />} */}
              {skills && skills.length > 0 ? (
                skills
                  .slice(0, showAllTB.skills ? skills.length : 3)
                  .map((item, index) => {
                    return (
                      <TimeBar
                        key={index}
                        index={index}
                        timebarData={skills}
                        showAllTB={showAllTB}
                        setShowAllTB={setShowAllTB}
                        type={1}
                      />
                    );
                  })
              ) : (
                <div className="text-center">No Skills added yet</div>
              )}
            </div>

            {/* {skills && skills.length > 0 && (
              <div className="d-md-block d-none col-2 my-auto ps-0">
                Time Spent on{" "}
                <span className="fw-bold">
                  {sessionUser.user?.profileType === "marketing"
                    ? "subRoles"
                    : "skills"}
                </span>
              </div>
            )} */}
          </div>

          {showAllTB.skills && (
            <div className="row">
              <div className="col-12 fs-responsive">
                <ShowBtnData
                  workData={workData}
                  showAllTB={showAllTB}
                  setShowAllTB={setShowAllTB}
                  type={1}
                />
              </div>
            </div>
          )}

          {/* {skills && skills.length > 0 && (
            <div className="d-md-none d-block col-12 text-center mt-2 my-auto ps-0">
              Time Spent on{" "}
              <span className="fw-bold">
                {sessionUser.user?.profileType === "marketing"
                  ? "subRoles"
                  : "skills"}
              </span>
            </div>
          )} */}

          {/* <div className="row mt-3">
            <div className="col-md-10 col-12">
              {tools && tools.length > 0 && <Labels />}
              {tools && tools.length > 0 ? (
                tools
                  .slice(0, showAllTB.tools ? tools.length : 3)
                  .map((item, index) => {
                    return (
                      <TimeBar
                        key={index}
                        index={index}
                        timebarData={tools}
                        showAllTB={showAllTB}
                        setShowAllTB={setShowAllTB}
                        type={2}
                      />
                    );
                  })
              ) : (
                <div className="text-center">No Tools/Software added yet</div>
              )}
            </div>

            {tools && tools.length > 0 && (
              <div className="d-md-block d-none col-md-2 col-12 my-auto ps-0">
                Time Spent on{" "}
                <span className="fw-bold">
                  {sessionUser.user?.profileType === "marketing"
                    ? "skills"
                    : "tools"}
                </span>
              </div>
            )}
          </div>

          {showAllTB.tools && (
            <div className="row">
              <div className="col-md-10 col-12 fs-responsive">
                <ShowBtnData
                  workData={workData}
                  showAllTB={showAllTB}
                  setShowAllTB={setShowAllTB}
                  type={2}
                />
              </div>
            </div>
          )}

          {tools && tools.length > 0 && (
            <div className="d-md-none d-block col-12 text-center mt-2 my-auto ps-0">
              Time Spent on{" "}
              <span className="fw-bold">
                {sessionUser.user?.profileType === "marketing"
                  ? "skills"
                  : "tools"}
              </span>
            </div>
          )} */}

          <div className="row mt-3">
            <div className="col-12">
              <HorizontalBar
                teamContribution={{ value: workData.teamContribution }}
              />
            </div>
          </div>
        </div>
      </div>
      <a
        href="#work-details"
        onClick={() => {
          dispatch(
            openEditExp({ value1: false, value2: true, mode: "update", index })
          );
        }}
      >
        <i className="far fa-edit work-edit"></i>
      </a>
    </div>
  );
};
