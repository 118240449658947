import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { adminSignIn } from '../../slices/sessionSlice';
import styles from "./AdminSignIn.module.css";
import { getBackendDomain } from '../../helpers/stubs';
const AdminSignIn = () => {
   
    const [modalShow, setModalShow] = useState(false);
  const [modalstate, setmodalstate] = useState("otpModal");
  const backendURL = getBackendDomain();
  const [searchParams] = useSearchParams();
  const { error: signInError, sessionUser } = useSelector((state) => state.sessionSlice);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const setFormField = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormField(name, value);
  };
  const handlePasswordVisibleToggle = () => {
    setPasswordVisible((previous) => !previous);
  };


  const validateForm = (formValues) => {
    const errors = {};
    const { email, password } = formValues;

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      errors.email = "Please enter a valid email";
    }
    if (!email) {
      errors.email = "Please enter your email";
    }
    if (!password) {
      errors.password = "Please enter your password";
    }

    return errors;
  };

  const handleAdminSignIn = (e) => {
    console.log("Submit")
    e.preventDefault();
    setIsSubmitted(true);

    const errors = validateForm(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);

      return;
    }

    dispatch(adminSignIn(formValues));
  };

  // redirect on successful sign-in
  if (sessionUser?.admin) {
    const redirectTo = searchParams.get("ref") || "/";
    return <Navigate to={redirectTo} replace />;
  }

  return (
    <div className="signin p-5 shadow border rounded">
    {/* <i className={`${styles["fa-bolt"]} fa-bolt fa-solid mb-3 fs-2`} />
    <h2 className="fw-bold">Sign In</h2>
    <p className="mb-4">Build marketing strategies by knowing your competitors better</p>
    <div className="socials-sign d-flex flex-column mb-2 flex-lg-row">
      <a className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`} href={`${backendURL}/api/session/sign-in/google?redirectTo=/`}>
        <img src={GoogleLogo} className={`${styles["social-sign-logo"]}`} alt="google logo" />
        <span className="ms-2">Sign in with Google</span>
      </a>
      <a className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`} href={`${backendURL}/api/session/sign-in/facebook?redirectTo=/`}>
        <img src={FacebookLogo} className={`${styles["social-sign-logo"]}`} alt="google logo" />
        <span className="ms-2">Sign in with Facebook</span>
      </a>
      <a className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`} href={`${backendURL}/api/session/sign-in/linked-in?redirectTo=/`}>
        <img src={LinkedinLogo} className={`${styles["social-sign-logo"]}`} alt="google logo" />
        <span className="ms-2">Sign in with Linkedin</span>
      </a>
    </div>
    <div className={`${styles["strike"]} mb-3`}>
      <span className={`${styles["strike-span"]}`}>or Sign In with email</span>
    </div> */}

    <form 
    onSubmit={handleAdminSignIn} 
    noValidate>
      {signInError ? (
        <p className={`${styles["formError"]} font-semibold p-3 px-3 m-0 bg-danger text-white mb-3 rounded`}>
          <i className="fa-solid fa-circle-exclamation pe-2"></i>
          {signInError}
        </p>
      ) : null}
        <h3 className='text-center'>Admin Sign-In</h3>
      <div className="mb-3">
        <label htmlFor="email" className="form-label font-semibold">
          Enter your email
        </label>
        <input type="email" className={`${styles["formInput"]} form-control ${formErrors.email ? "is-invalid" : ""}`} id="email" name="email" 
        value={formValues.email} 
        onChange={handleInputChange}  
        />
        <div className="invalid-feedback"> {formErrors.email} </div>
      </div>
      <div className="mb-3 position-relative">
        <label htmlFor="password" className="form-label font-semibold">
          Password
        </label>
        <input
          type={passwordVisible ? "text" : "password"}
          className={`${styles["formInput"]} form-control ${formErrors.password ? "is-invalid" : ""}`}
          id="password"
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
        />
        <i className={`${styles["passwordVisible"]} fa-solid ${passwordVisible ? "fa-eye-slash" : "fa-eye"} position-absolute`} 
        onClick={handlePasswordVisibleToggle}
        ></i>
        <div className="invalid-feedback">{formErrors.password}</div>
        <Link to="/forgot-password" className={`${styles["forgot-password"]} d-block text-end text-decoration-none mt-2`}>
          Forgot password?
        </Link>
      </div>

      <button type="submit" className={`${styles["sign-button"]} p-3 btn btn-primary w-100 shadow-sm mb-2 text-uppercase`}>
        Sign In
      </button>

      {/* <p>
        Don't have an account?{" "}
        <Link to="/sign-up" className="font-semibold">
          Sign Up
        </Link>
      </p> */}
    </form>
  </div>
  )
}

export default AdminSignIn
