import React from "react";
import styles from "./DisplayAdFormatImage.module.css";

function DisplayAdFormatImage({ adDetails }) {
  return (
    <div className={`${styles["carousel-image-div"]}`}>
      <img className={`${styles["carousel-image"]}`} src={adDetails?.media?.s3Url} alt="" />
    </div>
  );
}

export default DisplayAdFormatImage;
