import React from "react";
import "./articletag.css";
function ArticleTag({ tagGroups }) {
  return (
    <div className="d-flex gap-3  ">
      {tagGroups?.map((tagGroup) => {
        return (
          <div
            key={tagGroup.value}
            className="tagstyle rounded-pill "
            style={{
              backgroundColor: "#FBCDCD",
              fontFamily: "Plus Jakarta Sans",
              lineHeight: "26px",
            }}
          >
            {tagGroup.value}
          </div>
        );
      })}
      {/* <div className='tagstyle rounded-pill ' style={{ backgroundColor: "#FCE2CE", fontFamily: 'Plus Jakarta Sans', fontSize: '13px', }}> google-analytics</div> */}
    </div>
  );
}

export default ArticleTag;
