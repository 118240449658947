import React from 'react'
import { Button } from 'react-bootstrap'

function ShowMoreRecommendation() {
    return (
        <div className='mx-md-auto media pt-2' style={{ width: '100%', maxWidth: '680px' }} >
            <Button className='rounded-pill media'
                style={{
                    borderColor: '#757575',
                    color: '#292929',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    padding: '9px 23px 9px 22px',

                }}
            >See more recommendations</Button>
            <style jsx>{`
                @media (max-width: 767px) {
                    .media {
                        width: 450px;
                        max-width: 100%;
                    }
                    .media img {
                     max-width: 100%;
                    }
                }
            `}</style>
        </div>

    )
}

export default ShowMoreRecommendation