import React from "react";
import Spinner from "../Spinner/Spinner";

const PageSpinner = () => {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <Spinner style={{ width: "6rem", height: "6rem" }} />
    </div>
  );
};

export default PageSpinner;
