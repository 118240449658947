import { useEffect } from "react";
import paginationIcon from "../../assets/images/PaginationNextIcon.png";
import "./CustomPagination.css";
const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const displayPages = 5;
  const calculatePageNumbers = () => {
    const halfDisplay = Math.floor(displayPages / 2);
    let startPage = Math.max(1, currentPage - halfDisplay);
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages - endPage < halfDisplay) {
      startPage = Math.max(1, endPage - displayPages + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };
  const pageNumbers = calculatePageNumbers();

  return (
    <>
      <div className="pagination-container-layout">
        <div
          className={` pagination-button-layout ${
            currentPage === 1
              ? "pagination-button-disable"
              : "pagination-button-style"
          }`}
          style={{ transform: "scaleX(-1)" }}
        >
          <button
            onClick={() => onPageChange(currentPage - 1)}
            className=" border-0 bg-transparent "
            disabled={currentPage === 1}
          >
            <img src={paginationIcon} alt="previous" />
          </button>
        </div>

        {pageNumbers.map((number) => {
          return (
            <div
              key={number}
              className={`pagination-number-layout pagination-button-typography ${
                number === currentPage
                  ? "pagination-current-style"
                  : "pagination-button-style"
              }`}
            >
              <button
                onClick={() => onPageChange(number)}
                className="border-0 bg-transparent"
              >
                {number}
              </button>
            </div>
          );
        })}
        <div
          className={`pagination-button-layout ${
            currentPage === totalPages
              ? "pagination-button-disable"
              : "pagination-button-style"
          }`}
        >
          <button
            onClick={() => onPageChange(currentPage + 1)}
            className=" border-0 bg-transparent "
            disabled={currentPage === totalPages}
          >
            <img src={paginationIcon} alt="next" />
          </button>
        </div>
      </div>
    </>
  );
};
export default CustomPagination;
