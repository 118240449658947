import React, { useEffect, useState } from "react";
import styles from "./FbAdImageDetailsBelowPrimaryText.module.css";

const FBImage = ({ adDetails }) => {
  const [s3Url, setS3Url] = useState(adDetails.media.s3Url)

  useEffect(() => {
    setS3Url(adDetails.media.s3Url);
  }, [adDetails.media.s3Url])

  return (
    <div>
      <div className={`${styles["carousel-image-div"]}`}>
        <img className={`${styles["carousel-image"]}`} src={s3Url} onError={() => setS3Url("https://charts.fyers.in/1d898a3d9fd9ddf40af091a8d425d584/015fe56027ebed9072875636de26ff57.png")} alt="" />
      </div>
      <div className={`${styles["ad-footer"]} mt-1 d-flex align-items-center justify-content-between`}>
        <div className="d-flex flex-column w-75 pe-1">
          {adDetails.headline?.normalizedUrlWithSubFolderDomain && (
            <a className={`${styles["normalized-url"]}`} href={adDetails.headline.normalizedUrlWithSubFolderDomain} target="_blank" rel="noreferrer">
              {adDetails.headline.normalizedUrlWithSubFolderDomain}
            </a>
          )}
          {adDetails.headline?.title && (
            <a className={`${styles["headline"]}`} href={adDetails.headline.redirectedUrl} target="_blank" rel="noreferrer">
              {adDetails.headline.title}
            </a>
          )}

          {adDetails.description?.title && (
            <a className={`${styles["description"]}`} href={adDetails.description.redirectedUrl} target="_blank" rel="noreferrer">
              {adDetails.description.title.slice(0, 106) + "..."}
            </a>
          )}
        </div>

        <div className="cta-div">
          {adDetails.cta?.title && (
            <a href={adDetails.cta.redirectedUrl} target="_blank" rel="noreferrer" className={`${styles["cta-button"]} d-block text-decoration-none`}>
              {adDetails.cta.title}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default FBImage;
