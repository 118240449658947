import { useState } from "react";
import './workComponents.css'
import SliderPenIcon from "../../../assets/profile-images/slider_pen.svg";

export const MySlider = ({
  timebarData,
  setTimebarData,
  saveTimebarData,
  index = undefined,
  isContributionSlider = false,
}) => {
  const minValue = 0;
  const maxValue = isContributionSlider ? 100 : 45;
  const step = isContributionSlider ? 1 : 0.25;
  const multiplier = isContributionSlider ? 0.94 : 2.09;
  const color = `linear-gradient(to right, #C3E1FF ${Number(
    timebarData.value * multiplier
  )}%, #F3F9FF 0%)`;
  // console.log(timebarData);
  const barcolor = !isContributionSlider
    ? color
    : `linear-gradient(to right, #C3E1FF ${Number(
        timebarData.value * multiplier
      )}%, #7186CE 0%)`;

  const fShowSeperatorBars = (value) => {
    return (
      ((value > 1 && !setTimebarData) || setTimebarData) &&
      !isContributionSlider
    );
  };

  const setSliderValue = (e, save = false) => {
    // console.log({ value: e.target.value, save });
    if (!setTimebarData) return;
    const value = e.target.value;
    setTimebarData({ ...timebarData, value });
    if (save) {
      // console.log('saved')
      if (saveTimebarData) {
        saveTimebarData();
      } else return;
    }
  };
  return (
    <>
      <div
        className=""
        style={{
          width: "100%",
          position: "relative",
          transform: "scaleX(1.06) translateX(6px)",
        }}
      >
        {/* {fShowSeperatorBars(timebarData.value) && (
          <span className="sd1 slider-div"></span>
        )}
        {fShowSeperatorBars(timebarData.value) && (
          <span className="sd2 slider-div"></span>
        )}
        {fShowSeperatorBars(timebarData.value) && (
          <span className="sd3 slider-div"></span>
        )} */}
        {/* {setTimebarData && (
          <img
            className="slider-thumb"
            src={SliderPenIcon}
            alt="slider-thumb"
            style={{
              "--fa-rotate-angle": "-45deg",
              position: "absolute",
              marginLeft: `${timebarData.value * multiplier - 5}%`,
              marginTop: "-2%",
            }}
          ></img>
        )} */}
        <span
          className="slider-bg-limiter"
          style={{
            borderLeft: "none",
            width: isContributionSlider ? "15px" : "16px",
          }}
        ></span>
        <input
          type="range"
          min={minValue}
          max={maxValue}
          step={step}
          value={timebarData.value}
          onChange={(e) => setSliderValue(e, false)}
          onMouseUp={(e) => setSliderValue(e, true)}
          // onBlur={(e) => setSliderValue(e, true)}
          className={`slider ${
            !isContributionSlider ? "" : "contribution-slider"
          } ${!setTimebarData ? "noslider" : ""} slider1`}
          id="myRange"
          style={{
            background: barcolor,
            border: isContributionSlider
              ? "none"
              : setTimebarData
              ? "2px solid #B0D0F0"
              : "",
          }}
        />
      </div>
    </>
  );
};