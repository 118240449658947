import React from "react";

const ArticlePlaceholder = () => {
  return (
    <div className="px-lg-5 mx-4 text-center d-flex">
      <div
        className="content min-vh-100 px-lg-3 mx-auto "
        style={{ maxWidth: "880px", width: "100%", marginBottom: "100px" }}
      >
        <div className=" px-lg-5 mx-lg-auto mt-4 mt-md-5">
          <div>
            <div className="mt-4 placeholder-glow d-flex flex-column">
              <div
                className="placeholder bg-secondary my-2"
                style={{ height: "25px" }}
              ></div>
              <div
                className="placeholder bg-secondary my-2"
                style={{ height: "25px" }}
              ></div>
              <div
                className="placeholder bg-secondary"
                style={{ width: "25%", height: "25px" }}
              ></div>
            </div>

            <div className="d-flex placeholder-glow mt-4">
              <div
                className="my-2 me-2 placeholder bg-secondary"
                style={{ width: "64px", height: "64px", borderRadius: "50%" }}
              ></div>
              <div className="text-start w-100">
                <p
                  className="my-2 placeholder bg-secondary"
                  style={{ width: "40%", height: "25px" }}
                ></p>

                <p
                  className="my-2 placeholder bg-secondary"
                  style={{ width: "70%", height: "25px" }}
                ></p>
              </div>
            </div>

            <div className="placeholder-glow mt-2">
              <div
                style={{ width: "100%", height: "600px" }}
                className="placeholder bg-secondary"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePlaceholder;
