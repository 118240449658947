import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResponse } from "../../../slices/chatGPTSliceV2";
import AddHeadingOrQuestion from "../AddHeadingOrQuestion/AddHeadingOrQuestion";
import Arrow from "../Arrow/Arrow";
import GeneratingResponseLoader from "../Loader/Loader";
import Response from "../Response/Response";
import RegenerateResponseBtn from "../RegenerateResponseBtn/RegenerateResponseBtn";
import ResponseContent from "../ResponseContent/ResponseContent";

const getResponseStatusForQuestion = ({ questionId, responses }) => {
  return responses[questionId]?.length ? "completed" : "failed";
};

const Question = ({
  i,
  question,
  questionAlias,
  _id,
  containerClassName,
  questionTypeClassName,
  showOutline,
  level,
  relation,
  fStrikeout,
}) => {
  const [showResponse, setShowResponse] = useState(false);
  const dispatch = useDispatch();
  const responses = useSelector((state) => state.chatGPTSlice.responses);
  const questions = useSelector((state) => state.chatGPTSlice.questions);
  const topic = useSelector((state) => state.chatGPTSlice.topic);
  const responseStatus =
    questions[question]?.responseStatus ||
    getResponseStatusForQuestion({ questionId: question, responses });
  //   default: latest response index
  const currentResponseIdx = responses[question]?.length - 1;
  const currentResponseId =
    currentResponseIdx >= 0
      ? responses[question][currentResponseIdx]?._id
      : undefined;
  const filteredPointsForCurrentResponse = currentResponseId
    ? topic?.filteredPoints[question]?.points?.filter(
        (point) => point.response === currentResponseId
      )
    : undefined;
  const filteredSentencesForCurrentResponse = currentResponseId
    ? topic?.filteredSentences[question]?.sentences?.filter(
        (point) => point.response === currentResponseId
      )
    : undefined;

  return (
    <div className={containerClassName}>
      <AddHeadingOrQuestion
        fHasParentQuestion={true}
        indexInHeadingQuestions={i}
        question={question}
        filteredPoints={filteredPointsForCurrentResponse}
        level={level}
        relation={relation}
        fStrikeout={fStrikeout}
        headingQuestions={topic?.headingQuestions}
      />
      <RegenerateResponseBtn
        questionId={question}
        question={questions[question]?.question}
        topicId={topic?._id}
        responseStatus={responseStatus}
        contextProvided={questions[question]?.contextProvided}
      />
      {questionAlias ? (
        <div className={questionTypeClassName}>
          <Arrow level={level} type={""} />
          {/* <i className="bi bi-grip-vertical"></i> */}
          <p
            style={{
              textDecoration: fStrikeout ? "line-through" : "none",
              textTransform: "capitalize",
              cursor: "pointer",
              fontSize: "14px",
              "white-space": "nowrap",
            }}
            onClick={() => {
              dispatch(getResponse({ questionId: question }));
              setShowResponse(_id);
            }}
          >
            {questionAlias.substring(0, 80)}
            <span title={questionAlias}>
              {questionAlias.length < 80 ? "" : "..."}
            </span>
          </p>
        </div>
      ) : (
        <div className={questionTypeClassName}>
          <Arrow level={level} type={""} />
          {/* <i className="bi bi-grip-vertical"></i> */}
          <p
            style={{
              textDecoration: fStrikeout ? "line-through" : "none",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => {
              dispatch(getResponse({ questionId: question }));
              setShowResponse(_id);
            }}
          >
            {" "}
            {questions[question]?.question.substring(0, 80)}
            <span title={questions[question]?.question}>
              {questions[question]?.question.length < 80 ? "" : "..."}
            </span>
          </p>
        </div>
      )}{" "}
      {!questions[question].isResponseLoading ? (
        !showOutline &&
        topic?.filteredPoints &&
        filteredPointsForCurrentResponse?.length > 0 && (
          <>
            <p style={{ marginLeft: "-10px" }}>||</p>
            {filteredPointsForCurrentResponse?.map((fPoint, k) => {
              if (k < 20 && fPoint.response === currentResponseId) {
                return (
                  <p
                    key={k}
                    className="highlight-with-bg"
                    onClick={() => {
                      dispatch(getResponse({ questionId: question }));
                      setShowResponse(_id);
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: "12px",
                      // display: "inline-block",
                      flexWrap: "nowrap",
                      "white-space": "nowrap",
                    }}
                  >
                    {fPoint?.point?.substring(0, 40)}
                    <span title={fPoint?.point}>
                      {fPoint?.point?.length < 40 ? "" : "..."}
                    </span>
                    <span style={{ marginLeft: "4px" }}>|</span>
                  </p>
                );
              }
            })}
          </>
        )
      ) : (
        <GeneratingResponseLoader />
      )}
      <ResponseContent
        showResponse={showResponse} //headingAndQuestion elementId
        setShowResponse={setShowResponse} //headingAndQuestion elementId
        questionIndex={_id} //headingAndQuestion elementId
        sourceQuestion={questions[question]?.question} //question Text
        questionId={question} //questionId
        questionAlias={questionAlias}
        questionModal={false}
      />
    </div>
  );
};

export default Question;
