import React from "react";
import "./articletable.css";

function ArticleContentsTable({ tableOfContent }) {
  const headingStyles = {
    color: "#333333",
    fontSize: "20px",
    fontFamily: "Plus Jakarta Sans",
    lineHeight: "31.32px",
    // Font size for mobile view
  };
  const renderListItems = (tableOfContent, index, level) => {
    switch (tableOfContent[index].level) {
      case level + 1:
        return (
          <ul style={{ listStyleType: "disc" }} className="mb-1">
            <li>{tableOfContent[index].heading}</li>
          </ul>
        );
      case level + 2:
        return (
          <ul>
            <ul>
              <li>{tableOfContent[index].heading}</li>
            </ul>
          </ul>
        );
      case level + 3:
        return (
          <ul>
            <ul>
              <ul>
                <li>{tableOfContent[index].heading}</li>
              </ul>
            </ul>
          </ul>
        );

      default:
        return (
          <ul>
            <li>{tableOfContent[index].heading}</li>
          </ul>
        );
    }
  };
  return (
    <div
      className="article-toc bg border border-secondary ps-3 pe-4 py-3 px-lg-5 py-lg-3 mx-auto rounded media fontPara"
      style={{ width: "680px", lineHeight: "1.7rem" }}
    >
      <h3 style={headingStyles} className="head">
        <strong>Inside this article:</strong>
      </h3>

      {/* {tableOfContent?.map((heading, index) => (
        <div
          key={index}
          style={{ marginLeft: `${(heading.level - 1) * 28}px` }}
        >
          {heading.heading}
        </div>
      ))} */}
      <div>
        <ol className="">
          {tableOfContent.map((item, index) =>
            item.level === tableOfContent[0].level ? (
              <li key={item.heading}>{item.heading}</li>
            ) : (
              <div key={item.heading}>
                {renderListItems(
                  tableOfContent,
                  index,
                  tableOfContent[0].level
                )}
              </div>
            )
          )}
        </ol>
      </div>
      {/* <style jsx>{` */}
      <style>{`
                @media (max-width: 767px) {
                    .media {
                        width: 457px;
                        max-width: 100%;
                    }
                    .media img {
                     max-width: 100%;
                    }
                   .head{
                    font-size: 18px !important;
                   }
                   .mobList{
                    font-size:16px !important;
                    line-height:25px !important;
                   }
                }

            `}</style>
    </div>
  );
}

export default ArticleContentsTable;
