import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import "./Flag.css";
import FlagDropdownOptions from "react-bootstrap/Dropdown";
import { reportAds } from "../../../../slices/reportAdsSlice";

function UpdateRelevanceForAd({ adId, userId, query, isIrrelevantAd, toggleDisabled, adType }) {
  const dispatch = useDispatch();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [reportType, setReportType] = useState("");
  const [message, setMessage] = useState("");

  async function handleReportAds() {
    if (reportType === "incorrectTextInAd" || reportType === "incorrectCompanyAd") {
      dispatch(reportAds({ adId, userId, reportType, query }));
    } else if (reportType === "updateRelevanceForAd") {
      const result = await dispatch(reportAds({ adId, userId, isIrrelevantAd, query }));
      if (result.meta.requestStatus === "fulfilled") {
        toggleDisabled();
      }
    }
    setReportType("");
    setMessage("");
    setIsOpenPopup(false);
  }

  return (
    <div>
      <FlagDropdownOptions>
        <FlagDropdownOptions.Toggle variant="none" id="dropdown">
          <OverlayTrigger className="ms-2" overlay={<Tooltip id="tooltip-disabled">Report Ad</Tooltip>}>
            <i className={"fa-regular fa-flag"} />
          </OverlayTrigger>
        </FlagDropdownOptions.Toggle>

        <FlagDropdownOptions.Menu align="end">
          <FlagDropdownOptions.Item
            onClick={() => {
              if (isIrrelevantAd) {
                setMessage("Do You Want To Mark This Ad As Relevant Ad ?");
              } else {
                setMessage("Do You Want To Mark This Ad As Irrelevant Ad ?");
              }
              setReportType("updateRelevanceForAd");
              setIsOpenPopup(true);
            }}
          >
            {isIrrelevantAd ? "Mark As Relevant Ad" : "Mark As Irrelevant Ad"}
          </FlagDropdownOptions.Item>
          {(adType === "displayAd" || adType === "fbAd") && (
            <FlagDropdownOptions.Item
              onClick={() => {
                setMessage("Do You Want To Report Incorrect Text In This Ad ?");
                setReportType("incorrectTextInAd");
                setIsOpenPopup(true);
              }}
            >
              Report Incorrect Text in Ad
            </FlagDropdownOptions.Item>
          )}
          {adType === "displayAd" && (
            <FlagDropdownOptions.Item
              onClick={() => {
                setMessage("Do You Want To Report Incorrect Company Ad ?");
                setReportType("incorrectCompanyAd");
                setIsOpenPopup(true);
              }}
            >
              Report Incorrect Company Ad
            </FlagDropdownOptions.Item>
          )}
        </FlagDropdownOptions.Menu>
      </FlagDropdownOptions>
      {isOpenPopup && (
        <Modal show={isOpenPopup}>
          <Modal.Header>⚠️ WARNING ⚠️</Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <button className="confirmationDialogBox_buttonsContainer_buttons" onClick={handleReportAds}>
              Yes
            </button>
            <button className="confirmationDialogBox_buttonsContainer_buttons" onClick={() => setIsOpenPopup(false)}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default UpdateRelevanceForAd;
