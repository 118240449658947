import React, { useEffect, useState } from "react";
import "./Carousel.css";
import downloadIcon from "../../../assets/images/download-icon.svg";
// import aurora from "../../../assets/images/aurora.gif";
import ext_save from "../../../assets/images/ext_save.gif";
import ext_search from "../../../assets/images/ext_search.gif";
import ext_collapse from "../../../assets/images/ext-collapse.gif";
import thunderIcon from "../../../assets/images/Thunder.png";
import arrowRight from "../../../assets/images/arrow-right.svg";
import arrowLeft from "../../../assets/images/arrow-left.svg";
function getTimeInterval(slideNum) {
  const repeat = 1;
  switch (slideNum) {
    case 0: {
      return 5.9 * 1000 * repeat; //search
    }
    case 1: {
      return 9.3 * 1000 * repeat; //auto save
    }
    case 2: {
      return 7.5 * 1000 * repeat; //collapse
    }
    default:
      return 5.0 * 1000 * repeat;
  }
}
function getCurrImage(slideNum, setCurrImage) {
  switch (slideNum % 3) {
    case 0: {
      setCurrImage(ext_search);
      break;
    }
    case 1: {
      setCurrImage(ext_save);
      break;
    }
    default: {
      setCurrImage(ext_collapse);
      break;
    }
  }
}
const CarouselComponent = () => {
  const [slideNum, setSlideNum] = useState(0);
  const [currImage, setCurrImage] = useState(ext_save);
  const [hoverCarousel, setHoverCarousel] = useState(false);
  const slideChangeInterval = getTimeInterval(slideNum, setCurrImage);

  useEffect(() => {
    if (hoverCarousel) return;
    let isMounted = true;
    // document.getElementById('carousel-slide-next')?.click()
    // console.log(slideNum, new Date().getTime());
    const timeoutId = setTimeout(() => {
      if (isMounted) {
        getCurrImage(slideNum + 1, setCurrImage);
        setSlideNum((slideNum + 1) % 3);
      }
      clearTimeout(timeoutId);
    }, slideChangeInterval);
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideNum, hoverCarousel]);

  return (
    <div
      id="carouselExampleCaptions"
      className="newsfeed-slide slide carousel-fade mb-3"
      data-bs-ride="false"
    >
      <div className="position-absolute top-0 start-0 bottom-0 end-0 custom-overlay"></div>
      <div
        className="d-flex flex-lg-row flex-column justify-content-around mb-md-5 pt-lg-5 pt-md-4"
        style={{ zIndex: "3", position: "relative", marginBottom: "36px" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center text-center pb-md-5 extension-info">
          <div className="d-md-block">
            <div className="main-para-heading mb-lg-3 ms-lg-4 ms-1 mt-md-2 mt-4">
              Chat SearchNSave
            </div>
            <div
              className="sub-main-heading mt-lg-3 ps-lg-3"
              style={{
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "700",
              }}
            >
              Supercharge{" "}
              <img
                src={thunderIcon}
                alt="thunder-icon"
                style={{ maxHeight: "32px" }}
                className="supercharge-icon"
              ></img>
              ChatGPT
            </div>
          </div>
          <div className="d-flex align-items-center text-for-feature ms-md-3 mt-md-4 mb-md-4 mb-md-5">
            <button
              className="border-0 bg-transparent mx-md-1"
              onClick={() => {
                setSlideNum((prevSlideNum) => {
                  if (prevSlideNum === 0) {
                    getCurrImage(2, setCurrImage);
                    return 2;
                  }
                  prevSlideNum = prevSlideNum - 1;
                  getCurrImage(prevSlideNum, setCurrImage);
                  return prevSlideNum;
                });
              }}
            >
              <img
                src={arrowLeft}
                alt="arrow-left"
                className="carousel-arrow-left"
              />
            </button>
            <div
              className={`${
                slideNum === 0 ? "active-feature" : ""
              } mx-sm-2 mx-1`}
              onClick={() => {
                setSlideNum(0);
                getCurrImage(0, setCurrImage);
              }}
            >
              {" "}
              Search
            </div>
            <span className="mx-md-1">|</span>
            <div
              className={`${slideNum === 1 ? "active-feature" : ""} mx-2`}
              onClick={() => {
                setSlideNum(1);
                getCurrImage(1, setCurrImage);
              }}
            >
              {" "}
              Auto Save{" "}
            </div>
            <span className="mx-md-1">|</span>
            <div
              className={`${slideNum === 2 ? "active-feature" : ""} mx-2`}
              onClick={() => {
                setSlideNum(2);
                getCurrImage(2, setCurrImage);
              }}
            >
              {" "}
              Organize
            </div>
            <button
              className="border-0 bg-transparent mx-1"
              onClick={() => {
                setSlideNum((prevSlideNum) => {
                  if (prevSlideNum === 2) {
                    getCurrImage(0, setCurrImage);
                    return 0;
                  }
                  prevSlideNum = prevSlideNum + 1;
                  getCurrImage(prevSlideNum, setCurrImage);
                  return prevSlideNum;
                });
              }}
            >
              <img
                src={arrowRight}
                alt="arrow-right"
                className="carousel-arrow-right"
              />
            </button>
          </div>
          <div className="text-center">
            <a
              href="https://chrome.google.com/webstore/detail/chat-searchnsave/jbknbieibfhhdkcopebpddjgfkaophcc"
              target="_blank"
              className="text-decoration-none"
            >
              <button
                className="btn-carosel d-flex align-items-center mt-1 me-2 p-1"
                style={{ borderRadius: "10px" }}
              >
                <span className="fs-3 mx-2">
                  <img src={downloadIcon} alt="download-icon" />
                </span>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <span className="custom-download-now">Download Now</span>
                  <span className="custom-chrome-extension">
                    Chrome Extension
                  </span>
                </div>
                <br />
              </button>
            </a>
          </div>
        </div>
        <div className="d-flex align-items-center px-lg-5 pb-5 pb-lg-0 ps-lg-0 carousel-img-container">
          <img
            src={currImage}
            className="newsfeed-carousel-img w-100"
            alt="newsfeed-img"
          />
          {/* <div
                className="newsfeed-carousel text-center mb-0"
                onMouseEnter={() => {
                  setHoverCarousel(true);
                }}
                onMouseLeave={() => {
                  setHoverCarousel(false);
                }}
              > */}
          {/* <div className="d-md-none d-lock d-flex flex-column order-last order-md-first">
                  <div className="mobile-carousel-btn d-flex mt-0">
                    <div
                      className={`flex-fill  mobile-btn-feature border-end ${
                        slideNum === 0 ? "active-feature-mobile" : ""
                      }`}
                      onClick={() => {
                        setSlideNum(0);
                        getCurrImage(0, setCurrImage);
                      }}
                    >
                      {" "}
                      Search
                    </div>
                    <div
                      className={`flex-fill  mobile-btn-feature border-end ${
                        slideNum === 1 ? "active-feature-mobile" : ""
                      }`}
                      onClick={() => {
                        setSlideNum(1);
                        getCurrImage(1, setCurrImage);
                      }}
                    >
                      {" "}
                      Auto Save{" "}
                    </div>
                    <div
                      className={`flex-fill  mobile-btn-feature ${
                        slideNum === 2 ? "active-feature-mobile" : ""
                      }`}
                      onClick={() => {
                        setSlideNum(2);
                        getCurrImage(2, setCurrImage);
                      }}
                    >
                      {" "}
                      Organize
                    </div>
                  </div>
                </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
