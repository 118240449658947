import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  verifyEmail,
  setFRedirectUserToEmailAlreadyVerifiedEmailPage,
} from "../../../slices/sessionSlice";
import codeimg from "../../../assets/images/code.svg";
import "./VerifyEmailPage.css";
import { Spinner } from "react-bootstrap";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";

function VerifyEmailPage() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    sessionUser,
    error,
    fAuthCheckCompleted,
    fRedirectUserToEmailAlreadyVerifiedEmailPage,
  } = useSelector((slice) => slice.sessionSlice);


  useEffect(() => {
    if (fRedirectUserToEmailAlreadyVerifiedEmailPage) {
      dispatch(setFRedirectUserToEmailAlreadyVerifiedEmailPage(false));
      navigate("/email-already-verified");
    }
  }, [fRedirectUserToEmailAlreadyVerifiedEmailPage]);

  useEffect(() => {
    const vt = new URLSearchParams(search, [search]).get("vt");
    const email = new URLSearchParams(search, [search]).get("email");
    console.log(vt, email, "query");
    if (fAuthCheckCompleted) {
      if (sessionUser && sessionUser.user) {
        navigate("/");
      } else {
        dispatch(verifyEmail({ vt, email }));
      }
    }
    console.log(
      "fAuthCheckCompleted",
      fAuthCheckCompleted
    );
  }, [fAuthCheckCompleted]);



  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error]);

  return (
    // <div className="min-vh-100 px-sm-3 d-flex justify-content-center align-items-center">
    //   {!sessionUser?.user ? (
    //     <div className="d-flex align-items-center gap-3">
    //       {" "}
    //       <Spinner animation="border" role="status" size="sm">
    //         <span className="visually-hidden">Loading...</span>
    //       </Spinner>
    //       <h4>verifying...</h4>
    //     </div>
    //   ) : (
    //     <div
    //       className="d-flex align-items-center gap-3"
    //       style={{ color: "green" }}
    //     >
    //       <i
    //         className="bi bi-shield-fill-check"
    //         style={{ fontSize: "25px" }}
    //       ></i>
    //       <h4>Email Verified</h4>
    //     </div>
    //   )}
    // </div>
    <PageSpinner />
  );
}

export default VerifyEmailPage;
