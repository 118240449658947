import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { addNewHeading } from "../../../slices/chatGPTSliceV2";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AddHeadingModal = ({
  isModalOpen = false,
  setModalStatus,
  indexInHeadingQuestions,
}) => {
  const [headingName, setHeadingName] = useState("");
  const [headingLevel, setHeadingLevel] = useState("");
  const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(true);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  const resetForm = () => {
    setHeadingName("");
    setHeadingLevel("");
    setValidated(false);
  };
  const closeModal = () => {
    resetForm();
    setModalStatus(false);
    setIsSubmitBtnEnabled(true);
  };
  const createNewHeadingForTopic = () => {
    const payload = {
      headingName: headingName?.trim(),
      headingLevel,
      indexInHeadingQuestions,
      closeModal,
      setIsSubmitBtnEnabled,
    };
    dispatch(addNewHeading(payload));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (!headingName || !headingName.trim() || !headingLevel) {
      console.log("create new heading form validation failed");
      return;
    }

    setIsSubmitBtnEnabled(false);
    createNewHeadingForTopic();
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add new heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-4" md="4" controlId="validationCustom01">
            <Form.Label>Heading name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={headingName}
              onChange={(e) => setHeadingName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              heading name required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" md="4" controlId="validationCustom02">
            <Form.Label>Heading level</Form.Label>
            <Form.Select
              aria-label="select heading level"
              className="w-50"
              onChange={(e) => setHeadingLevel(e.target.value)}
              required={true}
              defaultValue=""
              selected={headingLevel}
            >
              <option value="" disabled={true}>
                select
              </option>
              {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
                <option value={number} key={number}>
                  {number}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              heading level required
            </Form.Control.Feedback>
          </Form.Group>

          <Modal.Footer className="border-0 p-0 mt-4">
            <Button
              type="submit"
              disabled={!isSubmitBtnEnabled}
              style={{ width: "150px" }}
            >
              {isSubmitBtnEnabled ? (
                "Add heading"
              ) : (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddHeadingModal;
