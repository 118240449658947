import { useLocation } from "react-router-dom";
import ApplicationNavbar from "../ApplicationNavbar/ApplicationNavbar";
import {
  fRoutePatternsToExclude,
  excludeNavbarFromRoutePattern,
} from "../../helpers/stubs";
import MentorStudentsLogo from "../../assets/profile-images/add_icon.svg";
import { useEffect } from "react";

export default function HeaderWrapper({ children }) {
  const location = useLocation();

  // Extract the pathname
  const { pathname, search } = location;
  const fullPath = pathname + search;

  const fExcludePattern = fRoutePatternsToExclude(fullPath);
  const fDoNotShowAnyNavbar = excludeNavbarFromRoutePattern(fullPath);
  useEffect(() => {
    if (fRoutePatternsToExclude(fullPath)) {
      document.title = "MentorStudents.org";
      // var link = document.querySelector("link[rel~='icon']");
      // if (!link) {
      //   link = document.createElement("link");
      //   link.rel = "icon";
      //   document.head.appendChild(link);
      // }
      // link.href = { MentorStudentsLogo };
    }
  }, []);
  if (fDoNotShowAnyNavbar) {
    return <></>;
  }
  if (fExcludePattern) {
    return <ApplicationNavbar />;
  }

  return <>{children}</>;
}
