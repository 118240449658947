import React from "react";
import { useParams } from "react-router-dom";
import styles from "./SiteLinksExtension.module.css";

const SiteLinksExtension = ({ siteLinksExtension, position: { position, blockPosition }, landingPageS3UrlWithTitle }) => {
  const isExpandedSiteLink = position === 1 && blockPosition === "top" ? true : false;
  const { adHeadline, orgName, adId } = useParams();

  return (
    <div className={`site-links-extension row g-1 mb-2 ${isExpandedSiteLink ? "mt-2 ps-2" : "mt-1"}`}>
      {siteLinksExtension.map((siteLink) => {
        const { redirectedUrl, title, description } = siteLink;

        return (
          <div className="col-6 col-md-12 col-lg-6 m-0" key={title}>
            {landingPageS3UrlWithTitle ? <a href={`${window.location.href.split('#')[0]}#${title.split(" ").join("")}`} className={`${styles["site-link"]} ${isExpandedSiteLink ? "me-2" : styles["site-link-small"]} d-inline-block`}>
              {title}
            </a> : <a href={`${window.location.href.split('#')[0]}#${title.split(" ").join("")}`} className={`${styles["site-link"]} ${isExpandedSiteLink ? "me-2" : styles["site-link-small"]} d-inline-block`} onClick={(e) => e.preventDefault()}>
              {title}
            </a>}

            {isExpandedSiteLink && description && <p className={`${styles["site-link-description"]}`}>{description.join(" ")}</p>}
          </div>
        );
      })}
    </div>
  );
};

export default SiteLinksExtension;
