import axiosInstance from "../axios";
import { newAlert } from "../slices/alertSlice";
import { getAiResponse } from "../slices/chatGPTSlice";

export const debounce = (callback, timeout) => {
  let timerId;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};
export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

function isRomanNumber(romanNum) {
  const romanRegex =
    /^(M{0,4})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
  return romanRegex.test(romanNum);
}

export function removeNumbersFromStart(str) {
  const numberRegex = /^((?:[IVXLCDM]+)|(?:\d{1,2}(?:\.\d{1,2})?))(\.| )/;

  let match = str.match(numberRegex);
  while (match && match[1] && (isRomanNumber(match[1]) || !isNaN(match[1]))) {
    str = str.slice(match[0].length);
    match = str.match(numberRegex);
  }

  return str.trim();
}

export function fRoutePatternsToExclude(pagePath) {
  const routePatternsToExclude = [
    /^\/user\/profile-page\/[0-9a-fA-F]+\/otp\/\d+/,
    /\/applicant\/forgot-password/,
    /\/applicant\/reset-password/,
    /\/applicant\/sign-in/,
    /\/user\/application-status/,
    /^\/verify-email$/,
  ];
  const fExcludePattern = routePatternsToExclude.some((routePattern) => {
    return routePattern.test(pagePath);
  });
  return fExcludePattern;
}

export const redirectToTestPage = async ({ sessionUser, dispatch }) => {
  try {
    const { data } = await axiosInstance.get(
      `/api/user/get-applicant-test-url/${sessionUser.user?.email}`
    );
    if (data.status === "success") {
      // console.log(data.testUrl);
      window.location.replace(data.testUrl);
    }
  } catch (e) {
    dispatch(
      newAlert({
        type: "danger",
        message: "Unable to redirect to start page",
      })
    );
  }
};
export function getBackendDomain() {
  const currentDomain = window.location.origin;
  const backendDomain = currentDomain;
  if (backendDomain.includes("localhost")) {
    return "http://localhost:8819";
  }
  return backendDomain;
}

export const setUserJobStageHistory = async ({ type, name }) => {
  try {
    const { data } = await axiosInstance.post(
      `/api/user/set-user-job-stage-history`,
      { type, name }
    );
    if (data.status === "success") {
      return data;
    }
  } catch (e) {
    // console.log(e);
  }
};

export function excludeNavbarFromRoutePattern(pagePath) {
  const routePatternsToExclude = [/^\/verify-email(\?.*)?$/];
  const fExcludeNavbar = routePatternsToExclude.some((routePattern) => {
    return routePattern.test(pagePath);
  });
  return fExcludeNavbar;
}

export function excludeFooterFromRoutePattern(pagePath) {
  const routePatternsToExclude = [
    /^\/user\/profile-page\/[0-9a-fA-F]+\/otp\/\d+/,
    /\/applicant\/forgot-password/,
    /\/applicant\/reset-password/,
    /\/applicant\/sign-in/,
    /\/user\/application-status/,
    /^\/verify-email(\?.*)?$/,
  ];
  const fExcludePattern = routePatternsToExclude.some((routePattern) => {
    return routePattern.test(pagePath);
  });
  return fExcludePattern;
}
