import axios from "axios";

const { REACT_APP_MASTER_BACK_END_DOMAIN: masterBackEndURL } = process.env;

const axiosConfig = {
  baseURL: masterBackEndURL,
  signal: new AbortController().signal,
  withCredentials: true,
};

const axiosInstance = axios.create(axiosConfig);

export default axiosInstance;
