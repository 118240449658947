import "./BlogCard.css";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import likesIcon from "../../../assets/images/like-icon.svg";
import savePostIcon from "../../../assets/images/save_post_icon.png";
import likesInactiveIcon from "../../../assets/images/like-inactive-icon.svg";
import dislikesIcon from "../../../assets/images/dislike-icon.svg";
import dislikesInactiveIcon from "../../../assets/images/dislike-inactive-icon.svg";
import {
  updateSavedCreatedPosts,
  updateVotesForCreatedPosts,
} from "../../../slices/newsFeedSlice";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../axios";
import { Link } from "react-router-dom";
function BlogCard({
  postId,
  authorProfilePic,
  author,
  readTime,
  url,
  title,
  key_learning,
  tags,
  articleImageUrl,
  upvoteCount,
  isUpvoted,
  isDownvoted,
  votes,
}) {
  const [currentUpvoteCount, setCurrentUpvoteCount] = useState(upvoteCount);
  const [upvoteStatus, setUpvoteStatus] = useState(isUpvoted);
  const [downvoteStatus, setDownvoteStatus] = useState(isDownvoted);
  const [postSaveStatus, setPostSaveStatus] = useState(false);
  const dispatch = useDispatch();
  const randomTagsColorsArray = [
    "#FCE2CE",
    "#FBCDCD",
    "#D9EAEA",
    "#D5D6EB",
    "#DFF0D6",
    "#FFFAC5",
  ];
  useEffect(() => {
    //check for a blog post which are upvoted/downvoted by the user
    let isVoted = votes && votes.find((vote) => vote.post === postId);
    if (isVoted && isVoted.voteType === "upvote") {
      isUpvoted = true;
    } else if (isVoted && isVoted.voteType === "downvote") {
      isDownvoted = true;
    }
    setUpvoteStatus(isUpvoted);
    setDownvoteStatus(isDownvoted);
    setCurrentUpvoteCount(upvoteCount);

    //set random colors for post tags
    const elements = document.querySelectorAll(".custom-post-tags");
    elements.forEach((element) => {
      element.style.backgroundColor =
        randomTagsColorsArray[
          Math.floor(Math.random() * randomTagsColorsArray?.length)
        ];
    });
  }, []);

  function showKeyLearnings(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let key_learning = tempElement.innerText;
    // let key_learning = htmlString?.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    key_learning =
      key_learning?.length <= 180
        ? key_learning
        : key_learning?.slice(0, 180) + "...";

    return key_learning;
  }

  const handleUpdateVotesForCreatedPost = async (currentVoteType) => {
    const result = await dispatch(
      updateVotesForCreatedPosts({
        postId,
        currentVoteType,
        isUpvoted: upvoteStatus,
        isDownvoted: downvoteStatus,
        query: "",
      })
    );
    if (result.meta.requestStatus === "fulfilled") {
      if (currentVoteType === "upvote") {
        setUpvoteStatus((prevUpvoteStatus) => !prevUpvoteStatus);
        setDownvoteStatus(false);
        if (result.payload.insertedVote || result.payload.updatedVoteType) {
          setCurrentUpvoteCount(
            (prevCurrentUpvoteCount) => prevCurrentUpvoteCount + 1
          );
        } else if (result.payload.deletedVote) {
          setCurrentUpvoteCount(
            (prevCurrentUpvoteCount) => prevCurrentUpvoteCount - 1
          );
        }
      } else if (currentVoteType === "downvote") {
        setDownvoteStatus((prevDownvoteStatus) => !prevDownvoteStatus);
        setUpvoteStatus(false);
        if (result.payload.updatedVoteType) {
          setCurrentUpvoteCount(
            (prevCurrentUpvoteCount) => prevCurrentUpvoteCount - 1
          );
        }
      }
    }
  };
  return (
    <div className="ms-md-4 mb-md-4 position-relative custom-blog-container me-3">
      <Row style={{ overflowWrap: "break-word" }}>
        <Col md={8} xl={9} xs={9}>
          <div className="d-flex flex-column ps-md-2">
            <div className="d-flex align-items-center">
              <div className="me-sm-1 author-pic-container">
                <img
                  src={authorProfilePic}
                  alt="profile-pic"
                  className="img-fluid rounded-circle author-profile-pic"
                />
              </div>
              <div className="mx-lg-2 custom-post-author">{author}</div>
              <span>
                <i className="bi bi-dot"></i>
              </span>
              <div className="mx-lg-1 custom-post-readtime">{readTime}</div>
            </div>
            <div className="custom-post-title mt-md-3 mt-md-2 mt-1">
              <Link
                to={`/article-page/${postId}`}
                target="_blank"
                className="text-decoration-none"
                style={{ fontWeight: "600" }}
              >
                {title?.length <= 75 ? title : title?.slice(0, 75) + "..."}
              </Link>
            </div>
            <div className="my-2 custom-post-content d-none d-md-block">
              {showKeyLearnings(key_learning)}
            </div>
            <div className="mt-md-2 d-flex" style={{ flexWrap: "wrap" }}>
              {tags?.map((tag, index) => {
                return (
                  <div
                    key={index}
                    className="custom-post-tags"
                    style={{
                      padding: "1px 8px 1px 8px",
                      marginRight: "8px",
                    }}
                  >
                    <span className="post-tag-value">{tag}</span>
                  </div>
                );
              })}
            </div>
            <div className="mt-md-4 d-flex align-items-center">
              <button
                className="bg-transparent border-0"
                onClick={() => handleUpdateVotesForCreatedPost("upvote")}
              >
                {upvoteStatus ? (
                  <img
                    src={likesIcon}
                    alt="like-icon"
                    className="post-like-icon"
                  />
                ) : (
                  <img
                    src={likesInactiveIcon}
                    alt="like-inactive-icon"
                    className="post-like-icon"
                  />
                )}
              </button>
              <span
                className="mt-1 mx-md-1 post-upvote-count"
                style={{
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "500",
                }}
              >
                {currentUpvoteCount}
              </span>
              <button
                className="mt-1 bg-transparent border-0 downvote-btn"
                onClick={() => handleUpdateVotesForCreatedPost("downvote")}
              >
                {downvoteStatus ? (
                  <img
                    src={dislikesIcon}
                    alt="dislike-icon"
                    className="post-dislike-icon"
                  />
                ) : (
                  <img
                    src={dislikesInactiveIcon}
                    alt="dislike-inactive-icon"
                    className="post-dislike-icon"
                  />
                )}
              </button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="custom-post-image-container">
        <img
          src={articleImageUrl}
          alt="article-image"
          className="w-100 h-100 custom-post-image"
          style={{ borderRadius: "13px" }}
        />
      </div>
    </div>
  );
}
export default BlogCard;
