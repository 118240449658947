import React from "react";
import RecommendedArticleCard from "./RecommendedArticleCard/RecommendedArticleCard";
import "./RecommededArticles.css";
import ShowMoreRecommendation from "../ShowMoreRecommendation/ShowMoreRecommendation";
function RecommendedArticles() {
  return (
    <div
      className=" pt-3 pt-md-5 mx-md-auto media "
      style={{ width: "100%", maxWidth: "680px" }}
    >
      <div className="pb-md-3 mb-4">
        <h4 className="mt-0">Recommended from Marketingnet</h4>
      </div>
      <div className=" d-md-flex  mb-md-5 mb-4 justify-content-between w-100">
        <div>
          <RecommendedArticleCard />
        </div>
        <div>
          <RecommendedArticleCard />
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 767px) {
          .media {
            width: 450px;
            max-width: 100%;
          }
          .media img {
            max-width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

export default RecommendedArticles;
// ,sans-serif
