import React, { useEffect, useState } from "react";
import "./ApplicationComponents.css";
import axiosInstance from "../../axios";
import { useSelector } from "react-redux";

const TestLink = () => {
  const [testLink, setTestLink] = useState("");
  const userData = useSelector((state) => state.sessionSlice.sessionUser.user);
  const getTestLink = async () => {
    const { data } = await axiosInstance.get(
      `/api/user/get-applicant-test-url/${userData.email}`
    );
    // console.log({ data });
    if (data.status === "success") {
      setTestLink(data.testUrl);
    }
  };
  useEffect(() => {
    getTestLink();
  }, []);
  return (
    <div style={{ marginTop: "110px" }}>
      <div className="text-center start-test-message">
        <div className="test-message mb-5">
          The next step in the application process is a test. It will last for
          one hour.
          <br />
          <br />
          <i style={{ fontSize: "80%", fontWeight: "lighter" }}>
            Please make sure you can stay for the whole test before you start.
          </i>
        </div>
        <a
          className="start-test-btn"
          style={
            !testLink ? { cursor: "not-allowed", pointerEvents: "none" } : {}
          }
          href={testLink}
        >
          Start Test
        </a>
      </div>
    </div>
  );
};

export default TestLink;
