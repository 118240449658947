import axiosInstance from "../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { newAlert } from "./alertSlice"; // Import the newAlert action from the alertSlice (assuming it exists)

const initialState = {
  courses: [],
  selectedCourse: "",
  modules: [],
  createContentForModuleId: null,
  coursesList: null,
  course: null,
  courseCohorts: [],
};

const generateCourseOutline = (course) => {
  return {
    courseId: course._id,
    name: course.name,
    description: course.description,
    price: course.price,
    discount: course.discount,
    modules: course.courseModules,
  };
};

// const { REACT_APP_BACK_END_DOMAIN: backendURL } = process.env;

export const getAllCourses = createAsyncThunk(
  "courseOutline/getAllCourses",
  async (payload, thunkAPI) => {
    const response = await axiosInstance.get(
      "/api/admin/course/get-all-courses"
    );
    return response.data;
  }
);

export const createCourse = createAsyncThunk(
  "courseOutline/createCourse",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosInstance.post(
        `/api/admin/course/create-course`,
        payload
      );
      dispatch(
        newAlert({ type: "success", message: "Course created successfully!" })
      );
      return response.data;
    } catch (error) {
      dispatch(
        newAlert({ type: "danger", message: "Failed to create a course!" })
      );
      throw new Error("Failed to create a course!");
    }
  }
);

export const courseOutlineSlice = createSlice({
  name: "courseOutline",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllCourses.pending]: (state) => {
      state.status = "loading";
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const courseData = action.payload.map((course) => ({
        ...course,
        courseOutline: generateCourseOutline(course),
      }));
      // console.log("coursesList ", courseData);
      state.coursesList = courseData;
    },
    [getAllCourses.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    [createCourse.pending]: (state) => {
      state.status = "loading";
    },
    [createCourse.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const course = action.payload.course;
      // console.log(course);
      state.coursesList.push({
        ...course,
        courseOutline: generateCourseOutline(course),
      });
    },
    [createCourse.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});
// Export Actions
// module.exports = {
//   getAllCourses,
//   createCourse,
// };

export default courseOutlineSlice.reducer;
