import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AddChildQuestionModal.css";
import { addNewChildQuestions } from "../../../slices/chatGPTSliceV2";
import Response from "../Response/Response";
import { getResponse } from "../../../slices/chatGPTSliceV2";
import SelectChatToAskQuestion from "../SelectChatToAskQuestion/SelectChatToAskQuestion";
import ResponseContent from "../ResponseContent/ResponseContent";
const AddChildQuestionModal = ({
  isModalOpen = false,
  setModalStatus,
  indexInHeadingQuestions,
  parentQuestionId,
  filteredPoints: allFilteredPointsForQuestion,
  level,
}) => {
  const dispatch = useDispatch();
  const [relation, setRelation] = useState("");
  const [questionTemplate, setQuestionTemplate] = useState("{{filteredPoint}}");
  const [questionAliasTemplate, setQuestionAliasTemplate] =
    useState("{{filteredPoint}}");
  const [useAliasTemplate, setUseAliasTemplate] = useState(false);
  const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(true);
  const [isPreviewQuestions, setIsPreviewQuestions] = useState(true);
  const [showContext, setShowContext] = useState(false);
  const [validated, setValidated] = useState(false);
  const [filteredPoints, setFilteredPoints] = useState([]);
  const [askQuestionInChat, setAskQuestionInChat] = useState("");
  // #region added for response Modal

  const [showResponse, setShowResponse] = useState(false);
  const questions = useSelector((state) => state.chatGPTSlice.questions);
  const responses = useSelector((state) => state.chatGPTSlice.responses);
  //#endregion
  useEffect(() => {
    const filteredPoints =
      allFilteredPointsForQuestion?.points?.map((filteredPoint, idx) => {
        return { id: idx, value: filteredPoint.point, isChecked: true };
      }) || [];

    setFilteredPoints(filteredPoints);
  }, [allFilteredPointsForQuestion]);

  const selectedFilteredPoints = filteredPoints?.filter(
    (filteredPoint) => filteredPoint.isChecked === true
  );

  const openResponseModal = (e) => {
    // setModalStatus(false);
    dispatch(getResponse({ questionId: parentQuestionId }));
    e.preventDefault();
    setShowResponse(parentQuestionId);
  };
  const childQuestions = selectedFilteredPoints
    ? selectedFilteredPoints.map((selectedFilteredPoint) => {
        const question = questionTemplate.replaceAll(
          "{{filteredPoint}}",
          selectedFilteredPoint.value
        );
        const questionAlias = useAliasTemplate
          ? questionAliasTemplate
              .trim()
              .replaceAll("{{filteredPoint}}", selectedFilteredPoint.value)
          : undefined;
        return { question, questionAlias };
      })
    : [];

  const resetForm = () => {
    setRelation("");
    setQuestionTemplate("{{filteredPoint}}");
    setQuestionAliasTemplate("{{filteredPoint}}");
    setValidated(false);
  };
  const closeModal = () => {
    setModalStatus(false);
    setIsSubmitBtnEnabled(true);
    setUseAliasTemplate(false);
  };
  const createNewChildQuestionForTopic = () => {
    const payload = {
      indexInHeadingQuestions,
      relation: relation?.trim(),
      questionTemplate: questionTemplate?.trim(),
      questionAliasTemplate: useAliasTemplate
        ? questionAliasTemplate
        : undefined,
      childQuestions,
      parentQuestionId,
      closeModal,
      resetForm,
      setIsSubmitBtnEnabled,
      level,
    };
    dispatch(addNewChildQuestions(payload));
  };
  const handleFilteredPointCheckboxChange = (event) => {
    const id = parseInt(event.target.value);
    const updatedFilteredPoints = filteredPoints.map((filteredPoint) => {
      if (filteredPoint.id === id) {
        return { ...filteredPoint, isChecked: !filteredPoint.isChecked };
      }
      return filteredPoint;
    });
    setFilteredPoints(updatedFilteredPoints);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (!relation || !relation.trim()) {
      console.log("create new child question form validation failed");
      return;
    }
    setIsSubmitBtnEnabled(false);

    createNewChildQuestionForTopic();
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add new child question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-4" md="4" controlId="validationCustom01">
              <Form.Label>Relation</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={relation}
                onChange={(e) => setRelation(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                relation is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4" md="4" controlId="validationCustom01">
              <Form.Label>Question template</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder=""
                value={questionTemplate}
                spellCheck={false}
                onChange={(e) => setQuestionTemplate(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                question template is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4" md="4" controlId="validationCustom01">
              <Form.Label>
                Question alias template{" "}
                <small className="text-muted">(optional)</small>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                value={useAliasTemplate ? questionAliasTemplate : ""}
                spellCheck={false}
                onChange={(e) => setQuestionAliasTemplate(e.target.value)}
                disabled={!useAliasTemplate}
                style={{ height: "50px" }}
              />
              <Form.Check
                type="checkbox"
                id={`useQuestionAliasTemplate`}
                label={`set alias`}
                className="mt-1"
                onChange={(e) => setUseAliasTemplate((prev) => !prev)}
              />
              <Form.Control.Feedback type="invalid">
                question alias template is required
              </Form.Control.Feedback>
            </Form.Group>
            <div className="mb-4">
              <p className="mb-2">Select from filtered points</p>
              <div className="filteredPointsContainerInModal d-flex flex-row flex-wrap">
                {filteredPoints && filteredPoints.length ? (
                  filteredPoints?.map((filteredPoint, i) => {
                    const fTruncateFilteredPoint =
                      filteredPoint.value.length > 30;
                    const filteredPointValue = fTruncateFilteredPoint
                      ? `${filteredPoint.value.substr(0, 30).trim()}...`
                      : filteredPoint.value;
                    return (
                      <div key={i}>
                        <input
                          type="checkbox"
                          id={filteredPoint.id}
                          value={filteredPoint.id}
                          checked={filteredPoint.isChecked}
                          onChange={handleFilteredPointCheckboxChange}
                          style={{ marginRight: "5px" }}
                        />
                        <label
                          title={filteredPoint.value}
                          className={
                            !filteredPoint.isChecked
                              ? "text-muted unCheckedFilteredPointInModal"
                              : ""
                          }
                          for={filteredPoint.id}
                        >
                          {filteredPointValue}
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <span className="text-danger">
                    no filtered points available to create child questions
                    <button
                      className="border-0 fw-light fst-italic rounded fs-6 btn btn-light p-0 m-0 px-1"
                      onClick={(e) => openResponseModal(e)}
                    >
                      Add Filtered Points
                    </button>
                  </span>
                )}
              </div>
            </div>
            {childQuestions?.length > 0 && (
              <div className="questionsPreviewInModal">
                <button
                  className="btn btn-light border border-secondary mb-3"
                  type="button"
                  onClick={() => setIsPreviewQuestions((prev) => !prev)}
                >
                  Preview questions
                  {isPreviewQuestions ? (
                    <i className="bi bi-chevron-up ps-2"></i>
                  ) : (
                    <i className="bi bi-chevron-down ps-2"></i>
                  )}
                </button>
                {isPreviewQuestions &&
                  childQuestions.map((childQuestion, j) => {
                    return (
                      <div className="mb-2" key={j}>
                        <p className="childQuestionPreviewInModal p-0 m-0 fw-normal">
                          {childQuestion.question}
                        </p>
                        {useAliasTemplate && (
                          <small className="childQuestionAliasPreviewInModal text-secondary">
                            alias: {childQuestion.questionAlias}
                          </small>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
            {/* //todo: Previous Question preview */}
            {childQuestions?.length > 0 && (
              <div className="questionsPreviewInModal">
                <button
                  className="btn btn-light border border-secondary mb-3"
                  type="button"
                  onClick={() => setShowContext((prev) => !prev)}
                >
                  Show Response
                  {showContext ? (
                    <i className="bi bi-chevron-up ps-2"></i>
                  ) : (
                    <i className="bi bi-chevron-down ps-2"></i>
                  )}
                </button>
                {showContext &&
                  responses[parentQuestionId] &&
                  responses[parentQuestionId]?.htmlResponse
                    .split("\n")
                    .map((res, i) => (
                      <p key={i} style={{ color: "#1e3a8a" }}>
                        {res}
                      </p>
                    ))}
              </div>
            )}
            <SelectChatToAskQuestion
              askQuestionInChat={askQuestionInChat}
              setAskQuestionInChat={setAskQuestionInChat}
            />
            <Modal.Footer className="border-0 p-0 mt-4">
              {childQuestions?.length > 0 && (
                <Button
                  type="submit"
                  disabled={!isSubmitBtnEnabled}
                  style={{ width: "200px" }}
                >
                  {isSubmitBtnEnabled ? (
                    "Add child questions"
                  ) : (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <ResponseContent
        showResponse={showResponse} // question id
        setShowResponse={setShowResponse} //question id
        questionIndex={parentQuestionId} // question id
        sourceQuestion={questions[parentQuestionId]?.question} // question text to render
        questionId={parentQuestionId} //question id
      />
    </>
  );
};

export default AddChildQuestionModal;
