export function checkImageType(imgSrc) {
  // Check if the imgSrc starts with "data:image"
  if (imgSrc.startsWith("data:image")) {
    return "Image file data";
  }

  // Check if the imgSrc has a common image file extension
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
  const fileExtension = imgSrc.substring(imgSrc.lastIndexOf(".")).toLowerCase();

  if (imageExtensions.includes(fileExtension)) {
    return "Image URL";
  }

  return "Not an image";
}

export function processFilename(uniqueTitle, imageId, imgSrc, overrideExtension = null) {
  let extension;
  if (checkImageType(imgSrc) === "Image file data") {
    const dataUrlRegex = /^data:image\/(\w+);base64,/; // Regex to match the data URL format
    const match = imgSrc.match(dataUrlRegex); // Match the regex against the image data

    if (match) {
      extension = "." + match[1]; // extension = the captured image extension
    } else {
      extension = ".png"; // default type
    }
  } else {
    // Check if the imgSrc has an extension
    const extensionMatch = imgSrc.match(/\.(jpeg|jpg|gif|png)$/i);
    extension = extensionMatch ? extensionMatch[0] : ".png";
  }
  // Append the extension to the uniqueTitle
  const filename = uniqueTitle + imageId + (overrideExtension || extension);

  return filename;
}

export async function extractImageSrc(htmlCode, workingPost) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlCode, "text/html");
  const imgElements = doc.getElementsByTagName("img");
  const srcValues = [];

  for (let i = 0; i < imgElements.length; i++) {
    const imgSrc = imgElements[i].getAttribute("src");
    if (imgSrc && !imgSrc.startsWith("https://marketing-net.s3")) {
      const uniqueImageName = processFilename(
        workingPost,
        `_${Date.now()}`,
        imgSrc
      );
      srcValues.push({ imgSrc, uniqueImageName });
      await new Promise((resolve) => setTimeout(resolve, 20));
    }
    else {
      continue;
    }
  }

  return srcValues;
}
export function replaceImageSrc(htmlCode, newUrls) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlCode, "text/html");
  const imgElements = doc.getElementsByTagName("img");
  let j = 0;
  for (let i = 0; i < imgElements.length; i++) {
    const imgSrc = imgElements[i].getAttribute("src");
    if (
      imgSrc &&
      newUrls[j] &&
      !imgSrc.startsWith("https://marketing-net.s3")
    ) {
      imgElements[i].setAttribute("src", newUrls[j]);
      j++;
    }
     else
      continue;
  }
  return doc.documentElement.outerHTML;
}

export function hasPendingLinks(htmlCode) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlCode, "text/html");
  const imgElements = doc.getElementsByTagName("img");

  for (let i = 0; i < imgElements.length; i++) {
    const imgSrc = imgElements[i].getAttribute("src");
    if (imgSrc && !imgSrc.startsWith("https://marketing-net.s3")) {
      return true;
    }
  }
  return false;
}

const addClassNameToElement = (element, className) => {
  if (!element.classList.contains(className)) {
    element.classList.add(className);
  }
};

export const modifyHtml = (html, modifyOutputHtml) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const images = doc.querySelectorAll("img");
    images.forEach((img) => {
      if (img.src) {
        img.style.height = "auto";
        img.style.minWidth = "unset";
        img.style.maxWidth = "100%";
        if (img.parentElement && img.parentElement.tagName === "FIGURE") {
          img.style.minWidth = "100%";
          img.style.width = img.parentElement.style.width;
          img.parentElement.style.backgroundColor = "#f7f7f7";
          img.parentElement.style.textAlign = "center";
          img.parentElement.style.margin = "auto";
          img.parentElement.style.fontSize = "12px";
        }
      }
    });

    const modifyElements = doc.querySelectorAll("blockquote, pre");
    // console.log({ modifyElements });
    modifyElements.forEach((element) => {
      // console.log({ tagName: element.tagName, element });
      if (element.tagName === "BLOCKQUOTE") {
        addClassNameToElement(element, "ck-blockquote");
      } else if (element.tagName === "PRE") {
        addClassNameToElement(element, "ck-pre-block");
      }
    });
    let updatedDoc = doc;
    if (modifyOutputHtml) {
      updatedDoc = modifyOutputHtml(doc);
    }
    const modifiedHtml = updatedDoc.body.innerHTML;
    // console.log('Modified HTML:', modifiedHtml);
    return modifiedHtml;
  } catch (error) {
    console.log({ error, html });
    return html;
  }
};

function getNumeralType(input) {
  // Regular expressions to check for English and Roman numerals
  const englishNumeralRegex = /^\d/;
  const romanNumeralRegex = /^(M|D|C|L|X|V|I)/i;

  // Match and store and remove the unnecessary characters at the start of the input
  const unnecessaryChars = input.match(/^[ \t\r\n#*'"`]+/);
  const trimmedInput = input.replace(/^[ \t\r\n#*'"`]+/, "");

  if (
    unnecessaryChars &&
    !unnecessaryChars[0].includes("**") &&
    !unnecessaryChars[0].includes("##")
  ) {
    // console.log("Not a heading.",unnecessaryChars,);
    return null;
  }

  const charsBeforeNumber = unnecessaryChars ? unnecessaryChars[0].length : 0;
  // console.log(`Found ${lengthOfUnnecessaryChars} unnecessary characters at the start.`);

  // Check if the first character looks like an English or Roman numeral
  if (englishNumeralRegex.test(trimmedInput)) {
    const type = "english";
    return { type, charsBeforeNumber };
  } else if (romanNumeralRegex.test(trimmedInput)) {
    const type = "roman";
    return { type, charsBeforeNumber };
  } else {
    // console.log("No valid English or Roman numeral found.");
    return null;
  }
}

export function convertGeneratedArticlesToHTML(articleString) {
  const lines = articleString.split(/\n+/);
  let html = "";
  let currentHeading = "";
  let romanNumerals = false;
  let hasromanNumerals = false;
  let lineCount = 0;
  for (let line of lines) {
    romanNumerals = false;
    let numberInfo = getNumeralType(line);
    line = line.trim();
    let isHeading2, isHeading3, isHeading4, isHeading5;
    if (numberInfo) {
      isHeading5 =
        line.match(/^\*\*\d+\.\d+\.\d+\.\d+\ .*?\*\*$/) ||
        line.startsWith("#####");
      isHeading4 =
        line.match(/^\*\*\d+\.\d+\.\d+\ .*?\*\*$/) ||
        (line.startsWith("####") && !isHeading5);
      isHeading3 =
        line.match(/^\*\*\d+\.\d+\ .*?\*\*$/) ||
        (line.startsWith("###") && !(isHeading5 || isHeading4));
      isHeading2 =
        line.match(/^\*\*\d+\.\ .*?\*\*$/) ||
        (!(isHeading3 || isHeading4 || isHeading5) &&
          ((line.startsWith("**") &&
            line.endsWith("**") &&
            numberInfo !== null) ||
            line.startsWith("#")));
      // if (isHeading2) {
      //   romanNumerals = numberInfo.type === "roman";
      //   if (!hasromanNumerals && lineCount === 0) {
      //     hasromanNumerals = romanNumerals;
      //   }
      //   // console.log(numberInfo);
      // }
    } else {
      isHeading4 = !isHeading5 && line.startsWith("####");
      isHeading3 = !(isHeading4 || isHeading5) && line.startsWith("###");
      isHeading2 =
        !(isHeading3 || isHeading4 || isHeading5) && line.startsWith("#");
    }
    const isHeading = isHeading2 || isHeading3 || isHeading4 || isHeading5;
    // console.log("isHeading: ", !isHeading, !line.match(/[\*]+ *?[\*]+/));
    const isBoldContent = line.match(/[*]+(.*?)[*]+/g) && !isHeading;

    const startsWithHash = line.startsWith("#");

    let sliceUntil = 0;
    for (let i = 0; i < line.length; i++) {
      const char = line.charAt(i);
      if (char !== "*" && char !== "#") {
        break;
      }
      sliceUntil++;
    }

    if (isHeading2 || isHeading3 || isHeading4 || isHeading5) {
      let headingLevel;
      //   console.log("hasromanNumerals: ",hasromanNumerals,startsWithHash)
      if (hasromanNumerals && !startsWithHash)
        headingLevel = isHeading2 ? 3 : isHeading3 ? 4 : isHeading4 ? 5 : 6;
      else headingLevel = isHeading2 ? 2 : isHeading3 ? 3 : isHeading4 ? 4 : 5;

      const sliceNum = numberInfo?.charsBeforeNumber || sliceUntil || 2;
      if (romanNumerals) {
        html += `<h${2}>${line.slice(sliceNum, -sliceNum)}</h${2}>`;
      } else {
        if (line.startsWith("#")) {
          html += `<h${headingLevel}>${line.slice(
            sliceNum
          )}</h${headingLevel}>`;
        } else {
          html += `<h${headingLevel}>${line.slice(
            sliceNum,
            -sliceNum
          )}</h${headingLevel}>`;
        }
        currentHeading = `h${headingLevel}`;
      }
    } else if (isBoldContent) {
      const regex = /[*]+(.*?)[*]+/g;
      // Replace the matched text with <strong> tags
      const replacedText = line.replace(regex, "<b>$1</b>");
      html += `<p>${replacedText}</p>`;
    } else {
      // Add a <br> tag inside the <p> tag if the line is empty.
      const content = line ? line : "<br>";
      if (!(line.startsWith("<") && line.endsWith(">")))
        html += `<p>${content}</p>`;
    }
    lineCount++;
  }

  return html;
}

export function getTimeAgo(targetDate) {
  // console.log("targetDate: " + targetDate);
  const now = new Date();
  const target = new Date(targetDate);
  const timeDifference = now.getTime() - target.getTime();
  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = days / 30;
  const years = days / 365;

  if (minutes < 1) {
     if (seconds < 5) {
      return `just now`;
    } else if(seconds<30 && seconds>5) {
      return `few seconds ago`;
    }
     else {
       return `30 seconds ago`
    }
    // return `just now`;
  } else if (hours < 1) {
    return `${Math.floor(minutes)} minutes ago`;
  } else if (days < 1) {
    return `${Math.floor(hours)} hours ago`;
  } else if (weeks < 1) {
    return `${Math.floor(days)} days ago`;
  } else if (months < 1) {
    return `${Math.floor(weeks)} weeks ago`;
  } else if (years < 1) {
    return `${Math.floor(months)} months ago`;
  } else {
    return `${Math.floor(years)} years ago`;
  }
}
export function getFullDate(timeInMilliseconds) {
  const date = new Date(timeInMilliseconds);

  // Define an array to map month numbers to their names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the date components
  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();

  // Get the time components
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad single digits with a leading zero
  const padWithZero = (num) => (num < 10 ? "0" + num : num);

  // Format the time string
  const formattedTime = `${day} ${monthName} ${year} ${padWithZero(
    hours
  )}:${padWithZero(minutes)}:${padWithZero(seconds)}`;
  return formattedTime;
}

export const checkForH1Heading = (htmlString) => {
  // Step 1: Parse the HTML string into a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Step 2: Traverse the DOM tree to check for h1 elements
  const h1Elements = doc.getElementsByTagName("h1");

  // Step 3: Update the state based on the search result
  return h1Elements.length > 0;
};

export function hasTextContent(htmlString) {
  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Check if there is any text content in the parsed HTML
  const hasText = tempElement.textContent.trim().length > 0;

  return hasText;
}

const parseHtml = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body;
};

export const getHeadings = (element, level = 1) => {
  const headings = [];
  const children = Array.from(parseHtml(element).children);

  for (const child of children) {
    if (child.tagName.startsWith("H")) {
      headings.push({
        heading: child.innerText,
        level: parseInt(child.tagName.substr(1), 10),
      });
    }
    headings.push(...getHeadings(child, level));
  }

  return headings;
};
