import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./SavedAds.module.css";
import GoogleSearchAd from "../../components/Ad/GoogleSearchAd/GoogleSearchAd";
import FacebookAd from "../../components/Ad/FacebookAd/FacebookAd";
import DisplayAd from "../../components/Ad/DisplayAd/DisplayAd";
import { getSavedAds } from "../../slices/savedAdsSlice";

function SavedAds() {
  const dispatch = useDispatch();
  const savedAds = useSelector((state) => state.savedAdsSlice.savedAds);
  // const userId = "630dbf15592c0acda000e654";
  const userId = "";

  useEffect(() => {
    dispatch(getSavedAds({ userId }));
  }, [dispatch]);

  return (
    <div className="savedAds-main">
      {savedAds && (
        <div className={styles.grid}>
          {savedAds.map((ad) => {
            let isSaved = false;
            if (savedAds && savedAds.find((e) => e.ad === ad._id)) {
              isSaved = true;
            }
            if (ad.type === "displayAd") {
              return (
                <div key={ad._id} className={styles.box}>
                  <DisplayAd ad={ad} isSaved={isSaved} userId={userId} />
                </div>
              );
            } else if (ad.type === "searchAd") {
              return (
                <div key={ad._id} className={styles.box}>
                  <GoogleSearchAd ad={ad} isSaved={isSaved} userId={userId} />
                </div>
              );
            } else if (ad.type === "fbAd") {
              return (
                <div key={ad._id} className={styles.box}>
                  <FacebookAd ad={ad} isSaved={isSaved} userId={userId} />
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default SavedAds;
