import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SearchForm.module.css";
import { getAdsForAdvancedSearch, getAdsForPredefinedSearchTerm, getAdsForSearchTerm } from "../../slices/adSlice";
import { debounce } from "../../helpers/stubs";
import { newAlert } from "../../slices/alertSlice";
import { clearAdsArray } from "../../slices/adSlice"
import { useLocation } from "react-router-dom";
import { defaultSearchTerm } from "../../config";

const searchDebounced = debounce((searchTerm, dispatch) => {
  // console.log(`Auto complete suggestions can be handled here "${searchTerm}"`);
}, 500);

const SearchBox = ({ searchTerm, handleSearchInputChange }) => {
  return (
    <input
      type="text"
      className={`${styles["search-input"]} form-control`}
      placeholder="Coming soon..."
      aria-label="Enter your keywords"
      aria-describedby="button-addon2"
      // defaultValue={searchTerm}
      defaultValue= ""
      onChange={handleSearchInputChange}
      name="keywords"
    />
  );
};

const SearchButtonIcon = ({ handleSearchBtnClick }) => {
  return (
    <button className={`${styles["enter-keywords-btn"]} btn`} type="button" onClick={handleSearchBtnClick} style={{backgroundColor:'#d8ad60', opacity:'1'}} disabled>
      <i className="fa fa-search" />
    </button>
  );
};

// const SearchType = ({ setSearchType }) => {
//   return (
//     <select
//       className={`${styles["search-input"]} ${styles["type-select"]} form-select d-inline-block`}
//       aria-label="types select"
//       defaultValue={"All types"}
//       name="type"
//       onChange={(e) => setSearchType(e.target.value)}
//     >
//       <option value={"All types"}>All types</option>
//       <option value={"fbAds"}>Facebook Ads</option>
//       <option value={"displayAds"}>Display Ads</option>
//       <option value={"searchAds"}>Search Ads</option>
//     </select>
//   );
// };

const SearchForm = ({ setSearchCount, searchTerm, setSearchTerm, adType, setAdType, industryType, setIndustryType, isAdvancedSearchOn, setIsAdvancedSearchOn, pageNumber, setPageNumber, setLoader, setActiveKey }) => {

  const dispatch = useDispatch();
  // const adsSlice = useSelector((state) => state.adsSlice);
  const { sessionUser } = useSelector(state => state.sessionSlice);

  //read query params
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const searchTermInUrl = params.get("st");
  const pageNumberInUrl = params.get("p");
  // const industryTypeInUrl = params.get("ind_t");
  const adTypeInUrl = params.get("ad_t");

  useEffect(() => {
    if (sessionUser?.sessionId) {
      if (searchTermInUrl != null && searchTerm === undefined) {
        setSearchTerm(searchTermInUrl);
        setPageNumber(pageNumberInUrl);
      setActiveKey('');
        //no of ads
        // if (industryTypeInUrl) {
        //   setIndustryType(industryTypeInUrl);
        //   if (adTypeInUrl) setAdType(adTypeInUrl);
        //   setIsAdvancedSearchOn(true)
        //   dispatch(getAdsForAdvancedSearch({ searchTerm: searchTermInUrl, adType: adTypeInUrl ? adTypeInUrl : adType, industryType: industryTypeInUrl, pageNumber: parseInt(pageNumberInUrl), loadAdsTillPageNumber: parseInt(pageNumberInUrl) }))
        // }
        if (adTypeInUrl && adTypeInUrl != "All types") {
          setAdType(adTypeInUrl);
          // setIsAdvancedSearchOn(true)
          dispatch(getAdsForAdvancedSearch({ searchTerm: searchTermInUrl, adType: adTypeInUrl ? adTypeInUrl : adType, pageNumber: parseInt(pageNumberInUrl), loadAdsTillPageNumber: parseInt(pageNumberInUrl), setLoader }))
        }
        else if (!adTypeInUrl || adTypeInUrl == "All types") {
          dispatch(getAdsForSearchTerm({ searchTerm: searchTermInUrl, pageNumber: parseInt(pageNumberInUrl), loadAdsTillPageNumber: parseInt(pageNumberInUrl), setLoader }))
        }
      }
    }

  }, [searchTermInUrl, adTypeInUrl, sessionUser]);

  useEffect(() => {
    if (searchTerm === undefined && searchTermInUrl === null) {
      setPageNumber(1);
      //no of ads
      // if (industryTypeInUrl) {
      //   // setIndustryType(industryTypeInUrl);
      //   if (adTypeInUrl) setAdType(adTypeInUrl);
      //   setIsAdvancedSearchOn(true)
      //   dispatch(getAdsForAdvancedSearch({ searchTerm: defaultSearchTerm, adType: adTypeInUrl ? adTypeInUrl : adType, industryType: industryTypeInUrl, pageNumber: 1, loadAdsTillPageNumber: 1 }))
      // }
      // else if (!industryTypeInUrl) {
      //   dispatch(getAdsForSearchTerm({ searchTerm: defaultSearchTerm, pageNumber: 1, loadAdsTillPageNumber: 1 }))

      // }
      // setIsAdvancedSearchOn(true)
      dispatch(getAdsForPredefinedSearchTerm({ searchTerm: defaultSearchTerm, pageNumber: 1, loadAdsTillPageNumber: 1, setLoader }))
    }
  }, []);

  useEffect(() => {
    // logic for autocomplete suggestion while user types
    if (searchTerm !== "") searchDebounced(searchTerm, dispatch);
  }, [searchTerm, dispatch]);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setPageNumber(1)
    setSearchTerm(searchTerm);
  };

  const handleSearchBtnClick = (e) => {
    e.preventDefault();
    setPageNumber(1)
    setLoader(true)
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    // dispatch(getAdsForSearchTerm({ encodedSearchTerm, searchType }));
    if (adType != "All types") {
      //change url => add searchTerm and pageNumber in url
      // if (searchTerm && adType && industryType) {
      if (searchTerm && adType) {
        dispatch(clearAdsArray())
        setActiveKey('')
        // window.history.pushState({}, "", `?st=${searchTerm}&p=${pageNumber}&ind_t=${industryType}&ad_t=${adType}`);
        // dispatch(getAdsForAdvancedSearch({ searchTerm, adType, industryType, pageNumber }))
        window.history.pushState({}, "", `?st=${searchTerm}&p=${1}&ad_t=${adType}`);
        dispatch(getAdsForAdvancedSearch({ searchTerm, adType, pageNumber: 1, setLoader }))
      }
      else {
        dispatch(newAlert({ type: "danger", message: "Enter all fields" }))
        setLoader(false)
      }
    }
    else if (adType == "All types") {
      //change url => add searchTerm and pageNumber in url
      if (searchTerm) {
        setActiveKey('')
        dispatch(clearAdsArray())
        window.history.pushState({}, "", `?st=${searchTerm}&p=${1}`);
        dispatch(getAdsForSearchTerm({ searchTerm, pageNumber: 1, setLoader }))
      } else {
        dispatch(newAlert({ type: "danger", message: "Search field cannot be empty" }))
        setLoader(false)
      }
    }

    // dispatch(saveSearchTermToMaster({searchTerm}))
    setSearchCount(prev => prev + 1)
    const count = parseInt(localStorage.getItem("searchCount")) + 1
    localStorage.setItem("searchCount", count)
  };

  const AdvanceSearchTextWithArrow = () => {
    return (
      <button className={`${styles["toggle-advanced-search"]} text-info mt-3`} onClick={() => setIsAdvancedSearchOn((prev) => !prev)}>
        advanced search
        {isAdvancedSearchOn ? <i className="fa-solid fa-angle-up ms-2" /> : <i className="fa-solid fa-angle-down ms-2"></i>}
      </button>
    );
  };

  return (
    <div id='home'>
      <div className={`${styles["search-ads-container"]} py-5 px-sm-3`}>
        <div className="container">
          <div className="description mb-4">
            <p className="text-center mb-1">Lorem ipsum dolor sit ame</p>
            <h1 className={`${styles["description-h1"]} text-uppercase text-center fw-bold`}>find ideas for marketing</h1>
          </div>
          <div className="search px-1">
            <form onSubmit={handleSearchBtnClick}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div className="input-group mb-3" style={{ width: '80%' }}>
                  <SearchBox searchTerm={searchTerm} handleSearchInputChange={handleSearchInputChange} />
                  <SearchButtonIcon handleSearchBtnClick={handleSearchBtnClick} />
                </div>
                <select
                  className={`${styles["search-input"]} ${styles["type-select"]} form-select d-inline-block`}
                  aria-label="types select"
                  defaultValue={adTypeInUrl ? adTypeInUrl : adType}
                  name="type"
                  onChange={(e) => setAdType(e.target.value)}
                  style={{ height: "55px", display: "inline-block" }}
                >
                  <option value={"All types"}>All types</option>
                  <option value={"fbAd"}>Facebook Ads</option>
                  <option value={"displayAd"}>Display Ads</option>
                  <option value={"searchAd"}>Search Ads</option>
                </select>
              </div>

              {isAdvancedSearchOn && (
                <div className="advanced-search-collapsible d-flex flex-column flex-lg-row">
                  <input
                    type="text"
                    className={`${styles["search-input"]} ${styles["max-w-lg-25"]} form-control mb-3 me-lg-3 max-w-25`}
                    placeholder="Enter Industry"
                    aria-label="Enter Industry"
                    aria-describedby="button-addon2"
                    name="industry"
                    defaultValue={industryType}
                    onChange={(e) => setIndustryType(e.target.value)}
                  />
                  {/* <input
                    type="text"
                    className={`${styles["search-input"]} ${styles["max-w-lg-25"]} form-control mb-3`}
                    placeholder="Enter Product"
                    aria-label="Enter Product"
                    aria-describedby="button-addon2"
                    name="product"
                  /> */}
                  <select
                    className={`${styles["search-input"]} ${styles["type-select"]} form-select d-inline-block`}
                    aria-label="types select"
                    defaultValue={adType}
                    name="type"
                    onChange={(e) => setAdType(e.target.value)}
                    style={{ height: "55px" }}
                  >
                    <option value={"All types"}>All types</option>
                    <option value={"fbAd"}>Facebook Ads</option>
                    <option value={"displayAd"}>Display Ads</option>
                    <option value={"searchAd"}>Search Ads</option>
                  </select>
                </div>
              )}

              {/* ----------Ads Type---------- */}

              {/* <SearchType setSearchType={setSearchType} /> */}
            </form>
            {/* <AdvanceSearchTextWithArrow /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
