import React from "react";
import ForgotPasswordPage from "../ForgotPasswordPage/ForgotPasswordPage";

const ApplicantForgotpassword = () => {
  return (
    <div>
      <ForgotPasswordPage fApplicantPage={true} />
    </div>
  );
};

export default ApplicantForgotpassword;
