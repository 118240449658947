import React, { useEffect, useState } from "react";
import SearchTermsReport from "../../../components/SearchTermsAnalysis/SearchTermsReport/SearchTermsReport";
import SearchTermsReportOptions from "../../../components/SearchTermsAnalysis/SearchTermsReportOptions/SearchTermsReportOptions";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
const SearchTermsAnalysis = () => {
  const [searchTermsReport, setSearchTermsReport] = useState([]);
  const [loading, setLoading] = useState();
  const [display, setDisplay] = useState(false);
  const [searchParams] = useSearchParams();
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const navigate = useNavigate();
  const [searchTermFilter, setSearchTermFilter] = useState("");
  const [sortOrder, setSortOrder] = useState(0);
  const [sortValue, setSortValue] = useState("");
  const [sortBtn, setSortBtn] = useState("");

  return (
    <div>
      <SearchTermsReportOptions
        setSearchTermsReport={setSearchTermsReport}
        setSearchTermFilter={setSearchTermFilter}
        setSortOrder={setSortOrder}
        setSortValue={setSortValue}
        setLoading={setLoading}
        setDisplay={setDisplay}
        setSortBtn={setSortBtn}
      />
      <SearchTermsReport
        searchTermsReport={searchTermsReport}
        loading={loading}
        display={display}
        setDisplay={setDisplay}
        searchTermFilter={searchTermFilter}
        setSearchTermFilter={setSearchTermFilter}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortValue={sortValue}
        setSortValue={setSortValue}
        sortBtn={sortBtn}
        setSortBtn={setSortBtn}
      />
    </div>
  );
};

export default SearchTermsAnalysis;
