import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { newAlert } from "./alertSlice";
import AxiosInstanceWithMarketingNetBackendUrl from "../axios";
import Axios from "axios";

export const getSession = createAsyncThunk(
  "session/getSession",
  async (payload, ThunkAPI) => {
    try {
      const { data } = await AxiosInstanceWithMarketingNetBackendUrl.get(
        "/api/session/getSession"
      );
      console.log("getSession", data);
      return data;
    } catch (error) {
      const { data } = error.response;
      console.log("error in signIn", data);
      return Promise.reject(data);
    }
  }
);

// Thunks
export const signIn = createAsyncThunk(
  "session/signIn",
  async (payload, thunkAPI) => {
    try {
      const { data } = await AxiosInstanceWithMarketingNetBackendUrl.post(
        "/api/public/signIn",
        payload
      );
      // if (!data.error) {
      //   thunkAPI.dispatch(
      //     newAlert({ type: "success", message: "Sign In Completed" })
      //   );
      // }
      return data;
      // console.log(data);
    } catch (error) {
      const { data } = error.response;
      if (data.loginStatus?.includes("Incorrect Email or Password")) {
        throw new Error("Invalid credentials");
      } else if (data.loginStatus === "verifyEmail") {
        throw new Error("verifyEmail");
      }
      return Promise.reject(data.error);
    }
  }
);

export const adminSignIn = createAsyncThunk(
  "session/adminSignIn",
  async (payload, thunkAPI) => {
    try {
      const { data } = await AxiosInstanceWithMarketingNetBackendUrl.post(
        "/api/public/admin-sign-in",
        payload
      );
      if (!data.error) {
        thunkAPI.dispatch(
          newAlert({ type: "success", message: "Sign In Completed" })
        );
      }
      return data;
      // console.log(data);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        return Promise.reject(data.loginStatus);
      } else {
        return Promise.reject(error.message);
      }
    }
  }
);

export const signUp = createAsyncThunk(
  "session/signUp",
  async (payload, thunkApi) => {
    try {
      const { data } = await AxiosInstanceWithMarketingNetBackendUrl.post(
        "/api/public/signup",
        payload
      );
      console.log(data);
      // if (!data.error) {
      //   thunkApi.dispatch(
      //     newAlert({ type: "success", message: "Sign Up Completed" })
      //   );
      // }

      return data;
    } catch (error) {
      const { data } = error.response;
      console.log("error in signUp", data.error);
      thunkApi.dispatch(
        newAlert({
          type: "danger",
          message: data.error ? data.error.message : error.message,
        })
      );
      return Promise.reject(data.error);
    }
  }
);

//#region SignOut
export const signOut = createAsyncThunk(
  "session/SignOut",
  async (payload, thunkApi) => {
    try {
      const { navigate } = payload;
      const { data } = await AxiosInstanceWithMarketingNetBackendUrl.post(
        `/api/user/sign-out`
      );
      if (data.success && !data.fRedirectToAuthms) {
        window.location.reload(true);
      }
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkApi.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);
//#endregion

//#region VerifyEmail
export const verifyEmail = createAsyncThunk(
  "session/verifyEmail",
  async (payload, thunkApi) => {
    try {
      const { data } = await AxiosInstanceWithMarketingNetBackendUrl.post(
        `/api/session/verify-email`,
        payload
      );

      return data;
    } catch (error) {
      const { data } = error.response;
      console.log("data", error, data.error);
      if (data && data.error && data.error.message === "Invalid Link") {
        thunkApi.dispatch(
          newAlert({ type: "danger", message: "Invalid Link" })
        );
        window.location.href = "/";
      }
      // thunkApi.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      throw new Error(data.error.message);
    }
  }
);
//#endregion

//#region resendVerificationEmail
export const resendVerificationEmail = createAsyncThunk(
  "session/resendVerificationEmail",
  async (payload, thunkApi) => {
    try {
      const { REACT_APP_AUTHMS_BACK_END_DOMAIN: backendURL, REACT_APP_DOMAIN } =
        process.env;
      const { data } = await Axios.post(
        `${backendURL}/api/public/resend-verification-email`,
        { ...payload, domain: REACT_APP_DOMAIN }
      );
      console.log(data);
      if (!data.error) {
        thunkApi.dispatch(
          newAlert({ type: "success", message: "Verification Email Sent" })
        );
      }

      return data;
    } catch (error) {
      const { data } = error.response;
      console.log("error in signUp", data.error);
      if (data.error.message !== "userDoesNotExists") {
        thunkApi.dispatch(
          newAlert({ type: "danger", message: data.error.message })
        );
      }
      throw new Error(data.error.message);
    }
  }
);

//#endregion
export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    sessionUser: {},
    loading: false,
    error: "",
    pending: false,
    fAuthCheckCompleted: false,
    fRedirectUserToVerifyEmailMessagePage: false,
    fRedirectUserToEmailAlreadyVerifiedEmailPage: false,
  },
  reducers: {
    setError: (state, { payload }) => {
      console.log("error", payload);
      state.error = payload;
    },
    setSessionUserFromCookie: (state, { payload }) => {
      state.sessionUser = { ...state.sessionUser, ...payload };
    },
    checkUserExist_RedirectToLoginOrOtpModal: (state, { payload }) => {
      state.sessionUser = { ...state.sessionUser, ...payload };
    },
    changeEmailPendingUser: (state, { payload }) => {
      state.sessionUser = { ...state.sessionUser, ...payload };
    },
    otpVerified: (state, { payload }) => {
      state.sessionUser = { ...state.sessionUser, ...payload };
    },
    UpdateUser: (state, { payload }) => {
      state.sessionUser = { ...state.sessionUser, ...payload };
    },
    updateEmailFromSignUp: (state, { payload }) => {
      state.sessionUser.pendingUser = { email: payload };
    },
    setAuthCheckCompleted: (state, { payload }) => {
      state.fAuthCheckCompleted = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setFRedirectUserToVerifyEmailMessagePage: (state, { payload }) => {
      state.fRedirectUserToVerifyEmailMessagePage = payload;
    },
    setFRedirectUserToEmailAlreadyVerifiedEmailPage: (state, { payload }) => {
      state.fRedirectUserToEmailAlreadyVerifiedEmailPage = payload;
    },
  },

  extraReducers: {
    [getSession.fulfilled]: (state, { payload }) => {
      state.sessionUser = payload.sessionUser;
      state.loading = false;
    },
    [getSession.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSession.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [signIn.fulfilled]: (state, { payload }) => {
      // state.sessionUser = { ...state.sessionUser, ...payload };
      console.log("payload", state.sessionUser);
      const { REACT_APP_AUTHMS_BACK_END_DOMAIN, REACT_APP_HOMEPAGE_URL } =
        process.env;
      const url = `${REACT_APP_AUTHMS_BACK_END_DOMAIN}?fAuthenticated=true&redirectTo=${REACT_APP_HOMEPAGE_URL}?action=si`;
      window.location.href = url;
    },
    [signIn.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [signIn.rejected]: (state, { error }) => {
      console.log(error);
      if (error && error.message === "verifyEmail") {
        state.fRedirectUserToVerifyEmailMessagePage = true;
      } else {
        state.error = error.message;
      }
      state.loading = false;
    },
    [adminSignIn.fulfilled]: (state, { payload }) => {
      state.sessionUser = {
        ...state.sessionUser,
        user: { ...payload.admin },
        ...payload,
      };
      state.loading = false;
    },
    [adminSignIn.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [adminSignIn.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [signUp.fulfilled]: (state, { payload }) => {
      state.pending = true;
      state.sessionUser = payload.pendingUser;
      state.loading = false;
    },
    [signUp.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [signUp.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [signOut.fulfilled]: (state, { payload }) => {
      console.log(payload, "payload");
      if (payload.fRedirectToAuthms) {
        const { REACT_APP_AUTHMS_BACK_END_DOMAIN, REACT_APP_HOMEPAGE_URL } =
          process.env;
        const url = `${REACT_APP_AUTHMS_BACK_END_DOMAIN}/sign-out?redirectTo=${REACT_APP_HOMEPAGE_URL}?action=lo`;
        window.location.href = url;
      }
    },
    [signOut.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [signOut.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [verifyEmail.fulfilled]: (state, { payload }) => {
      const { REACT_APP_AUTHMS_BACK_END_DOMAIN, REACT_APP_HOMEPAGE_URL } =
        process.env;
      const url = `${REACT_APP_AUTHMS_BACK_END_DOMAIN}?fAuthenticated=true&redirectTo=${REACT_APP_HOMEPAGE_URL}?action=su`;
      window.location.href = url;
    },
    [verifyEmail.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [verifyEmail.rejected]: (state, { error }) => {
      if (error && error.message === "noPendingUserExists") {
        state.fRedirectUserToEmailAlreadyVerifiedEmailPage = true;
      } else {
        state.error = error.message;
      }
      state.loading = false;
    },
    [resendVerificationEmail.fulfilled]: (state, { payload }) => {},
    [resendVerificationEmail.pending]: (state, { payload }) => {},
    [resendVerificationEmail.rejected]: (state, { error }) => {
      if (error && error.message === "userDoesNotExists") {
        state.fRedirectUserToEmailAlreadyVerifiedEmailPage = true;
      }
    },
  },
});

// Export Actions
export const {
  setError,
  setSessionUserFromCookie,
  checkUserExist_RedirectToLoginOrOtpModal,
  changeEmailPendingUser,
  otpVerified,
  UpdateUser,
  updateEmailFromSignUp,
  setAuthCheckCompleted,
  setLoading,
  setFRedirectUserToVerifyEmailMessagePage,
  setFRedirectUserToEmailAlreadyVerifiedEmailPage,
} = sessionSlice.actions;

// Export Reducer
export default sessionSlice.reducer;
