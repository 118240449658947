import React from "react";

const ShowOutline = ({ outlineState }) => {
  const colors = ["#800000", "#8B0000", "#A52A2A", "#B22222", "#DC143C"];

  return (
    <div className="border-start ps-2 w-50 outline-container">
      {outlineState.length < 1 && (
        <div>
          <h6>No outline</h6>
          <p>
            Try{" "}
            <span className="fst-italic bg-light">
              Show Outline Without Statements
            </span>{" "}
            or{" "}
            <span className="fst-italic bg-light">
              Show Outline With Statements
            </span>
          </p>
        </div>
      )}
      {outlineState.map((block) => {
        let backgroundColor;
        if (block.question) {
          backgroundColor = "#fff0ff";
        }

        if (block.point) {
          backgroundColor = "#dff0ff";
        }
        if (block.sentence) {
          backgroundColor = "#e8fcff";
        }
        let colors;
        if (block.question) {
          colors = "outline-question";
        }
        if (block.point) {
          colors = "outline-point";
        }
        if (block.sentence) {
          colors = "outline-sentence";
        }
        return (
          <p
            key={block._id || block.question}
            style={{
              marginLeft: `${block.level * 20}px`,
              // color: `${colors[block.level]}`,
              // backgroundColor: `${backgroundColor}`,
              fontSize: "12px",
            }}
            className={`rounded px-1 my-1  ${
              block.relationText
                ? "fst-italic text-muted border-0 bg-transparent"
                : ""
            } ${colors}`}
          >
            {block.text || block.point || block.sentence || block.relationText}
            {/* {block.text && `Q - ${block.text}`}
            {block.point && `P - ${block.point}`}
            {block.sentence && `S - ${block.sentence}`}
            {block.relationText && `R - ${block.relationText}`} */}
          </p>
        );
      })}
    </div>
  );
};

export default ShowOutline;
