import React from "react";

const CustomModal = ({ modalDetails, setModalDetails }) => {
  return (
    <div>
      {modalDetails.active && (
        <div className="">
          <div className="modal-background"></div>
          <div
            className="bg-light border rounded position-fixed top-50 start-50 translate-middle zindex-modal "
            style={{
              width: "70%",
              zIndex: 20,
              boxShadow: "1px 1px 6px gray",
            }}
          >
            <h4 className="text-center m-4">{modalDetails.title}</h4>
            <h6 className="text-center">
              {modalDetails.content && <span>{modalDetails.content}</span>}
            </h6>
            <div className="d-flex w-100 justify-content-center">
              <button
                className="btn btn-success mx-4 my-2"
                onClick={modalDetails.onClick}
              >
                Yes
              </button>
              <button
                className="btn btn-danger mx-4 my-2"
                onClick={() => {
                  setModalDetails({
                    active: false,
                    title: "",
                    content: "",
                    onClick: null,
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomModal;
