import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// #region Views Imports
import PageNotFound from "./views/public/PageNotFound/PageNotFound";
import AdSearch from "./views/public/LandingPage/LandingPage";
import Navbar from "./containers/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import SignInPage from "./views/public/SignInPage/SignInPage";
import SignUpPage from "./views/public/SignUpPage/SignUpPage";
import VerifyEmailPage from "./views/public/VerifyEmailPage/VerifyEmailPage";
import SavedAdsPage from "./views/public/SavedAdsPage/SavedAdsPage";
import SearchForm from "./containers/SearchForm/SearchForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getSession,
  setSessionUserFromCookie,
  setAuthCheckCompleted,
} from "./slices/sessionSlice";
import { getCookie } from "./helpers/stubs";
// #endregion
import Axios from "./axios";
import ForgotPasswordPage from "./views/public/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./views/public/ResetPasswordPage/ResetPasswordPage";
import AdminSignInPage from "./views/public/AdminSignInPage/AdminSignInPage";
import AdminAdsBySearchTerm from "./views/public/AdminAdsBySearchTerm/AdminAdsBySearchTerm";
import SearchTermsAnalysis from "./views/public/SearchTermsAnalysis/SearchTermsAnalysis";
import AdPage from "./views/public/AdPage/AdPage";
import ChatGPT from "./views/public/ChatGPT/ChatGPT";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Newsfeed from "./views/public/NewsFeed/NewsFeed";
import ProfilePage from "./views/public/ProfilePage/ProfilePage";
import { CreatePost } from "./components/NewsFeed/CreatePost/CreatePost";
import ChatGPTInterfaceV2 from "./views/public/ChatGPTInterfaceV2/ChatGPTInterfaceV2";
import ChatGptResponse from "./views/public/GetChatGptResponse/ChatGptResponse";
// import ArticlePage from "./views/public/ArticlePage/ArticlePage";
import GenerateArticleStatus from "./views/public/GenerateArticleStatus/GenerateArticleStatus";
import ReviewGeneratedArticlesV2 from "./views/public/ReviewGeneratedArticlesV2/ReviewGeneratedArticlesV2";
import ArticlePage from "./views/public/ArticlePage/ArticlePage";
import CourseOutline from "./views/admin/courseOutline/CourseOutline.js";
import PreviewPost from "./components/NewsFeed/PreviewPost/PreviewPost";
import { EditPost } from "./components/NewsFeed/EditPost/EditPost";
import BookmarkedAndLikedArticles from "./components/BookmarkedAndLikedArticles/BookmarkedAndLikedArticles";
import MyCourses from "./views/user/MyCourses/MyCourses";
import CoursePage from "./views/user/CoursePage/Course";
import CreateCourse from "./views/admin/CreateCourse/CreateCourse";
import EditSubsection from "./views/admin/EditSubsection/EditSubsection";
import EditSection from "./views/admin/EditSection/EditSection";
import ApplicationInterface from "./views/public/ApplicationInterface/ApplicationInterface.jsx";
import HeaderWrapper from "./containers/HeaderFooterWrapper/HeaderWrapper.js";
import FooterWrapper from "./containers/HeaderFooterWrapper/FooterWrapper.js";
import ApplicantForgotpassword from "./views/public/ApplicationInterface/ApplicantForgotpassword.js";
import ApplicantResetpassword from "./views/public/ApplicationInterface/ApplicantResetpassword.js";
import ApplicantSignIn from "./views/public/ApplicationInterface/ApplicantSignIn.js";
import ApplicationStatus from "./views/public/ApplicationStatus/ApplicationStatus.jsx";
import Testing from "./views/public/Testing/Testing.jsx";
import VerifyEmailMessagePage from "./components/VerifyEmailMessagePage/VerifyEmailMessagePage.jsx";
import PageSpinner from "./components/PageSpinner/PageSpinner.jsx";
import { newAlert } from "../src/slices/alertSlice.js";
import EmailAlreadyVerifiedPage from "../src/components/EmailAlreadyVerified/EmailAlreadyVerified.jsx";
import IframeComponent from "./components/IframeComponent/IframeComponent.jsx";

function App() {
  const dispatch = useDispatch();
  const { fAuthCheckCompleted } = useSelector(
    (state) => state.sessionSlice
  );

  useEffect(() => {
    const createSession = async (retry = 1) => {
      try {
        await Axios.post("/api/public/create-session");
        getCookie_updateReduxStore();
      } catch (error) {
        if (retry > 0) {
          console.log("Retrying createSession...");
          createSession(retry - 1);
        } else {
          console.error("createSession failed:", error);
        }
      }
    };

    const getCookie_updateReduxStore = () => {
      const sessionCookie = getCookie("sc");
      if (sessionCookie) {
        const { sessionId, pendingUser, user } = JSON.parse(sessionCookie);
        dispatch(setSessionUserFromCookie({ sessionId, pendingUser, user }));
      }
    };

    const sessionCookie = getCookie("sc");
    if (!sessionCookie) {
      createSession();
    } else {
      getCookie_updateReduxStore();
    }

    const messageHandler = (event) => {
      if (event.data.type === "UPDATE_REDUX") {
        getCookie_updateReduxStore();
      } else if (event.data.type === "AUTH_CHECK_COMPLETED") {
        dispatch(setAuthCheckCompleted(true));
      }
    };

    window.addEventListener("message", messageHandler);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (fAuthCheckCompleted) {
      // Function to get query parameters from the URL
      const getQueryParams = (search) => {
        return new URLSearchParams(search);
      };

      // Get the query parameters from the current URL
      const queryParams = getQueryParams(window.location.search);
      const action = queryParams.get("action");
      // console.log(queryParams, action);
      if (action === "si" || action === "su" || action === "lo") {
        let message = null;
        if (action === "si") {
          message = "Sign-in successfull";
        } else if (action === "su") {
          message = "Email verification successfull";
        } else if (action === "lo") {
          message = "Signed Out";
        }

        if (message) {
          dispatch(
            newAlert({
              type: "success",
              message: message,
            })
          );
        }
      }
      queryParams.delete("action");
      const newSearch = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        newSearch ? `?${newSearch}` : ""
      }`;
      window.history.replaceState({}, "", newUrl);
    }
  }, [dispatch, fAuthCheckCompleted]);

  return (
    <>
      <BrowserRouter>
        <IframeComponent/>
        {fAuthCheckCompleted ? (
          <>
            <HeaderWrapper>
              <Navbar />
            </HeaderWrapper>
            <Routes>
              <Route path="/" element={<Newsfeed />} />
              <Route
                path="/user/bookmarked-articles"
                element={<BookmarkedAndLikedArticles />}
              />
              <Route path="/article-page/:id" element={<ArticlePage />} />
              <Route path="/ad-search" element={<AdSearch />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/verify-email" element={<VerifyEmailPage />} />
              <Route
                path="/verify-email-message"
                element={<VerifyEmailMessagePage />}
              />
              <Route
                path="/email-already-verified"
                element={<EmailAlreadyVerifiedPage />}
              />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/user/saved-ads" element={<SavedAdsPage />} />
              <Route
                path="/user/profile-page/:userId"
                element={<ProfilePage />}
              />
              <Route
                path="/user/profile-page/:userId/otp/:otp"
                element={<ApplicationInterface />}
              />
              <Route
                path="/applicant/forgot-password"
                element={<ApplicantForgotpassword />}
              />
              <Route
                path="/applicant/reset-password/:encodedURIEmail/:token"
                element={<ApplicantResetpassword />}
              />
              <Route path="/applicant/sign-in" element={<ApplicantSignIn />} />
              <Route
                path="/user/application-status/:email"
                element={<ApplicationStatus />}
              />
              {/* <Route path="/user/my-courses" element={<MyCourses />} /> */}
              {/* <Route path="/user/course/:courseName/:courseId" element={<CoursePage />} /> */}
              <Route path="/searchAds" element={<SearchForm />} />
              <Route
                path="/reset-password/:encodedURIEmail/:token"
                element={<ResetPasswordPage />}
              />
              {/* <Route path="/cross-origin-test" element={<Testing />} /> */}
              <Route path="/admin/sign-in" element={<AdminSignInPage />} />
              <Route path="/:adHeadline/:orgName/:adId" element={<AdPage />} />
              {/* <Route
            exact
            path="/valid"
            element={
              <PrivateRoute
                allowedUserTypes={["user"]}
                component={AdSearch}
              />
            }
          /> */}

              <Route path="*" element={<PageNotFound />} />
              <Route
                path="/admin/ads-by-search-term/:stemmedSearchTerm/:searchTermId"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <AdminAdsBySearchTerm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/search-terms-analysis"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <SearchTermsAnalysis />
                  </PrivateRoute>
                }
              />

              {/* <Route
            exact
            path="/admin/chatGPTInterface"
            element={
              <PrivateRoute adminRole="low-admin">
                <ChatGPT />
              </PrivateRoute>
            }
          /> */}
              <Route
                exact
                path="/admin/chatGPTInterfaceV2"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <ChatGPTInterfaceV2 />
                  </PrivateRoute>
                }
              />
              {/* <Route
            exact
            path="/admin/chatGPTInterface/:topicName/:topicId"
            element={
              <PrivateRoute adminRole="low-admin">
                <ChatGPT />
              </PrivateRoute>
            }
          /> */}
              <Route
                exact
                path="/admin/chatGPTInterfaceV2/:topicName/:topicId"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <ChatGPTInterfaceV2 />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/admin/review-generated-articles"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <GenerateArticleStatus />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/admin/review-generated-articles-v2"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <ReviewGeneratedArticlesV2 />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/admin/get-chat-gpt-api-response"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <ChatGptResponse />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admin/course/course-outline"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <CourseOutline />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/admin/create-post"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <CreatePost />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admin/create-post/edit"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <EditPost />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admin/create-post/preview"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <PreviewPost />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/admin/create-course"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <CreateCourse />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/admin/edit-subsection/:subsectionId"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <EditSubsection />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admin/edit-section/:sectionId"
                element={
                  <PrivateRoute adminRole="low-admin">
                    <EditSection />
                  </PrivateRoute>
                }
              />
            </Routes>
            <FooterWrapper>
              <Footer />
            </FooterWrapper>
          </>
        ) : (
          <PageSpinner />
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
