import axiosInstance from "../../../axios";

// loads user data from backend
export const loadUserData = async (userId, setUserInfo = undefined) => {
  const uData = await axiosInstance.post("api/user/getUserInfo", { userId });
  const userData = uData.data.userInfo;
  // console.log("uData: ", uData);
  userData.socialLinks = sortSocialLinks(userData.socialLinks);
  if (setUserInfo && uData) setUserInfo(userData);
  return userData;
};

export const sortSocialLinks = (socialLinks) => {
  const sortedSocialLinks = [...socialLinks];
  const desiredOrder = ["facebook", "twitter", "linkedin", "mail"];
  sortedSocialLinks?.sort((a, b) => {
    return desiredOrder.indexOf(b.platform) - desiredOrder.indexOf(a.platform);
  });
  return sortedSocialLinks;
};

// sends updated user data to backend
export const updateUserData = async (userId, userData) => {
  const uData = await axiosInstance.post("api/user/updateUserInfo", {
    userId,
    userData,
  });
  // console.log(uData);
  return uData;
};
//get work details from backend
export const getWorkDetails = async (userId) => {
  const wData = await axiosInstance.post("api/user/getWorkDetails", { userId });
  return wData.data.workDetails;
};

// add or updates the workDetails on the backend
export const saveWorkDetails = async (workDetail, mode, userId) => {
  const wData = await axiosInstance.post("api/user/saveWorkDetails", {
    workDetail,
    mode,
    userId,
  });
  return wData;
};

//get education details from backend
export const getEduDetails = async (userId) => {
  // console.trace("eduDetails: ");
  const eData = await axiosInstance.post("api/user/getEduDetails", { userId });
  return eData.data.eduDetails;
};

// add or updates the eduDetails on the backend
export const saveEduDetails = async (eduDetail, mode, userId) => {
  try {
    const pData = await axiosInstance.post("api/user/saveEduDetails", {
      eduDetail,
      mode,
      userId,
    });
    // console.log("projectData", pData);
    return pData;
  } catch (err) {
    console.log(err);
    return false;
  }
};

//delete job or education details from backend
export const deleteUserProfileDetails = async (deleteId, userId, type) => {
  // console.trace("eduDetails: ");
  const eData = await axiosInstance.post(
    "api/user/delete-user-profile-details",
    {
      deleteId,
      userId,
      type,
    }
  );
  return eData.data.status;
};

// get stages for application interface
export const getStagesStatusForApplication = async () => {
  const eData = await axiosInstance.get(
    "api/user/get-stages-status-for-application"
  );
  return eData.data;
};

// update stages for application interface
export const updateStagesStatusForApplication = async (
  stagesStatus,
  paymentPreference
) => {
  const body = { stagesStatus };
  if (paymentPreference) {
    body.paymentPreference = paymentPreference;
  }
  const eData = await axiosInstance.post(
    "api/user/update-stages-status-for-application",
    body
  );
  return eData.data;
};

// get questions for application
export const getQuestionsForApplication = async () => {
  const eData = await axiosInstance.get(
    "api/user/get-questions-for-application"
  );
  return eData.data;
};

// save questions for application
export const saveQuestionsForApplicant = async (questions, fRejected) => {
  const eData = await axiosInstance.post(
    "api/user/save-questions-for-applicant",
    { questions, fRejected }
  );
  return eData.data;
};

// get projectDetails
export const getProjectDetails = async () => {
  const pData = await axiosInstance.get("api/user/get-project-details");
  return pData.data;
};

// add or updates the projectDetails on the backend
export const saveProjectDetails = async (projectDetails, mode, userId) => {
  const wData = await axiosInstance.post("api/user/save-project-details", {
    projectDetails,
    mode,
    userId,
  });
  // console.log({ wData });
  return wData.data;
};

// get certificationDetails
export const getCertificationDetails = async () => {
  const pData = await axiosInstance.get("api/user/get-certification-details");
  return pData.data;
};

// add or updates the certificationDetails on the backend
export const saveCertificationDetails = async (
  certificationDetails,
  mode,
  userId
) => {
  const wData = await axiosInstance.post(
    "api/user/save-certification-details",
    {
      certificationDetails,
      mode,
      userId,
    }
  );
  // console.log({ wData });
  return wData.data;
};

// updates the socialLinks on UsersSchema
export const updateSocialLinks = async (socialLinks) => {
  const wData = await axiosInstance.post("api/user/update-social-links", {
    socialLinks,
    // mode,
  });
  // console.log({ wData });
  return wData.data;
};

export const checkFDaysPassedSinceSave = (savedAt, dayPassed) => {
  if (!savedAt) {
    return true;
  }
  // console.log({
  //   savedAt: new Date(savedAt),
  //   dayPassed,
  //   currentDate: new Date().toISOString(),
  // });
  const rejectApplicantAfterDays = new Date(savedAt);
  rejectApplicantAfterDays.setDate(
    rejectApplicantAfterDays.getDate() + dayPassed
  );
  return (
    new Date(new Date().toISOString()).getTime() >
    rejectApplicantAfterDays.getTime()
  );
};

export const saveTestPrefForTest3 = async (email) => {
  const { data } = await axiosInstance.post(
    "api/user/save-test-pref-for-test3",
    {
      email,
    }
  );
  // console.log({ data });
  return data;
};