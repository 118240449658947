import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const ContextBeforeQuestion = ({
  questionsInTopic,
  setContextBeforeQuestion,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [contextAdded, setContextAdded] = useState([]);
  const handleQuestionChange = (event) => {
    const selectedQuestion = event.target.value;
    setSelectedQuestion(selectedQuestion);
  };

  const deleteContext = (index) => {
    setContextAdded((prevContextAdded) => {
      const updatedContextAdded = [...prevContextAdded];
      updatedContextAdded.splice(index, 1);
      return updatedContextAdded;
    });
    setContextBeforeQuestion((prevContextAdded) => {
      const updatedContextAdded = [...prevContextAdded];
      updatedContextAdded.splice(index, 1);
      return updatedContextAdded;
    });
  };
  return (
    <>
      <Form className="p-0 m-0">
        <Form.Group controlId="questionSelect">
          <Form.Label>Select a question</Form.Label>
          <Form.Control
            as="select"
            value={selectedQuestion}
            onChange={handleQuestionChange}
          >
            <option value="">Select a question</option>
            {questionsInTopic &&
              questionsInTopic.map((question) => (
                <option key={question.id} value={question.value}>
                  {question.label}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        {selectedQuestion && (
          <ResponsesOfQuestionToAddInContext
            questionId={selectedQuestion}
            setContextAdded={setContextAdded}
            setContextBeforeQuestion={setContextBeforeQuestion}
          />
        )}
      </Form>
      <ContextAdded
        contextAdded={contextAdded}
        setContextAdded={setContextAdded}
        setContextBeforeQuestion={setContextBeforeQuestion}
        deleteContext={deleteContext}
      />
    </>
  );
};

const ResponsesOfQuestionToAddInContext = ({
  questionId,
  setContextAdded,
  setContextBeforeQuestion,
}) => {
  const [responses, setResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState("");
  const questionsInTopic = useSelector((state) => state.chatGPTSlice.questions);

  const responsesInRedux = useSelector((state) => state.chatGPTSlice.responses);
  const handleResponseCheck = (event) => {
    setSelectedResponse(event.target.value);
  };

  const handleAddContext = () => {
    const selectedQuestion = questionId;
    console.log(responsesInRedux);
    const context = {
      user: questionsInTopic[selectedQuestion].question,
      assistant: selectedResponse,
    };

    setContextAdded((prevContextAdded) => [context, ...prevContextAdded]);
    setContextBeforeQuestion((prevContextAdded) => [
      context,
      ...prevContextAdded,
    ]);
    console.log(context);
  };

  useEffect(() => {
    console.log(responsesInRedux);
    setResponses(responsesInRedux[questionId]);
  }, [questionId]);
  return (
    <Form>
      {responses?.map((response, index) => (
        <>
          <Form.Check
            key={response._id}
            type="radio"
            name="selectedResponse"
            value={response.htmlResponse}
            onChange={handleResponseCheck}
            style={{ display: "inline-block", height: "10px" }}
          />
          <span style={{ fontSize: "12px" }}>Response {index + 1}</span>
          <Form.Control
            as="textarea"
            readOnly
            value={response.htmlResponse}
            style={{ fontSize: "12px", height: "100px" }}
          />
        </>
      ))}

      <Button
        variant="primary"
        onClick={handleAddContext}
        style={{
          backgroundColor: "#3FACCB",
          border: "none",
          fontSize: "10px",
          padding: "3px 5px",
          margin: "10px 0",
        }}
      >
        Add Context
      </Button>
    </Form>
  );
};

const ContextAdded = ({
  contextAdded,
  setContextAdded,
  setContextBeforeQuestion,
  deleteContext,
}) => {
  const handleContextChange = (index, field, value) => {
    const updatedContext = [...contextAdded];
    updatedContext[index][field] = value;
    setContextAdded(updatedContext);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, targetIndex) => {
    const sourceIndex = parseInt(event.dataTransfer.getData("index"));
    const updatedContext = [...contextAdded];
    const [removedContext] = updatedContext.splice(sourceIndex, 1);
    updatedContext.splice(targetIndex, 0, removedContext);
    setContextAdded(updatedContext);
    setContextBeforeQuestion(updatedContext);
  };

  return (
    <div>
      <p>Context Added</p>
      {contextAdded?.map((context, index) => (
        <ContextBlock
          key={index}
          context={context}
          onChange={(field, value) => handleContextChange(index, field, value)}
          deleteContext={() => deleteContext(index)}
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
          draggable
        />
      ))}
    </div>
  );
};

const ContextBlock = ({
  context,
  onChange,
  deleteContext,
  onDragStart,
  onDragOver,
  onDrop,
  draggable,
}) => {
  const handleUserChange = (event) => {
    onChange("user", event.target.value);
  };

  const handleAssistantChange = (event) => {
    onChange("assistant", event.target.value);
  };

  return (
    <div
      className="d-flex"
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      draggable={draggable}
    >
      <div
        className="border my-2 p-1 rounded w-100"
        style={{ fontSize: "10px" }}
      >
        <i
          class="bi bi-grip-vertical"
          style={{ position: "absolute", left: "10px ", cursor: "pointer" }}
        ></i>

        <Form.Group>
          <Form.Label>User:</Form.Label>
          <Form.Control
            as="textarea"
            value={context.user}
            style={{ fontSize: "12px" }}
            onChange={handleUserChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Assistant:</Form.Label>
          <Form.Control
            as="textarea"
            value={context.assistant}
            style={{ fontSize: "12px" }}
            onChange={handleAssistantChange}
          />
        </Form.Group>
      </div>
      <Button
        onClick={deleteContext}
        style={{
          fontSize: "8px",
          padding: "0 5px",
          marginLeft: "-15px",
          height: "20px",
        }}
        variant="outline-danger"
      >
        <i class="bi bi-x-lg"></i>
      </Button>
    </div>
  );
};

export default ContextBeforeQuestion;
