import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import axiosInstance from "../../../axios";
import { newAlert } from '../../../slices/alertSlice';
import ErrorBoundary from '../../../components/NewsFeed/EditPost/ErrorBoundary';
import leftArrow from "../../../assets/images/right-arrows.svg";
import { debounce } from "../../../helpers/stubs.js";
import {
  extractImageSrc,
  modifyHtml,
  replaceImageSrc,
} from "../../../components/NewsFeed/utilities.js";
import BlogTextEditor from "../../../components/BlogTextEditor/BlogTextEditor.js";

function EditSubsection() {
  const [subsection, setSubsection] = useState();
  const [isFixed, setIsFixed] = useState(false);
  const [contentStatus, setContentStatus] = useState({
    saved: true,
    lastContentState: null,
    pendingLinks: false,
  });
  const [hasH1Heading, setHasH1Heading] = useState(true);
  const subsectionComponent = useRef(null);
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const [subsectionContent, setSubsectionContent] = useState("");
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [fieldChange, setFieldChange] = useState({});

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sectionId = searchParams.get("sectionId");
  const courseId = searchParams.get("courseId");
  const { subsectionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const rect = subsectionComponent.current?.getBoundingClientRect();
      if (rect) setIsFixed(rect.top <= 0 && rect.top + rect.height >= 0);
    };
    const debouncedHandleScroll = debounce(handleScroll, 40); // Adjust the debounce delay as needed

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  useEffect(() => {
    const getCourseSubsection = async (courseId) => {
      try {
        const { data } = await axiosInstance.get(
          `/api/admin/get-course-subsection/${subsectionId}?sectionId=${sectionId}`
        );
        if (data && data.subsection) {
          const subsection = data.subsection?.at(0);
          if (!subsection) {
            dispatch(
              newAlert({
                type: "danger",
                message: "Something Went Wrong Contact Admin",
              })
            );
          }
          const subsectionContent = subsection.content;
          if (subsectionContent) {
            setSubsection(data.subsection);
            setSubsectionContent(subsectionContent);
          }
          setLastSavedAt(Date.parse(data.updatedAt));
          setFormData({
            subsectionName: subsection.name,
            duration: subsection.duration,
          });
          setInitialFormData({
            subsectionName: subsection.name,
            duration: subsection.duration,
          });
        } else {
          dispatch(
            newAlert({
              type: "danger",
              message: "Failed to get course subsection!",
            })
          );
        }
      } catch (err) {
        dispatch(newAlert({ type: "danger", message: err }));
      }
    };
    if (sectionId && subsectionId) {
      getCourseSubsection();
    }
  }, [sectionId, subsectionId]);

  const getImagesS3Url = async () => {
    try {
      if (subsectionId) {
        const imagesData = await extractImageSrc(
          subsectionContent,
          subsectionId
        );

        if (imagesData.length > 0) {
          const { data } = await axiosInstance.post(
            `/api/admin/save-course-content-images-in-s3`,
            { imagesData }
          );

          const { imagesS3Url } = data;
          const newSubSectionContent = replaceImageSrc(
            subsectionContent,
            imagesS3Url
          );
          setContentStatus({ ...contentStatus, pendingLinks: true });
          setSubsectionContent(newSubSectionContent);
          return newSubSectionContent;
        } else {
          return subsectionContent;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateSubsectionContent = async () => {
    try {
      if (subsectionId && subsectionContent) {
        let newSubsectionContent = await getImagesS3Url();
        newSubsectionContent = modifyHtml(newSubsectionContent);
        const { data } = await axiosInstance.post(
          `/api/admin/update-course-subsection-details`,
          {
            courseId,
            subsectionId,
            sectionId,
            content: newSubsectionContent,
          }
        );
        if (data && data.section) {
          setLastSavedAt(Date.parse(data.updatedAt));
          setContentStatus({ ...contentStatus, pendingLinks: false });
          setContentStatus({ ...contentStatus, saved: true });
        }
      }
    } catch (err) {
      console.log("Error: ", err);
      dispatch(
        newAlert({
          type: "danger",
          message: "Failed to update subsection details!",
        })
      );
    } finally {
      // setSavingPostData(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (["subsectionName", "duration"].includes(name)) {
      setFieldChange({
        ...fieldChange,
        [name]: value !== initialFormData[name],
      });
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setFieldChange({});
  };

  const updateSubsectionDetails = async () => {
    try {
      const body = {
        courseId,
        subsectionId,
        sectionId,
      };
      if (fieldChange.subsectionName) {
        body["name"] = formData.subsectionName;
      }
      if (fieldChange.duration) {
        body["duration"] = formData.duration;
      }
      const { data } = await axiosInstance.post(
        `/api/admin/update-course-subsection-details`,
        body
      );
      setInitialFormData(formData);
      setFieldChange({});
      dispatch(
        newAlert({
          type: "success",
          message: "Successfully Updated SubSection Detail",
        })
      );
    } catch (error) {
      let errorMsg = error.response?.data?.message;
      if (errorMsg && errorMsg.includes("slugUrlAlreadyExistsForCourse")) {
        errorMsg =
          "Please use another name for subsection. A subsection with this name already exists in the course";
      }
      console.error("Error saving data:", error);
      dispatch(
        newAlert({
          type: "danger",
          message: errorMsg || "Failed to update subsection details!",
        })
      );
    }
  };

  const isFormChanged = () => {
    return (
      formData.subsectionName !== initialFormData.subsectionName ||
      formData.duration !== initialFormData.duration
    );
  };

  return (
    <div style={{ minHeight: "100dvh" }}>
      <Container style={{ marginBottom: "100px" }}>
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "40px" }}
        >
          <div
            className="d-flex align-items-center gap-1"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/admin/create-course?courseId=${courseId}`)
            }
          >
            <img
              src={leftArrow}
              alt="go-back"
              style={{ width: "0.8rem", transform: "scaleX(-1)" }}
            />
            <span style={{ color: "rgb(10 88 202)" }}>Edit Course</span>
          </div>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              flex: "1",
            }}
          >
            Edit Subsection
          </h1>
        </div>
        <Form style={{ marginBottom: "10px", marginTop: "50px" }}>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="subsectionName">
                <Form.Label>Subsection Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Subsection Name"
                  name="subsectionName"
                  value={formData.subsectionName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="duration">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
              {(fieldChange.subsectionName || fieldChange.duration) && (
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => updateSubsectionDetails()}
                      style={{ marginRight: "5px" }}
                    >
                      Save Changes
                    </Button>
                    <Button variant="secondary" onClick={() => handleCancel()}>
                      Revert Changes
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
        <ErrorBoundary>
          <div ref={subsectionComponent} className="editor-container">
            <BlogTextEditor
              data={subsectionContent}
              onChange={(content) => {
                setSubsectionContent(content);
                setContentStatus({ ...contentStatus, saved: false });
              }}
              placeholder={"Write course subsection content here"}
              contentStatus={contentStatus}
              setContentStatus={setContentStatus}
              lastSavedAt={lastSavedAt}
              fShowEditor={subsectionId}
              onAutoSave={async () =>
                await updateSubsectionContent(false, null, false)
              }
              hasH1Heading={false}
              setHasH1Heading={setHasH1Heading}
              showH1Warning={() => {}}
            />
          </div>
        </ErrorBoundary>
      </Container>
    </div>
  );
}

export default EditSubsection;