import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteHeading,
  strikeOutQuestion,
} from "../../../slices/chatGPTSliceV2";
import AddChildQuestionModal from "../AddChildQuestionModal/AddChildQuestionModal";
import AddHeadingModal from "../AddHeadingModal/AddHeadingModal";
import AddQuestionModal from "../AddQuestionModal/AddQuestionModal";
import ImportQuestionsFromChats from "../ImportQuestionsFromChatsModal/ImportQuestionsFromChats";
import Axios from "../../../axios";
import NewQuestionModal from "../NewQuestionModal/NewQuestionModal";
const AddHeadingOrQuestion = ({
  fHasParentQuestion,
  indexInHeadingQuestions = -1,
  question,
  level,
  filteredPoints,
  relation,
  fStrikeout,
  headingQuestions,
}) => {
  const [isAddHeadingModalOpen, setIsAddHeadingModalOpen] = useState(false);
  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
  const [isAddChildQuestionModalOpen, setIsAddChildQuestionModalOpen] =
    useState(false);
  const [isImportQuestionsModalOpen, setIsImportQuestionsModalOpen] =
    useState(false);
  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const userId = useSelector(
    (state) => state.sessionSlice.sessionUser.user._id
  );
  const dispatch = useDispatch();
  const strikeQuestions = () => {
    dispatch(
      strikeOutQuestion({
        indexInHeadingQuestions,
        fStrikeout: !fStrikeout,
        headingQuestions,
      })
    );
  };

  // const deleteHeadingElement = () => {
  //   if (indexInHeadingQuestions !== -1) {
  //     dispatch(
  //       deleteHeading({
  //         headingId: headingQuestions[indexInHeadingQuestions]._id,
  //       })
  //     );
  //   }
  // };

  const identifyAllQuestionFromGptMapping_addInArray = async (chats) => {
    await chats.map((chat) => {
      const myArray = Object.values(chat.gptQuestionsMap);
      chat.questions = myArray.filter(
        (qr) => qr.message && qr.message.author.role === "user"
      );
      console.log(chat);
    });
    console.log(chats);
    setChats(chats);
  };
  const getAllGptChatsOfUser = async () => {
    const { data } = await Axios.get(
      `/api/chat-gpt-interface/get-chats-of-user/${userId}`
    );
    // setChats(data);
    identifyAllQuestionFromGptMapping_addInArray(data);
  };
  return (
    <>
      <p
        className="addHeadingOrQuestion"
        style={{ display: indexInHeadingQuestions === -1 ? "block" : "" }}
      >
        <Dropdown>
          <Dropdown.Toggle
            className="px-1 py-0 m-0"
            variant="secondary"
            id="dropdown-basic"
          >
            <i className="bi bi-plus-lg"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ fontSize: "12px" }}>
            <Dropdown.Item
              onClick={() => {
                setIsNewQuestionModalOpen(true);
              }}
            >
              New Question
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setIsAddHeadingModalOpen(true)}>
              Heading
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => setIsAddQuestionModalOpen(true)}>
              {question ? "Peer question" : "Question"}
            </Dropdown.Item> */}
            {/* {fHasParentQuestion && level < 4 && (
            <Dropdown.Item onClick={() => setIsAddChildQuestionModalOpen(true)}>
              Child question
            </Dropdown.Item>
          )} */}
            {/* <Dropdown.Item
              onClick={() => {
                setIsImportQuestionsModalOpen(true);
                getAllGptChatsOfUser();
              }}
            >
              Import Questions
            </Dropdown.Item> */}
            {/* {indexInHeadingQuestions !== -1 &&
              headingQuestions[indexInHeadingQuestions].heading && (
                <Dropdown.Item onClick={() => deleteHeadingElement()}>
                  Delete Heading
                </Dropdown.Item>
              )} */}
            {fStrikeout !== undefined && (
              <Dropdown.Item onClick={() => strikeQuestions()}>
                <i className="fas fa-strikethrough"></i>{" "}
                {fStrikeout ? "Unstrike Question" : "Strikeout Question"}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </p>
      <AddHeadingModal
        isModalOpen={isAddHeadingModalOpen}
        setModalStatus={setIsAddHeadingModalOpen}
        indexInHeadingQuestions={indexInHeadingQuestions}
      />
      <AddQuestionModal
        isModalOpen={isAddQuestionModalOpen}
        setModalStatus={setIsAddQuestionModalOpen}
        indexInHeadingQuestions={indexInHeadingQuestions}
        parentQuestionId={question}
        level={relation ? level : 1}
        relation={relation}
      />
      <NewQuestionModal
        isModalOpen={isNewQuestionModalOpen}
        setModalStatus={setIsNewQuestionModalOpen}
        indexInHeadingQuestions={indexInHeadingQuestions}
      />
      <AddChildQuestionModal
        isModalOpen={isAddChildQuestionModalOpen}
        setModalStatus={setIsAddChildQuestionModalOpen}
        indexInHeadingQuestions={indexInHeadingQuestions}
        parentQuestionId={question}
        filteredPoints={filteredPoints}
        level={level}
      />
      <ImportQuestionsFromChats
        isModalOpen={isImportQuestionsModalOpen}
        setModalStatus={setIsImportQuestionsModalOpen}
        indexInHeadingQuestions={indexInHeadingQuestions}
        chats={chats}
      />
    </>
  );
};

export default AddHeadingOrQuestion;
