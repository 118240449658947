import React from "react";
import SearchForm from "../../../containers/SearchForm/SearchForm";
import SearchResults from "../../../containers/SearchResults/SearchResults";
import Newsletter from "../../../components/Newsletter/Newsletter";
// import Comp_GuestUserConversionModal from "../../../components/GuestUserConversionModal/Comp_GuestUserConversionModal";
// import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios from "../../../axios"   //for testing
import { getCookie } from "../../../helpers/stubs"
import { useNavigate } from "react-router-dom";
import { setSessionUserFromCookie } from "../../../slices/sessionSlice";
import upArrow from '../../../assets/images/up_arrow.png'
// import SuggestionBox from "../../../components/SuggestionBox/SuggestionBox";
import Shimmer from "../../../components/Shimmer/Shimmer";

const LandingPage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalstate, setmodalstate] = useState("userTypeEmailModal");
  const [searchCount, setSearchCount] = useState(parseInt(localStorage.getItem("searchCount")));
  const [modalClosed, setModalClosed] = useState(0)
  const sessionUser = useSelector(store => store.sessionSlice.sessionUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState();
  const [adType, setAdType] = useState("All types");
  const [industryType, setIndustryType] = useState()
  const [isAdvancedSearchOn, setIsAdvancedSearchOn] = useState(false);
  const [pageNumber, setPageNumber] = useState(1)
  const [loader, setLoader] = useState(true);
  const [activeKey, setActiveKey] = useState('0');
  const checkPendingUserInUser = async (email) => {
    const reqBody = { "email": email }
    const user = await Axios.post("/api/public/checkPendingUserInUser", reqBody)
    return user
  }

  const createSession = async () => {
    await Axios.post("/api/session/create-session")
      .then(() => getCookie_updateReduxStore())
  }
  const getCookie_updateReduxStore = () => {
    const sessionCookie = getCookie("sc")
    if (sessionCookie) {
      const { sessionId, pendingUser, user } = JSON.parse(sessionCookie)
      dispatch(setSessionUserFromCookie({ sessionId, pendingUser, user }))
    }

  }

  const decideModal = async () => {
    const { sessionId, pendingUser, user } = JSON.parse(getCookie("sc"))
    // console.log(sessionId, pendingUser, user)
    if (!sessionId) {
      createSession()
      setmodalstate("userTypeEmailModal")
      setModalShow(true)
      setSearchCount(0)
      localStorage.setItem("searchCount", 0)
    }

    if (user) {
      if (!user.firstName || !user.lastName || !user.position || !user.companyName) {

        setmodalstate("namePositionCompanyModal")
        setModalShow(true)
        setSearchCount(0)
        localStorage.setItem("searchCount", 0)
      } else {
        setModalShow(false)
        setSearchCount(0)
        localStorage.setItem("searchCount", 0)
      }
    } else {
      if (pendingUser) {
        const { user } = await checkPendingUserInUser(pendingUser.email)

        if (user) {
          navigate("/sign-in")
          setSearchCount(0)
          localStorage.setItem("searchCount", 0)
        }
        else {
          setmodalstate("otpModal")
          setModalShow(true)
          setSearchCount(0)
          localStorage.setItem("searchCount", 0)
        }
      } else {
        setmodalstate("userTypeEmailModal")
        setModalShow(true)
        setSearchCount(0)
        localStorage.setItem("searchCount", 0)
      }
    }
  }

  const showModalAfterInterval = () => {
    const durationToShowModal = 90000; // config.durationToShowModal
    return setTimeout(() => decideModal(), durationToShowModal)
  }

  const showModalAfterXSearches_ResetCount = () => {
    const maxSearchesBeforeModal = 3; //config.maxSearchesBeforeModal
    if (searchCount >= maxSearchesBeforeModal) {
      decideModal()

    }
  }
  const closeModal = () => {
    setModalShow(false)
    setSearchCount(0)
    localStorage.setItem("searchCount", 0)
    setModalClosed(prev => prev + 1)
  }


  useEffect(() => {
    if (localStorage.getItem("searchCount") == null) {
      localStorage.setItem("searchCount", 0)
    }

    showModalAfterXSearches_ResetCount()
    let TimeOut = showModalAfterInterval()

    return () => {
      clearTimeout(TimeOut)
    }

  }, [searchCount, modalClosed])

  return (
    <>
      <SearchForm
        setSearchCount={setSearchCount}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        adType={adType}
        setAdType={setAdType}
        industryType={industryType}
        setIndustryType={setIndustryType}
        isAdvancedSearchOn={isAdvancedSearchOn}
        setIsAdvancedSearchOn={setIsAdvancedSearchOn}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setLoader={setLoader}
        setActiveKey={setActiveKey}
      />

      {/* <Comp_GuestUserConversionModal
        show={modalShow}
        modalstate={modalstate}
        setmodalstate={setmodalstate}
        onHide={() => {
          closeModal()
        }} /> */}

      {/* <SuggestionBox
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      /> */}

      {loader ? <Shimmer /> : <SearchResults
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        adType={adType}
        industryType={industryType}
        isAdvancedSearchOn={isAdvancedSearchOn}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setLoader={setLoader}
      />}
      <div className="h3 text-center">Coming soon...</div>

      <Newsletter />
      <a href={`${window.location.href.split('#')[0]}#home`}>
        <img src={upArrow} alt='up-arrow' className='card shadow' style={{
          position: "fixed",
          height: '45px',
          right: '40px',
          bottom: '50px',
          borderRadius: '50%',
          padding: '7px',
        }} />
      </a>
    </>
  );
};

export default LandingPage;
