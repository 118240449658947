import React from "react";
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage";

const ApplicantResetpassword = () => {
  return (
    <div>
      <ResetPasswordPage fApplicantPage={true} />
    </div>
  );
};

export default ApplicantResetpassword;
