import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Container, Form, Row, Button, Col, Dropdown, DropdownButton, Modal, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axiosInstance from "../../../axios";
import { newAlert } from '../../../slices/alertSlice';

const AddSectionModal = ({ props: { setSelectedCourse, setFShowModal, selectedCourse } }) => {
    const dispatch = useDispatch();
    const [newSection, setNewSection] = useState('');
    const [duration, setDuration] = useState('');
    const handleSave = async () => {
        try {
            if (newSection.trim() !== '' && duration.trim() !== '') {
                const payload = { sectionName: newSection, duration, courseId: selectedCourse._id };
                const { data } = await axiosInstance.post(
                    `/api/admin/add-section-in-course`, payload);
                if (data && data.course) {
                    const courseData = {
                        value: data.course.name,
                        label: data.course.name,
                        _id: data.course._id,
                        sections: data.course.sections,
                        ...data.course
                    }
                    setSelectedCourse(courseData);
                    dispatch(newAlert({ type: "success", message: "Added new section in course" }));
                    setNewSection('');
                    setDuration('');
                    setFShowModal(false);
                }
                else {
                    dispatch(newAlert({ type: "danger", message: data }));
                }
            }
        } catch (error) {
            dispatch(newAlert({ type: "danger", message: error }));
        }
    }

    const handleCancel = () => {
        setNewSection('');
        setDuration('');
        setFShowModal(false);
    };
    return (
        <Modal show={true} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Section In Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="sectionInput">
                    <Form.Label>Section Name:</Form.Label>
                    <Form.Control
                        type="text"
                        value={newSection}
                        onChange={(e) => setNewSection(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="durationInput">
                    <Form.Label>Duration:</Form.Label>
                    <Form.Control
                        type="text"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={newSection.trim() === '' || duration.trim() === ''}
                >
                    Save
                </Button>
                <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const AddSubsectionModal = ({ props: { setSelectedCourse, setFShowModal, selectedCourse, sectionId, setEditSectionId } }) => {
    const dispatch = useDispatch();
    const [subsectionName, setSubsectionName] = useState('');
    const [duration, setDuration] = useState('');
    const handleSave = async () => {
        try {
            if (subsectionName.trim() !== '' && duration.trim() !== '') {
                const payload = { subsectionName: subsectionName, duration, sectionId, courseId: selectedCourse._id };
                const { data } = await axiosInstance.post(
                    `/api/admin/add-subsection-in-course`, payload);
                if (data && data.course) {
                    const courseData = {
                        value: data.course.name,
                        label: data.course.name,
                        _id: data.course._id,
                        sections: data.course.sections,
                        ...data.course
                    }
                    setSelectedCourse(courseData);
                    dispatch(newAlert({ type: "success", message: "Added new subsection in section" }));
                    setSubsectionName('');
                    setDuration('')
                    setEditSectionId(null)
                    setFShowModal(false);
                }
                else {
                    dispatch(newAlert({ type: "danger", message: data }));
                }
            }
        } catch (error) {
            let errorMsg = error.response?.data?.message;
            if (
              errorMsg &&
              errorMsg.includes("slugUrlAlreadyExistsForCourse")
            ) {
              errorMsg =
                "Please use another name for subsection. A subsection with this name already exists in the course";
            }
            // console.log({ errorMsg: error });
            dispatch(
              newAlert({ type: "danger", message: errorMsg || error.message })
            );
        }
    }

    const handleCancel = () => {
        setSubsectionName('');
        setDuration('')
        setFShowModal(false);
    };
    return (
        <Modal show={true} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Add Sub Section In Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="sectionInput">
                    <Form.Label>Subsection Name:</Form.Label>
                    <Form.Control
                        type="text"
                        value={subsectionName}
                        onChange={(e) => setSubsectionName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="durationInput">
                    <Form.Label>Duration:</Form.Label>
                    <Form.Control
                        type="text"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={subsectionName.trim() === '' || duration.trim() === ''}
                >
                    Save
                </Button>
                <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


const getModal = (props) => {
    if (props.type === "AddSectionModal") {
        return <AddSectionModal props={props} />
    }
    else if (props.type === "AddSubsectionModal") {
        return <AddSubsectionModal props={props} />
    }
}


function CreateCourseModal(props) {
    return (
        getModal(props)
    )
}

export default CreateCourseModal