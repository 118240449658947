import { useEffect, useState } from "react";
import "./AdminAdsBySearchTerm.css";
import AdsGrid from "../../../components/AdminAdsGrid/AdsGrid/AdsGrid";
import { useNavigate, useParams } from "react-router-dom";
import { adsForSearchTermPayloadMappingBeforeStoring } from "../../../slices/adSlice";
import axiosInstance from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import { useSearchParams } from "react-router-dom";
import Shimmer from "../../../components/Shimmer/Shimmer";

function AdminAdsBySearchTerm() {
  const { searchTermId, stemmedSearchTerm } = useParams();
  console.log(searchTermId + stemmedSearchTerm);
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);

  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getAdsForSearchTerm = async (stemmedSearchTerm) => {
      const data = await axiosInstance
        .get(
          `/api/admin/get-ads-by-search-term/${stemmedSearchTerm}&pg=${page}`
        )
        .catch((err) => console.log(err));
      // console.log(data)
      if (data?.status === 200) {
        setLoader(false);
        if (data.data.length === 0) {
          dispatch(
            newAlert({ type: "danger", message: "No More ads available!!" })
          );
        }
        // let modifiedPayload = adsForSearchTermPayloadMappingBeforeStoring(data.data.data);
        // console.log(data.data)
        let modifiedPayload = adsForSearchTermPayloadMappingBeforeStoring(
          data.data
        );
        setAds([...ads, ...modifiedPayload]);
      } else {
        dispatch(
          newAlert({ type: "danger", message: "Show more ads failed!!" })
        );
        setLoader(false);
      }
    };
    getAdsForSearchTerm(stemmedSearchTerm);
  }, [stemmedSearchTerm, page]);

  return (
    <div>
      {loader ? (
        <Shimmer />
      ) : (
        <>
          <AdsGrid ads={ads} setAds={setAds} />
          <button
            className="show-more-ads-btn"
            onClick={(_) => setPage(page + 1)}
          >
            Show More Ads
          </button>
        </>
      )}
    </div>
  );
}
export default AdminAdsBySearchTerm;
