import React from "react";
import styles from "./LocationExtension.module.css";

const LocationExtension = ({ locationExtension }) => {
  return (
    <div className={`d-flex align-items-center mt-1`}>
      <i className={`${styles["location-icon"]} fa-solid fa-location-dot`}></i>
      <span className={`${styles["location-name"]}`}>{locationExtension}</span>
    </div>
  );
};

export default LocationExtension;
