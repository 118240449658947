import React, { useState } from "react";
import GeneratingResponseLoader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { upsertChatMap } from "../../../slices/chatGPTSliceV2";

const SyncBtn = () => {
  const dispatch = useDispatch();
  const [syncingChat, setSyncingChat] = useState(false);

  const getAccessTokenAndSyncChat = async () => {
    console.log("Event Fired");
    setSyncingChat(true);

    //fire  event
    const SyncAllChats = new CustomEvent("SyncAllChats", {
      detail: {
        message: "SYNC_CHAT",
      },
    });
    window.dispatchEvent(SyncAllChats);
    window.addEventListener("sendChatIdsFromChatGPT", async (event) => {
      console.log(event?.detail?.syncComplete);
      console.log(event?.detail?.chatGptOpen);

      if (event?.detail?.syncComplete !== undefined) {
        dispatch(
          upsertChatMap({
            ...event?.detail?.chatMap,
            syncComplete: event?.detail?.syncComplete,
            setSyncingChat,
          })
        );
      } else if (event?.detail?.chatGptOpen !== undefined) {
        setSyncingChat(false);
      }
    });
    // setTimeout(() => {
    //   console.log("SetTimeout Tiggerred", syncingChat);
    //   if (!syncingChat) {
    //     setSyncingChat(false);
    //     dispatch(newAlert({ type: "danger", message: "Sync failed" }));
    //   }
    // }, 15000);
  };
  return (
    <button
      className="btn btn-primary my-2 py-0 d-flex"
      id="sync-btn"
      style={{
        backgroundColor: "#3FACCB",
        border: "none",
        display: "none",
        fontSize: "10px",
        padding: "0px  5px",
      }}
      // style={{ display: "none" }}
      onClick={() => getAccessTokenAndSyncChat()}
      disabled={syncingChat}
    >
      {syncingChat ? (
        <>
          <p className="m-0">Syncing</p> <GeneratingResponseLoader />
        </>
      ) : (
        <>
          <p className="m-0">Sync</p>
          <i
            className="bi bi-arrow-repeat p-0 "
            style={{ marginLeft: "5px" }}
          ></i>
        </>
      )}
    </button>
  );
};

export default SyncBtn;
