import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Form, Row, Button, Col, Dropdown, DropdownButton, Modal, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiTrash2, FiEdit, FiPlus, FiCheck, FiX } from 'react-icons/fi'; // Import icons
import axiosInstance from "../../../axios";
import { newAlert } from '../../../slices/alertSlice';
import CreateCourseSearch from '../../../components/CreateCourseSearch/CreateCourseSearch';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import "./CreateCourse.css";
// import SelectAuthor from '../../../components/NewsFeed/EditPost/SubComponents/SelectAuthor';
import CreateCourseModal from './CreateCourseModal';
import ThumbnailUploader from "./ThumbnailUploader.js";
import { debounce } from "../../../helpers/stubs.js";
let timeout = null;
const CreateCourse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get("courseId");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [newCourseName, setNewCourseName] = useState(null);
  const [thumbnailImg, setThumbnailImg] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);
  const [askConfirmation, setAskConfirmation] = useState({
    active: false,
    title: false,
    content: "",
    onClick: null,
  });
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [fShowModal, setFShowModal] = useState(false);
  const [duration, setDuration] = useState();
  const [description, setDescription] = useState("");

  useEffect(() => {
    const getSelectedCourse = async (courseId) => {
      try {
        const { data } = await axiosInstance.get(
          `/api/admin/get-course/${courseId}`
        );
        if (data && data.course) {
          const courseData = {
            value: data.course.name,
            label: data.course.name,
            _id: data.course._id,
            sections: data.course.sections,
            ...data.course,
          };
          setSelectedCourse(courseData);
          if (data.course.thumbnail) {
            setThumbnailImg(data.course.thumbnail);
          } else {
            setThumbnailImg(null);
          }
          if (data.course.author) {
            setSelectedAuthor({
              ...data.course.author,
              label: data.course.author.name,
            });
          } else {
            setSelectedAuthor(null);
          }
          if (data.course.duration) {
            setDuration(data.course.duration);
          } else {
            setDuration(null);
          }
          if (data.course.description) {
            setDescription(data.course.description);
          } else {
            setDescription("");
          }
        } else {
          dispatch(
            newAlert({ type: "danger", message: "Failed to get course!" })
          );
        }
      } catch (err) {
        // dispatch(newAlert({ type: "danger", message: "Failed to get course!" }));
      }
    };

    if (courseId) {
      getSelectedCourse(courseId);
    }
  }, [courseId, location.pathname]);

  useEffect(() => {
    async function fetchAllAuthors() {
      try {
        const { data } = await axiosInstance.get(
          "/api/admin/fetch-all-authors"
        );
        const authors = data.authors.map((author) => ({
          label: author.name,
          _id: author._id,
          name: author.name,
        }));
        setAuthors(authors);
      } catch (error) {
        console.error(error);
      }
    }
    if (authors && authors.length === 0) {
      fetchAllAuthors();
    }
  }, []);

  const Section = ({ section, index }) => {
    const [isEditing, setEditing] = useState(false);
    const [newSectionName, setNewSectionName] = useState(section.name);
    const [fshowModal, setfShowModal] = useState(false);
    const [editSectionId, setEditSectionId] = useState();
    const handleEdit = () => {
      if (isEditing) {
        setNewSectionName(section.name);
      }
      setEditing(!isEditing);
    };

    const renderTooltip = (text) => (
      <Tooltip id="button-tooltip">{text}</Tooltip>
    );

    const onDeleteSection = async (sectionId) => {
      setAskConfirmation({
        active: true,
        title: false,
        type: "delete",
        content:
          "Are you sure you want to delete this section, it will also delete all its subsections?",
        onClick: async () => {
          const { data } = await axiosInstance.delete(
            `/api/admin/delete-course-section/${sectionId}?courseId=${selectedCourse._id}`
          );
          if (data && data.course) {
            const courseData = {
              value: data.course.name,
              label: data.course.name,
              _id: data.course._id,
              sections: data.course.sections,
              ...data.course,
            };
            setSelectedCourse(courseData);
          }
          setAskConfirmation({
            active: false,
            title: false,
            content: "",
            onClick: null,
          });
        },
      });
    };

    const onAddSubsection = async (sectionId) => {
      setEditSectionId(sectionId);
      setfShowModal(true);
    };

    const onDeleteSubsection = async (sectionId, subsectionId) => {
      setAskConfirmation({
        active: true,
        title: false,
        type: "delete",
        content: "Are you sure you want to delete this subsection?",
        onClick: async () => {
          const { data } = await axiosInstance.delete(
            `/api/admin/delete-course-subsection/${subsectionId}?courseId=${selectedCourse._id}&sectionId=${sectionId}`
          );
          if (data && data.course) {
            const courseData = {
              value: data.course.name,
              label: data.course.name,
              _id: data.course._id,
              sections: data.course.sections,
              ...data.course,
            };
            setSelectedCourse(courseData);
          }
          setAskConfirmation({
            active: false,
            title: false,
            content: "",
            onClick: null,
          });
        },
      });
    };

    return (
      <div>
        {fshowModal && editSectionId && (
          <CreateCourseModal
            type={"AddSubsectionModal"}
            setSelectedCourse={setSelectedCourse}
            setFShowModal={setfShowModal}
            selectedCourse={selectedCourse}
            setEditSectionId={setEditSectionId}
            sectionId={editSectionId}
          />
        )}
        <Card style={{ marginBottom: "10px" }}>          
                <Card.Body
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
              >
            <div style={{ flex: "1" }}>
              {isEditing ? (
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type="text"
                    value={newSectionName}
                    onChange={(e) => setNewSectionName(e.target.value)}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "30px",
                      bottom: "10px",
                      cursor: "pointer",
                      color: "green",
                    }}
                    onClick={async () => {
                      const { data } = axiosInstance.patch(
                        `/api/admin/update-course-section-details`,
                        {
                          sectionId: section._id,
                          name: newSectionName,
                        }
                      );
                      if (data && data.status !== "success") {
                        dispatch(
                          newAlert({
                            type: "danger",
                            message: "Update Failed",
                          })
                        );
                        return;
                      }
                      const updatedSelectedCourse = selectedCourse;
                      updatedSelectedCourse.sections =
                        updatedSelectedCourse.sections.map((sc) => {
                          if (sc._id === section._id) {
                            return { ...sc, name: newSectionName };
                          }
                          return sc;
                        });
                      setSelectedCourse(updatedSelectedCourse);
                      setEditing(false);
                      dispatch(
                        newAlert({
                          type: "success",
                          message: "Updated Section Name",
                        })
                      );
                    }}
                  >
                    <FiCheck />
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      cursor: "pointer",
                      color: "red",
                      bottom: "10px",
                    }}
                    onClick={() => {
                      setNewSectionName(section.name);
                      setEditing(false);
                    }}
                  >
                    <FiX />
                  </span>
                </div>
              ) : (
                <>
                  <span style={{ fontWeight: "bold", margin: "5px" }}>
                    {index + 1}.{" "}
                  </span>
                  <span></span>
                  {newSectionName}
                </>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip("Add Subsection")}
              >
                <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                  <FiPlus onClick={() => onAddSubsection(section._id)} />
                </span>
              </OverlayTrigger>              
              
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Edit Section")}
                  >
                    <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                      <a
                        href={`/admin/edit-section/${section._id}?courseId=${courseId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiEdit />
                      </a>
                    </span>
              </OverlayTrigger>
              
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip("Delete Section")}
              >
                <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                  <FiTrash2 onClick={() => onDeleteSection(section._id)} />
                </span>
              </OverlayTrigger>
            </div>
          </Card.Body>
                   
                  

        
          {section.subsections &&
            section.subsections.map((subsection, subsectionIndex) => (
              <Card.Body
                key={subsection._id}
                style={{
                  marginLeft: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: "1" }}>
                  <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {" "}
                    {index + 1}.{subsectionIndex + 1}.
                  </span>
                  {subsection.name}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Edit Subsection")}
                  >
                    <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                      <a
                        href={`/admin/edit-subsection/${subsection._id}?sectionId=${section._id}&courseId=${courseId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiEdit />
                      </a>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Delete Subsection")}
                  >
                    <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                      <FiTrash2
                        onClick={() =>
                          onDeleteSubsection(section._id, subsection._id)
                        }
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </Card.Body>
            ))}
        </Card>
      </div>
    );
  };

  const handleDurationChange = (data) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { data } = axiosInstance
        .patch(`/api/admin/update-course-details`, {
          courseId,
          duration,
        })
        .then(() =>
          dispatch(
            newAlert({ type: "success", message: "Updated Course Duration" })
          )
        )
        .catch(() =>
          dispatch(newAlert({ type: "danger", message: "Update Failed" }))
        )
        .finally(() => {
          clearTimeout(timeout);
          timeout = null;
        });
    }, 1000);
  };
  
  const handleDescriptionChange = (description) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { data } = axiosInstance
        .patch(`/api/admin/update-course-details`, {
          courseId,
          description,
        })
        .then(() =>
          dispatch(
            newAlert({ type: "success", message: "Updated Course Description" })
          )
        )
        .catch(() =>
          dispatch(newAlert({ type: "danger", message: "Update Failed" }))
        )
        .finally(() => {
          clearTimeout(timeout);
          timeout = null;
        });
    }, 1000);
  };

  const SelectAuthor = ({
    author,
    setAuthor,
    authorOptions,
    setAuthorOptions,
  }) => {
    const handleChange = async (author) => {
      try {
        const { data } = await axiosInstance.patch(
          `/api/admin/update-course-details`,
          {
            courseId,
            author: author._id,
          }
        );
        setSelectedAuthor(author);
      } catch (error) {}
    };

    return (
      <div>
        <div className="author-container">
          <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
            Author
          </div>
          <Select
            value={author}
            placeholder="Select Author"
            name="colors"
            options={authorOptions}
            onChange={handleChange}
            classNamePrefix="select"
            noOptionsMessage={() => "no author available"}
          />
        </div>
      </div>
    );
  };

  const validateDetails = () => {
    let error = "";
    if (!selectedCourse?._id) {
      error = "Course not selected";
    } else if (!thumbnailImg) {
      error = "Thumbnail image not added";
    } else if (!selectedAuthor) {
      error = "Author not selected";
    } else if (!duration) {
      error = "Duration not added";
    } else if (!selectedCourse.sections?.length > 0) {
      error = "Sections not added";
    } else if (!selectedCourse.sections?.[0]?.subsections?.length > 0) {
      error = "subsections not added";
    }
    if (error) {
      dispatch(newAlert({ type: "danger", message: error }));
      return false;
    }
    return true;
  };

  return (
    <div style={{ minHeight: "100vh", marginBottom: "100px" }}>
      <Container>
        <h1
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          Create Course
        </h1>
        {askConfirmation.active && (
          <ConfirmationModal
            askConfirmation={askConfirmation}
            setAskConfirmation={setAskConfirmation}
            message={`New course Name: ${newCourseName}`}
            updateState={setNewCourseName}
          />
        )}
        {fShowModal && (
          <CreateCourseModal
            type={"AddSectionModal"}
            setSelectedCourse={setSelectedCourse}
            setFShowModal={setFShowModal}
            selectedCourse={selectedCourse}
          />
        )}
        <CreateCourseSearch
          setCourseOptions={setCourseOptions}
          courseOptions={courseOptions}
          setSelectedCourse={setSelectedCourse}
          setNewCourseName={setNewCourseName}
          setAskConfirmation={setAskConfirmation}
          selectedCourse={selectedCourse}
        />
        {selectedCourse && (
          <div style={{ marginTop: "10px" }}>
            <ThumbnailUploader
              courseId={courseId}
              thumbnailImg={thumbnailImg}
              setThumbnailImg={setThumbnailImg}
            />
            <Form.Group
              controlId="duration"
              className="mb-0"
              style={{ width: "25%", marginTop: "20px" }}
            >
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course duration"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                  handleDurationChange(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group
              controlId="description"
              className="mb-0"
              style={{ width: "50%", marginTop: "20px" }}
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  handleDescriptionChange(e.target.value);
                }}
              />
            </Form.Group>

            <div style={{ marginTop: "20px" }}>
              <SelectAuthor
                author={selectedAuthor}
                setAuthor={setSelectedAuthor}
                authorOptions={authors}
                setAuthorOptions={setAuthors}
              />
            </div>
            <button
              style={{
                height: "50px",
                paddingLeft: "10px",
                paddingRight: "10px",
                marginTop: "30px",
                marginBottom: "30px",
                position: "relative",
                left: "75vw",
              }}
              className="add-section"
              onClick={() => {
                setFShowModal(true);
              }}
            >
              <i className="fa-solid fa-plus"></i> New Section
            </button>
            {selectedCourse.sections.map((section, index) => {
              return (<Section key={section._id} section={section} index={index++} />)
            })}
            <div>
              <Button
                // variant="primary"
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  backgroundColor: selectedCourse.fPublish
                    ? "#ef888c"
                    : "#0099ff",
                  border: "none",
                }}
                onClick={async () => {
                  const fValidDetails = validateDetails();
                  if (!fValidDetails) {
                    return;
                  }
                  const { data } = await axiosInstance.patch(
                    `/api/admin/update-course-details`,
                    {
                      courseId,
                      fPublish: !selectedCourse.fPublish,
                    }
                  );
                  setSelectedCourse({
                    ...selectedCourse,
                    fPublish: !selectedCourse.fPublish,
                  });
                  dispatch(
                    newAlert({
                      type: "success",
                      message: `Successfully ${
                        selectedCourse.fPublish ? "UNPUBLISHED" : "PUBLISHED"
                      } course `,
                    })
                  );
                }}
              >
                {selectedCourse.fPublish ? "Unpublish" : "Publish"}
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default CreateCourse;


