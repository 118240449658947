import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Container, Row, Col, Form } from "react-bootstrap";

import QuestionsInTopic from "./QuestionsInTopic";
import CreateNewQuestionOutsideFilteredPoint from "../CreateNewQuestionOutsideFilteredPoint/CreateNewQuestionOutsideFilteredPoint";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewQuestions,
  getResponse,
  setFShowSelectionMenu,
} from "../../../slices/chatGPTSliceV2";
import SyncBtn from "../SyncBtn/SyncBtn";

import QuestionsList from "../QuestionsList/QuestionsList";
import Response from "../Response/Response";
import SelectedFilteredPointQuestions from "../SelectedFilteredPointQuestions/SelectedFilteredPointQuestions";

const NewQuestionModal = ({
  isModalOpen = false,
  setModalStatus,
  indexInHeadingQuestions,
}) => {
  const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(true);
  const [selectedQuestionFromList, setSelectedQuestionFromList] = useState("");
  const [questionOptions, setQuestionOptions] = useState([]);
  const [askQuestionInChat, setAskQuestionInChat] = useState("newChat");
  const [questionsList, setQuestionsList] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [selectedFilteredPoints, setSelectedFilteredPoints] = useState([]);
  const [checkAllFPForResponses, setCheckAllFPForResponses] = useState([]);
  const [fShow, setfShow] = useState(false);

  const closeModal = () => {
    setModalStatus(false);
    setQuestionsList([]);
    setSelectedFilteredPoints([]);
    setSelectedQuestionFromList("");
    setIsSubmitBtnEnabled(true);
  };
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const topic = useSelector((state) => state.chatGPTSlice.topic);

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };
  useEffect(() => {
    const defaultSelectedQuestion =
      topic?.headingQuestions[indexInHeadingQuestions]?.question;

    if (defaultSelectedQuestion) {
      setSelectedQuestionFromList(defaultSelectedQuestion);
    }
  }, []);
  useEffect(() => {
    if (selectedQuestionFromList !== "") {
      setSelectedFilteredPoints([]);
      dispatch(getResponse({ questionId: selectedQuestionFromList }));
    }
  }, [selectedQuestionFromList]);
  function ResponsesLoop({
    responses,
    handleFilterChange,
    selectedFilters,
    questionId,
  }) {
    console.log("selected filters: ", selectedFilters);
    return (
      <Container>
        {console.log(responses)}
        {responses?.map((response, index) => (
          <div key={response?._id} style={{ marginTop: "2rem" }}>
            <p>Response {index + 1}:</p>
            <FilteredPointsLoop
              // filters={response.filters}
              filters={[]}
              handleFilterChange={handleFilterChange}
              selectedFilters={selectedFilters}
              currentResponseId={response?._id}
              questionId={questionId}
            />
            <div
              style={{
                maxHeight: "fitContent",
                overflowY: "auto",
                resize: "vertical",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                height: "150px",
              }}
            >
              {response?.htmlResponse &&
                response?.htmlResponse?.split("\n").map((res, i) => (
                  <p
                    key={i}
                    style={{
                      color: "#1e3a8a",
                      fontSize: "18px",
                      margin: "2px",
                    }}
                    // ref={textRef}
                    // onMouseUp={() =>
                    //   handleSelection(
                    //     responses[questionId][
                    //       currentResponseIdx
                    //     ]?.htmlResponse?.split("\n"),
                    //     i
                    //   )
                    // }
                  >
                    {res}
                  </p>
                ))}
            </div>
          </div>
        ))}
      </Container>
    );
  }

  function FilteredPointsLoop({
    handleFilterChange,
    selectedFilters,
    currentResponseId,
    questionId,
  }) {
    const filters = currentResponseId
      ? topic?.filteredPoints[questionId]?.points?.filter(
          (point) => point.response === currentResponseId
        )
      : [];
    return (
      <Container>
        {filters?.map((filter) => (
          <Row key={filter.id}>
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                checked={selectedFilters.includes(filter)}
                onChange={() => handleFilterChange(filter)}
              />
            </Col>
            <Col>
              <p>{filter?.point}</p>
            </Col>
          </Row>
        ))}
      </Container>
    );
  }

  const headingQuestions = useSelector(
    (state) => state.chatGPTSlice.topic.headingQuestions
  );
  const questionsInTopic = useSelector((state) => state.chatGPTSlice.questions);
  //   const responsesOfSelectedQuestion = useSelector(
  //     (state) => state.chatGPTSlice.responses[`${selectedQuestionFromList}`]
  //   );
  useEffect(() => {
    const questionsArray = headingQuestions.filter(
      (q) => q.question !== undefined
    );
    // console.log(questionsArray);
    // console.log(questionsInTopic);
    const options = questionsArray?.map((q) => {
      // console.log(questionsInTopic[q.question].question);
      return {
        label: questionsInTopic[q.question].question,
        value: q.question,
      };
    });
    // console.log(options);
    setQuestionOptions(options);
  }, [questionsInTopic]);

  const addQuestionsFromList = () => {
    console.log(questionsList);
    dispatch(
      addNewQuestions({
        questionsList,
        askQuestionInChat,
        topicId: topic._id,
        closeModal,
        setIsSubmitBtnEnabled,
      })
    );
    setIsSubmitBtnEnabled(false);
  };

  const handleSelectionMenuDismissOnClickOutside = (e) => {
    if (!e.target.parentNode.classList.contains("responseSelectionPopup")) {
      dispatch(setFShowSelectionMenu(false));
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} size="xl">
      <Modal.Header closeButton className="py-3">
        <Modal.Title className="d-flex align-items-center ">
          <h5 className="m-0 p-0">Add new questions</h5>
          <span className="px-4">
            <SyncBtn />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body onMouseDown={handleSelectionMenuDismissOnClickOutside}>
        {/* <p>Create new questions using filtered point:</p>
          <QuestionsInTopic
            setSelectedQuestionFromList={setSelectedQuestionFromList}
            placeholder={"Select question for filtered points"}
          /> */}
        <div onClick={() => setfShow((prev) => !prev)}>
          <h6
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center text-maroon"
          >
            Create questions using Filtered points
            <span
              className="text-secondary fw-light text-decoration-underline ms-2"
              style={{ fontSize: "15px", cursor: "pointer" }}
            >
              {fShow ? (
                <i className="bi bi-chevron-up"></i>
              ) : (
                <i className="bi bi-chevron-down"></i>
              )}
            </span>
          </h6>
        </div>
        {fShow && (
          <Container>
            <Form.Group className="mt-2">
              <Form.Label>Question to show responses:</Form.Label>
              <Form.Control
                as="select"
                onChange={(event) =>
                  setSelectedQuestionFromList(event.target.value)
                }
                value={selectedQuestionFromList}
              >
                <option value="">Select a question...</option>
                {questionOptions?.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <br />
            {selectedQuestionFromList && (
              <Response
                showResponse={showResponse} //headingAndQuestion elementId
                setShowResponse={setShowResponse} //headingAndQuestion elementId
                questionId={selectedQuestionFromList} //questionId
                questionModal={true}
                selectedFilteredPoints={selectedFilteredPoints}
                setSelectedFilteredPoints={setSelectedFilteredPoints}
                setCheckAllFPForResponses={setCheckAllFPForResponses}
                checkAllFPForResponses={checkAllFPForResponses}
              />
            )}

            {selectedQuestionFromList && (
              <SelectedFilteredPointQuestions
                selectedFilteredPoints={selectedFilteredPoints}
                setSelectedFilteredPoints={setSelectedFilteredPoints}
                questionsList={questionsList}
                setQuestionsList={setQuestionsList}
                indexInHeadingQuestions={indexInHeadingQuestions}
                setCheckAllFPForResponses={setCheckAllFPForResponses}
                checkAllFPForResponses={checkAllFPForResponses}
              />
            )}
          </Container>
        )}
        <br />
        <CreateNewQuestionOutsideFilteredPoint
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
          indexInHeadingQuestions={indexInHeadingQuestions}
        />
        <QuestionsList
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
        />
        
        <Modal.Footer
          className="d-flex justify-content-between border-0 p-0 mt-4"
          onMouseDown={handleSelectionMenuDismissOnClickOutside}
        >
          <Button
            onClick={() => setModalStatus(false)}
            style={{
              width: "150px",
              backgroundColor: "#3FACCB",
              border: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isSubmitBtnEnabled}
            style={{
              width: "150px",
              backgroundColor: "#3FACCB",
              border: "none",
            }}
            onClick={addQuestionsFromList}
          >
            {isSubmitBtnEnabled ? (
              "Add"
            ) : (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default NewQuestionModal;
