import React from "react";
import "./ApplicationComponents.css";

const StageIndicator = ({
  currentPage,
  setaskConfirmation,
  saveCurrentStage,
  redirectToPage,
}) => {
  const StageBlock = ({ stageNumber, stageName }) => {
    const handleStagesChange = (stage) => {
      // console.log("clicked stage ", stageNumber, stageName);
      if (currentPage === 3) {
        return;
      }
      if (currentPage === 1 && stageNumber === 2) {
        saveCurrentStage();
      } else {
        redirectToPage(stageNumber);
      }
    };
    return (
      <div
        className="d-flex flex-column align-items-center stages-block"
        style={currentPage === stageNumber ? { color: "#7EB2D9" } : {}}
      >
        {currentPage === stageNumber && (
          <i className={`fa fa-chevron-down current-stage-pointor`}></i>
        )}
        <span
          className={`stage-circle ${
            currentPage > stageNumber ? "stage-circle-completed" : ""
          }`}
          style={currentPage === stageNumber ? { borderColor: "#7EB2D9" } : {}}
        >
          {currentPage <= stageNumber ? `${stageNumber}` : ""}
        </span>
        <span
          className="stage-btn-item"
          style={
            stageNumber === 2 ? { paddingTop: "2px", paddingBottom: "2px" } : {}
          }
          onClick={handleStagesChange}
        >
          {stageName}
        </span>
      </div>
    );
  };
  const StageConnector = ({ stageNumber }) => {
    return (
      <div
        className=""
        style={{
          width: "100%",
          height: "3px",
          backgroundColor: currentPage > stageNumber ? "#74CD63" : "gray",
        }}
      ></div>
    );
  };
  return (
    <div className="stage-indicator-container">
      <div className="stage-indicator">
        <div className="d-flex flex-column">
          <div
            className="d-flex flex-column align-items-center"
            style={{ position: "relative", top: "-6px" }}
          >
            <div className="px-sm-4 px-1 py-3 stages-connector">
              <div className="d-flex">
                <StageConnector stageNumber={1} />
                {/* <StageConnector stageNumber={2} /> */}
              </div>
            </div>
            <div
              className="d-flex justify-content-around px-sm-4 px-1 py-3"
              style={{ width: "100%" }}
            >
              <StageBlock stageNumber={1} stageName={"Profile page"} />
              <StageBlock
                stageNumber={2}
                stageName={"Time & Work Preferences"}
              />
              {/* <StageBlock stageNumber={3} stageName={"Test"} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageIndicator;
