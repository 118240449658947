import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import testSubmittedBackgroundImage from "../../../assets/profile-images/testSubmittedBackground.svg";
import tick from "../../../assets/profile-images/tick.svg";
import "./Test1Completion.css";

function Test1Completion() {
  return (
    <div style={{ height: "100dvh" }}>
      <Container className="d-flex flex-column align-items-center">
        <div className="test1Completion">
          <Row style={{ textAlign: "center", marginTop: "20px" }}>
            <Col>
              <img
                style={{ height: "240px", width: "488px", marginLeft: "110px" }}
                src={testSubmittedBackgroundImage}
                alt="testSubmitedSuccessfully"
              />
              <img
                style={{
                  height: "116px",
                  width: "116px",
                  position: "relative",
                  top: "-2px",
                  right: "300px",
                }}
                src={tick}
                alt="tick"
              />
              <h3
                style={{
                  fontWeight: "bold",
                  marginTop: "15px",
                }}
              >
                Thank you for submitting your attempt.
              </h3>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              textAlign: "center",
              marginTop: "30px",
              fontSize: "20px",
            }}
          >
            <div className="d-flex justify-content-center">
              <h5 style={{ maxWidth: "500px", fontWeight: "400" }}>
                You can check your application status by coming back to this
                page after 4-5 days.
              </h5>
            </div>
          </Row>
          <Row
            style={{
              textAlign: "center",
              marginTop: "10px",
              position: "relative",
              top: "-30px",
              justifyContent: "center",
            }}
          >
            {/* <Button
          style={{ width: "100px" }}
          variant="danger"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </Button> */}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Test1Completion;
