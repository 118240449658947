import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./NewsFeed.css";
import axiosInstance from "../../../axios";
import CarouselComponent from "../../../components/NewsFeed/Carousel/Carousel";
import Blog from "../../../components/NewsFeed/Blog/Blog";
import subscribeArrow from "../../../assets/images/subscribe-arrow.svg";
import {
  getArticles,
  getSavedCreatedPosts,
  getVotesForCreatedPosts,
} from "../../../slices/newsFeedSlice";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "../../../containers/SignIn/SignIn";
function Newsfeed() {
  const [showSignIn, setShowSignIn] = useState(false);
  const showSignInModal = () => {
    setShowSignIn(true);
    document.body.style.overflow = "hidden";
  };
  return (
    <>
      {showSignIn && (
        <div className="signin-overlay">
          <div className="signin-container">
            <SignIn />
          </div>
        </div>
      )}
      <div>
        <CarouselComponent />
        <div className="px-3 container-md">
          <NewsFeed showSignInModal={showSignInModal} />
        </div>
      </div>
    </>
  );
}

function NewsFeed({ showSignInModal }) {
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.newsFeedSlice.articles);
  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);
  useEffect(() => {
    if (
      sessionUser?.user &&
      articles.fArticlesFetched &&
      !articles.fVotesFetched
    ) {
      // console.log({
      //   fArticlesFetched: articles.fArticlesFetched,
      //   fVotesFetched: !articles.fVotesFetched,
      // });
      dispatch(
        getVotesForCreatedPosts({
          postIds: articles.data.map((article) => article.postId),
        })
      );
    }
    if (
      sessionUser?.user &&
      articles.fArticlesFetched &&
      !articles.fBookmarksFetched
    ) {
      dispatch(
        getSavedCreatedPosts({
          postIds: articles.data.map((article) => article.postId),
        })
      );
    }
  }, [dispatch, articles, sessionUser]);
  return (
    <>
      <div
        className="d-sm-flex flex-column justify-content-center pt-md-3 pb-md-5"
        style={{
          boxSizing: "border-box",
          paddingBottom: "10px",
        }}
      >
        <h1 className="mt-3 mb-md-4 mb-3 text-center custom-latest-heading">
          Latest Marketing Ideas & Strategies
        </h1>
        <div className="d-lg-flex justify-content-center align-items-center">
          <div className="d-sm-flex flex-column mt-3">
            <p className="text-center custom-tagline-top me-lg-4 me-0 my-1">
              Supercharge your Growth :
            </p>
            <p className="custom-tagline-bottom me-lg-5 mt-1">
              Never miss a great idea
            </p>
          </div>
          <div className="custom-subscribe-container mx-auto mx-lg-1">
            <input
              type="email"
              placeholder="Your Email"
              className="py-md-3 py-2 mx-2 my-2 custom-input custom-subscribe-email w-100"
            />
            <button className="px-3 py-2 my-2 custom-subscribe-button">
              <img
                className="custom-subscribe-arrow"
                src={subscribeArrow}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={11} className="mb-5">
          <Blogs blogList={articles?.data} showSignInModal={showSignInModal} />
        </Col>
      </Row>
    </>
  );
}

const Blogs = ({ blogList, showSignInModal }) => {
  return (
    <Row>
      {blogList?.length >= 0 &&
        blogList?.map((blogData, index) => {
          if (blogData) {
            return (
              <Col
                key={blogData?.postId}
                xs={12}
                className="mb-md-4"
                style={{ paddingRight: "64px", marginBottom: "18px" }}
              >
                <Blog
                  postId={blogData?.postId}
                  authorProfilePic={blogData?.authorProfilePic}
                  author={blogData?.author}
                  readTime={blogData?.readTime}
                  url={blogData?.url}
                  title={blogData?.title}
                  key_learning={blogData?.key_learning}
                  tags={blogData?.tags}
                  articleImageUrl={blogData?.articleImageUrl}
                  upvoteCount={blogData?.upvoteCount}
                  isUpvoted={blogData?.fLiked || false}
                  isDownvoted={blogData?.fDisliked || false}
                  isBookmarked={blogData?.fBookmarked || false}
                  objectName="articles"
                  showSignInModal={showSignInModal}
                />
              </Col>
            );
          }
        })}
    </Row>
  );
};

export default Newsfeed;
