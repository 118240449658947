import React, { createContext, useEffect, useReducer, useState } from "react";
import { WorkExperience } from "./WorkExperience";
import { ExperienceDetails } from "./ExperienceDetails";
import { initTimebar, timeBarReducer } from "./reducers/timeBarReducer";
import { useSelector, useDispatch } from "react-redux";
import messageBox from "../../../assets/profile-images/message_box.svg";
import {
  addWorkDetails,
  deleteWorkDetails,
  updateWorkDetails,
} from "../../../slices/profileSlices/workSlice";
import {
  closeEditExp,
  openEditExp,
} from "../../../slices/profileSlices/openTabSlice";
import {
  deleteUserProfileDetails,
  saveWorkDetails,
} from "../utility/userUtils";
import { useParams } from "react-router-dom";
import { newAlert } from "../../../slices/alertSlice";
import AskConfirmationModal from "./subComp/AskConfirmationModal";

export const SkillContext = createContext("");
export const ToolsContext = createContext("");

export const EditWorkData = () => {
  const { openDetails: editExpDetails } = useSelector((state) => {
    return state.openTabSlice;
  });
  const workData = useSelector((state) => {
    return state.workSlice.workData;
  });
  const [askConfirmation, setaskConfirmation] = useState(false);
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [skillData, setSkillData] = useReducer(timeBarReducer, [
    ...initTimebar,
  ]);
  // const [toolsData, setToolsData] = useReducer(timeBarReducer, [
  //   ...initTimebar,
  // ]);
  const [organizationPic, setOrganizationPic] = useState("");
  const [teamContribution, setTeamContribution] = useState({ value: 99 });
  const [orgDetails, setOrgDetails] = useState({
    name: "",
    position: "",
    url: "",
  });
  const [timePeriod, setTimePeriod] = useState({
    startMonth: "",
    endMonth: "",
    startYear: "",
    endYear: "",
  });
  const [currWorking, setCurrentlyWorking] = useState(false);
  const [workDescribe, setWorkDescribe] = useState({
    workDescription: "",
    workingHours: "",
  });

  const { index, mode } = editExpDetails;
  const localData = {
    orgDetails,
    setOrgDetails,
    timePeriod,
    setTimePeriod,
    currWorking,
    setCurrentlyWorking,
    workDescribe,
    setWorkDescribe,
  };
  const workInfo = {
    orgDetails,
    timePeriod,
    currWorking,
    workDescribe,
    teamContribution,
    setTeamContribution,
  };

  const saveJobDetails = () => {
    // console.log(workData[index]);
    let sdata = { ...workData[index] };
    sdata.orgPic = organizationPic;
    sdata.orgInfo = orgDetails;
    sdata.timePeriod = timePeriod;
    sdata.workDescription = workDescribe.workDescription;
    sdata.workingHours = workDescribe.workingHours;
    sdata.currWorking = currWorking;
    sdata.teamContribution = teamContribution.value;
    sdata.skills = skillData
      .sort((a, b) => {
        return b.value - a.value;
      })
      .map((skill) => {
        return { value: skill.value, name: skill.name };
      });
    // sdata.tools = toolsData
    //   .sort((a, b) => {
    //     return b.value - a.value;
    //   })
    //   .map((skill) => {
    //     return { value: skill.value, name: skill.name };
    //   });
    // console.log("sdata", sdata);
    // console.log("mode", mode);
    if (mode === "update") {
      saveWorkDetails(sdata, "update", userId)
        .then((wData) => {
          if (wData.status === 200) {
            dispatch(updateWorkDetails({ index, data: sdata }));
            dispatch(
              newAlert({
                message: "Job Updated Successfully",
                type: "success",
              })
            );
          } else {
            dispatch(
              newAlert({
                message: "Failed to update Job",
                type: "danger",
              })
            );
          }
        })
        .finally(() => {
          tabChange();
        });
    } else {
      saveWorkDetails(sdata, "add", userId)
        .then((wData) => {
          sdata.workId = wData.data?.workDetailId;
          if (wData.status === 200) {
            dispatch(addWorkDetails({ index, data: sdata }));
            dispatch(
              newAlert({
                message: "Job Added Successfully",
                type: "success",
              })
            );
          } else {
            dispatch(
              newAlert({
                message: "Failed to add Job",
                type: "danger",
              })
            );
          }
        })
        .finally(() => {
          tabChange();
        });
    }
  };

  const deleteJobDetails = async () => {
    const { workId, orgInfo } = workData[index];
    const removeWorkDetails = async () => {
      console.log(workId);
      const status = await deleteUserProfileDetails(workId, userId, "job");
      // const status = "success";
      console.log(status);
      if (status === "success") {
        dispatch(deleteWorkDetails({ index }));
        dispatch(closeEditExp());
        // window.location.reload();
      }
    };
    setaskConfirmation({
      active: true,
      title: "",
      heading: `Are you sure you want to delete "${orgInfo?.name}" from your profile`,
      onClick: removeWorkDetails,
      type: "delete",
    });
  };

  const tabChange = () => {
    if (editExpDetails.value1 === true) {
      // console.log(workData);
      if (mode === "add") {
        // as previous data is saved mode changed to update and index pointing at last
        dispatch(
          openEditExp({
            value1: false,
            value2: true,
            mode: "update",
            index: workData.length,
          })
        );
      } else
        dispatch(
          openEditExp({ ...editExpDetails, value1: false, value2: true })
        );
    } else
      dispatch(
        openEditExp({ ...editExpDetails, value1: false, value2: false })
      );
    // console.log(sdata);
  };

  useEffect(() => {
    // console.log(workData[index]);
    if (mode === "update" && workData[index] && workData[index].orgInfo) {
      setOrgDetails(workData[index].orgInfo);
      setTimePeriod(workData[index].timePeriod);
      setCurrentlyWorking(workData[index].currWorking);
      setTeamContribution({ value: workData[index].teamContribution });
      if (workData[index].orgPic) setOrganizationPic(workData[index].orgPic);
      setWorkDescribe({
        workDescription: workData[index].workDescription,
        workingHours: workData[index].workingHours,
      });
      let skills = [...workData[index].skills];
      // let tools = [...workData[index].tools];
      setSkillData({
        type: "setList",
        index: 0,
        value: skills.sort((a, b) => {
          return b.value - a.value;
        }),
      });
      // setToolsData({
      //   type: "setList",
      //   index: 0,
      //   value: tools.sort((a, b) => {
      //     return b.value - a.value;
      //   }),
      // });
    } else {
      setOrgDetails({ name: "", position: "", url: "" });
      setTimePeriod({
        startMonth: "Jan",
        endMonth: "Jan",
        startYear: "",
        endYear: "",
      });
      setCurrentlyWorking(false);
      setWorkDescribe({ workDescription: "", workingHours: "" });
      setTeamContribution({ value: 99 });
      setSkillData({ type: "setList", index: 0, value: [] });
      // setToolsData({ type: "setList", index: 0, value: [] });
    }
    // console.log(workData[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editExpDetails]);
  return (
    <>
      <AskConfirmationModal
        askConfirmation={askConfirmation}
        setaskConfirmation={(data) => setaskConfirmation(data)}
      />
      <div>
        <div
          className="mb-2 row"
          style={{ position: "relative", margin: "0px" }}
        >
          <SkillContext.Provider value={{ skillData, setSkillData }}>
            {/* <ToolsContext.Provider value={{ toolsData, setToolsData }}> */}
            {editExpDetails.value1 && (
              <div className="mb-2">
                <WorkExperience
                  localData={localData}
                  saveJobDetails={saveJobDetails}
                />
              </div>
            )}
            {editExpDetails.value2 && (
              <ExperienceDetails
                workInfo={workInfo}
                orgPic={{ organizationPic, setOrganizationPic }}
                saveJobDetails={saveJobDetails}
                deleteJobDetails={deleteJobDetails}
              />
            )}
            {/* </ToolsContext.Provider> */}
          </SkillContext.Provider>
        </div>
      </div>
    </>
  );
};
