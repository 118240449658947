import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import axiosInstance from "../../../axios";
import { newAlert } from '../../../slices/alertSlice';
import ErrorBoundary from '../../../components/NewsFeed/EditPost/ErrorBoundary';
import TextEditor from "../../../components/NewsFeed/TextEditor/TextEditor.js";
import leftArrow from "../../../assets/images/right-arrows.svg";
import { debounce } from "../../../helpers/stubs.js";
import {
  extractImageSrc,
  modifyHtml,
  replaceImageSrc,
} from "../../../components/NewsFeed/utilities.js";
import BlogTextEditor from "../../../components/BlogTextEditor/BlogTextEditor.js";

function EditSection() {
  const [section, setSection] = useState();
  const [isFixed, setIsFixed] = useState(false);
  const [contentStatus, setContentStatus] = useState({
    saved: true,
    lastContentState: null,
    pendingLinks: false,
  });
  const [hasH1Heading, setHasH1Heading] = useState(true);
  const sectionComponent = useRef(null);
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const [sectionContent, setSectionContent] = useState("");
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [fieldChange, setFieldChange] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const courseId = searchParams.get("courseId");
  const { sectionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const rect = sectionComponent.current?.getBoundingClientRect();
      if (rect) setIsFixed(rect.top <= 0 && rect.top + rect.height >= 0);
    };
    const debouncedHandleScroll = debounce(handleScroll, 40); // Adjust the debounce delay as needed

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  useEffect(() => {
    const getCourseSection = async (courseId) => {
      try {
        const { data } = await axiosInstance.get(
          `/api/admin/get-course-section/${sectionId}`
        );
        if (data) {
          const newSectionContent = data.content;
          if (newSectionContent) {
            setSection(data);
            setSectionContent(newSectionContent);
          }
          setLastSavedAt(Date.parse(data.updatedAt));
          if (data.fShowContent) setIsChecked(data.fShowContent);
          setFormData({
            sectionName: data.name,
            duration: data.duration,
          });
          setInitialFormData({
            sectionName: data.name,
            duration: data.duration,
          });
        } else {
          dispatch(
            newAlert({
              type: "danger",
              message: "Failed to get course section!",
            })
          );
        }
      } catch (err) {
        dispatch(newAlert({ type: "danger", message: err }));
      }
    };
    if (sectionId) {
      getCourseSection();
    }
  }, [sectionId]);

  const getImagesS3Url = async () => {
    try {
      if (sectionId) {
        const imagesData = await extractImageSrc(sectionContent, sectionId);

        if (imagesData.length > 0) {
          const { data } = await axiosInstance.post(
            `/api/admin/save-course-content-images-in-s3`,
            { imagesData }
          );

          const { imagesS3Url } = data;
          const newsectionContent = replaceImageSrc(
            sectionContent,
            imagesS3Url
          );
          setContentStatus({ ...contentStatus, pendingLinks: true });
          setSectionContent(newsectionContent);
          return newsectionContent;
        } else {
          return sectionContent;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateSectionContent = async () => {
    try {
      // if (showAlerts) setSavingPostData(true);
      if (sectionId && sectionContent) {
        let newSectionContent = await getImagesS3Url();
        newSectionContent = modifyHtml(newSectionContent);
        const { data } = await axiosInstance.post(
          `/api/admin/update-course-section-details`,
          {
            courseId,
            sectionId,
            content: newSectionContent,
          }
        );
        if (data) {
          setLastSavedAt(Date.parse(data.section.updatedAt));
          setContentStatus({ ...contentStatus, pendingLinks: false });
          setContentStatus({ ...contentStatus, saved: true });
        }
      }
    } catch (err) {
      console.log("Error: ", err);
      dispatch(
        newAlert({
          type: "danger",
          message: "Failed to update section details!",
        })
      );
    } finally {
      // setSavingPostData(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (["sectionName", "duration"].includes(name)) {
      setFieldChange({
        ...fieldChange,
        [name]: value !== initialFormData[name],
      });
    }
  };

  const handleToggle = async (e) => {
    try {
      const newCheckedState = !isChecked; // Determine the new checked state
      setIsChecked(newCheckedState); // Update the local state immediately for responsiveness

      // Prepare the body for the API request
      const body = {
        courseId,
        sectionId,
        fShowContent: newCheckedState,
      };
      // Make the API call to update the section details
      const { data } = await axiosInstance.post(
        `/api/admin/update-course-section-details`,
        body
      );
      if (!data.fShowContent) {
        data.fShowContent = newCheckedState;
      }
      setFieldChange({ ...fieldChange, fShowContent: data.fShowContent });
    } catch (error) {
      // Handle error and dispatch error alert
      console.error("Error saving data:", error);
      dispatch(
        newAlert({
          type: "danger",
          message: "Failed to update Show Content!",
        })
      );
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setFieldChange({});
  };

  const updateSectionDetails = async () => {
    try {
      const body = {
        courseId,
        sectionId,
      };
      if (fieldChange.sectionName) {
        body["name"] = formData.sectionName;
      }
      if (fieldChange.duration) {
        body["duration"] = formData.duration;
      }
      const { data } = await axiosInstance.post(
        `/api/admin/update-course-section-details`,
        body
      );
      setInitialFormData(formData);
      setFieldChange({});
      dispatch(
        newAlert({
          type: "success",
          message: "Successfully Updated Section Detail",
        })
      );
    } catch (error) {
      let errorMsg = error.response?.data?.message;
      if (errorMsg && errorMsg.includes("slugUrlAlreadyExistsForCourse")) {
        errorMsg =
          "Please use another name for section. A section with this name already exists in the course";
      }
      console.error("Error saving data:", error);
      dispatch(
        newAlert({
          type: "danger",
          message: errorMsg || "Failed to update section details!",
        })
      );
    }
  };

  const isFormChanged = () => {
    return (
      formData.sectionName !== initialFormData.sectionName ||
      formData.duration !== initialFormData.duration
    );
  };

  return (
    <div style={{ minHeight: "100dvh" }}>
      <Container style={{ marginBottom: "100px" }}>
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "40px" }}
        >
          <div
            className="d-flex align-items-center gap-1"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/admin/create-course?courseId=${courseId}`)
            }
          >
            <img
              src={leftArrow}
              alt="go-back"
              style={{ width: "0.8rem", transform: "scaleX(-1)" }}
            />
            <span style={{ color: "rgb(10 88 202)" }}>Edit Course</span>
          </div>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              flex: "1",
            }}
          >
            Edit Section
          </h1>
        </div>
        <Form style={{ marginBottom: "10px", marginTop: "50px" }}>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="sectionName">
                <Form.Label>Section Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Section Name"
                  name="sectionName"
                  value={formData.sectionName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="duration">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
              {(fieldChange.sectionName || fieldChange.duration) && (
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => updateSectionDetails()}
                      style={{ marginRight: "5px" }}
                    >
                      Save Changes
                    </Button>
                    <Button variant="secondary" onClick={() => handleCancel()}>
                      Revert Changes
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Section Content"
                checked={isChecked}
                onChange={handleToggle}
              />
            </Col>
          </Row>
        </Form>
        <ErrorBoundary>
          {isChecked && (
            <div ref={sectionComponent} className="editor-container">
              <BlogTextEditor
                data={sectionContent}
                onChange={(content) => {
                  setSectionContent(content);
                  setContentStatus({ ...contentStatus, saved: false });
                }}
                placeholder={"Write course section content here"}
                contentStatus={contentStatus}
                setContentStatus={setContentStatus}
                lastSavedAt={lastSavedAt}
                fShowEditor={sectionId}
                onAutoSave={async () =>
                  await updateSectionContent(false, null, false)
                }
                hasH1Heading={false}
                setHasH1Heading={setHasH1Heading}
                showH1Warning={() => {}}
              />
            </div>
          )}
        </ErrorBoundary>
      </Container>
    </div>
  );
}

export default EditSection;