// components/CKEditorComponent.js
import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import './RichTextEditor.css';
import { modifyHtml } from "../NewsFeed/utilities";

const placeholderFn = (data) => {
  return data;
};

const RichTextEditor = ({
  onChange,
  data,
  placeholder,
  modifyOutputHtml = placeholderFn,
  onAutoSave = placeholderFn,
}) => {
  const saveTimeout = useRef(null);
  const [isFirstChange, setIsFirstChange] = useState(true);

  useEffect(() => {
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current);
      setIsFirstChange(false);
    }

    saveTimeout.current = setTimeout(() => {
      if (!isFirstChange) {
        const modifiedHtml = modifyHtml(data,modifyOutputHtml);
        onAutoSave(modifiedHtml);
      }
      // console.log('saving content,', { isFirstChange });
      setIsFirstChange(false);
      // setPrevData(data);
    }, 5000);

    return () => {
      if (saveTimeout.current) {
        clearTimeout(saveTimeout.current);
      }
    };
  }, [data]);

  const setDefaultFontFamily = (editor) => {
    // console.log({ data: editor.getData() });
    if (!editor.getData()) {
      const fontFamily = editor.commands.get("fontFamily");
      const fontSize = editor.commands.get("fontSize");
      fontFamily.execute({ value: "Georgia, serif" });
      // fontSize.execute({ value: "20px" });
    }
  };

  return (
    <div>
      <CKEditor
        editor={Editor}
        data={data}
        onReady={setDefaultFontFamily}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
          setDefaultFontFamily(editor);
        }}
        config={{
          placeholder: placeholder,
          ckfinder: {
            uploadUrl: "/api/upload-image",
          },
          toolbar: {
            items: [
              "heading",
              "bold",
              "italic",
              "underline",
              "blockQuote",
              "strikethrough",
              "code",
              "codeBlock",
              "|",
              "fontColor",
              "fontBackgroundColor",
              "fontFamily",
              "fontSize",
              "highlight",
              "removeFormat",
              "link",
              "subscript",
              "superscript",
              "|",
              "bulletedList",
              "numberedList",
              // 'outdent',
              "alignment",
              "indent",
              "|",
              // 'imageUpload',
              "imageInsert",
              "insertTable",
              "specialCharacters",
              "mediaEmbed",
              "undo",
              "redo",
            ],
          },
          language: "en",
          image: {
            toolbar: [
              "imageTextAlternative",
              "toggleImageCaption",
              "imageStyle:inline",
              "imageStyle:block",
              // 'imageStyle:side'
            ],
            insert: {
              type: "inline",
            },
          },
          table: {
            contentToolbar: [
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "tableCellProperties",
              "tableProperties",
            ],
          },
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
          fontFamily: {
            // options: [
            //     'default',
            //     'Arial, Helvetica, sans-serif',
            //     'Courier New, Courier, monospace',
            //     'Georgia, serif',
            //     'Lucida Sans Unicode, Lucida Grande, sans-serif',
            //     'Tahoma, Geneva, sans-serif',
            //     'Times New Roman, Times, serif',
            //     'Trebuchet MS, Helvetica, sans-serif',
            //     'Verdana, Geneva, sans-serif',
            // ],
            supportAllValues: true,
            // default: 'Arial, Helvetica, sans-serif'
          },
          fontSize: {
            options: [
              "8px",
              "9px",
              "10px",
              "11px",
              "12px",
              "13px",
              "14px",
              "16px",
              "18px",
              "20px",
              "22px",
              "24px",
              "28px",
              "32px",
              "36px",
              "48px",
              "60px",
              "72px",
            ],
            supportAllValues: true,
          },
          htmlSupport: {
            allow: [
              {
                name: /^(?!\s*$).+/, // Allow all elements
                attributes: {
                  id: true,
                  "data-highlight-id": true,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default RichTextEditor;
