import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { addNewQuestion } from "../../../slices/chatGPTSlice";
import SelectChatToAskQuestion from "../SelectChatToAskQuestion/SelectChatToAskQuestion";
import SyncBtn from "../SyncBtn/SyncBtn";

const AddQuestionModal = ({
  isModalOpen = false,
  setModalStatus,
  indexInHeadingQuestions,
  level,
  relation,
}) => {
  const [question, setQuestion] = useState("");
  const [questionAlias, setQuestionAlias] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(true);
  const [askQuestionInChat, setAskQuestionInChat] = useState("");
  const dispatch = useDispatch();

  const resetForm = () => {
    setQuestion("");
    setQuestionAlias("");
    setValidated(false);
  };
  const closeModal = () => {
    setModalStatus(false);
    setIsSubmitBtnEnabled(true);
  };
  const createNewQuestionForTopic = () => {
    const payload = {
      question: question?.trim(),
      questionAlias: questionAlias?.trim(),
      indexInHeadingQuestions,
      closeModal,
      resetForm,
      level,
      setIsSubmitBtnEnabled,
      relation,
      askQuestionInChat,
    };
    dispatch(addNewQuestion(payload));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (!question || !question.trim()) {
      console.log("create new question  form validation failed");
      return;
    }

    setIsSubmitBtnEnabled(false);
    createNewQuestionForTopic();
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add new question</Modal.Title>
        <span style={{ marginLeft: "55px", marginTop: "15px" }}>
          <SyncBtn />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-4" md="4" controlId="validationCustom01">
            <Form.Label>Question</Form.Label>
            <Form.Control
              required
              as="textarea"
              placeholder=""
              style={{ height: "80px" }}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              question is required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4" md="4" controlId="validationCustom01">
            <Form.Label>
              Question alias <small className="text-muted">(optional)</small>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder=""
              style={{ height: "50px" }}
              value={questionAlias}
              onChange={(e) => setQuestionAlias(e.target.value)}
            />
          </Form.Group>
          <SelectChatToAskQuestion
            askQuestionInChat={askQuestionInChat}
            setAskQuestionInChat={setAskQuestionInChat}
          />
          <Modal.Footer className="border-0 p-0 mt-4">
            <Button
              type="submit"
              disabled={!isSubmitBtnEnabled}
              style={{ width: "150px" }}
            >
              {isSubmitBtnEnabled ? (
                "Add Question"
              ) : (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddQuestionModal;
