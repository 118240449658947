import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios";
import { newAlert } from "../../../slices/alertSlice";
import { useDispatch } from "react-redux";
import "./PreviewPost.css";
import { Card } from "react-bootstrap";
import CustomModal from "../Components/CustomModal";

const PreviewPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get("postId");
  const dispatch = useDispatch();

  const [postData, setPostData] = useState({
    title: "",
    tagGroups: [],
    thumbnailSrc: "",
    author: "",
    post: "",
    postOutline: [],
  });
  const [modalDetails, setModalDetails] = useState({
    title: "",
    active: false,
    content: "",
    onClick: null,
  });

  const getAuthorAvatarForTagGroup = async (tagGroupId, authorId) => {
    if (tagGroupId) {
      const { data } = await axiosInstance.get(
        `/api/admin/get-author-avatars-for-tagGroup/${tagGroupId}`
      );
      //   console.log(data);
      const authorOpt = data.authors.find((author) => {
        return author._id === authorId;
      });
      return authorOpt;
    } else {
      console.log("no tagId to look for author avatar");
      return [];
    }
  };

  // Simulating API call to load post content
  const loadPostContent = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/admin/get-selected-post-details/${postId}/preview`
      );
      //   console.log("selectedPost: ", data.selectedPost);
      const postContents = data.selectedPost.post ? data.selectedPost.post : "";
      const title = data.selectedPost.title;
      const tagGroups = data.selectedPost.tagGroup.map((item) => {
        return {
          label: item.tagGroupName,
          value: item.tagGroupName,
          id: item._id,
        };
      });
      //   setLastSavedAt(Date.parse(data.selectedPost.updatedAt));
      const authorId = data.selectedPost.author;
      let author = await getAuthorAvatarForTagGroup(tagGroups[0].id, authorId);
      const thumbnailSrc = data.selectedPost.thumbnail;
      const post = postContents;
      const postOutline = data.selectedPost.tableOfContent;
      const postData = {
        title,
        tagGroups,
        thumbnailSrc,
        author,
        post,
        postOutline,
      };
      //   console.log("postData: ", postData);
      setPostData(postData);
    } catch (err) {
      console.log(err);
      dispatch(
        newAlert({ type: "danger", message: "Failed to load preview of post!" })
      );
    }
  };

  // Load post content on component mount
  useEffect(() => {
    loadPostContent();
    window.location = "#preview-post-header";
  }, []);

  return (
    <div
      className="preview-post container"
      style={{ marginTop: "30px", marginBottom: "80px", minHeight: "70vh" }}
    >
      <CustomModal
        modalDetails={modalDetails}
        setModalDetails={setModalDetails}
      />
      <PostHeader navigate={navigate}></PostHeader>
      <Title>{postData.title}</Title>
      <Tag>{postData.tagGroups[0]?.value}</Tag>
      <Thumbnail src={postData.thumbnailSrc} alt="Thumbnail" />
      <Author>{postData.author.name}</Author>
      <Post content={postData.post}></Post>
      <PostOutline outline={postData.postOutline}></PostOutline>
      <UnpublishAndEdit
        text="Unpublish and Edit"
        postId={postId}
        navigate={navigate}
        postTitle={postData.title}
        setModalDetails={setModalDetails}
      ></UnpublishAndEdit>
    </div>
  );
};

const PostHeader = ({ navigate }) => {
  return (
    <div className="d-flex" id="preview-post-header">
      <h2>Preview Post</h2>
      <button
        className="ms-auto add-post"
        onClick={() => {
          navigate(`/admin/create-post`);
        }}
      >
        <i className="fa-solid fa-plus"></i> New Post
      </button>
    </div>
  );
};

const Title = ({ children }) => {
  return (
    <div className="mt-3">
      <span className="text-muted text-small mt-3">Title</span>
      <div className="post-title post-data-border">{children}</div>
    </div>
  );
};

const Tag = ({ children }) => {
  return (
    <div className="">
      <span className="text-muted text-small mt-3">Tag</span>
      <div className="post-tag post-data-border">{children}</div>
    </div>
  );
};

const Thumbnail = ({ src, alt }) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <span className="text-muted text-small mt-3">Thumbnail</span>
      <div className="thumbnail-image-container mt-1">
        <img
          src={src}
          alt={alt}
          className="w-100 h-100 custom-post-image"
          style={{ borderRadius: "13px" }}
        />
      </div>
    </div>
  );
};

const Author = ({ children }) => {
  return (
    <div className="">
      <span className="text-muted text-small mt-3">Author</span>
      <div className="post-author post-data-border">{children}</div>
    </div>
  );
};

const Post = ({ content }) => {
  return (
    <div className="" style={{ marginTop: "32px" }}>
      {/* <span className="text-muted text-small">Post</span> */}
      <div
        className="post-content post-data-border px-3 py-3"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
};

const PostOutline = ({ outline }) => {
  //   console.log("outline: " + typeof outline);
  return (
    <div>
      <Card className="mt-4 post-outline">
        <Card.Header>Outline</Card.Header>
        <Card.Body>
          {outline?.map((heading, index) => (
            <div
              key={index}
              style={{ marginLeft: `${(heading.level - 1) * 28}px` }}
            >
              {heading.heading}
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

const UnpublishAndEdit = ({
  postId,
  text,
  postTitle,
  navigate,
  setModalDetails,
}) => {
  const dispatch = useDispatch();

  const handleUnpublishPost = () => {
    setModalDetails({
      title: "Are you sure you want to unpublish and edit the post?",
      active: true,
      content: `Post title: ${postTitle}`,
      onClick: unpublishAndEditPost,
    });
  };
  const unpublishAndEditPost = async () => {
    try {
      const response = await axiosInstance.post(
        "api/admin/unpublish-created-post",
        { postId }
      );
      console.log("response: " + response);
      if (response.status === 200) {
        navigate(`/admin/create-post?postId=${postId}`);
        dispatch(newAlert({ type: "success", message: "Post unpublished!" }));
      }
    } catch (e) {
      console.log("Error in unpublishing post: ", e);
    }
  };
  return (
    <div className="text-center">
      <button
        className="mt-3 btn btn-primary unpublish-edit-btn"
        style={{ backgroundColor: "rgb(0, 153, 255)", border: "none" }}
        onClick={handleUnpublishPost}
      >
        {text}
      </button>
    </div>
  );
};

export default PreviewPost;
