import React from "react";
import { useDispatch } from "react-redux";
import {
  getAiResponse,
  regenerateResponse,
} from "../../../slices/chatGPTSliceV2";
import { setLoadingStateForQuestion } from "../../../slices/chatGPTSliceV2";

const getRegenerateButtonVariant = (responseStatus) => {
  const buttonVariants = {
    pending: "warning",
    completed: "success",
    failed: "danger",
  };

  return buttonVariants[responseStatus];
};

const RegenerateResponseBtn = ({
  questionId,
  question,
  topicId,
  responseStatus,
  contextProvided,
}) => {
  const buttonVariant = getRegenerateButtonVariant(responseStatus);
  const dispatch = useDispatch();
  const regenerateResponseForQuestion = () => {
    dispatch(setLoadingStateForQuestion({ questionId, loadingState: true }));

    regenerateResponse({
      questionId,
      question,
      topicId,
      contextProvided,
      //   fGenerateNewResponse: true,
    });
  };

  return (
    <p className="regenerateResponseBtn">
      <button
        className={`btn btn-sm btn-${buttonVariant}`}
        onClick={regenerateResponseForQuestion}
      >
        <i className="bi bi-arrow-clockwise"></i>
      </button>
    </p>
  );
};

export default RegenerateResponseBtn;
