import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditProject from "./EditProject";
import { EditProjectSkills } from "./EditProjectSkills";
import { useParams } from "react-router-dom";
import { timeDifference } from "../workComponents/subComp/WorkPeriod";
// import { EditProjectSkills } from "./EditProjectSkills";

export const ProjectSkillDetails = ({
  project,
  setProject,
  editMode,
  setEditMode,
  saveProjectDetails,
  deleteProjectDetails,
  errors,
  setErrors,
}) => {
  const [skillData, setSkillData] = useState([]);
  const { userId } = useParams();

  const [showLessSkillHrsMsg, setShowLessSkillHrsMsg] = useState({
    lessSkillHrs: false,
    closedWarning: false,
  });

  const validateProjectDetails = (e) => {
    // e.preventDefault();
    // console.log({ project });
    const { currWorking } = project;
    let timePeriod = project.timePeriod || {};
    // Perform validation before submitting the form
    const newErrors = {};
    if (!project.title?.trim()) {
      newErrors.title = "Project title is required.";
    }
    if (!project.description?.trim()) {
      newErrors.description = "Work description is required.";
    }
    if (!timePeriod.startMonth || !timePeriod.startYear) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        start: "Start month & year is required.",
      };
    } else if (
      !currWorking &&
      (timePeriod.endMonth === "" || timePeriod.endYear === "")
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        end: "End month & year is required.",
      };
    } else if (
      timePeriod.endMonth === timePeriod.startMonth &&
      timePeriod.endYear === timePeriod.startYear
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        all: "There must be at least one month of experience",
      };
    }
    const [yearDiff] = timeDifference(timePeriod, currWorking);
    const currYear = new Date().getFullYear();
    const yearExceed =
      (timePeriod.endYear > currYear && !currWorking) ||
      timePeriod.startYear > currYear;
    if (
      ((currWorking && timePeriod.startYear) ||
        (timePeriod.startYear && timePeriod.endYear)) &&
      (yearDiff < 0 ||
        yearDiff > 100 ||
        yearExceed ||
        timePeriod.startYear < currYear - 80)
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        all: "Invalid Time period given",
      };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return true;
    } else {
      setErrors("");
      return false;
    }
  };

  const saveProjectDetailsWithSkills = () => {
    const validationFailed = validateProjectDetails();
    if (validationFailed) {
      return;
    }
    // console.log(project);
    const skills = skillData
      .sort((a, b) => {
        return b.value - a.value;
      })
      .map((skill) => {
        return {
          value: skill.value,
          skill: skill.name?._id,
          label: skill.name?.label,
        };
      });
    const updatedProjects = { ...project, skills };
    setProject(updatedProjects);
    // console.log({ updatedProjects });
    saveProjectDetails({
      mode: editMode.mode,
      userId,
      projectDetails: updatedProjects,
    });
  };

  // const { title, timePeriod, currWorking, description } = project;
  const closeExpDetails = () => {
    setEditMode({ value: false, mode: "" });
  };

  useEffect(() => {
    // console.log({ skills: project?.skills });
    if (project?.skills?.length) {
      return setSkillData(project?.skills);
    }
  }, [project]);

  return (
    <div className="card p-4 col-12" style={{ backgroundColor: "#F9FEFF" }}>
      <div className="row">
        <div className="col-lg-6 col-md-11 col-12">
          <EditProject
            project={project}
            setProject={setProject}
            editMode={editMode}
            setEditMode={setEditMode}
            saveProjectDetails={saveProjectDetails}
            deleteProjectDetails={deleteProjectDetails}
            errors={errors}
            setErrors={setErrors}
            fProjectWithSkills={true}
          />
        </div>
        <div
          className="col-lg-6 col-md-11 col-12 pe-sm-3"
          style={{ marginLeft: "-14px" }}
        >
          <div className="row" style={{ position: "relative" }}>
            <div className="text-center ps-5 mb-3" style={{ fontSize: "13px" }}>
              <div className="fw-bold mt-2 mt-0">
                Hrs spent on skills for the project
              </div>
              {/* <div className="fw-bold">
                (Assume you work for 45 hrs per week)
              </div> */}
              <div
                className={`${
                  !showLessSkillHrsMsg.lessSkillHrs ||
                  showLessSkillHrsMsg.closedWarning
                    ? "d-none"
                    : ""
                }`}
                style={{
                  position: "relative",
                  borderRadius: "2px",
                  backgroundColor: "#ffff0042",
                }}
              >
                some of the skills went below 1 hr
                <i
                  className="fa fa-close mt-1"
                  style={{
                    position: "absolute",
                    right: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setShowLessSkillHrsMsg({
                      ...showLessSkillHrsMsg,
                      closedWarning: true,
                    })
                  }
                ></i>
              </div>
            </div>
            <div className="col-12">
              <EditProjectSkills
                dataList={skillData}
                setDataList={setSkillData}
                timebarData={{
                  placeholder: "Skill Name",
                  addText: "Add a Skill",
                }}
                showLessSkillHrsMsg={showLessSkillHrsMsg}
                setShowLessSkillHrsMsg={setShowLessSkillHrsMsg}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-left text-lg-center mt-3">
        <button
          className="btn btn-success me-2"
          style={{
            backgroundColor: "#074A75",
            minWidth: "85px",
            border: "none",
          }}
          onClick={() => {
            saveProjectDetailsWithSkills();
          }}
        >
          Save
        </button>
        <button
          onClick={() => closeExpDetails()}
          style={{
            backgroundColor: "#EF888C",
            minWidth: "85px",
            border: "none",
          }}
          className="btn btn-secondary"
        >
          Cancel
        </button>
        <span
          onClick={async () => {
            await deleteProjectDetails(project._id, userId);
          }}
          className="delete-btn-work text-center"
        >
          <i className="far fa-trash-can delete-profile-btn cursor-pointor"></i>
          <span className="d-none d-sm-inline-block">Delete</span>
        </span>
      </div>
    </div>
  );
};
