import React from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import styles from "./FacebookAd.module.css";
import FbAdImageDetailsBelowPrimaryText from "./FbAdImageDetailsBelowPrimaryText/FbAdImageDetailsBelowPrimaryText";
import FBVideo from "./FBVideo/FBVideo";
import FbAdTypeCarousel from "./FbAdTypeCarousel/FbAdTypeCarousel";
import FeedbackButtons from "../FeedbackButtons/FeedbackButtons";

const PrimaryText = ({ primaryText }) => {
  const sanitizedPrimaryText = DOMPurify.sanitize(primaryText);
  return <div className={`${styles["primary-text"]}`}>{parse(sanitizedPrimaryText)}</div>;
};

const FacebookAd = ({ ad, isSaved, userId, isIrrelevantAd, isUpvoted, isDownvoted, query }) => {
  const { organization, primaryText, format, adDetails, displayUrl } = ad;

  const [isDisabled, setIsDisabled] = React.useState(isIrrelevantAd);
  const toggleDisabled = () => setIsDisabled(!isDisabled);

  return (
    <div className={`${styles["facebook-ad"]} card shadow`}>
      <div className={`${styles["card-body"]} pt-1`}>
        <div className={`${styles["ad-header"]} d-flex justify-content-between`}>
          <div className={`d-flex`} style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
            {organization && <img src={organization.logoUrl} className={`${styles["logo-url"]} me-2`} alt="" />}

            <div className={`d-flex flex-column`}>
              {organization && (
                <a href={organization.fbHandle} target="_blank" rel="noreferrer" className={`${styles["organization-name"]} d-flex flex-column`} onClick={e => e.preventDefault()}>
                  {organization.name}
                </a>
              )}

              <div className={`${styles["sponsored-indicator"]}`}>Sponsored</div>
            </div>
          </div>

          <FeedbackButtons
            adId={ad._id}
            upvoteCount={ad.upvoteCount}
            query={query}
            userId={userId}
            isDisabled={isDisabled}
            isSaved={isSaved}
            toggleDisabled={toggleDisabled}
            isIrrelevantAd={isIrrelevantAd}
            adType="fbAd"
            isUpvoted={isUpvoted}
            isDownvoted={isDownvoted}
          />
        </div>
        <div style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          {primaryText ? <PrimaryText primaryText={primaryText} /> : null}
          {/* <h4 style={{ margin: '5px', marginLeft: '15px' }} >{ad.rankingScore}</h4> //! for testing only */}
          {format === "image" && <FbAdImageDetailsBelowPrimaryText adDetails={adDetails[0]} />}
          {format === "carousel" && <FbAdTypeCarousel adDetails={adDetails} />}
          {/* {format === "video" && <FBVideo videoUrl={adDetails[0].media.sourceUrl} />} */}
        </div>
      </div>
    </div>
  );
};

export default FacebookAd;
