import React, { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../../axios";
import { useDispatch } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import { useNavigate } from "react-router-dom";

const FindCreateTopic = () => {
  const [topicOptions, setTopicOptions] = useState([]);
  const [newTopicName, setNewTopicName] = useState();
  const [askConfirmation, setaskConfirmation] = useState(false);
  const [testQuestion, setTestQuestion] = useState(""); //for testing purposes
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getExistingTopicsByTopicName = async (input) => {
    // console.log("Getting", input);
    const { data } = await axiosInstance.get(
      `/api/admin/chat-gpt-interface-v2/get-existing-topics/${input}`
    );
    const options = await data.map((doc) => {
      return { value: doc.topic, label: doc.topic, id: doc._id };
    });
    options.push({ value: "xpZQ", label: `Create new Topic:${input}` });
    setTopicOptions(options);
  };

  const createNewTopic = async () => {
    const response = await axiosInstance
      .post("/api/admin/chat-gpt-interface-v2/create-topic", { newTopicName })
      .then((response) => {
        setaskConfirmation(false);
        setNewTopicName("");
        dispatch(newAlert({ type: "success", message: "New topic created!!" }));
        console.log(response.data);
        navigate(
          `/admin/chatGPTInterfaceV2/${response.data.topic}/${response.data._id}`
        );
      })
      .catch((error) => {
        setaskConfirmation(false);
        setNewTopicName("");
        dispatch(
          newAlert({ type: "danger", message: "Failed to create new topic!!" })
        );
      });
  };

  return (
    <>
      {askConfirmation && (
        <div
          className="bg-light border rounded position-absolute top-50 start-50 translate-middle zindex-modal "
          style={{ width: "70%" }}
        >
          <h4 className="text-center m-4">
            Are you sure you want to create new topic?
          </h4>
          <h6 className="text-center">Topic Name: {newTopicName}</h6>
          <div className="d-flex w-100 justify-content-center">
            <button
              className="btn btn-success mx-4 my-2"
              onClick={createNewTopic}
            >
              Yes
            </button>
            <button
              className="btn btn-danger mx-4 my-2"
              onClick={() => {
                setaskConfirmation(false);
                setNewTopicName("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="topic-button  ">
        <Select
          closeMenuOnSelect={true}
          placeholder={<div>Enter Topic Name</div>}
          onChange={(choice) => {
            if (choice.value == "xpZQ") {
              const newTopicNameEntered =
                choice.label.split("Create new Topic:")[1];

              setNewTopicName(newTopicNameEntered);
              setaskConfirmation(true);
            } else {
              const formatedTopicName = choice.label
                .split(/[\s,./-]+/)
                .slice(0, 20)
                .join(" ")
                .trim()
                .replace(/[^a-zA-Z0-9-]/g, "-")
                .split("-")
                .filter((x) => x != "")
                .join("-");
              navigate(
                `/admin/chatGPTInterfaceV2/${formatedTopicName}/${choice.id}`
              );
            }
          }}
          onInputChange={(input) => {
            if (input) getExistingTopicsByTopicName(input);
          }}
          styles={{
            control: (styles) => ({
              ...styles,
              width: "30rem",
              padding: ".3rem",
            }),
          }}
          noOptionsMessage={() => ""}
          options={topicOptions}
        />
      </div>
    </>
  );
};

export default FindCreateTopic;
