import "./TextEditor.less";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css";
import React from "react";
import ReactDOM from "react-dom";
import {
  EditorState,
  Modifier,
  getDefaultKeyBinding,
  AtomicBlockUtils,
  SelectionState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import {
  checkForH1Heading,
  getFullDate,
  getTimeAgo,
  hasPendingLinks,
  modifyHtml,
} from "../utilities";
// import { convertFromHTML } from 'draft-convert';
import { Map } from "immutable";

class EditorConvertToHTML extends React.Component {
  constructor(props) {
    super(props);
    const contentState = stateFromHTML(props.value || "");

    if (contentState) {
      this.props.setEditorState({
        editorState: EditorState.createWithContent(contentState),
      });
    }
    this.placeholder = this.props.placeholder || "Write Post here.";
    this.timeoutId = null;
  }

  componentDidUpdate(prevProps) {
    try {
      if (this.props.value === prevProps.value) return;
      const contentState = stateFromHTML(this.props.value);
      if (contentState) {
        const editorState = EditorState.createWithContent(contentState);
        this.props.setEditorState({ editorState });
      }
    } catch (err) {
      console.log("Failed to update content! Error: ", err);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  onEditorStateChange = (editorState) => {
    try {
      this.props.setEditorState({ editorState });
      const contentState = editorState.getCurrentContent();
      const html = stateToHTML(contentState);
      // this.props.onChange(html);

      // Remove image tags whose parent is not a figure tag
      const doc = new DOMParser().parseFromString(html, "text/html");
      const images = doc.querySelectorAll("img");
      images.forEach((image) => {
        if (image.parentNode.tagName.toLowerCase() !== "figure") {
          image.parentNode.removeChild(image);
        }
      });
      const sanitizedHTML = doc.body.innerHTML;
      const containsH1Heading = checkForH1Heading(sanitizedHTML);
      if (containsH1Heading && !this.props.hasH1Heading) {
        this.props.showH1Warning();
      }
      this.props.setHasH1Heading(containsH1Heading);
      this.props.onChange(sanitizedHTML);
      // console.log(sanitizedHTML);

      const lastContentState = this.props.contentStatus.lastContentState;
      const workingPost = this.props.workingPost;
      const currentContentState = editorState.getCurrentContent();
      const contentChanged = lastContentState !== currentContentState;
      let pendingLinks;

      pendingLinks = hasPendingLinks(sanitizedHTML);
      // console.log('pendingLinks',pendingLinks)
      if (!lastContentState) {
        this.props.setContentStatus({
          ...this.props.contentStatus,
          saved: true,
          lastContentState: contentState,
          pendingLinks,
        });
      } else if (contentChanged && workingPost) {
        this.props.setContentStatus({
          ...this.props.contentStatus,
          saved: false,
          pendingLinks,
        });

        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          if (
            !this.props.contentStatus.saved &&
            this.props.contentStatus.lastContentState !== contentState &&
            this.props.workingPost
          ) {
            this.props.setContentStatus({
              ...this.props.contentStatus,
              saved: true,
              lastContentState: contentState,
            });
            this.props.savePost();
            console.log("Content is saved");
          }
        }, 5000);
      }
    } catch (err) {
      console.log("Failed to update content! Error: ", err);
    }
  };

  mapKeyToEditorCommand = (e) => {
    try {
      if (e.keyCode === 9) {
        e.preventDefault();
        const { editorState } = this.props.editorState;
        const selection = editorState.getSelection();

        if (selection.isCollapsed()) {
          // Insert tab character at the current selection position
          const currentContent = editorState.getCurrentContent();
          const contentWithTab = Modifier.insertText(
            currentContent,
            selection,
            "\t"
          );
          const newEditorState = EditorState.push(
            editorState,
            contentWithTab,
            "insert-characters"
          );
          this.props.setEditorState({ editorState: newEditorState });
        } else {
          // Indent the selected lines by adding a tab character before each line
          const currentContent = editorState.getCurrentContent();
          const startKey = selection.getStartKey();
          const endKey = selection.getEndKey();
          const startBlock = currentContent.getBlockForKey(startKey);
          const endBlock = currentContent.getBlockForKey(endKey);
          const startOffset = selection.getStartOffset();
          const endOffset = selection.getEndOffset();

          const selectedBlocks = currentContent
            .getBlockMap()
            .skipUntil((_, k) => k === startKey)
            .takeUntil((_, k) => k === endKey)
            .concat(Map([[endKey, endBlock]]));

          const indentedText = selectedBlocks
            .map((block) => {
              const text = block.getText();
              const start = startKey === block.getKey() ? startOffset : 0;
              const end = endKey === block.getKey() ? endOffset : text.length;
              const selectedText = text.slice(start, end);
              return "\t" + selectedText;
            })
            .join("\n");

          const contentWithIndent = selectedBlocks.reduce((content, block) => {
            const start = startKey === block.getKey() ? startOffset : 0;
            const end =
              endKey === block.getKey() ? endOffset : block.getText().length;
            return Modifier.replaceText(
              content,
              selection.merge({
                anchorKey: block.getKey(),
                focusKey: block.getKey(),
                anchorOffset: start,
                focusOffset: end,
                isBackward: false,
                hasFocus: true,
              }),
              "\t" + block.getText().slice(start, end)
            );
          }, currentContent);

          const newEditorState = EditorState.push(
            editorState,
            contentWithIndent,
            "insert-characters"
          );
          this.props.setEditorState({ editorState: newEditorState });
        }

        return "handled";
      } else return getDefaultKeyBinding(e);
    } catch (err) {
      console.log("Failed to update content! Error: ", err);
    }
  };

  getSelectedBlock = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    return contentState.getBlockForKey(blockKey);
  };

  handlePastedText = (text, html, editorState, onChange) => {
    try {
      const modifiedHtml = modifyHtml(html);
      // console.log(modifiedHtml)
      const selectedBlock = this.getSelectedBlock(editorState);
      if (selectedBlock && selectedBlock.type === "code") {
        const contentState = Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          text,
          editorState.getCurrentInlineStyle()
        );
        onChange(
          EditorState.push(editorState, contentState, "insert-characters")
        );
        return true;
      } else if (modifiedHtml) {
        const blockMap = stateFromHTML(modifiedHtml).blockMap;
        const newState = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          blockMap
        );
        onChange(EditorState.push(editorState, newState, "insert-fragment"));
        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  addQuote = () => {
    try {
      const { editorState } = this.props.editorState;
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const startKey = selection.getStartKey();
      const endKey = selection.getEndKey();
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();

      let newContentState = contentState;

      // Check if selection spans multiple blocks
      if (startKey !== endKey) {
        const blockMap = contentState.getBlockMap();
        const selectedBlocks = blockMap
          .skipUntil((_, key) => key === startKey)
          .takeUntil((_, key) => key === endKey)
          .concat(Map([[endKey, blockMap.get(endKey)]]));

        let updatedContentState = contentState;

        selectedBlocks.forEach((block) => {
          const blockKey = block.getKey();
          const blockText = block.getText();
          const isQuoted = blockText.trim().startsWith("❝");

          // Toggle the quote for each block
          const quoteText = isQuoted
            ? blockText.slice(1, -1)
            : `❝${blockText}❞`;

          let blockContentState = Modifier.replaceText(
            updatedContentState,
            SelectionState.createEmpty(blockKey).merge({
              anchorOffset: 0,
              focusOffset: blockText.length,
            }),
            quoteText
          );

          const blockTextWithoutQuotes = isQuoted
            ? blockText.slice(2, -2)
            : blockText;
          if (!isQuoted) {
            // Apply italic inline style to the text between the quotes
            blockContentState = Modifier.applyInlineStyle(
              blockContentState,
              SelectionState.createEmpty(blockKey).merge({
                anchorOffset: isQuoted ? 2 : 0,
                focusOffset: isQuoted ? blockText.length - 2 : blockText.length,
              }),
              "ITALIC"
            );
          } else {
            // Apply italic inline style to the text between the quotes
            blockContentState = Modifier.removeInlineStyle(
              blockContentState,
              SelectionState.createEmpty(blockKey).merge({
                anchorOffset: isQuoted ? 2 : 0,
                focusOffset: isQuoted ? blockText.length - 2 : blockText.length,
              }),
              "ITALIC"
            );
          }

          // Remove italic inline style from the text outside the quotes
          blockContentState = Modifier.removeInlineStyle(
            blockContentState,
            SelectionState.createEmpty(blockKey).merge({
              anchorOffset: isQuoted ? 0 : blockText.length,
              focusOffset: isQuoted ? 2 : blockText.length,
            }),
            "ITALIC"
          );

          updatedContentState = blockContentState;
        });

        newContentState = updatedContentState;
      } else {
        const block = contentState.getBlockForKey(startKey);
        const blockText = block.getText();
        const isQuoted = blockText.trim().startsWith("❝");

        // Toggle the quote for the single line
        const quoteText = isQuoted ? blockText.slice(1, -1) : `❝${blockText}❞`;

        let blockContentState = Modifier.replaceText(
          contentState,
          selection.merge({
            anchorOffset: 0,
            focusOffset: blockText.length,
          }),
          quoteText
        );

        const blockTextWithoutQuotes = isQuoted
          ? blockText.slice(2, -2)
          : blockText;

        if (!isQuoted) {
          // Apply italic inline style to the text between the quotes
          blockContentState = Modifier.applyInlineStyle(
            blockContentState,
            selection.merge({
              anchorOffset: isQuoted ? 2 : 0,
              focusOffset: isQuoted ? blockText.length - 2 : blockText.length,
            }),
            "ITALIC"
          );
        } else {
          // Remove italic inline style from the text outside the quotes
          blockContentState = Modifier.removeInlineStyle(
            blockContentState,
            selection.merge({
              anchorOffset: isQuoted ? 0 : blockText.length,
              focusOffset: isQuoted ? 2 : blockText.length,
            }),
            "ITALIC"
          );
        }
        newContentState = blockContentState;
      }

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );

      this.props.setEditorState({
        editorState: newEditorState,
      });
    } catch (err) {
      console.log("Failed to add quote! Error: ", err);
    }
  };
  quoteText = () => {
    return (
      <div
        className="rdw-local-image-wrapper mx-1 border"
        style={{ borderRadius: "2px", paddingLeft: "5px", paddingRight: "5px" }}
        onClick={() => this.addQuote()}
      >
        <i className="fa-solid fa-quote-left"></i>
      </div>
    );
  };

  uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({ data: { link: e.target.result } });
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  render() {
    const { editorState } = this.props.editorState;
    const { useImagePlugin = false } = this.props;

    return (
      <div className="app-editor border">
        <div className="d-flex text-muted text-small">
          {this.props.hasH1Heading && (
            <div>
              <i
                className="fa fa-exclamation-triangle text-warning ms-2 me-1"
                aria-hidden="true"
              ></i>
              contains h1 heading
            </div>
          )}
          {this.props.workingPost && (
            <div
              className="text-muted ms-auto mt-1 me-1 mytooltip"
              style={{ fontSize: "small" }}
            >
              {this.props.contentStatus.saved ? (
                <span>
                  {/* {console.log(this.props.contentStatus)} */}
                  last saved {getTimeAgo(this.props.lastSavedAt)}
                </span>
              ) : (
                <span>unsaved changes</span>
              )}
              <div className="tooltiptext">
                {getFullDate(this.props.lastSavedAt)}
              </div>
            </div>
          )}
          {/* {useImagePlugin && this.localImageUploader()}&nbsp; */}
        </div>
        {/* <button className='' id= "img-upload-by-url">Click</button> */}

        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          handleKeyCommand={this.mapKeyToEditorCommand}
          keyBindingFn={this.mapKeyToEditorCommand}
          spellCheck={true}
          placeholder={this.placeholder}
          toolbar={{
            image: {
              uploadCallback: this.uploadImageCallback,
              alt: { present: true, mandatory: false },
              previewImage: true,
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              defaultSize: {
                height: "auto",
                width: "100%",
              },
            },
          }}
          handlePastedText={this.handlePastedText}
        />
      </div>
    );
  }
}

export default EditorConvertToHTML;
