import { useEffect, useState } from "react";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import ContextBeforeQuestion from "../ContextBeforeQuestion/ContextBeforeQuestion";

const CreateNewQuestionOutsideFilteredPoint = ({
  questionsList,
  setQuestionsList,
  indexInHeadingQuestions,
}) => {
  const [peerOrChildToQuestion, setPeerOrChildToQuestion] = useState("");
  const [peerOrChild, setPeerOrChild] = useState("");
  const [relationToParent, setRelationToParent] = useState("");
  const [contextNeeded, setContextNeeded] = useState(false);
  const [contextBeforeQuestion, setContextBeforeQuestion] = useState("");
  const [question, setQuestion] = useState("");
  const [questionAlias, setQuestionAlias] = useState("");
  const [questionOptions, setQuestionOptions] = useState([]);
  const [fShow, setfShow] = useState(false);
  const [validationError, setValidationError] = useState("");
  const headingQuestions = useSelector(
    (state) => state.chatGPTSlice.topic.headingQuestions
  );
  const questionsInTopic = useSelector((state) => state.chatGPTSlice.questions);
  useEffect(() => {
    const questionsArray = headingQuestions.filter(
      (q) => q.question !== undefined
    );
    console.log(questionsArray);
    console.log(questionsInTopic);
    const options = questionsArray.map((q) => {
      // console.log(questionsInTopic[q.question].question);
      return {
        label: questionsInTopic[q.question].question,
        value: q.question,
      };
    });
    // console.log(options);
    setQuestionOptions(options);
  }, [questionsInTopic]);

  const handlePeerOrChildChange = (event) => {
    setPeerOrChild(event.target.value);
    if (event.target.value === "child") {
      setRelationToParent("");
    }
  };

  const handleContextNeededChange = (event) => {
    setContextNeeded(event.target.value === "true");
  };

  const handleAddToListClick = () => {
    // if (peerOrChildToQuestion === "") {
    //   setValidationError("Please select a question.");
    //   return;
    // }
    if (peerOrChildToQuestion !== "" && peerOrChild == "") {
      setValidationError("Please select Peer Or Child.");
      return;
    }

    if (peerOrChild === "child" && relationToParent === "") {
      setValidationError("Please enter the relation to the parent.");
      return;
    }

    if (question === "") {
      setValidationError("Please enter the question.");
      return;
    }
    if (peerOrChildToQuestion !== "" && peerOrChild == "child") {
      const relationObject = {
        relation: relationToParent,
        questions: [
          {
            question,
            questionAlias,
            peerOrChild,
            peerOrChildToQuestion,
            contextBeforeQuestion,
          },
        ],
      };
      setQuestionsList((prev) => [...prev, relationObject]);
      console.log(questionsList);
    }
    if (peerOrChildToQuestion !== "" && peerOrChild == "peer") {
      const { relation, level } = headingQuestions.find(
        (question) => question.question == peerOrChildToQuestion
      );

      const relationObject = {
        relation: "same as peer",
        questions: [
          {
            question,
            questionAlias,
            peerOrChild,
            peerOrChildToQuestion,
            relation,
            level,
            contextBeforeQuestion,
          },
        ],
      };
      setQuestionsList((prev) => [...prev, relationObject]);
      // console.log(questionsList);
    }
    if (peerOrChildToQuestion == "") {
      const relationObject = {
        relation: "",
        questions: [
          {
            question,
            questionAlias,
            contextBeforeQuestion,
            idOfClickedObject:
              indexInHeadingQuestions !== -1
                ? headingQuestions[indexInHeadingQuestions]._id
                : -1,
          },
        ],
      };
      setQuestionsList((prev) => [...prev, relationObject]);
    }
    setQuestion("");
    setQuestionAlias("");
  };

  useEffect(() => {
    setValidationError("");
  }, [peerOrChildToQuestion, peerOrChild, relationToParent, question]);

  return (
    <>
      <div onClick={() => setfShow((prev) => !prev)}>
        <h6
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center text-maroon"
        >
          Create questions without using Filtered points
          <span
            className="text-secondary fw-light text-decoration-underline ms-2"
            style={{ fontSize: "15px", cursor: "pointer" }}
          >
            {fShow ? (
              <i className="bi bi-chevron-up"></i>
            ) : (
              <i className="bi bi-chevron-down"></i>
            )}
          </span>
        </h6>
      </div>
      {fShow && (
        <Form className="m-3">
          <Form.Group as={Row} controlId="peerOrChildToQuestion">
            <Form.Label row>Peer or Child to Question:</Form.Label>
            <Row sm="9">
              <Form.Control
                as="select"
                value={peerOrChildToQuestion}
                onChange={(event) =>
                  setPeerOrChildToQuestion(event.target.value)
                }
              >
                <option value="">Select an question...</option>
                {questionOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Row>
          </Form.Group>
          {peerOrChildToQuestion != "" && (
            <Form.Group as={Row} controlId="peerOrChild">
              <Form.Label inline="true" className="mr-1 " column sm="2">
                Peer or Child:
              </Form.Label>
              <Col sm="9" className="py-2">
                <Form.Check
                  inline
                  type="radio"
                  label="Peer"
                  name="peerOrChild"
                  value="peer"
                  checked={peerOrChild === "peer"}
                  onChange={handlePeerOrChildChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Child"
                  name="peerOrChild"
                  value="child"
                  checked={peerOrChild === "child"}
                  onChange={handlePeerOrChildChange}
                />
              </Col>
            </Form.Group>
          )}
          {peerOrChild === "child" && (
            <Form.Group as={Row} controlId="relationToParent">
              <Form.Label row>Relation to Parent:</Form.Label>
              <Row sm="9">
                <Form.Control
                  type="text"
                  value={relationToParent}
                  onChange={(event) => setRelationToParent(event.target.value)}
                />
              </Row>
            </Form.Group>
          )}
          <Form.Group as={Row} controlId="contextNeeded">
            <Form.Label inline="true" className="mr-1 " column sm="2">
              Context Needed:
            </Form.Label>
            <Col sm="9" className="py-2">
              <Form.Check
                inline
                type="radio"
                label="Yes"
                name="contextNeeded"
                value="true"
                checked={contextNeeded}
                onChange={handleContextNeededChange}
              />
              <Form.Check
                inline
                type="radio"
                label="No"
                name="contextNeeded"
                value="false"
                checked={!contextNeeded}
                onChange={handleContextNeededChange}
              />
            </Col>
          </Form.Group>
          {/* {contextNeeded && (
            <Form.Group as={Row} controlId="contextBeforeQuestion">
              <Form.Label row>Context Before Question:</Form.Label>
              <Row sm="9">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={contextBeforeQuestion}
                  onChange={(event) =>
                    setContextBeforeQuestion(event.target.value)
                  }
                />
              </Row>
            </Form.Group>
          )} */}
          {contextNeeded && (
            <ContextBeforeQuestion
              questionsInTopic={questionOptions}
              setContextBeforeQuestion={setContextBeforeQuestion}
            />
          )}
          <Form.Group as={Row} controlId="question">
            <Form.Label row>Question:</Form.Label>
            <Row sm="9">
              <Form.Control
                as="textarea"
                value={question}
                onChange={(event) => setQuestion(event.target.value)}
              />
            </Row>
          </Form.Group>
          <Form.Group as={Row} controlId="questionAlias">
            <Form.Label row>Question Alias:</Form.Label>
            <Row sm="9">
              <Form.Control
                type="text"
                value={questionAlias}
                onChange={(event) => setQuestionAlias(event.target.value)}
              />
            </Row>
          </Form.Group>
          {validationError && (
            <p className="text-danger p-0 m-0 " style={{ fontSize: "12px" }}>
              {validationError}
            </p>
          )}
          <Form.Group as={Row}>
            <Row sm={{ offset: 3 }}>
              <Button
                className="w-25 my-2 mt-3"
                style={{
                  backgroundColor: "#3FACCB",
                  border: "none",
                }}
                onClick={handleAddToListClick}
              >
                Add to List
              </Button>
            </Row>
          </Form.Group>
        </Form>
      )}
      <br />
    </>
  );
};

export default CreateNewQuestionOutsideFilteredPoint;
