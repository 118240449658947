import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moreBtnIcon from "../../assets/images/more button icon.svg";
import shareBtnIcon from "../../assets/images/share button icon.svg";
import savePostIcon from "../../assets/images/save_post_icon.png";
import likeImg from "./../../assets/articlepage-images/Like icon.svg";
import likeNotImg from "./../../assets/articlepage-images/Like not active.svg";
import dislikeImg from "./../../assets/articlepage-images/Dislike icon.svg";
import dislikeNotImg from "./../../assets/articlepage-images/Dislike not active.svg";
import "./ArticleInteractions.css";
import { useDispatch } from "react-redux";
import { updateSavedCreatedPosts } from "../../slices/newsFeedSlice";
function ArticleInteractions({
  postInteraction,
  postId,
  savedPosts,
  updateVotesForPost,
  loadingEngagement,
  loadingsaved,
}) {
  const [isLike, setIsLike] = useState(postInteraction.upvoteStatus);
  const [isDisLike, setIsDisLike] = useState(postInteraction.downvoteStatus);
  const [likeCount, setLikeCount] = useState(postInteraction.upvoteCount);
  const [postSaved, setPostSaved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLike(postInteraction.upvoteStatus);
    setIsDisLike(postInteraction.downvoteStatus);
    setLikeCount(postInteraction.upvoteCount);
  }, [postInteraction]);

  useEffect(() => {
    let isSavedPost =
      savedPosts &&
      savedPosts.find((savedPost) => {
        return savedPost.post === postId;
      });
    // console.log("SavedPost: ", savedPosts);
    let isSaved = false;
    if (isSavedPost) {
      isSaved = true;
    }
    setPostSaved(isSaved);
  }, []);

  const toggleLike = () => {
    const likeStatus = !isLike;
    updateVotesForPost("upvote");
    setIsLike(!likeStatus);
    setIsDisLike(false);
  };

  const toggleDisLike = () => {
    const dislikeStatus = !isDisLike;
    updateVotesForPost("downvote");
    setIsDisLike(!dislikeStatus);
    setIsLike(false);
  };

  const handleSaveUnsavePost = async () => {
    const result = await dispatch(
      updateSavedCreatedPosts({
        postId,
        isSaved: postSaved,
        query: "",
      })
    );
    if (result.meta.requestStatus === "fulfilled") {
      setPostSaved(!postSaved);
    }
  };

  return (
    <div
      className="mb-md-0 mx-auto media"
      style={{ width: "680px", maxWidth: "100%" }}
    >
      {/* <hr className='p-0 m-0' style={{ borderTop: '1px solid #cccccc' }} /> */}
      <div className="d-flex align-items-center justify-content-between text-nowrap borderStyle mb-md-3 mt-md-2 interactStyles">
        <div>
          <Button
            onClick={toggleLike}
            id="likebtn"
            variant="link"
            className="btn text-dark mb-1 ms-1"
            disabled={loadingEngagement}
          >
            <img
              src={isLike ? likeImg : likeNotImg}
              alt="Like"
              className="like"
              width={20}
            />
          </Button>
          <span
            className={loadingEngagement ? "d-none" : ""}
            style={{ fontSize: "14px", fontWeight: 500 }}
          >
            {likeCount}
          </span>{" "}
          &nbsp;
          <Button
            variant="link"
            onClick={toggleDisLike}
            className="btn text-secondary ms-2"
            disabled={loadingEngagement}
          >
            <img
              src={isDisLike ? dislikeImg : dislikeNotImg}
              alt="Like"
              width={20}
            />
          </Button>
        </div>
        <div>
          <Button
            variant="link"
            className="btn text-secondary"
            onClick={handleSaveUnsavePost}
          >
            {!postSaved ? (
              <img
                src={savePostIcon}
                alt="save-post-icon"
                width={18}
                disabled={loadingsaved}
                style={{ opacity: 0.4, filter: "grayscale(1)" }}
              />
            ) : (
              <img
                src={savePostIcon}
                alt="saved-post-icon"
                width={18}
                style={{ opacity: 1, filter: "grayscale(1)" }}
              />
            )}
          </Button>
          <Button variant="link" className="btn text-secondary sharebtn mx-2">
            <img src={shareBtnIcon} alt="shareBtn" width={16} />
          </Button>
          <Button variant="link" className="btn morebtn me-1">
            <img src={moreBtnIcon} alt="morebtn" width={18} />
          </Button>
        </div>
      </div>
      {/* <hr className='p-0 mt-0 mb-5' style={{ borderTop: '1px solid #cccccc' }} /> */}
      {/* <style jsx>{`
                #likebtn{
                    width:3rem; 
                    height:3rem;
                    
                }
                #likebtn img {
                    width: 100%;
                    height:auto;
                }
               

                .like:hover {
                    fill:#f29111;
                }

                @media (max-width: 767px) {
                    .media {
                        width: 457px;
                        max-width: 100%;
                    }
                }
            `}</style> */}
    </div>
  );
}

export default ArticleInteractions;
