import React, { useState } from "react";
import "./ChatGptResponse.css";
import { newAlert } from "../../../slices/alertSlice";
import { useDispatch } from "react-redux";
import { setError } from "../../../slices/sessionSlice";

const axios = require("axios");

const { REACT_APP_PROMPT_MASTER_BACK_END_DOMAIN: promptMasterBackendURL } =
  process.env;

const ChatGptResponse = () => {
  const [gptPrompt, setGptPrompt] = useState("");
  const [gptResponse, setGptResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const sleep = (time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  };

  const getResponseByResponseId = async (
    responseId,
    minutesToWait,
    retryTimeInSec
  ) => {
    const millisToWait = minutesToWait * 60 * 1000;
    const endTime = Date.now() + millisToWait;
    let promptResponse;
    while (Date.now() < endTime) {
      const responseValue = await axios.get(
        `${promptMasterBackendURL}/api/prompt-master/get-response-by-id/${responseId}`,
        {
          prompt: gptPrompt,
          type: "api",
        }
      );
      promptResponse = responseValue.data;
      // console.log("responseValue: ",promptResponse);
      if (promptResponse.response) {
        // console.log("response: ",promptResponse.response);
        if (promptResponse.response?.status === "success") {
          return {
            status: "success",
            response: promptResponse.response?.response,
          };
        } else return { status: "failed", error: promptResponse };
      }
      // console.log("Time remain: ",(endTime-Date.now())/1000);
      await sleep(retryTimeInSec * 1000);
    }
    if (promptResponse && promptResponse.length > 0) {
      if (promptResponse.response?.status === "success")
        return {
          status: "success",
          response: promptResponse.response?.response,
        };
      else return { status: "failed", error: promptResponse.error };
    } else {
      return {
        status: "failed",
        error: "Time Limit exceeded for fetching response by Id!",
      };
    }
  };

  const getChatGptResponse = async () => {
    try {
      setLoading(true);
      setGptResponse("");
      document
            .querySelector("textarea.gpt-response")
            .classList.remove("response-error");
      const response = await axios.post(
        `${promptMasterBackendURL}/api/prompt-master/get-chat-gpt-response`,
        {
          prompt: gptPrompt,
          type: "api",
        }
      );
      if (response?.data && response.data.status === "success") {
        const responseId = response.data.responseId;
        console.log("responseId: ", responseId);

        let promptResponse = await getResponseByResponseId(responseId, 2, 10);
        // console.log("promptResponse: ",promptResponse);
        // console.log(JSON.stringify(promptResponse.error,null,2));
        if (promptResponse?.status === "success") {
          setGptResponse(promptResponse.response);
        } else {
          dispatch(
            newAlert({
              type: "danger",
              message: "Failed to get chatGpt response!",
            })
          );
          document
            .querySelector("textarea.gpt-response")
            .classList.add("response-error");
          if (promptResponse.error) {
            const error = JSON.stringify(promptResponse.error, null, 2);
            setGptResponse(error);
          } else {
            setGptResponse("Failed to get chatGpt response!");
          }
        }
      } else throw new Error("Failed to get response from chatGpt!");
    } catch (err) {
      // dispatch(newAlert({type:"danger",message:"Failed to get response from chatGpt!"}))
      dispatch(newAlert({ type: "danger", message: "" + err.toString() }));
      document
        .querySelector("textarea.gpt-response")
        .classList.add("response-error");
      const error = JSON.stringify(err, null, 2);
      setGptResponse(error);
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!gptPrompt) {
      dispatch(
        newAlert({
          type: "danger",
          message: "The prompt should not be empty!.",
        })
      );
      return;
    }
    if (!loading) getChatGptResponse();
    else {
      dispatch(
        newAlert({
          type: "warning",
          message:
            "A request is already in progress, please wait before trying again.",
        })
      );
    }
  };

  return (
    <div style={{ minHeight: "90vh" }} className="px-5 pt-5">
      <h2>Get ChatGPT API Response</h2>
      <div className="" style={{ marginBottom: "20px" }}>
        <div className="small text-muted">Prompt</div>
        <textarea
          className="gpt-prompt p-2"
          onChange={(e) => setGptPrompt(e.target.value)}
          value={gptPrompt}
          rows={5}
          placeholder="Enter your prompt here."
        />
      </div>

      <div className="text-end">
        {loading && (
          <div
            className="spinner-border text-primary response-spinner"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          className="btn btn-primary"
          style={{ backgroundColor: "#0099FF", border: "none" }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>

      <div className="" style={{ marginBottom: "50px" }}>
        <div className="small text-muted">Response</div>
        <textarea
          className="gpt-response p-2"
          value={gptResponse}
          rows={5}
          placeholder={
            loading
              ? "loading response..."
              : "Your response will be shown here."
          }
          readOnly
        />
      </div>
    </div>
  );
};

export default ChatGptResponse;
