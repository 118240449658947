import React, { useEffect, useState } from "react";
import "./ProjectsSection.css";
import EditProject from "./EditProject";
import { useDispatch, useSelector } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import {
  deleteUserProfileDetails,
  getProjectDetails,
  saveProjectDetails,
} from "../utility/userUtils";
import AskConfirmationModal from "../workComponents/subComp/AskConfirmationModal";
import { ProjectSkillDetails } from "./projectSkillDetails";
import { ShowBtnData, TimeBar } from "../workComponents/SavedExperience";

const ProjectsSection = () => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [askConfirmation, setaskConfirmation] = useState(false);
  const [editMode, setEditMode] = useState({
    value: false,
    mode: "",
    index: 0,
  });
  const [errors, setErrors] = useState({});
  const [fFirstTimeLoad, setFFirstTimeLoad] = useState(true);
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const fProjectWithSkills =
    sessionUser.user?.profileType === "mern" &&
    sessionUser.user?.experienceLevel === "fresher";

  const initProjectDetails = {
    title: "",
    timePeriod: {
      startMonth: "January",
      endMonth: "January",
      startYear: "",
      endYear: "",
    },
    skills: [],
    currWorking: false,
    description: "",
  };
  const [projectDetails, setProjectDetails] = useState(initProjectDetails);

  const getProjectsData = async () => {
    let { projectDetails } = await getProjectDetails();
    // console.log({ projectDetails });
    projectDetails = projectDetails?.map((project) => {
      if (project.skills?.length) {
        const skills = project.skills.map((skillData) => {
          return {
            value: skillData.value,
            name: { label: skillData.skill?.name, _id: skillData.skill?._id },
          };
        });
        return { ...project, skills };
      } else {
        return project;
      }
    });
    // console.log({ projectDetails });
    setProjects(projectDetails || []);
  };

  const saveProjectData = async ({ mode, userId, projectDetails }) => {
    if (!fProjectWithSkills) {
      delete projectDetails.skills;
    }
    // console.log({ projectDetails });
    try {
      if (!["update", "add"].includes(mode)) {
        throw new Error(`Invalid mode provided: ${mode}`);
      }
      const pData = await saveProjectDetails(projectDetails, mode, userId);
      if (pData.status === "success") {
        const dbProjectDetails = pData?.projectDetails;
        let projectData = [...projects];
        // console.log({ projectDetails });
        if (projectDetails.skills?.length && fProjectWithSkills) {
          const skills = projectDetails.skills?.map((skillData) => {
            const { label, skill, value } = skillData || {};
            return {
              value,
              name: { _id: skill, label },
            };
          });
          dbProjectDetails.skills = skills;
        }

        if (mode === "update") {
          projectData[editMode.index] = dbProjectDetails;
          setProjects(projectData);
        } else {
          projectData.push(dbProjectDetails);
          setProjects(projectData);
        }
        dispatch(
          newAlert({
            message: "Project Details updated successfully",
            type: "success",
          })
        );
      } else {
        throw new Error(`Failed to add Project details`);
      }
    } catch (error) {
      console.log({ error });
      dispatch(
        newAlert({
          message: "Failed to add Project details",
          type: "error",
        })
      );
    } finally {
      setEditMode({ value: false, mode: "", index: 0 });
    }
  };

  const deleteProjectDetails = async (deleteId, userId) => {
    // const index = editMode.index;
    const removeProjectsDetails = async () => {
      const status = await deleteUserProfileDetails(
        deleteId,
        userId,
        "project"
      );
      // console.log(status);
      if (status === "success") {
        let projectData = [...projects];
        projectData.splice(editMode.index, 1);
        setProjects(projectData);
        dispatch(
          newAlert({
            message: "Project deleted successfully",
            type: "success",
          })
        );
        setEditMode({ value: false, mode: "" });
      }
    };
    setaskConfirmation({
      active: true,
      title: "",
      heading: `Are you sure you want to delete project "${
        projects[editMode.index].title
      }" from your profile`,
      onClick: removeProjectsDetails,
      type: "delete",
    });
  };

  useEffect(() => {
    if (editMode.mode === "update") {
      const selectedProject = projects[editMode.index];
      // if (selectedProject.skills?.length) {
      //   selectedProject.skills = selectedProject.skills.map((skillData) => {
      //     return {
      //       value: skillData.value,
      //       name: { label: skillData.skill?.name, _id: skillData.skill?._id },
      //     };
      //   });
      // }
      setProjectDetails(selectedProject);
    } else {
      setProjectDetails(initProjectDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    getProjectsData();
  }, []);

  return (
    <div className="pb-4">
      <AskConfirmationModal
        askConfirmation={askConfirmation}
        setaskConfirmation={(data) => setaskConfirmation(data)}
      />
      <div id="project-details" className="d-flex justify-content-between mt-5">
        <h3>Projects</h3>
        <button
          className="add-details-btn"
          onClick={() => {
            setEditMode({ value: true, mode: "add" });
          }}
        >
          + Add Project
        </button>
      </div>
      {editMode.value === true && (
        <>
          {!fProjectWithSkills ? (
            <EditProject
              project={projectDetails}
              setProject={setProjectDetails}
              editMode={editMode}
              setEditMode={setEditMode}
              saveProjectDetails={saveProjectData}
              deleteProjectDetails={deleteProjectDetails}
              errors={errors}
              setErrors={setErrors}
            />
          ) : (
            <ProjectSkillDetails
              project={projectDetails}
              setProject={setProjectDetails}
              editMode={editMode}
              setEditMode={setEditMode}
              saveProjectDetails={saveProjectData}
              deleteProjectDetails={deleteProjectDetails}
              errors={errors}
              setErrors={setErrors}
            />
          )}
        </>
      )}
      <div className="d-flex">
        {projects?.[0] &&
          !(projects?.length === 1 && editMode.mode === "update") && (
            <div style={{ overflow: "hidden" }}>
              <div className="saved-project-line"></div>
            </div>
          )}
        <div className="">
          {projects?.map((projectInfo, index) => {
            return (
              <div key={index}>
                {!(editMode.value && editMode.index === index) && (
                  <Project
                    project={projectInfo}
                    setEditMode={setEditMode}
                    index={index}
                    sessionUser={sessionUser}
                    fFirstTimeLoad={fFirstTimeLoad}
                    setFFirstTimeLoad={setFFirstTimeLoad}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const Project = ({
  project,
  setEditMode,
  index,
  sessionUser,
  fFirstTimeLoad,
  setFFirstTimeLoad,
}) => {
  // const { setEditMode } = openEditMode;
  const skillsData = project?.skills || [];
  const [showAllTB, setShowAllTB] = useState({ skills: false, tools: false });
  const skills = [...skillsData]?.sort((a, b) => {
    return b.value - a.value;
  });
  const fProjectWithSkills =
    sessionUser.user?.profileType === "mern" &&
    sessionUser.user?.experienceLevel === "fresher";
  useEffect(() => {
    if (fFirstTimeLoad && fProjectWithSkills) {
      setEditMode({ value: true, mode: "update", index: 0 });
      setFFirstTimeLoad(false);
    }
  }, [fProjectWithSkills]);
  return (
    <div className="">
      <div
        className="row"
        style={{
          padding: "25px 40px",
          position: "relative",
          paddingTop: "0px",
        }}
      >
        <div
          className={`${
            fProjectWithSkills ? "col-lg-6 col-md-11" : ""
          } col-12 pt-3`}
        >
          <div className="pt-3" style={{ color: "#064B74", fontSize: "24px" }}>
            <span className="heading-bullet"></span>
            {project.title}
          </div>
          {project.timePeriod?.startMonth && (
            <div className={`my-2`}>{`${project.timePeriod?.startMonth || ""} ${
              project.timePeriod?.startYear || ""
            } - ${
              project.currWorking
                ? "Present"
                : `${project.timePeriod?.endMonth || ""} ${
                    project.timePeriod?.endYear || ""
                  }`
            }`}</div>
          )}
          <div style={{ fontSize: "14px" }}>{project.description}</div>
        </div>
        {fProjectWithSkills && (
          <div className="col-lg-6 col-md-11 col-12 pt-3">
            <div className="row">
              <div
                className="text-center ps-3 mb-3"
                style={{ fontSize: "13px" }}
              >
                <div className="fw-bold pt-3 ps-3 mt-0">
                  Hrs spent on skills for the project
                </div>
                {/* <div className="fw-bold">
                  (Assume you work for 45 hrs per week)
                </div> */}
              </div>
              <div className="col-12">
                {/* {skills && skills.length > 0 && <Labels />} */}
                {skills && skills.length > 0 ? (
                  skills
                    .slice(0, showAllTB.skills ? skills.length : 4)
                    .map((item, index) => {
                      return (
                        <TimeBar
                          key={index}
                          index={index}
                          timebarData={skills}
                          showAllTB={showAllTB}
                          setShowAllTB={setShowAllTB}
                          maxSkillsToShow={4}
                          type={1}
                        />
                      );
                    })
                ) : (
                  <div className="text-center">No Skills added yet</div>
                )}
              </div>
            </div>

            {showAllTB.skills && (
              <div className="row">
                <div className="col-12 fs-responsive">
                  <ShowBtnData
                    workData={skills}
                    showAllTB={showAllTB}
                    setShowAllTB={setShowAllTB}
                    type={1}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <a
          href="#project-details"
          onClick={() => {
            setEditMode({ value: true, mode: "update", index: index });
          }}
        >
          <i
            className="far fa-edit work-edit"
            style={{ borderTop: "1px solid #cccdce", top: "32px" }}
          ></i>
        </a>
      </div>
    </div>
  );
};

export default ProjectsSection;
