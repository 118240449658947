import React from "react";
import SadEmojiPage from "../../../assets/profile-images/sad_emoji_page.svg";
import "./CannotContinuePage.css";
import { Container } from "react-bootstrap";

const CannotContinuePage = () => {
  return (
    <div className="">
      <Container className="d-flex flex-column align-items-center">
        <div className="cannot-continue-page">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ margin: "80px 10px", fontSize: "24px" }}
          >
            {/* <img
              src={SadEmojiPage}
              alt="sad-page-icon"
              className="mb-5 sad-page-icon"
            />
            <div className="cannot-continue-message">
              <div className="text-center fw-bold" style={{ color: "#F27677" }}>
                Apologies, but at the moment, we can't continue with you.
              </div>
              <div className="fw-bold text-black mb-4">
                <span style={{ fontWeight: "500" }}>Remember</span>, it's not
                you, it's us.{" "}
              </div> */}
            <div className="text-center">
              <div>Our expectations don't align with your preferences.</div>
              <br />
              <div>
                Apologies, but at the moment, we can't continue with you.
              </div>
              <br />
              <div>
                We hope we can reach out to you for future suitable
                opportunities.
              </div>
              <br />
              <br />
              <div>Best !</div>
              <div>Mentorstudents.org</div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CannotContinuePage;
