import React from "react";
import SignIn from "../../../containers/SignIn/SignIn";

const SignInPage = ({ fApplicantPage }) => {
  return (
    <div className="content min-vh-100 py-2 my-5 px-sm-3 py-sm-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 col-xl- mx-auto">
            <SignIn fApplicantPage={fApplicantPage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
