import React, { useState } from "react";
import "./ArticleIssues.css"; // Import your custom CSS file

const ArticleIssues = ({ data, isOutermost = true }) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const renderValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return <ArticleIssues data={value} isOutermost={false} />;
    } else {
      return <span>{JSON.stringify(value)}</span>;
    }
  };

  return (
    <>
      <span
        className={`${isOutermost ? "d-none" : ""}`}
        onClick={toggleExpansion}
        style={{ cursor: "pointer" }}
      >
        <i
          className={`icon ${expanded ? "fa fa-caret-up" : "fa fa-caret-down"}`}
        />
      </span>
      <div className={`${isOutermost ? "article-issues outermost" : ""}`}>
        <div
          className={`${isOutermost ? "header" : "d-none"}`}
          onClick={toggleExpansion}
        >
          <i
            className={`icon ${
              expanded ? "fa fa-caret-up" : "fa fa-caret-down"
            }`}
          />
          {isOutermost && "Issues"}
        </div>

        {expanded && (
          <div className={`content ${isOutermost ? "outermost-content" : ""}`}>
            {Object.keys(data).map((key, index) => (
              <div key={index} className="property">
                <span className="key">{key}:</span>
                {renderValue(data[key])}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ArticleIssues;
