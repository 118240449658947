import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import cloverLeafIcon from "../../../assets/profile-images/clover_leaf.png";

import "./RejectedByAssessment.css";

function RejectedByAssessment() {
  return (
    <div style={{ height: "100dvh" }}>
      <Container className="d-flex flex-column align-items-center">
        <div className="test2Completion">
          <Row style={{ textAlign: "center", marginTop: "40px" }}>
            <Col>
              <img
                style={{ height: "64px", width: "64px" }}
                src={cloverLeafIcon}
                alt="testSubmitedSuccessfully"
              />
              <div
                style={{
                  margin: "8px 28px",
                  marginTop: "18px",
                  fontSize: "20px",
                }}
              >
                We were fortunate to receive interest from many talented
                professionals such as yourself. We decided to pursue other
                candidates at this time. We hope we can reach back to you in the
                future.
              </div>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              textAlign: "center",
              marginTop: "20px",
              fontSize: "20px",
            }}
          >
            <div className="d-flex flex-column justify-content-center">
              <div>Best!</div> <div> MentorStudents.org team</div>
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginTop: "24px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <hr style={{ width: "90%" }}></hr>
            </div>
          </Row>
          <Row>
            <div
              style={{ marginTop: "20px", fontSize: "20px" }}
              className="d-flex justify-content-center"
            >
              <span>
                As promised, you will get to attend the live lecture on{" "}
              </span>
            </div>
          </Row>
          <Row>
            <div
              style={{ fontSize: "20px", marginTop: "2px" }}
              className="d-flex justify-content-center"
            >
              <span>AI–RAG for Chatbot.</span>
            </div>
          </Row>
          <Row>
            <div
              style={{ marginTop: "18px", fontSize: "20px" }}
              className="d-flex justify-content-center"
            >
              <span>Pick time option for the lecture here: &nbsp; </span>
              <span> </span>
              <a
                target="_blank"
                href="https://meet.zoho.com/2VcfBrY9JT"
                rel="noreferrer"
                style={{ textDecoration: "underline", color: "#0056D2" }}
              >
                Time options
              </a>
              <span>⏰</span>
            </div>
          </Row>
          <Row
            style={{
              textAlign: "center",
              marginTop: "10px",
              position: "relative",
              top: "-30px",
              justifyContent: "center",
            }}
          >
            {/* <Button
          style={{ width: "100px" }}
          variant="danger"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </Button> */}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default RejectedByAssessment;
