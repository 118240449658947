import React, { useState } from "react";
import Select from "react-select";

const SelectDropdown = ({
  options,
  selectedValue,
  setSelectedValue,
  onValueChange,
  onInputChange,
  placeholder,
  removeDropdownIndicator,
  noOptionsMessage,
  getOptionLabel,
}) => {
  return (
    <>
      <Select
        closeMenuOnSelect={true}
        placeholder={placeholder}
        value={selectedValue}
        onChange={async (choice) => {
          setSelectedValue(choice);
          onValueChange(choice);
        }}
        onInputChange={(input) => {
          onInputChange(input);
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            width: "100%",
            minHeight: "32px",
            height: "32px",
          }),
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "250px",
          }),
        }}
        components={
          removeDropdownIndicator
            ? {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }
            : {}
        }
        noOptionsMessage={() => noOptionsMessage}
        options={options}
        getOptionLabel={(option) => {
          return getOptionLabel ? getOptionLabel : `${option.label}`;
        }}
      />
    </>
  );
};

export default SelectDropdown;
