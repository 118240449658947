import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAiResponse,
  updateFilteredPointsAndSentences,
  setFShowSelectionMenu,
  addAlias,
} from "../../../slices/chatGPTSliceV2";
import GeneratingResponseLoader from "../Loader/Loader";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./Response.css";

const SelectionMenu = ({ coords, onOptionSelected, onDismiss }) => {
  const handleOptionClick = (option) => {
    onOptionSelected(option);
    onDismiss();
  };

  const style = coords
    ? {
        position: "absolute",
        top: coords.y,
        left: coords.x,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignItems: "center",
        backgroundColor: "white",
        boxShadow: "0 0px 25px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        border: "1px solid #555",
        fontSize: "13px",
        zIndex: "9999",
      }
    : { display: "none" };

  return (
    <div style={style} className="responseSelectionPopup">
      <span
        className="filter-btn p-1 px-2"
        role="button"
        onClick={() => handleOptionClick("filteredPoint")}
      >
        Filter as Point
      </span>
      <span
        className="filter-btn p-1 px-2"
        role="button"
        onClick={() => handleOptionClick("filteredSentence")}
      >
        Filter as Sentence
      </span>
    </div>
  );
};

const FilteredPoints = ({
  currentResponseId,
  topic,
  questionId,
  questionModal,
  removeFilteredPointsAndSentences,
  selectedFilteredPoints,
  setSelectedFilteredPoints,
  filteredPoints,
}) => {
  const handleSelectFilteredPoint = (e, filteredPoint) => {
    if (e.target.checked === true) {
      const updatedSelectedFP = [
        ...selectedFilteredPoints,
        { ...filteredPoint, isChecked: true },
      ];
      setSelectedFilteredPoints(updatedSelectedFP);
    } else if (e.target.checked === false) {
      const updatedSelectedFP = selectedFilteredPoints.filter(
        (selectedFP) => selectedFP._id !== filteredPoint._id
      );
      setSelectedFilteredPoints(updatedSelectedFP);
    }
  };

  return !questionModal ? (
    <div className="response-filtered-text">
      {filteredPoints?.length > 0 && (
        <b style={{ width: "100%", fontSize: "12px" }}>Filtered Points</b>
      )}
      {filteredPoints?.length > 0 &&
        filteredPoints?.map((fPoint, i) => (
          <p
            key={i}
            className="filtered-text position-relative"
            style={{ fontSize: "12px" }}
          >
            {fPoint?.point}

            <span
              className="removeFilteredTextBtn position-absolute top-0 translate-middle badge bg-danger"
              onClick={() =>
                removeFilteredPointsAndSentences(i, "point", fPoint?._id)
              }
              style={{
                cursor: "pointer",
                fontSize: "10px",
                width: "20px",
                right: "0",
              }}
            >
              X
            </span>

            <span>|</span>
          </p>
        ))}
    </div>
  ) : (
    <Container>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px 0",
        }}
      >
        {filteredPoints?.map((filter) => (
          <Row key={filter._id} style={{ marginRight: "2rem" }}>
            <Col xs="auto" className="p-0">
              <Form.Check
                type="checkbox"
                id={`selectFilteredPoint_${filter._id}`}
                checked={
                  selectedFilteredPoints.find(
                    (selectedFP) => selectedFP._id === filter._id
                  )
                    ? true
                    : false
                }
                onChange={(e) => handleSelectFilteredPoint(e, filter)}
                data-fp-for-resp={currentResponseId}
              />
            </Col>
            <Col className="ps-2">
              <label htmlFor={`selectFilteredPoint_${filter._id}`}>
                {filter?.point}
              </label>
            </Col>
          </Row>
        ))}
      </div>
    </Container>
  );
};

const FilteredSentences = ({
  currentResponseId,
  topic,
  questionId,
  questionModal,
  removeFilteredPointsAndSentences,
  filteredSentences,
}) => {
  return (
    !questionModal && (
      <div style={{ marginTop: "1rem" }}>
        {filteredSentences?.length > 0 && (
          <b style={{ width: "100%", marginBottom: "1rem" }}>
            Filtered Sentence
          </b>
        )}
        {filteredSentences?.length > 0 &&
          filteredSentences?.map((fSentence, i) => (
            <p
              key={i}
              className="filtered-text position-relative"
              style={{
                backgroundColor: "rgba(211, 211, 211, 0.187)",
                fontSize: "12px",
              }}
            >
              - {fSentence?.sentence}
              <span
                className="removeFilteredTextBtn position-absolute top-0 translate-middle badge bg-danger"
                onClick={() =>
                  removeFilteredPointsAndSentences(
                    i,
                    "sentence",
                    fSentence?._id
                  )
                }
                style={{
                  cursor: "pointer",
                  fontSize: "8px",
                  right: "0",
                }}
              >
                X
              </span>
            </p>
          ))}
      </div>
    )
  );
};

const sleep = async (timeout) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res();
    }, timeout);
  });
};

const AddAlias = ({ setfShowAddAlias, questionId }) => {
  const dispatch = useDispatch();
  const [questionAlias, setquestionAlias] = useState("");
  const handleAddAlias = (e) => {
    e.preventDefault();

    if (questionAlias.trim()) {
      console.log(questionAlias.trim());
      dispatch(addAlias({ questionId, questionAlias: questionAlias.trim() }));
    } else {
      console.error("questionAlias field required");
    }
  };
  return (
    <Form onSubmit={handleAddAlias} className="mb-3">
      <Form.Group controlId="relationToParent">
        <Form.Label className="small">Question Alias:</Form.Label>
        <Form.Control
          type="text"
          className="mb-2 small"
          value={questionAlias}
          onChange={(event) => setquestionAlias(event.target.value)}
          required
        />
        <button className="btn btn-sm btn-primary me-1" type="submit">
          Add
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          onClick={() => setfShowAddAlias(false)}
        >
          Cancel
        </button>
      </Form.Group>
    </Form>
  );
};

const Response = ({
  questionId,
  sourceQuestion,
  questionAlias,
  questionModal,
  selectedFilteredPoints,
  setSelectedFilteredPoints,
  setCheckAllFPForResponses,
  checkAllFPForResponses,
}) => {
  const fShowSelectionMenu = useSelector(
    (state) => state.chatGPTSlice.fShowSelectionMenu
  );
  const [selectedText, setSelectedText] = useState("");
  const [selectedTextForResponse, setSelectedTextForResponse] = useState("");
  const [selectionCoords, setSelectionCoords] = useState(null);
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [fShowContext, setfShowContext] = useState(false);
  const [fShowAddAlias, setfShowAddAlias] = useState(false);
  const textRef = useRef(null);
  const responses = useSelector((state) => state.chatGPTSlice.responses);
  const topic = useSelector((state) => state.chatGPTSlice.topic);
  const questions = useSelector((state) => state.chatGPTSlice.questions);

  const dispatch = useDispatch();
  const handleSelection = async (array, index, responseId, e) => {
    await sleep(20);
    const selection = window.getSelection();
    const text = selection.toString();
    if (!text) return;

    dispatch(setFShowSelectionMenu(true));

    const range = selection.getRangeAt(0);
    const startInTextResponse = array.reduce((acc, cur, i) => {
      if (i < index) {
        return acc + cur.length;
      }
      return acc;
    }, range.startOffset);
    setSelectedText({ text, startInTextResponse });
    setSelectedTextForResponse(responseId);
    var rect = document.querySelector(".modal-body").getBoundingClientRect();
    var x = e.pageX - rect.left + 10;
    var y = e.pageY - rect.top + 10;
    console.log({ x, y });
    setSelectionCoords({ x, y });
  };

  const addFilteredPointsAndSentences = (option, currentResponseId) => {
    // TODO: Update app state to add selectedText as filteredPoint or filteredSentence
    dispatch(
      updateFilteredPointsAndSentences({
        topicId: topic?._id,
        type:
          option === "filteredPoint" ? "filteredPoints" : "filteredSentences",
        operation: "add",
        element: {
          [option === "filteredPoint" ? "point" : "sentence"]:
            selectedText?.text,
          startInTextResponse: selectedText?.startInTextResponse,
          question: questionId,
          response: currentResponseId,
        },
      })
    );
  };

  const handleDismiss = () => {
    setSelectedText("");
    setSelectedTextForResponse("");
    setSelectionCoords(null);
  };

  const removeFilteredPointsAndSentences = (index, type, elementId) => {
    console.log(index, type, elementId);
    dispatch(
      updateFilteredPointsAndSentences({
        topicId: topic?._id,
        type: type === "point" ? "filteredPoints" : "filteredSentences",
        operation: "delete",
        elementId,
      })
    );
  };

  const handleCheckAllUncheckAllFP = (e, responseId, filteredPoints) => {
    console.log({ responseId });
    const isCheckAllFP = e.target.checked;
    if (isCheckAllFP === true) {
      const updatedSelectedFP = selectedFilteredPoints.filter(
        (selectedFP) => selectedFP.response !== responseId
      );
      updatedSelectedFP.push(...filteredPoints);
      setSelectedFilteredPoints(updatedSelectedFP);
      setCheckAllFPForResponses((checkAllFPForResponses) => [
        ...checkAllFPForResponses,
        responseId,
      ]);
    } else if (isCheckAllFP === false) {
      const updatedSelectedFP = selectedFilteredPoints.filter(
        (selectedFP) => selectedFP.response !== responseId
      );
      setSelectedFilteredPoints(updatedSelectedFP);
      setCheckAllFPForResponses((checkAllFPForResponses) =>
        checkAllFPForResponses.filter((respId) => respId !== responseId)
      );
    }
  };

  return (
    <>
      <div>
        {sourceQuestion && (
          <>
            <h5 className="m-0">Question</h5>

            {/* <div className="d-flex align-items-end "> */}
            <p
              style={{
                fontSize: "16px",
                color: "#1e3a8a",
                margin: "0 ",
                whiteSpace: "pre-wrap",
              }}
            >
              {sourceQuestion}
            </p>
            <div className="d-flex gap-2 mt-1">
              {!questionAlias && (
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    paddingBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setfShowAddAlias((prev) => !prev)}
                >
                  Add Alias
                </span>
              )}
              <span
                style={{
                  fontSize: "13px",
                  textDecoration: "underline",
                  paddingBottom: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setfShowContext((prev) => !prev)}
              >
                {fShowContext ? "Hide" : "Show"} Context
              </span>
            </div>
          </>
        )}
        {questionAlias && (
          <>
            <h6>Question Alias</h6>
            <p
              style={{
                textTransform: "capitalize",
                color: "#6b1e1e",
                fontSize: "14px",
              }}
            >
              {questionAlias}
            </p>
          </>
        )}
        {fShowAddAlias && !questionAlias && (
          <AddAlias
            setfShowAddAlias={setfShowAddAlias}
            questionId={questionId}
          />
        )}
        {fShowContext &&
          (questions[questionId].contextProvided != "" ? (
            <div>
              <h6>Context Provided</h6>
              <p
                className="border px-1 rounded "
                style={{
                  fontSize: "12px",
                  height: "80px",
                  overflowY: "scroll",
                  resize: "vertical",
                  whiteSpace: "pre-wrap",
                }}
              >
                {/* {JSON.stringify(questions[questionId].contextProvided)} */}
                {questions[questionId].contextProvided.map((context) => (
                  <>
                    <span>
                      <span className="fw-bold">"user"</span>:"{context.user}"
                    </span>
                    <br />
                    <span className="fw-bold">"assistant"</span>:"
                    {context.assistant}"
                    <br />
                  </>
                ))}
              </p>
            </div>
          ) : (
            <div>
              <p
                className="border px-1 rounded "
                style={{
                  fontSize: "10px",
                }}
              >
                No Context Provided
              </p>
            </div>
          ))}
        <h6 className="my-3">Responses</h6>
        <div>
          {responses[questionId]?.length > 0 ? (
            responses[questionId]?.map((response, index) => {
              const filteredPoints = response?._id
                ? topic?.filteredPoints[questionId]?.points?.filter(
                    (point) => point.response === response?._id
                  )
                : [];
              const filteredSentences = response?._id
                ? topic?.filteredSentences[questionId]?.sentences?.filter(
                    (sentence) => sentence.response === response?._id
                  )
                : [];
              return (
                <div key={response?._id} style={{ marginTop: "1rem" }}>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    Response {index + 1}:
                    {questionModal && filteredPoints?.length > 0 && (
                      <input
                        class="checkAllUncheckAllFP form-check-input ms-2"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        title="checkAll / uncheckAll"
                        onChange={(e) =>
                          handleCheckAllUncheckAllFP(
                            e,
                            response?._id,
                            filteredPoints
                          )
                        }
                        checked={checkAllFPForResponses.includes(response?._id)}
                      />
                    )}
                  </p>
                  <FilteredPoints
                    currentResponseId={response?._id}
                    topic={topic}
                    questionId={questionId}
                    questionModal={questionModal}
                    removeFilteredPointsAndSentences={
                      removeFilteredPointsAndSentences
                    }
                    selectedFilteredPoints={selectedFilteredPoints}
                    setSelectedFilteredPoints={setSelectedFilteredPoints}
                    filteredPoints={filteredPoints}
                  />
                  <FilteredSentences
                    currentResponseId={response?._id}
                    topic={topic}
                    questionId={questionId}
                    questionModal={questionModal}
                    removeFilteredPointsAndSentences={
                      removeFilteredPointsAndSentences
                    }
                    filteredSentences={filteredSentences}
                  />
                  <div
                    style={{
                      maxHeight: "fitContent",
                      overflowY: "auto",
                      resize: "vertical",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      height: "100px",
                      marginTop: "1rem",
                    }}
                  >
                    {response?.htmlResponse &&
                      response?.htmlResponse?.split("\n").map((res, i) => (
                        <p
                          key={i}
                          style={{
                            color: "#1e3a8a",
                            fontSize: "14px",
                            margin: "2px",
                          }}
                          ref={textRef}
                          onMouseUp={(e) =>
                            handleSelection(
                              response?.htmlResponse?.split("\n"),
                              i,
                              response?._id,
                              e
                            )
                          }
                        >
                          {res}
                        </p>
                      ))}
                  </div>
                  {selectedText?.text &&
                    response?._id === selectedTextForResponse &&
                    fShowSelectionMenu && (
                      <SelectionMenu
                        coords={selectionCoords}
                        responseId={response?._id}
                        onOptionSelected={(option) => {
                          addFilteredPointsAndSentences(option, response?._id);
                        }}
                        onDismiss={handleDismiss}
                        selectedText={selectedText}
                      />
                    )}
                </div>
              );
            })
          ) : !generatingResponse ? (
            <Button
              variant="primary"
              onClick={() => {
                setGeneratingResponse(true);
                dispatch(
                  getAiResponse({
                    question: sourceQuestion,
                    questionId: questionId,
                    topicId: topic?._id,
                  })
                );
              }}
            >
              Retry
            </Button>
          ) : (
            <GeneratingResponseLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default Response;
