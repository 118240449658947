import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "@/../../src/components/RichTextEditor/CKContents.css";

import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import AlertWrapper from "./components/AlertWrapper/AlertWrapper";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className="notifications top-right">
        <AlertWrapper />
      </div>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
