import { createSlice } from "@reduxjs/toolkit";

const { REACT_APP_NOTIFICATION_TIMEOUT: timeOut } = process.env;


export const alertSlice = createSlice({
  name: "alerts",
  initialState: {
    alerts: [],
  },
  reducers: {
    newAlert: (state, action) => {
      const { type, message } = action.payload;
      const uniqueAlertId = Math.random() * 100;
      const alertObject = {
        id: uniqueAlertId,
        type,
        message,
        dt: Date.now(),
      };
      state.alerts.push(alertObject);
    },

    clearOldAlerts: (state) => {
      const now = Date.now();
      const fiveSecondsInMS = parseInt(timeOut);
      const filteredAlerts = state.alerts.filter(
        ({ dt }) => now < dt + fiveSecondsInMS
      );
      state.alerts = filteredAlerts;
    },
    clearAlert: (state, action) => {
      const { id } = action.payload;
      const filteredAlerts = state.alerts.filter((item) => item.id !== id);
      state.alerts = filteredAlerts;
    },
  },
});

// Export Actions
export const { newAlert, clearOldAlerts, clearAlert } = alertSlice.actions;

// Export Reducer
export default alertSlice.reducer;
