import React, { useState } from 'react'
import { SavedExperience } from './SavedExperience'
import { useSelector } from 'react-redux'

export const ExperienceList = () => {
  const { openDetails: editExpDetails, fFirstTimeLoad } = useSelector(
    (state) => {
      return state.openTabSlice;
    }
  );
  const workData = useSelector((state) => {
    return state.workSlice.workData;
  });
  const defaultShowCount = 2;
  // console.log(workData);
  const [showCount, setShowCount] = useState(defaultShowCount);
  // console.log(workData[0].skills);
  const loadMoreExperience = () => {
    if (showCount !== workData.length) setShowCount(workData.length);
    else setShowCount(defaultShowCount);
  };
  return (
    <div>
      {workData.slice(0, showCount).map((sdata, index) => {
        if (
          (editExpDetails.value2 === true || editExpDetails.value1 === true) &&
          editExpDetails.index === index &&
          editExpDetails.mode === "update"
        ) {
          return null;
        } else
          return (
            <div className="mt-3" key={index}>
              <SavedExperience
                index={index}
                workData={sdata}
                fFirstTimeLoad={fFirstTimeLoad}
              />
            </div>
          );
      })}
      <div className="text-center mt-3 mb-5">
        {workData.length > defaultShowCount && (
          <button
            className="btn load-more-exp-btn p-2"
            style={{ minWidth: "150px" }}
            onClick={() => {
              loadMoreExperience();
            }}
          >
            {showCount !== workData.length ? (
              <span>
                Load More <i className="fa-solid fa-chevron-down"></i>
              </span>
            ) : (
              <span>
                Show Less <i className="fa-solid fa-chevron-up"></i>
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
