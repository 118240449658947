import React, { useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import { useDispatch, useSelector } from "react-redux";
import "./CourseOutline.css";
import {
  createCourse,
  getAllCourses,
} from "../../../slices/courseOutlineSlice";
import { modifyHtml } from "../../../components/NewsFeed/utilities";

const CourseOutline = () => {
  const [createNewCourse, setCreateNewCourse] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  const SelectCourse = () => {
    const coursesList = useSelector(
      (state) => state.courseOutlineSlice.coursesList
    );

    const handleCourseChange = (e) => {
      const slugUrl = e.target.value;
      window.location.href = `/admin/course-detail/${slugUrl}`;
      // setSelectedCourse(e.target.value);
    };

    return (
      <Container className="px-0">
        <Form.Select onChange={handleCourseChange} value="">
          <option disabled={true} value={""} hidden className="text-muted">
            select course...
          </option>
          {coursesList?.map((course) => (
            <option
              className="course-options"
              key={course._id}
              value={course.slugUrl}
            >
              {course.name}
            </option>
          ))}
        </Form.Select>
      </Container>
    );
  };

  const CreateNewCourse = () => {
    const [courseName, setCourseName] = useState("");
    const [description, setDescription] = useState("");

    function hasTextContent(htmlString) {
      // Create a temporary element to parse the HTML string
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;

      // Check if there is any text content in the parsed HTML
      const hasText = tempElement.textContent.trim().length > 0;

      return hasText;
    }

    // useEffect(() => {
    //   console.log(description);
    // }, [description]);
    return (
      <Container className="px-0">
        <Form>
          <Form.Label className="mb-0 small text-muted">Course Name</Form.Label>
          <Form.Control
            value={courseName}
            placeholder="Enter course name here..."
            onChange={(event) => setCourseName(event.target.value)}
          />
          <Form.Label className="mb-0 mt-3 small text-muted">
            Description
          </Form.Label>
          <RichTextEditor
            data={description}
            placeholder="Write Description..."
            onChange={(content) => {
              setDescription(content);
            }}
          />
          <Button
            onClick={() =>
              dispatch(
                createCourse({
                  name: courseName,
                  description: modifyHtml(description),
                })
              )
            }
            style={{ marginBottom: "50px", marginTop: "10px" }}
            disabled={!courseName || !hasTextContent(description)}
          >
            Save
          </Button>
        </Form>
      </Container>
    );
  };

  const MoreOptions = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleOptions = () => {
      setIsOpen(!isOpen);
    };

    const handleEditExisting = () => {
      setCreateNewCourse(false);
    };

    const handleCreateNew = () => {
      setCreateNewCourse(true);
    };

    return (
      <div className="plus-icon-container ms-auto">
        <div className="add-outline" onClick={handleToggleOptions}>
          <i className="fa-solid fa-plus"></i>
        </div>
        {isOpen && (
          <div className="options-container">
            <div
              className="option"
              onClick={handleEditExisting}
              style={
                !createNewCourse
                  ? { backgroundColor: "#eee", cursor: "default" }
                  : {}
              }
            >
              Edit existing course
            </div>
            <div
              className="option"
              onClick={handleCreateNew}
              style={
                createNewCourse
                  ? { backgroundColor: "#eee", cursor: "default" }
                  : {}
              }
            >
              Create new course
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Container
      className="mb-4"
      style={{ marginTop: "30px", marginBottom: "80px", minHeight: "80vh" }}
    >
      <div className="d-flex mb-3 mt-3">
        {!createNewCourse ? (
          <h2>Edit existing course</h2>
        ) : (
          <h2>Create new course</h2>
        )}
        {<MoreOptions />}

        {/* <button
          className="ms-auto add-outline"
          onClick={() => {
            setCreateNewCourse(true);
          }}
        >
          <i className="fa-solid fa-plus"></i> New Course
        </button> */}
      </div>

      {!createNewCourse ? <SelectCourse /> : <CreateNewCourse />}
    </Container>
  );
};

export default CourseOutline;
