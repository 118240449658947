import { createSlice } from '@reduxjs/toolkit'

export const openExpSlice = createSlice({
  name: "editExpDetails",
  initialState: {
    openDetails: {
      value1: false,
      value2: false,
      type: "",
      mode: "add",
      index: 0,
    },
    fFirstTimeLoad: true,
  },
  reducers: {
    openEditExp: (state, action) => {
      state.openDetails = action.payload;
    },
    closeEditExp: (state) => {
      state.openDetails.value1 = false;
      state.openDetails.value2 = false;
      state.openDetails.mode = "";
      state.openDetails.index = 0;
    },
    unsetFirstTimeLoad: (state, action) => {
      state.fFirstTimeLoad = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openEditExp, closeEditExp, unsetFirstTimeLoad } =
  openExpSlice.actions;

export default openExpSlice.reducer