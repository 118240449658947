import "./ProfilePage.css";
import { UserCard } from "../../../components/ProfilePage/UserCard/UserCard";
import { useEffect, useState } from "react";
import UserDescription from "../../../components/ProfilePage/UserDescription/UserDescription";
import { WorkDetails } from "../../../components/ProfilePage/WorkDetails/WorkDetails";
import { EducationDetails } from "../../../components/ProfilePage/EducationDetails/EducationDetails";
import { loadUserData } from "../../../components/ProfilePage/utility/userUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios";
import { newAlert } from "../../../slices/alertSlice";
import { getSession } from "../../../slices/sessionSlice";
import { getCookie } from "../../../helpers/stubs";
import ProjectsSection from "../../../components/ProfilePage/Projects/ProjectsSection";
import CertificateDetails from "../../../components/ProfilePage/Certifications/CertificateDetails";

function ProfilePage({ isApplicationInterface, handlingOTPLogin }) {
  const params = useParams();
  const { userId } = params;
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(userId);
  const initUserInfo = {
    name: "Your Name",
    position: "Add Position",
    education: "Add Education",
    description: "Add Description",
    location: "Add Location",
    portfolio: { link: "Add link", text: "Add text" },
    email: "Add mail",
    socialMediaLinks: {
      mail: "Add mail",
      facebook: "add facebook link",
      twitter: "add twitter link",
      linkedIn: "add linkedIn link",
    },
    socialLinks: [],
  };
  const isOwnerOfProfilePage = sessionUser?.user?._id === userId;

  const [userInfo, setUserInfo] = useState(initUserInfo);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [eduDetails, setEduDetails] = useState([]);
  const [dynamicMessage, setDynamicMessage] = useState({
    heading: "",
    text: "",
  });

  function getBrowserInfo() {
    let browser = `unknown:${navigator.userAgent}`;
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      browser = "Opera";
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      browser = "Edge";
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      browser = "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      browser = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      browser = "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      browser = "IE";
    }
    return browser;
  }
  function getOSInfo() {
    let os = navigator.userAgent;
    let finalOs = `Unknown`;
    if (os.search("Windows") !== -1) {
      finalOs = "Windows";
    } else if (os.search("Mac") !== -1) {
      finalOs = "MacOS";
    } else if (os.search("X11") !== -1 && !(os.search("Linux") !== -1)) {
      finalOs = "UNIX";
    } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
      finalOs = "Linux";
    }
    return finalOs;
  }

  const updateDurationForUser = async () => {
    try {
      await axiosInstance.post(`/api/user/update-user-session-details`, {
        durationSlot: {
          fUpdateExisting: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserSession = async () => {
    try {
      // set profileVisitedAt & create duration slot on load
      let profileVisitedAt;
      let durationSlot;
      if (sessionUser.user) {
        if (sessionUser.user?.fNaukriApplicant) {
          profileVisitedAt = new Date();
          durationSlot = {
            fCreateNew: true,
          };
        }
        const userBrowser = getBrowserInfo();
        const userOS = getOSInfo();
        await axiosInstance.post(`/api/user/update-user-session-details`, {
          profileVisitedAt,
          userBrowser,
          userOS,
          durationSlot,
        });

        // set polling for duration tracking
        if (sessionUser.user?.fNaukriApplicant) {
          setInterval(
            updateDurationForUser,
            process.env.REACT_APP_UPDATE_DURATION_INTERVAL
          );
        }
      }
    } catch (error) {
      console.error(error);
      // let errorMsg = error.response?.data?.message || error.message;
      // dispatch(newAlert({ type: "danger", message: errorMsg }));
    }
  };

  // onPostRender: Fetch data after component renders
  useEffect(() => {
    if (!handlingOTPLogin && sessionUser.user) {
      updateUserSession();
    }
  }, [handlingOTPLogin, sessionUser.user]);

  useEffect(() => {
    if (sessionUser?.user && !handlingOTPLogin && isOwnerOfProfilePage) {
      setLoadingUserData(true);
      loadUserData(userId, setUserInfo).finally(() => {
        setLoadingUserData(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlingOTPLogin, sessionUser]);

  if (!isOwnerOfProfilePage) {
    navigate("/");
    return null;
  }

  return handlingOTPLogin ? (
    <div style={{ height: "70vh" }}>
      <h2>Please wait, validating Naukri OTP</h2>
    </div>
  ) : (
    <>
      <div className="container">
        {/* <div style={{ color: "green" }}>
          This is the applicant tracking system for mentorstudents, your profile
          is not publicly visible and your information will not shared with
          anybody. Your information is only used for hiring purposes.
        </div> */}
        <div className="row d-flex justify-content-center">
          <div className="col-lg-9 col-md-8 col-11 order-md-1 order-2 ps-sm-5">
            <div className="d-none d-md-block">
              <UserDescription
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                loadingUserData={loadingUserData}
                latestEducation={{
                  degree: eduDetails[0]?.degree,
                  institution: eduDetails[0]?.organizationName,
                }}
              />
            </div>
            <WorkDetails />
            <hr />
            <ProjectsSection />
            <hr />
            <CertificateDetails />
            <hr />
            <EducationDetails
              educationDetails={{ eduDetails, setEduDetails }}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-11 order-md-2 order-1 pe-sm-4">
            <UserCard
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              loadingUserData={loadingUserData}
              latestEducation={eduDetails[0]?.degree}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
