import React from "react";
import FeedbackButtons from "../FeedbackButtons/FeedbackButtons";
import DisplayAdFormatImage from "./DisplayAdFormatImage/DisplayAdFormatImage";
import DisplayAdFormatCarousel from "./DisplayAdFormatCarousel/DisplayAdFormatCarousel";

const DisplayAd = ({ ad, isSaved, userId, isIrrelevantAd, isUpvoted, isDownvoted, query }) => {
  const [isDisabled, setIsDisabled] = React.useState(isIrrelevantAd);
  const toggleDisabled = () => setIsDisabled(!isDisabled);
  return (
    <div className="card shadow">
      <div className="card-body">
        <div className="d-flex justify-content-end align-items-center" style={{ justifyContent: "flex-end !important" }}>
          <FeedbackButtons
            adId={ad._id}
            upvoteCount={ad.upvoteCount}
            query={query}
            userId={userId}
            isDisabled={isDisabled}
            isSaved={isSaved}
            toggleDisabled={toggleDisabled}
            isIrrelevantAd={isIrrelevantAd}
            adType="displayAd"
            isUpvoted={isUpvoted}
            isDownvoted={isDownvoted}
          />
        </div>
        <div style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          {ad?.format === "image" && <DisplayAdFormatImage adDetails={ad?.adDetails[0]} />}
          {ad?.format === "carousel" && <DisplayAdFormatCarousel adDetails={ad?.adDetails} />}
        </div>
      </div>
    </div>
  );
};

export default DisplayAd;
