
export const timeDifference = (timePeriod, currWorking) => {
  const currentTime = new Date();
  let { startMonth, startYear, endMonth, endYear } = timePeriod;
  startMonth = startMonth?.slice(0, 3);
  endMonth = endMonth?.slice(0, 3);
  let monthDiff, yearDiff;
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const timeFilled =
    (startYear && endYear && endMonth && startMonth) ||
    (startYear && startMonth && currWorking);
  if (!timeFilled) return [-1, -1];

  if (!currWorking) {
    monthDiff = month.indexOf(endMonth) - month.indexOf(startMonth);
    yearDiff = endYear - startYear;
    const overCurrentMonth = currentTime.getMonth() < month.indexOf(endMonth);
    const currentEndYear = String(currentTime.getFullYear()) === endYear;

    if (currentEndYear && overCurrentMonth) {
      return [-1, -1];
    }
  } else {
    monthDiff = currentTime.getMonth() - month.indexOf(startMonth);
    yearDiff = currentTime.getFullYear() - startYear;
  }

  const endMonthNum = month.indexOf(endMonth);
  const startMonthNum = month.indexOf(startMonth);

  const yearDifference = monthDiff >= 0 ? yearDiff : yearDiff - 1;
  const monthDifference = monthDiff >= 0 ? monthDiff : 12 + monthDiff;
  return [yearDifference, monthDifference, startMonthNum, endMonthNum];
};

export const isPastTimeGiven = (month, year) => {
  const currentTime = new Date();
  month = month?.slice(0, 3);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const timeFilled = month && year;
  if (!timeFilled) {
    return -1;
  }

  const monthDiff = currentTime.getMonth() - months.indexOf(month);
  const yearDiff = currentTime.getFullYear() - year;

  const yearDifference = monthDiff >= 0 ? yearDiff : yearDiff - 1;
  // const monthDifference = (monthDiff>=0?monthDiff:12+monthDiff)
  return yearDifference >= 0;
};

const WorkPeriod = ({ timePeriod, currWorking, editMode = false }) => {
  // console.log(timeperiod);

  const timeRange = timePeriod.startYear
    ? currWorking
      ? `${timePeriod.startMonth} ${timePeriod.startYear} - Present`
      : `${timePeriod.startMonth} ${timePeriod.startYear} - ${timePeriod.endMonth} ${timePeriod.endYear}`
    : "";

  const [yearDiff, monthDiff] = timeDifference(timePeriod, currWorking);
  const timeDiff = `(${yearDiff} year and ${monthDiff} month)`;

  if (!timePeriod.startMonth || !timePeriod.startYear) {
    return <></>;
  }

  return (
    <div>
      <div className={`mt-2 ${editMode ? "text-underline" : ""}`}>
        {timeRange}
      </div>
      <div className={`mt-2 ${editMode ? "text-underline" : ""}`}>
        {timeDiff}
      </div>
    </div>
  );
};

  export default WorkPeriod