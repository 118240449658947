import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./BookmarkedAndLikedArticles.css";
import { useDispatch, useSelector } from "react-redux";
import BookmarkArticles from "./BookmarkArticles/BookmarkArticles";
import LikedArticles from "./LikedArticles/LikedArticles";
import {
  getBookmarkedArticles,
  getLikedArticles,
} from "../../slices/newsFeedSlice";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../CustomPagination/CustomPagination";

const BOOKMARKED_ARTICLES_PER_PAGE = 10;
const LIKED_ARTICLES_PER_PAGE = 10;

function BookmarkedAndLikedArticles() {
  const bookmarkedArticles = useSelector(
    (state) => state.newsFeedSlice.bookmarkedArticles
  );
  const likedArticles = useSelector(
    (state) => state.newsFeedSlice.likedArticles
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bookmarksCurrentPage, setBookmarksCurrentPage] = useState(1);
  const [showMoreBookmarks, setShowMoreBookmarks] = useState(false);
  const [visibleBookmarkedArticles, setVisibleBookmarkedArticles] = useState(
    []
  );
  const [likedCurrentPage, setLikedCurrentPage] = useState(1);
  const [showMoreLikedArticles, setShowMoreLikedArticles] = useState(false);
  const [visibleLikedArticles, setVisibleLikedArticles] = useState([]);
  const [fUserChangedPage, setFUserChangedPage] = useState(false);
  const [scrollTo, setScrollTo] = useState("");
  const [fShowLessBookmarksClicked, setFShowLessBookmarksClicked] =
    useState(false);
  const [fShowLessLikedArticlesClicked, setFShowLessLikedArticlesClicked] =
    useState(false);
  const showMoreBookmarksRef = useRef(null);
  const showMoreLikedArticlesRef = useRef(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const bookmarkedPage = urlParams.get("b");
    const likedPage = urlParams.get("l");
    setBookmarksCurrentPage(parseInt(bookmarkedPage, 10) || 1);
    setLikedCurrentPage(parseInt(likedPage, 10) || 1);
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const bookmarkedPage = urlParams.get("b");
    dispatch(
      getBookmarkedArticles({
        currentPage: parseInt(bookmarkedPage, 10) || 1,
        bookmarkedArticlesPerPage: BOOKMARKED_ARTICLES_PER_PAGE,
      })
    );
  }, [dispatch, bookmarksCurrentPage]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const likedPage = urlParams.get("l");
    dispatch(
      getLikedArticles({
        currentPage: parseInt(likedPage, 10) || 1,
        likedArticlesPerPage: LIKED_ARTICLES_PER_PAGE,
      })
    );
  }, [dispatch, likedCurrentPage]);

  useEffect(() => {
    const bookmarkStartIndex = 0;
    const bookmarksToShow = showMoreBookmarks
      ? BOOKMARKED_ARTICLES_PER_PAGE
      : 4;
    const visibleBookmarks = bookmarkedArticles?.data?.slice(
      bookmarkStartIndex,
      bookmarkStartIndex + bookmarksToShow
    );
    setVisibleBookmarkedArticles(visibleBookmarks);
  }, [bookmarksCurrentPage, bookmarkedArticles, showMoreBookmarks]);

  useEffect(() => {
    const likedArticlesStartIndex = 0;
    const likedArticlesToShow = showMoreLikedArticles
      ? LIKED_ARTICLES_PER_PAGE
      : 4;
    const visibleLikes = likedArticles?.data?.slice(
      likedArticlesStartIndex,
      likedArticlesStartIndex + likedArticlesToShow
    );
    setVisibleLikedArticles(visibleLikes);
  }, [likedCurrentPage, likedArticles, showMoreLikedArticles]);

  useEffect(() => {
    if (fUserChangedPage) {
      const targetElement = document.getElementById(scrollTo);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      //reset state fUserChangedPage to false and scrollTo to empty string
      setFUserChangedPage(false);
      setScrollTo("");
    }
  }, [fUserChangedPage, scrollTo]);
  const handlePageChangeForBookmarks = (pageNumber) => {
    const likedPage = new URLSearchParams(window.location.search).get("l");
    navigate(`?b=${pageNumber}&l=${likedPage || 1}`);
    setBookmarksCurrentPage(pageNumber);
    setFUserChangedPage(true);
    setScrollTo("bookmarked-articles");
  };
  const handlePageChangeForLikedPosts = (pageNumber) => {
    const bookmarkedPage = new URLSearchParams(window.location.search).get("b");
    navigate(`?b=${bookmarkedPage || 1}&l=${pageNumber}`);
    setLikedCurrentPage(pageNumber);
    setFUserChangedPage(true);
    setScrollTo("liked-articles");
  };
  const handleToggleShowMoreBookmarkedArticles = () => {
    setShowMoreBookmarks((prevShowBookmarksValue) => !prevShowBookmarksValue);
    if (showMoreBookmarksRef.current?.textContent === "Show Less") {
      setFShowLessBookmarksClicked(true);
    } else {
      setFShowLessBookmarksClicked(false);
    }
  };
  const handleToggleShowMoreLikedArticles = () => {
    setShowMoreLikedArticles(
      (prevShowLikedPostsValue) => !prevShowLikedPostsValue
    );
    if (showMoreLikedArticlesRef.current?.textContent === "Show Less") {
      setFShowLessLikedArticlesClicked(true);
    } else {
      setFShowLessLikedArticlesClicked(false);
    }
  };
  useEffect(() => {
    if (fShowLessBookmarksClicked) {
      window.location.hash = "#show-more-bookmarks";
    } else {
      window.history.replaceState(
        null,
        "",
        window.location.pathname + window.location.search
      );
    }
  }, [fShowLessBookmarksClicked]);
  useEffect(() => {
    if (fShowLessLikedArticlesClicked) {
      window.location.hash = "#show-more-liked-articles";
    } else {
      window.history.replaceState(
        null,
        "",
        window.location.pathname + window.location.search
      );
    }
  }, [fShowLessLikedArticlesClicked]);
  return (
    <>
      <div className="bookmarked-articles-container">
        <div className="position-absolute top-0 start-0 bottom-0 end-0 custom-overlay"></div>
        <Row className="justify-content-center position-relative custom-bookmarked-articles">
          <div
            id="bookmarked-articles"
            className="bookmarked-article-heading py-5"
          >
            Bookmarked Articles
          </div>
          {bookmarkedArticles?.data?.length > 0 ? (
            <Col lg={11} className="bookmarked-liked-articles">
              <BookmarkArticles
                bookmarkedArticles={visibleBookmarkedArticles}
              />
            </Col>
          ) : (
            <div
              className=" text-center mb-5 fst-italic "
              style={{ opacity: 0.4, filter: "grayscale(1)" }}
            >
              You do not have any Bookmarked articles
            </div>
          )}
          <div className="d-flex flex-column justify-content-center align-items-center ">
            {showMoreBookmarks &&
              bookmarkedArticles?.totalBookmarkedArticles > 10 && (
                <CustomPagination
                  currentPage={bookmarksCurrentPage}
                  totalPages={Math.ceil(
                    bookmarkedArticles.totalBookmarkedArticles /
                      BOOKMARKED_ARTICLES_PER_PAGE
                  )}
                  onPageChange={handlePageChangeForBookmarks}
                />
              )}
            {bookmarkedArticles?.totalBookmarkedArticles > 4 && (
              <>
                <button
                  id="show-more-bookmarks"
                  className="show-more-button-typography show-more-button-style"
                  onClick={() => {
                    handleToggleShowMoreBookmarkedArticles();
                  }}
                  ref={showMoreBookmarksRef}
                >
                  {showMoreBookmarks ? "Show Less" : "Show More"}
                </button>
              </>
            )}
          </div>
        </Row>
      </div>
      <div>
        <Row className=" justify-content-center custom-liked-articles">
          <div id="liked-articles" className=" bookmarked-article-heading py-5">
            Liked Articles
          </div>
          {likedArticles?.data?.length > 0 ? (
            <Col lg={11} className="bookmarked-liked-articles">
              <LikedArticles likedArticles={visibleLikedArticles} />
            </Col>
          ) : (
            <div
              className=" text-center mb-5 fst-italic "
              style={{ opacity: 0.4, filter: "grayscale(1)" }}
            >
              You do not have any Liked articles
            </div>
          )}
          <div className="d-flex flex-column justify-content-center align-items-center ">
            {showMoreLikedArticles &&
              likedArticles?.totalLikedArticles > 10 && (
                <CustomPagination
                  currentPage={likedCurrentPage}
                  totalPages={Math.ceil(
                    likedArticles.totalLikedArticles / LIKED_ARTICLES_PER_PAGE
                  )}
                  onPageChange={handlePageChangeForLikedPosts}
                />
              )}
            {likedArticles?.totalLikedArticles > 4 && (
              <button
                id="show-more-liked-articles"
                className="show-more-button-typography show-more-button-style"
                onClick={() => {
                  handleToggleShowMoreLikedArticles();
                }}
                ref={showMoreLikedArticlesRef}
              >
                {showMoreLikedArticles ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
}

export default BookmarkedAndLikedArticles;
