import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Axios from "../../../axios"
import { newAlert } from '../../../slices/alertSlice'

const ResetPasswordPage = ({ fApplicantPage }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, encodedURIEmail: email } = useParams();

  const validateForm = () => {
    const errors = {};
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords don't match";
    }

    if (password.length < 8) {
      errors.password = "Password should be minimum 8 characters";
    }

    if (!password) {
      errors.password = "Please enter a password";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    }

    return errors;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }
    const reqBody = { token, email, password, fApplicantPage };
    Axios.post("/api/public/reset-password", reqBody)
      .then((res) => {
        if (res.status == 200) {
          dispatch(
            newAlert({
              type: "success",
              message: "Password changed successfully!",
            })
          );
          let navigateTo = "/sign-in?rd=false";
          if (fApplicantPage) {
            navigateTo = `/applicant/sign-in?email=${email}&rd=false`;
          }
          setTimeout(() => navigate(navigateTo), 3000);
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(
            newAlert({ type: "danger", message: err.response.data.message })
          );
        } else {
          dispatch(newAlert({ type: "danger", message: err.message }));
        }
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center py-5 my-5 px-3">
      <Form
        className="w-100"
        style={{ "max-width": "450px", textAlign: "center" }}
      >
        <Form.Label>
          <h3>Reset Password</h3>
        </Form.Label>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          {/* <Form.Label>Password</Form.Label> */}
          <Form.Control
            type="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {formErrors.password && (
            <p className="text-danger" style={{ "font-size": "0.75rem" }}>
              {formErrors.password}
            </p>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
          {/* <Form.Label>Confirm Password</Form.Label> */}
          <Form.Control
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {formErrors.confirmPassword && (
            <p className="text-danger" style={{ "font-size": "0.75rem" }}>
              {formErrors.confirmPassword}
            </p>
          )}
        </Form.Group>
        <Button
          style={{ backgroundColor: "#3FACCB" }}
          onClick={(e) => handleResetPassword(e)}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ResetPasswordPage
