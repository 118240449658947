import React, { useEffect, useState } from "react";
import styles from "./VotingForAd.module.css";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { updateVotesForAd } from "../../../../slices/votingsSlice";

function VotingForAd({ userId, adId, query, upvoteCount, isUpvoted, isDownvoted }) {
  const [currentUpvoteCount, setCurrentUpvoteCount] = useState(upvoteCount);
  const [upvoteStatus, setUpvoteStatus] = useState(isUpvoted);
  const [downvoteStatus, setDownvoteStatus] = useState(isDownvoted);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentUpvoteCount(upvoteCount)
  }, [upvoteCount])

  async function handleUpdateVotesForAd(currentVoteType) {
    const result = await dispatch(updateVotesForAd({ userId, adId, currentVoteType: currentVoteType, isUpvoted: upvoteStatus, isDownvoted: downvoteStatus, query }));
    if (result.meta.requestStatus === "fulfilled") {
      if (currentVoteType === "upvote") {
        setUpvoteStatus((prevUpvoteStatus) => !prevUpvoteStatus);
        setDownvoteStatus(false);
        if (result.payload.insertedVote || result.payload.updatedVoteType) {
          setCurrentUpvoteCount((prevCurrentUpvoteCount) => prevCurrentUpvoteCount + 1);
        } else if (result.payload.deletedVote) {
          setCurrentUpvoteCount((prevCurrentUpvoteCount) => prevCurrentUpvoteCount - 1);
        }
      } else if (currentVoteType === "downvote") {
        setDownvoteStatus((prevDownvoteStatus) => !prevDownvoteStatus);
        setUpvoteStatus(false);
        if (result.payload.updatedVoteType) {
          setCurrentUpvoteCount((prevCurrentUpvoteCount) => prevCurrentUpvoteCount - 1);
        }
      }
    }
  }
  return (
    <div className="d-flex justify-content-between my-1 bg-light rounded px-1" style={{ alignItems: "center" }}>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Upvote</Tooltip>}>
        <div role="button" onClick={() => handleUpdateVotesForAd("upvote")} className="like-button">
          <i className={upvoteStatus ? "fa-solid fa-thumbs-up text-info" : "fa-regular fa-thumbs-up"} />
        </div>
      </OverlayTrigger>

      <div className={styles.currentUpvoteCountText}>{currentUpvoteCount}</div>

      <div className={styles.verticleLine}></div>

      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Downvote</Tooltip>}>
        <div role="button" onClick={() => handleUpdateVotesForAd("downvote")} className="dislike-buton">
          <i className={downvoteStatus ? "fa-solid fa-thumbs-down text-info" : "fa-regular fa-thumbs-down"} />
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default VotingForAd;
