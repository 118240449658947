import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { isPastTimeGiven } from "../workComponents/subComp/WorkPeriod";

const MonthOptions = () => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      <option value="" disabled></option>
      {month.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })}
    </>
  );
};

const EditCertificate = ({
  certificate,
  setCertificate,
  editMode,
  setEditMode,
  saveCertificationData,
  deleteCertificationDetails,
}) => {
  const { userId } = useParams();
  const { name, issuedAt, issuedBy } = certificate;
  const [errors, setErrors] = useState({});
  const setOrgInfo = (e) => {
    const name = e.target.name;
    let data;
    if (name === "name") {
      data = { ...certificate, name: e.target.value };
    }
    if (data) {
      setCertificate(data);
    }
  };
  const setTimeInfo = (e) => {
    const name = e.target.name;
    let issuedAt = certificate.issuedAt || {};
    let data;
    if (name === "issuedMonth") {
      data = { ...issuedAt, issuedMonth: e.target.value };
    } else if (name === "issuedYear") {
      data = { ...issuedAt, issuedYear: e.target.value };
    }
    // console.log({ data });
    if (data) {
      setCertificate({ ...certificate, issuedAt: data });
    }
  };

  const closeExpDetails = () => {
    setEditMode({ value: false, mode: "" });
  };

  const SubmitWorkDetails = (e) => {
    e.preventDefault();
    // console.log({ certificate });

    let issuedAt = certificate.issuedAt || {};
    // Perform validation before submitting the form
    const newErrors = {};
    if (!certificate.name?.trim()) {
      newErrors.name = "Certificate name is required.";
    }
    if (!issuedBy?.trim()) {
      newErrors.issuedBy = "IssuedBy is required.";
    }
    const currYear = new Date().getFullYear();
    const fPastTime = isPastTimeGiven(
      issuedAt.issuedMonth,
      issuedAt.issuedYear
    );
    // console.log({ fPastTime });
    if (!issuedAt.issuedMonth || !issuedAt.issuedYear) {
      newErrors.issuedAt = {
        ...newErrors.issuedAt,
        start: "Issue month & year is required.",
      };
    }
    if (
      (issuedAt.issuedMonth &&
        issuedAt.issuedYear &&
        (issuedAt.issuedYear < 0 ||
          issuedAt.issuedYear > currYear ||
          issuedAt.issuedYear < currYear - 80)) ||
      !fPastTime
    ) {
      newErrors.issuedAt = {
        ...newErrors.issuedAt,
        start: "Invalid Time period given",
      };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors("");
      // console.log({ certificate });
      saveCertificationData({
        mode: editMode.mode,
        userId,
        certificationDetails: certificate,
      });
    }
  };

  return (
    <div className="card p-4 bg-light">
      <div>
        <div>
          <Row>
            <Col>
              <input
                type="text"
                className="form-control input-h-50 me-1 mb-2"
                value={certificate.name}
                name="name"
                placeholder="Certificate Name"
                onChange={(e) => {
                  setOrgInfo(e);
                }}
              />
              {errors && errors.name && (
                <span className="text-danger">{errors.name}</span>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <div className="fw-bold col-12 text-center">Time period</div>
          <span className="col-12">IssuedAt</span>
          <div className="col-12 d-flex">
            <select
              className="form-select me-2"
              name="issuedMonth"
              value={issuedAt?.issuedMonth || ''}
              onChange={(e) => setTimeInfo(e)}
            >
              <MonthOptions />
            </select>
            <input
              type="number"
              value={issuedAt?.issuedYear}
              name="issuedYear"
              className="form-control input-h-50"
              placeholder="Year"
              onChange={(e) => setTimeInfo(e)}
            />
          </div>
          {errors.issuedAt && errors.issuedAt.start && (
            <span className="text-danger">{errors.issuedAt.start}</span>
          )}
        </div>
        <div className="col-md-6 mt-5">
          <input
            type="text"
            className="form-control input-h-50 me-1 mb-2"
            value={certificate.issuedBy}
            name="issuedBy"
            placeholder="Issued by"
            onChange={(e) => {
              setCertificate({ ...certificate, issuedBy: e.target.value });
            }}
          />
          {errors && errors.issuedBy && (
            <span className="text-danger">{errors.issuedBy}</span>
          )}
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-2 col-11 mt-2 d-flex justify-content-center"
          style={{ width: "100%" }}
        >
          {/* <div className="ms-auto mt-2"> */}
          <button
            className="btn btn-success p-2 me-2"
            style={{
              backgroundColor: "#074A75",
              minWidth: "85px",
              border: "none",
            }}
            onClick={(e) => SubmitWorkDetails(e)}
          >
            Save
          </button>
          <button
            onClick={() => closeExpDetails()}
            style={{
              backgroundColor: "#EF888C",
              minWidth: "85px",
              border: "none",
            }}
            className="btn btn-secondary p-2"
          >
            Cancel
          </button>
          {/* </div> */}

          {editMode.mode !== "add" && (
            <span
              className=""
              onClick={async () => {
                await deleteCertificationDetails(certificate._id, userId);
              }}
              style={{ position: "absolute", right: "25px" }}
            >
              <i className="far fa-trash-can delete-proj-btn cursor-pointor"></i>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCertificate;
