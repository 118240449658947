import React, { useContext, useState } from "react";
import companylogo from "../../../assets/profile-images/companylogo.png";
import camera from "../../../assets/profile-images/camera.png";
import infoIcon from "../../../assets/profile-images/info_icon.svg";
import infoIconActive from "../../../assets/profile-images/green_check.svg";
import { EditTimeBar } from "./EditTimeBar";
import { MySlider } from "./MySlider";
import { SkillContext, ToolsContext } from "./EditWorkData";
import WorkPeriod from "./subComp/WorkPeriod";
import { useDispatch, useSelector } from "react-redux";
import { openEditExp } from "../../../slices/profileSlices/openTabSlice";
// import MessageBox from "./subComp/MessageBox";

const HorizontalBar = ({
  teamContribution,
  setTeamContribution,
  messageBox,
  setMessageBox,
  getMessageForMessageBox,
}) => {
  // console.log(teamContribution);

  const verticalDashedBorderStyle = {
    opacity: "0.1",
    borderLeft: "2px dashed black",
    height: "15px",
  };
  return (
    <div>
      <div className="row">
        <span className="col-1"></span>
        <div className="d-flex contribution-label justify-content-between col-lg-11 col-md-11 col-7">
          <span
            className="label-text ps-2"
            // style={{ position: "relative", left: "-56px" }}
          >
            Individual Contribution
          </span>
          <span className="label-text"></span>
          <span
            className="label-text"
            // style={{ position: "relative", left: "56px" }}
          >
            Team Management{" "}
            {/* <span
              onClick={() => {
                const activeMessageBox = messageBox === 2 ? 0 : 2;
                setMessageBox(activeMessageBox);
              }}
              className={`${
                messageBox === 2 ? "active-info-icon" : ""
              } info-icon`}
            >
              <img
                src={infoIcon}
                alt="info-btn"
                style={{ margin: "-5px 0px 0px -2.2px" }}
              ></img>
            </span> */}
          </span>
        </div>
        <div className="col-lg-3 col-md-8 col-3" />
        <div className="d-flex justify-content-between col-lg-7 col-md-11 col-7">
          <span style={verticalDashedBorderStyle}></span>
          <span style={verticalDashedBorderStyle}></span>
        </div>
      </div>
      <div className="row" style={{ position: "relative" }}>
        <div className="col-lg-3 col-md-8 col-3 text-end">
          {teamContribution.value}%
        </div>
        <div className="col-lg-7 col-md-11 col-7">
          <MySlider
            timebarData={teamContribution}
            setTimebarData={setTeamContribution}
            saveTimebarData={() => {
              setTeamContribution({ ...teamContribution });
            }}
            isContributionSlider={true}
          />
        </div>
        <span className="col-lg-2 col-md-4 col-2 p-0 w-auto">
          {100 - teamContribution.value + "%"}
        </span>

        {/* {messageBox === 2 && (
          <MessageBox
            messageData={getMessageForMessageBox(messageBox)}
            closeMessageBox={() => {
              setMessageBox(0);
            }}
            topPosition={"-130px"}
          />
        )} */}
      </div>
    </div>
  );
};

export const ExperienceDetails = ({
  workInfo,
  orgPic,
  saveJobDetails,
  deleteJobDetails,
}) => {
  // const { openExpDetails} = useContext(OpenExpDetails)
  const dispatch = useDispatch();
  const { workMessages } = useSelector((state) => {
    return state.workSlice.edit_profile_field_messages;
  });
  const { openDetails: editExpDetails } = useSelector((state) => {
    return state.openTabSlice;
  });
  const [showLessSkillHrsMsg, setShowLessSkillHrsMsg] = useState({
    lessSkillHrs: false,
    closedWarning: false,
  });
  // const getMessageForMessageBox = (activeMessageBox) => {
  //   switch (activeMessageBox) {
  //     case 1:
  //       return {
  //         heading: "Tools Section",
  //         text: <div>
  //         <div className="">This visual representation illustrates the percentage of your working hours dedicated to various skills. It's important to note that this graph reflects time allocation, not proficiency levels. The total will always add up to 100%.</div>
  //         <div className="">Accurate reporting of time distribution is crucial for ensuring a fair assessment, we can focus interview questions on skills you spend more time on.</div>
  //         <div className="">If you regularly invest time in additional skills, you can include them by clicking the "Add a Skill" button.</div>
  //         </div>,
  //       };
  //     case 2:
  //       return {
  //         heading: "Management Section",
  //         text: <div>

  //         <div className="">Please indicate your level of involvement in team management relative to individual tasks. This helps us understand your work dynamics better. For instance:</div>
  //         <div className="">If your work primarily involves individual tasks, set 'Individual Contribution' to 100% and 'Team Management' to 0%.</div>
  //         <div className="">If you split your time evenly between team management and individual tasks, set both 'Individual Contribution' and 'Team Management' to 50%.</div>
  //         </div>,
  //       };
  //     default:
  //       return { heading: "", text: "" };
  //   }
  // };
  const [messageBox, setMessageBox] = useState(1);
  const { skillData, setSkillData } = useContext(SkillContext);
  // const { toolsData, setToolsData } = useContext(ToolsContext);
  const { organizationPic, setOrganizationPic } = orgPic;
  const {
    orgDetails,
    timePeriod,
    currWorking,
    workDescribe,
    teamContribution,
    setTeamContribution,
  } = workInfo;
  // console.log(workInfo);

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    const MAX_FILE_SIZE = 1 * 2048;
    if (!file || file.size / 1024 > MAX_FILE_SIZE) {
      console.log(`File size exceeds ${MAX_FILE_SIZE}kb`);
      return;
    }
    console.log(file.size / 1024, MAX_FILE_SIZE);
    reader.onloadend = () => {
      setOrganizationPic(reader.result);
      // console.log(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const closeEditTab = () => {
    if (editExpDetails.mode === "update")
      dispatch(
        openEditExp({ value1: false, value2: false, mode: "", index: 0 })
      );
    else
      dispatch(openEditExp({ ...editExpDetails, value1: true, value2: false }));
  };

  const openEditFormTab = () => {
    dispatch(openEditExp({ ...editExpDetails, value1: true, value2: false }));
  };

  return (
    <div className="card p-4 col-12" style={{ backgroundColor: "#F9FEFF" }}>
      <div className="row">
        <div className="col-lg-6 col-md-11 col-12">
          <div className="d-flex flex-column" style={{ height: "100%" }}>
            <div className="d-flex">
              <div className="orgpic-parent">
                <img
                  src={organizationPic === "" ? companylogo : organizationPic}
                  style={{ maxHeight: "80px" }}
                  alt="companylogo"
                  className="orgpic-work orgpic-bg"
                />

                <label htmlFor="organiztionPic" style={{ cursor: "pointer" }}>
                  <input
                    id="organiztionPic"
                    type="file"
                    accept="image/*"
                    onChange={photoUpload}
                  />
                  <img src={camera} alt="camera" className="orgpic-fg" />
                </label>
              </div>
              <div className="ms-2">
                <div className="h4 text-underline" onClick={openEditFormTab}>
                  {orgDetails && orgDetails.name}
                </div>
                <div
                  className="h4 text-underline"
                  style={{ fontSize: "18px" }}
                  onClick={openEditFormTab}
                >
                  {orgDetails && orgDetails.position}
                </div>
              </div>
            </div>
            {timePeriod && (
              <div onClick={openEditFormTab} className="my-3">
                <WorkPeriod
                  editMode={true}
                  timePeriod={timePeriod}
                  currWorking={currWorking}
                />
              </div>
            )}
            {workDescribe && workDescribe.workDescription !== "" && (
              <div
                className="mt-2 p-2 timebar-input bg-white"
                style={{
                  height: "100%",
                  maxHeight: "50%",
                  overflowY: "auto",
                  fontSize: "15px",
                }}
                onClick={openEditFormTab}
              >
                {workDescribe.workDescription}
              </div>
            )}
          </div>
        </div>
        <div
          className="col-lg-6 col-md-11 col-12 pe-sm-3"
          style={{ marginLeft: "-14px" }}
        >
          <div className="row" style={{ position: "relative" }}>
            <div className="text-center ps-5 mb-3" style={{ fontSize: "13px" }}>
              <div className="fw-bold mt-2 mt-0">
                Average Time You Spend per Week on Following Skills
              </div>
              <div className="fw-bold">
                (Assume you work for 45 hrs per week)
              </div>
              <div
                className={`${
                  !showLessSkillHrsMsg.lessSkillHrs ||
                  showLessSkillHrsMsg.closedWarning
                    ? "d-none"
                    : ""
                }`}
                style={{
                  position: "relative",
                  borderRadius: "2px",
                  backgroundColor: "#ffff0042",
                }}
              >
                some of the skills went below 1 hr
                <i
                  className="fa fa-close mt-1"
                  style={{
                    position: "absolute",
                    right: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setShowLessSkillHrsMsg({
                      ...showLessSkillHrsMsg,
                      closedWarning: true,
                    })
                  }
                ></i>
              </div>
              {/* <span
                onClick={() => {
                  const activeMessageBox = messageBox === 1 ? 0 : 1;
                  setMessageBox(activeMessageBox);
                }}
                className={`${
                  messageBox === 1 ? "active-info-icon" : ""
                } info-icon`}
              >
                <img
                  src={infoIcon}
                  alt="info-btn"
                  style={{ margin: "-5px 0px 0px -2.2px" }}
                ></img>
              </span> */}
            </div>
            <div className="col-12">
              <EditTimeBar
                dataList={skillData}
                setDataList={setSkillData}
                timebarData={{
                  placeholder: "Skill Name",
                  addText: "Add a Skill",
                }}
                showLessSkillHrsMsg={showLessSkillHrsMsg}
                setShowLessSkillHrsMsg={setShowLessSkillHrsMsg}
              />
            </div>
            {/* {messageBox === 1 && (
              <MessageBox
                messageData={getMessageForMessageBox(messageBox)}
                closeMessageBox={() => {
                  setMessageBox(0);
                }}
              />
            )} */}
            {/* <div className="col-md-2 col-12 my-auto ps-0">
              Time Spent on{" "}
              <span className="fw-bold">
                {sessionUser.user?.profileType === "marketing"
                  ? "subRoles"
                  : "skills"}
              </span>
            </div> */}
          </div>
          {/* <div className="row">
            <div className="col-md-10 col-12">
              <EditTimeBar
                dataList={toolsData}
                setDataList={setToolsData}
                timebarData={{
                  placeholder: "Tool/Software Name",
                  addText: "+ Add a Tool/Software",
                }}
                showDynamicMsg={() => showDynamicMsg("tools", "")}
                hideDynamicMsg={hideDynamicMsg}
              />
            </div>
            <div className="col-md-2 col-12 my-auto ps-0">
              Time Spent on{" "}
              <span className="fw-bold">
                {sessionUser.user?.profileType === "marketing"
                  ? "skills"
                  : "tools"}
              </span>
            </div>
          </div> */}
          <div className="row mt-3">
            <div className="col-12">
              <HorizontalBar
                teamContribution={teamContribution}
                setTeamContribution={setTeamContribution}
                // messageBox={messageBox}
                // setMessageBox={setMessageBox}
                // getMessageForMessageBox={getMessageForMessageBox}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-left text-lg-center mt-3">
        <button
          className="btn btn-success me-2"
          style={{
            backgroundColor: "#074A75",
            minWidth: "85px",
            border: "none",
          }}
          onClick={() => {
            saveJobDetails();
          }}
        >
          Save
        </button>
        <button
          onClick={() => closeEditTab()}
          style={{
            backgroundColor: "#EF888C",
            minWidth: "85px",
            border: "none",
          }}
          className="btn btn-secondary"
        >
          Cancel
        </button>
        <span
          onClick={deleteJobDetails}
          className="delete-btn-work text-center"
        >
          <i className="far fa-trash-can delete-profile-btn cursor-pointor"></i>
          <span className="d-none d-sm-inline-block">Delete</span>
        </span>
      </div>
    </div>
  );
};
