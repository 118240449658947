import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Dropdown } from "react-bootstrap";
import "./ReviewGeneratedArticlesV2.css";
import { SplitView } from "../../../components/GenerateArticleStatus/SplitView/SplitView";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { newAlert } from "../../../slices/alertSlice";
import { useLocation } from "react-router-dom";
import PromptModal from "./PromptModal";
import axiosInstance from "../../../axios";
import PageNotFound from "../PageNotFound/PageNotFound";
const { REACT_APP_PROMPT_MASTER_BACK_END_DOMAIN: backendURL } = process.env;

const leftRightContainerStyle = {
  width: "100%",
  borderRadius: "4px",
  backgroundColor: "#edf2fa",
  paddingBottom: "1rem",
  height: "100vh",
  overflow: "scroll",
};

const LeftSide = ({
  mainArticle,
  serpArticles,
  pc,
  allPcTasks,
  generatedArticle,
  tasksLoading,
  mktgTaskOutputs,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  // const [showPromptModal,setShowPromptModal] = useState(true);
  const [promptModalDetails, setPromptModalDetails] = useState({
    prompt: "",
    title: "",
    active: false,
  });
  const [generatedArticleActiveKey, setGeneratedArticleActiveKey] = useState(0);

  const serpArticleToSerpLabel = (serpArticle) => {
    const serpIndex = serpArticles.findIndex((a) => a._id === serpArticle);
    if (serpIndex !== -1) {
      return ` serp-${serpIndex + 1}`;
    } else {
      return "";
    }
  };
  // console.log("generatedArticle 👍👍", generatedArticle);
  const [maxRunIndex, setMaxRunIndex] = useState(0);
  const [activeKey, setActiveKey] = useState([]);
  const [resActiveKey, setResActiveKey] = useState([]);
  const [highlightedErrorIndexes, setHighlightedErrorIndexes] = useState([]);
  const [allNonEmptyPcTasks, setAllNonEmptyPcTasks] = useState([]);
  const [firstPendingIndex, setFirstPendingIndex] = useState(-1);
  const accordionRef = useRef(null);
  let firstIndexSet = false;

  useEffect(() => {
    const allNonEmptyPcTasks = allPcTasks.map((task) => task.taskName);
    // console.log(allNonEmptyPcTasks);
    setAllNonEmptyPcTasks(allNonEmptyPcTasks);
  }, [allPcTasks]);

  const isEmptyTaskToSkip = (taskName) => {
    const skipEmptyPcTasks = ["combineDetailedSummary"];

    if (
      skipEmptyPcTasks.includes(taskName) &&
      !allNonEmptyPcTasks.includes(taskName)
    ) {
      return true;
    }
    return false;
  };

  const openPromptModal = (prompt, title) => {
    // console.log("Opening modal...");
    if (typeof prompt === "object") {
      prompt = JSON.stringify(prompt, null, 2);
    }
    setPromptModalDetails({ prompt, title, active: true });
  };
  const closePromptModal = () => {
    setPromptModalDetails({ prompt: "", title: "", active: false });
  };

  function getPcTasksForTaskNameAndType(taskName, type, mainIndex) {
    let pcTaskForTaskNameAndType = allPcTasks.filter(
      (task) => task.taskName === taskName
    );

    const hasIndexForLoop = pcTaskForTaskNameAndType.some(
      (task) => task.indexForLoop !== undefined
    );
    const hasSerArticle = pcTaskForTaskNameAndType.some(
      (task) => task.serpArticle !== undefined
    );
    if (hasIndexForLoop) {
      pcTaskForTaskNameAndType.sort((a, b) => {
        let inta = a.indexForLoop;
        let intb = b.indexForLoop;
        return inta - intb;
      });
      if (hasSerArticle) {
        pcTaskForTaskNameAndType.sort((a, b) => {
          let inta = serpArticleToSerpLabel(a.serpArticle);
          let intb = serpArticleToSerpLabel(b.serpArticle);
          return inta.localeCompare(intb);
        });
      }
    } else {
      pcTaskForTaskNameAndType.sort((a, b) => {
        return a._id.toString().localeCompare(b._id.toString());
      });
    }
    // console.log({ pcTaskForTaskNameAndType });
    if (!pcTaskForTaskNameAndType || !pcTaskForTaskNameAndType.length) {
      return [];
    }
    const failedSkippedPcTasks = pcTaskForTaskNameAndType.filter(
      (task) => task.status === "failed" || task.status === "skipped"
    );
    if (
      failedSkippedPcTasks &&
      failedSkippedPcTasks?.length &&
      !highlightedErrorIndexes.includes(mainIndex)
    ) {
      // console.log({ mainIndex });
      setHighlightedErrorIndexes([...highlightedErrorIndexes, mainIndex]);
    }

    const pendingPcTasks = pcTaskForTaskNameAndType.filter(
      (task) => task.status === "pending"
    );
    if (
      pendingPcTasks &&
      pendingPcTasks?.length &&
      !firstIndexSet &&
      firstPendingIndex === -1
    ) {
      // console.log({ firstPendingIndex, mainIndex });
      setFirstPendingIndex(mainIndex);
      firstIndexSet = true;
    }

    if (
      taskName === "getSerpRelevantKeypoints" ||
      taskName === "createSummaryOfSerpKeyPointSummaries"
    ) {
      if (type === "same" || type === "zero") {
        const initialPcTasks = pcTaskForTaskNameAndType.filter(
          (pcTask) => pcTask.indexForLoop === 0
        );
        const firstPcTask = pcTaskForTaskNameAndType.slice(0, 1);
        return initialPcTasks?.length ? initialPcTasks : firstPcTask;
      }
    }
    if (type === "same") {
      return pcTaskForTaskNameAndType;
    } else if (type === "zero") {
      const firstPcTasksForLoop = pcTaskForTaskNameAndType.filter(
        (pcTask) => pcTask.indexForLoop === 0
      );
      const firstPcTask = pcTaskForTaskNameAndType.slice(0, 1);
      return hasIndexForLoop ? firstPcTasksForLoop : firstPcTask;
    } else if (type === "middle") {
      return pcTaskForTaskNameAndType.slice(1, -1);
    } else if (type === "rest") {
      const restPcTasksForLoop = pcTaskForTaskNameAndType.filter(
        (pcTask) => pcTask.indexForLoop !== 0
      );
      const restPcTask = pcTaskForTaskNameAndType.slice(1);
      return hasIndexForLoop ? restPcTasksForLoop : restPcTask;
    } else if (type === "last") {
      return pcTaskForTaskNameAndType.length > 1
        ? pcTaskForTaskNameAndType.slice(-1)
        : [];
    }

    // Add a default return in case the type doesn't match any condition
    return [];
  }

  const copyResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        // Handle successful copying if needed
        console.log("Response copied to clipboard!");
        dispatch(
          newAlert({ type: "success", message: "Text copied successfully" })
        );
      })
      .catch((error) => {
        // Handle error if the copy operation fails
        console.error("Failed to copy response:", error);
      });
  };

  function objectKeysToArrayWithExcludedKeys(obj, index) {
    // Extract keys from the object

    let prevObj;
    let prevTaskName;
    if (index > 0) {
      const prevTask = pc.taskFlow[index - 1];
      prevObj = prevTask?.api || {};
      prevTaskName = pc.taskFlow[index]?.completedTask;
    } else {
      prevObj = { same: {} };
      prevTaskName = "start";
    }
    // console.log({ prevObj });
    const objKeys = Object.keys(prevObj);
    const excludedKeys = ["same", "zero", "middle", "rest", "last", "end"];
    const filteredKeys =
      objKeys.filter((key) => excludedKeys.includes(key)) || [];
    const keyTaskMap = filteredKeys.map((key) => {
      return { key: key, taskName: prevTaskName };
    });
    // console.log({ filteredKeys });
    return keyTaskMap;
  }

  const makeKeysBoldForObject = (obj, depth = 0) => {
    return Object.keys(obj || {})?.map((key) => (
      <div
        key={key}
        style={{
          overflowWrap: "anywhere",
        }}
      >
        <strong style={{ marginLeft: `${15 * depth}px` }}>{key}</strong>:{" "}
        <div style={{ marginLeft: `${15 * depth}px` }}>
          {/* ? JSON.stringify(obj[key], null, 2) */}
          {typeof obj[key] === "object"
            ? makeKeysBoldForObject(obj[key], depth + 1)
            : obj[key].toString()}
        </div>
      </div>
    ));
  };

  const getObjectWithBoldKeysOrValue = (obj) => {
    return typeof obj === "object" ? makeKeysBoldForObject(obj) : obj;
  };

  const getMktgObject = (pcTask) => {
    // console.log({ mktgTaskOutputs });
    if (pcTask?.generatedOutput) {
      return pcTask.generatedOutput;
    }
    const mktgTaskOutput = mktgTaskOutputs.find(
      (task) => task.dataInput?._id === pcTask._id
    );
    let mktgObject =
      mktgTaskOutput?.dataOutput?.error?.additionalData?.rawResponse ||
      mktgTaskOutput?.dataOutput?.rawResponse ||
      mktgTaskOutput?.dataOutput?.error;
    return mktgObject;
  };

  const getHeadingStylesForIndex = (index, type, taskName) => {
    let styles = {};
    // let classes = [];
    if (highlightedErrorIndexes.includes(index) && type === "error") {
      if (taskName === "getSerpArticle") {
        styles["--bs-accordion-btn-bg"] = "#ffffad";
        styles["--bs-accordion-active-bg"] = "#fffccc";
      } else {
        styles["--bs-accordion-btn-bg"] = "lightcoral";
        styles["--bs-accordion-active-bg"] = "#ffcccc";
      }
    }
    // console.log({ firstPendingIndex, index });
    if (firstPendingIndex === index && type === "pending") {
      styles = {
        position: "absolute",
        left: 0,
        top: "42%",
        transform: "translate(0, -50%)",
        width: "12px",
        height: "12px",
        backgroundColor: "green",
        borderRadius: "50%",
        animation: "pulse 1s infinite alternate",
      };
    }
    return styles;
  };

  const scrollToPrompt = (index) => {
    const promptTemplate = accordionRef.current.querySelector(
      `.accordion-item:nth-child(${index + 1})`
    );
    // console.log("➡️➡️➡️➡️➡️", promptTemplate);
    promptTemplate.scrollIntoView({ behavior: "smooth" });
    setActiveKey([index]);
    setResActiveKey([index + "-" + 0]);
    setGeneratedArticleActiveKey(0);
  };

  return (
    <div style={leftRightContainerStyle}>
      {/* outline, prompt and responses */}
      <div
        className="d-flex w-100"
        style={{ height: "92%", paddingLeft: "5%" }}
      >
        <h6
          className="vertical-outline"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Outline
          <i className="bi bi-arrow-down"></i>
        </h6>
        <div
          className={`rounded-2 p-2 m-2 mt-3 outline-container ${
            isHovered ? "expand" : ""
          }`}
          style={{
            background: "#f9fbfd",
            border: "1px solid grey",
            wordBreak: "break-all",
            whiteSpace: "normal",
          }}
        >
          {pc?.taskFlow?.map((obj, i) => (
            <p
              key={obj?.completedTask}
              onClick={() => scrollToPrompt(i)}
              className="m-1 p-0 py-2"
            >
              <b>-</b> {obj?.completedTask}
            </p>
          ))}
          <br />
          {mainArticle && generatedArticle && (
            <p
              className="m-1 p-0 py-2"
              onClick={() => {
                const generatedArticle = document
                  .getElementById("generated-article")
                  ?.querySelector(`.accordion-header`);
                // console.log("👍👍👍", generatedArticle);
                if (!generatedArticle) {
                  return;
                }
                setActiveKey([]);
                setResActiveKey([]);
                setGeneratedArticleActiveKey(1);
                generatedArticle?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <b>-</b> Generated Article
            </p>
          )}
        </div>

        {!pc?.taskFlow && (
          <div className="text-center pctask-loader">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {/* prompts and response */}
        <div className="p-2 m-2 rounded-2 response-container">
          <Accordion
            ref={accordionRef}
            activeKey={activeKey}
            onSelect={(e) => setActiveKey(e)}
            alwaysOpen
          >
            {pc?.taskFlow?.map(
              (obj, i) =>
                !isEmptyTaskToSkip(obj?.completedTask) && (
                  <Accordion.Item key={obj?.completedTask} eventKey={i}>
                    <Accordion.Header
                      style={getHeadingStylesForIndex(
                        i,
                        "error",
                        obj?.completedTask
                      )}
                    >
                      <h6 className="m-0 p-0">{obj?.completedTask}</h6>
                      <div
                        className="green-dot"
                        style={getHeadingStylesForIndex(i, "pending")}
                      ></div>
                    </Accordion.Header>
                    <Accordion.Body className="responses-text">
                      <Accordion
                        activeKey={resActiveKey}
                        onSelect={setResActiveKey}
                        alwaysOpen
                      >
                        {objectKeysToArrayWithExcludedKeys(obj.api, i)?.map(
                          (objKey, j) =>
                            getPcTasksForTaskNameAndType(
                              objKey.taskName,
                              objKey.key,
                              i
                            ).length > 0 && (
                              <Accordion.Item key={j} eventKey={i + "-" + j}>
                                <Accordion.Header>
                                  <h6 className="m-0 p-0">{objKey.key}</h6>
                                </Accordion.Header>

                                <Accordion.Body className="responses-text">
                                  <Accordion>
                                    {getPcTasksForTaskNameAndType(
                                      objKey.taskName,
                                      objKey.key,
                                      i
                                    )?.map((pcTask, j) => (
                                      <Accordion.Item
                                        key={j}
                                        eventKey={i + "-" + j}
                                      >
                                        <Accordion.Header>
                                          <h6 className="m-0 p-0">
                                            {pcTask.indexForLoop || 0}
                                            {
                                              <small
                                                style={{
                                                  fontSize: "11px",
                                                  color: "grey",
                                                }}
                                              >
                                                {serpArticleToSerpLabel(
                                                  pcTask.serpArticle
                                                )}
                                              </small>
                                            }
                                            {pcTask.status !== "completed"
                                              ? " - " + pcTask.status
                                              : ""}
                                          </h6>
                                        </Accordion.Header>

                                        <Accordion.Body className="task-output">
                                          <Accordion>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                width: "95%",
                                                gap: "1rem",
                                              }}
                                            >
                                              {["claude", "chatGPT"].includes(
                                                pcTask.taskType
                                              ) && (
                                                <button
                                                  className="btns-inside-accordion"
                                                  onClick={() =>
                                                    openPromptModal(
                                                      pcTask.promptString,
                                                      "Prompt"
                                                    )
                                                  }
                                                >
                                                  Prompt
                                                </button>
                                              )}

                                              {pcTask.additionalInfo &&
                                                ["skipped", "failed"].includes(
                                                  pcTask.status
                                                ) && (
                                                  <button
                                                    className="btns-inside-accordion"
                                                    onClick={() => {
                                                      openPromptModal(
                                                        pcTask.additionalInfo,
                                                        "Additional Info"
                                                      );
                                                    }}
                                                  >
                                                    Additional Info
                                                  </button>
                                                )}
                                              {(pcTask?.generatedOutput ||
                                                getMktgObject(pcTask)) && (
                                                <button
                                                  className="btns-inside-accordion"
                                                  onClick={() =>
                                                    copyResponse(
                                                      JSON.stringify(
                                                        pcTask?.generatedOutput ||
                                                          getMktgObject(pcTask),
                                                        null,
                                                        2
                                                      )
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-clipboard"></i>{" "}
                                                  Copy
                                                </button>
                                              )}
                                            </div>
                                            <br />
                                            {typeof pcTask?.generatedOutput ===
                                            "object"
                                              ? makeKeysBoldForObject(
                                                  pcTask?.generatedOutput
                                                )
                                              : tasksLoading &&
                                                !(
                                                  pcTask.generatedOutput ||
                                                  pcTask.additionalInfo
                                                )
                                              ? "Loading..."
                                              : getObjectWithBoldKeysOrValue(
                                                  getMktgObject(pcTask)
                                                )}
                                          </Accordion>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ))}
                                  </Accordion>
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                        )}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                )
            )}
          </Accordion>
          <PromptModal
            showPromptModal={promptModalDetails.active}
            closePromptModal={closePromptModal}
            prompt={promptModalDetails?.prompt}
            title={promptModalDetails?.title}
          />
          <br />
          {generatedArticle && (
            <div className="mt-2" id="generated-article">
              <Accordion
                // alwaysOpen
                activeKey={generatedArticleActiveKey}
                onSelect={setGeneratedArticleActiveKey}
              >
                <Accordion.Item eventKey={1}>
                  <Accordion.Header>
                    <h6>Generated Article</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "95%",
                        gap: "1rem",
                        overflowWrap: "anywhere",
                      }}
                    >
                      <button
                        className="btns-inside-accordion"
                        onClick={() =>
                          copyResponse(
                            `${generatedArticle?.generatedTitle}\n\n${generatedArticle?.articleString}`
                          )
                        }
                      >
                        <i className="bi bi-clipboard"></i> Copy
                      </button>
                    </div>
                    <h2>{generatedArticle?.generatedTitle}</h2>
                    {generatedArticle?.articleString}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <br />
            </div>
          )}
        </div>
        {/*  */}
      </div>
    </div>
  );
};

const RightSide = ({
  mainArticle,
  pc,
  url,
  setUrl,
  parsedHTML,
  setParsedHTML,
  title,
  setTitle,
  serpArticles,
}) => {
  // const dispatch = useDispatch();

  // const serpArticles = generatedArticle?.serpArticles || [];
  const [urlPrefix, setUrlPrefix] = useState("Main Article - ");
  const [serpIndex, setSerpIndex] = useState(null);
  const [currentArticle, setCurrentArticle] = useState("main");

  const setCurrentSerpArticle = (e) => {
    const event = JSON.parse(e);
    const {
      serpArticleRef,
      serpArticleUrl,
      serpArticleTitle,
      serpArticleParsedHtml,
      index,
    } = event;

    // console.log(event, serpArticleUrl, serpArticleTitle);
    // set(serpArticleRef),
    setUrl(serpArticleUrl);
    setTitle(serpArticleTitle);
    setParsedHTML(serpArticleParsedHtml);
    setUrlPrefix(`Serp ${index + 1} - `);
    setSerpIndex(index);
    setCurrentArticle("serp");
  };

  const currentArticleStyle = (isActive) => {
    return {
      backgroundColor: isActive === true ? "#3EACCA" : "#EEE",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      color: isActive === true ? "white" : "black",
      boxShadow: isActive === true ? "2px 2px 2px rgba(0, 0, 0, 0.2)" : "",
      marginRight: "5px",
    };
  };

  return (
    <div style={leftRightContainerStyle}>
      {/* //right dropdown */}

      <div className="d-flex flex-row flex-row flex-wrap gap-1 p-2">
        {mainArticle && (
          <Button
            onClick={() => {
              setUrl(mainArticle?.url);
              setParsedHTML(mainArticle?.parsedHTML);
              setTitle(mainArticle?.title);
              setUrlPrefix("Main Article - ");
              setCurrentArticle("main");
              // setSerpArticle({});
              window.history.pushState(
                {},
                "",
                window.location.href.split("&serp")[0]
              );
            }}
            style={
              currentArticle === "main"
                ? currentArticleStyle(true)
                : currentArticleStyle(false)
            }
          >
            {currentArticle === "main" ? "Selected" : "Select"} Main Article
          </Button>
        )}
        {serpArticles.length > 0 && (
          <Dropdown onSelect={setCurrentSerpArticle}>
            <Dropdown.Toggle
              style={
                currentArticle === "serp"
                  ? currentArticleStyle(true)
                  : currentArticleStyle(false)
              }
              id="dropdown-basic"
            >
              {serpArticles?.[serpIndex]?.url
                ? serpArticles?.[serpIndex]?.url.substring(0, 30)
                : "Select Serp Article"}{" "}
              <i className="bi bi-caret-down-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {serpArticles?.map((serpObj, index) => (
                <Dropdown.Item
                  key={serpObj?._id}
                  eventKey={JSON.stringify({
                    serpArticleRef: serpObj?._id,
                    serpArticleUrl: serpObj?.url,
                    serpArticleTitle: serpObj?.title,
                    serpArticleParsedHtml: serpObj?.parsedHTML,
                    index,
                  })}
                  bsPrefix="custom-dropdown-item"
                  style={{
                    backgroundColor:
                      index === serpIndex && currentArticle === "serp"
                        ? "rgb(55 166 246 / 22%)"
                        : "",
                  }}
                >
                  {`${serpObj?.url} (${serpObj?.status})`}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <div className="m-3">
        {url && (
          <div>
            {urlPrefix}
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </div>
        )}
        <br />
        <br />
        {title && <h1>{title}</h1>}
        {parsedHTML && (
          <div
            className="parsed-html-container"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parsedHTML),
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

const GenerateArticleStatus = () => {
  const [mainArticle, setMainArticle] = useState(null);
  const [serpArticles, setSerpArticles] = useState([]);
  const [pc, setPc] = useState({});
  const [allPcTasks, setAllPcTasks] = useState([]);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [parsedHTML, setParsedHTML] = useState("");
  const [generatedArticle, setGeneratedArticle] = useState(null);
  const [mktgTaskOutputs, setMktgTaskOutputs] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);
  // const dispatch = useDispatch();

  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const pcInUrl = params.get("pc");
  const keyInUrl = params.get("key");
  const IdInUrl = params.get("id");
  const getArticlesPcAndAllPcTasks = async (pc, key, id) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/admin/get-article-pc-allpctasks/pc=${pc}&key=${key}&id=${id}`
      );
      setAllPcTasks(data.allPcTasks);
      setMainArticle(data.articles.mainArticle);
      setSerpArticles(data.articles.serpArticles);
      setPc(data.promptChain);
      setUrl(data.articles.mainArticle?.url);
      setTitle(data.articles.mainArticle?.title);
      setParsedHTML(data.articles.mainArticle?.parsedHTML);
      setGeneratedArticle(data.generatedArticle);
      // console.log(data);
      return data;
    } catch (error) {
      // const errorMsg = error.response?.data?.message || error.message;
      // dispatch(newAlert({ type: "danger", message: errorMsg }));
      setApiFailed(true);
    }
  };
  const getAllFailedMktgOutputTasks = async (pcData) => {
    const allPcTasks = pcData.allPcTasks;
    const failedPcTasks =
      allPcTasks?.filter((pcTask) => pcTask.status === "failed") || [];
    const failedPcTasksIds = failedPcTasks.map((task) => task._id);
    // console.log({ failedPcTasks });
    const { data } = await axiosInstance.post(
      `/api/admin/get-mktg-task-outputs-for-pctasks`,
      {
        pcTaskIds: failedPcTasksIds,
        selectFields: "dataOutput.rawResponse dataOutput.error dataInput._id",
      }
    );
    setMktgTaskOutputs(data?.mktgTaskOutputs || []);
    // console.log(data?.mktgTaskOutputs);
  };
  useEffect(() => {
    setTasksLoading(true);
    getArticlesPcAndAllPcTasks(pcInUrl, keyInUrl, IdInUrl)
      .then((pcData) => {
        if (pcData) {
          getAllFailedMktgOutputTasks(pcData).finally(() =>
            setTasksLoading(false)
          );
        }
      })
      .catch(() => setTasksLoading(false));
  }, []);

  return apiFailed ? (
    <PageNotFound />
  ) : (
    <div
      style={{
        display: "block",
        minHeight: "90vh",
        width: "98vw",
        height: "fit-content",
      }}
      className="m-2"
    >
      <SplitView
        left={
          <LeftSide
            mainArticle={mainArticle}
            setMainArticle={setMainArticle}
            serpArticles={serpArticles}
            pc={pc}
            allPcTasks={allPcTasks}
            generatedArticle={generatedArticle}
            tasksLoading={tasksLoading}
            mktgTaskOutputs={mktgTaskOutputs}
          />
        }
        right={
          <RightSide
            mainArticle={mainArticle}
            pc={pc}
            url={url}
            setUrl={setUrl}
            parsedHTML={parsedHTML}
            setParsedHTML={setParsedHTML}
            title={title}
            setTitle={setTitle}
            serpArticles={serpArticles}
          />
        }
      />
    </div>
  );
};

export default GenerateArticleStatus;
