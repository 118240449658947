import { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ContextBeforeQuestion from "../ContextBeforeQuestion/ContextBeforeQuestion";

const SelectedFilteredPointQuestions = ({
  selectedFilteredPoints,
  setSelectedFilteredPoints,
  questionsList,
  setQuestionsList,
  indexInHeadingQuestions,
  setCheckAllFPForResponses,
  checkAllFPForResponses,
}) => {
  const [peerOrChildToQuestion, setPeerOrChildToQuestion] = useState("");
  const [peerOrChild, setPeerOrChild] = useState("");
  const [relationToParent, setRelationToParent] = useState("");
  const [contextNeeded, setContextNeeded] = useState(false);
  const [contextBeforeQuestion, setContextBeforeQuestion] = useState("");
  const [questionTemplate, setQuestionTemplate] = useState("{{filteredPoint}}");
  const [questionAliasTemplate, setQuestionAliasTemplate] =
    useState("{{filteredPoint}}");
  const [useAliasTemplate, setUseAliasTemplate] = useState("");
  const [questionOptions, setQuestionOptions] = useState([]);
  const [fShow, setfShow] = useState(true);
  const FPquestions = selectedFilteredPoints
    ? selectedFilteredPoints.map((selectedFilteredPoint) => {
        const question = questionTemplate.replaceAll(
          "{{filteredPoint}}",
          selectedFilteredPoint.point
        );
        const questionAlias = useAliasTemplate
          ? questionAliasTemplate
              .trim()
              .replaceAll("{{filteredPoint}}", selectedFilteredPoint.point)
          : undefined;
        return { question, questionAlias };
      })
    : [];

  const headingQuestions = useSelector(
    (state) => state.chatGPTSlice.topic.headingQuestions
  );
  const questionsInTopic = useSelector((state) => state.chatGPTSlice.questions);
  useEffect(() => {
    const questionsArray = headingQuestions.filter(
      (q) => q.question !== undefined
    );
    const options = questionsArray.map((q) => {
      return {
        label: questionsInTopic[q.question].question,
        value: q.question,
      };
    });
    setQuestionOptions(options);
  }, [questionsInTopic]);

  const handlePeerOrChildChange = (event) => {
    setPeerOrChild(event.target.value);
    if (event.target.value === "child") {
      setRelationToParent("");
    }
  };

  const handleContextNeededChange = (event) => {
    setContextNeeded(event.target.value === "true");
  };

  const handleAddToListClick = () => {
    if (peerOrChildToQuestion !== "" && peerOrChild == "child") {
      const questions = FPquestions.map((FPQuestion) => ({
        question: FPQuestion.question,
        questionAlias: FPQuestion.questionAlias,
        peerOrChild,
        peerOrChildToQuestion,
        contextBeforeQuestion,
      }));
      const relationObject = {
        relation: relationToParent,
        questions,
      };
      setQuestionsList((prev) => [...prev, relationObject]);
    }
    if (peerOrChildToQuestion !== "" && peerOrChild == "peer") {
      const { relation, level } = headingQuestions.find(
        (question) => question.question == peerOrChildToQuestion
      );

      const questions = FPquestions.map((FPQuestion) => ({
        question: FPQuestion.question,
        questionAlias: FPQuestion.questionAlias,
        peerOrChild,
        peerOrChildToQuestion,
        relation,
        level,
        contextBeforeQuestion,
      }));
      const relationObject = {
        relation: "same as peer",
        questions,
      };
      setQuestionsList((prev) => [...prev, relationObject]);
    }
    if (peerOrChildToQuestion == "") {
      const questions = FPquestions.map((FPQuestion) => ({
        question: FPQuestion.question,
        questionAlias: FPQuestion.questionAlias,
        contextBeforeQuestion,
        idOfClickedObject:
          indexInHeadingQuestions !== -1
            ? headingQuestions[indexInHeadingQuestions]._id
            : -1,
      }));
      const relationObject = {
        relation: "",
        questions,
      };
      setQuestionsList((prev) => [...prev, relationObject]);
    }
    setSelectedFilteredPoints([]);
    setQuestionTemplate("{{filteredPoint}}");
    setQuestionAliasTemplate("{{filteredPoint}}");
    // setUseAliasTemplate("");
    setCheckAllFPForResponses([]);
  };

  return (
    <>
      <Form className="m-3">
        <Form.Group as={Row} controlId="peerOrChildToQuestion">
          <Form.Label row>Peer or Child to Question:</Form.Label>
          <Row sm="9">
            <Form.Control
              as="select"
              value={peerOrChildToQuestion}
              onChange={(event) => setPeerOrChildToQuestion(event.target.value)}
            >
              <option value="">Select an question...</option>
              {questionOptions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Row>
        </Form.Group>

        {peerOrChildToQuestion != "" && (
          <Form.Group as={Row} controlId="peerOrChild">
            <Form.Label inline="true" className="mr-1 " column sm="2">
              Peer or Child:
            </Form.Label>
            <Col sm="9" className="py-2">
              <Form.Check
                inline
                type="radio"
                label="Peer"
                name="peerOrChild"
                value="peer"
                checked={peerOrChild === "peer"}
                onChange={handlePeerOrChildChange}
              />
              <Form.Check
                inline
                type="radio"
                label="Child"
                name="peerOrChild"
                value="child"
                checked={peerOrChild === "child"}
                onChange={handlePeerOrChildChange}
              />
            </Col>
          </Form.Group>
        )}

        {peerOrChild === "child" && (
          <Form.Group as={Row} controlId="relationToParent">
            <Form.Label row>Relation to Parent:</Form.Label>
            <Row sm="9">
              <Form.Control
                type="text"
                value={relationToParent}
                onChange={(event) => setRelationToParent(event.target.value)}
              />
            </Row>
          </Form.Group>
        )}

        <Form.Group as={Row} controlId="contextNeeded">
          <Form.Label inline="true" className="mr-1 " column sm="2">
            Context Needed:
          </Form.Label>
          <Col sm="9" className="py-2">
            <Form.Check
              inline
              type="radio"
              label="Yes"
              name="contextNeeded"
              value="true"
              checked={contextNeeded}
              onChange={handleContextNeededChange}
            />
            <Form.Check
              inline
              type="radio"
              label="No"
              name="contextNeeded"
              value="false"
              checked={!contextNeeded}
              onChange={handleContextNeededChange}
            />
          </Col>
        </Form.Group>

        {/* {contextNeeded && (
          <Form.Group as={Row} controlId="contextBeforeQuestion">
            <Form.Label row>Context Before Question:</Form.Label>
            <Row sm="9">
              <Form.Control
                as="textarea"
                rows={3}
                value={contextBeforeQuestion}
                onChange={(event) =>
                  setContextBeforeQuestion(event.target.value)
                }
              />
            </Row>
          </Form.Group>
        )} */}
        {contextNeeded && (
          <ContextBeforeQuestion
            questionsInTopic={questionOptions}
            setContextBeforeQuestion={setContextBeforeQuestion}
          />
        )}
        <Form.Group
          as={Row}
          className="mb-2"
          md="4"
          controlId="validationCustom01"
        >
          <Form.Label>Question template</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder=""
            value={questionTemplate}
            spellCheck={false}
            onChange={(e) => setQuestionTemplate(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            question template is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Row} md="4" controlId="validationCustom01">
          <Form.Label>Question alias template </Form.Label>
          <Form.Control
            as="textarea"
            placeholder=""
            value={useAliasTemplate ? questionAliasTemplate : ""}
            spellCheck={false}
            onChange={(e) => setQuestionAliasTemplate(e.target.value)}
            disabled={!useAliasTemplate}
            style={{ height: "50px" }}
          />
          <Form.Check
            type="checkbox"
            id={`useQuestionAliasTemplate`}
            label={`set alias`}
            className="mt-1"
            checked={useAliasTemplate}
            onChange={(e) => setUseAliasTemplate((prev) => !prev)}
          />
          <Form.Control.Feedback type="invalid">
            question alias template is required
          </Form.Control.Feedback>
        </Form.Group>

        <div className="mt-3 row" onClick={() => setfShow((prev) => !prev)}>
          <h5
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center ps-0"
          >
            selected filtered points questions
            <span
              className="text-secondary fw-light text-decoration-underline ms-2"
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {fShow ? (
                <i className="bi bi-chevron-up"></i>
              ) : (
                <i className="bi bi-chevron-down"></i>
              )}
            </span>
          </h5>
        </div>
        {fShow ? (
          FPquestions?.length > 0 ? (
            FPquestions.map((childQuestion, j) => {
              return (
                <ul className="mb-2 row" key={j}>
                  <li className="p-0 m-0 fw-normal">
                    {childQuestion.question}
                  </li>
                  {useAliasTemplate && (
                    <small className="text-secondary ps-0">
                      alias: {childQuestion.questionAlias}
                    </small>
                  )}
                </ul>
              );
            })
          ) : (
            <p className="text-danger"></p>
          )
        ) : null}

        <Form.Group as={Row}>
          <Row sm={{ offset: 3 }}>
            <Button
              className="w-25 my-2"
              style={{
                backgroundColor: "#3FACCB",
                border: "none",
              }}
              onClick={handleAddToListClick}
              disabled={
                !FPquestions?.length > 0 ||
                (peerOrChildToQuestion != "" && peerOrChild == "") ||
                !questionTemplate.trim() ||
                (useAliasTemplate === true && !questionAliasTemplate.trim())
              }
            >
              Add to List
            </Button>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
};

export default SelectedFilteredPointQuestions;
