import React, { useEffect, useRef, useState } from "react";
import { Container, Form, Button, Card, InputGroup } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import "./EditPost.css";
import {
  checkForH1Heading,
  convertGeneratedArticlesToHTML,
  extractImageSrc,
  getHeadings,
  hasPendingLinks,
  hasTextContent,
  modifyHtml,
  replaceImageSrc,
} from "../utilities";
import ErrorBoundary from "./ErrorBoundary";
import SelectAuthor from "./SubComponents/SelectAuthor";
import ThumbnailUploader from "./SubComponents/ThumbnailUploader";
import PostOutline from "./SubComponents/PostOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArticleIssues from "./SubComponents/ArticleIssues";
import MarkReviewArticle from "./SubComponents/MarkReviewArticle";
import BlogTextEditor from "../../BlogTextEditor/BlogTextEditor";

export const EditPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get("postId");
  const [postContent, setPostContent] = useState("");
  const [contentStatus, setContentStatus] = useState({
    saved: true,
    lastContentState: null,
    pendingLinks: false,
  });
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const [workingPost, setWorkingPost] = useState("");
  const [savingPostData, setSavingPostData] = useState(false);
  const [tagGroups, setTagGroups] = useState();
  const [tagOptions, setTagOptions] = useState([]);
  const [author, setAuthor] = useState();
  const [authorOptions, setAuthorOptions] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [newTitleName, setNewTitleName] = useState();
  const [thumbnailImg, setThumbnailImg] = useState("");
  const [outline, setOutline] = useState([]);
  const [issues, setIssues] = useState();
  const [generatedArticle, setGeneratedArticle] = useState("");
  const [attractiveTitle, setAttractiveTitle] = useState("");
  const [attractiveSubtitle, setAttractiveSubtitle] = useState("");
  const [savedTitles, setSavedTitles] = useState({
    attractiveTitle: "",
    attractiveSubtitle: "",
  });
  const [mainArticle, setMainArticle] = useState("");
  const [promptChain, setPromptChain] = useState("");
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [askConfirmation, setaskConfirmation] = useState({
    active: false,
    title: false,
    heading: "",
    onClick: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [showOutline, setShowOutline] = useState(false);
  const [hasH1Heading, setHasH1Heading] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [isFinalized, setIsFinalized] = useState(false);
  const [articleLabel, setArticleLabel] = useState({
    personalExperience: false,
    promotionalArticle: false,
    inAppropriate: false,
  });
  const userData = useSelector((state) => state.sessionSlice.sessionUser.user);
  const dispatch = useDispatch();
  let timeoutId;

  const getExistingTitles = async (titleName) => {
    try {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        const { data } = await axiosInstance.get(
          `/api/admin/get-existing-title-by-title-name/${titleName}`
        );
        // console.log('matchedPost: ',data.matchedPost);
        const options = await data.matchedPost.map((doc) => {
          return {
            value: doc.title,
            label: doc.title,
            id: doc._id,
            type: doc.type,
            promptChain: doc.promptChain,
            updatedAt: doc.updatedAt,
          };
        });
        options.push({
          value: `Create new Title:${titleName}`,
          label: `Create new Title:${titleName}`,
          // id: "abc",
          // type: "create",
        });
        setOutline(getHeadings(postContent));
        setTitleOptions(options);
        // console.log("Got title; ", options);
      }, 1000);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const showH1Warning = async () => {
    dispatch(newAlert({ type: "warning", message: "Contains h1 heading!" }));
  };
  const updatePostContent = (updatedPost) => {
    try {
      // const contentState = stateFromHTML(updatedPost);
      // const editorState = EditorState.createWithContent(contentState);
      // setEditorState({ editorState });
      setPostContent(updatedPost);
      const containsH1Heading = checkForH1Heading(updatedPost);
      if (containsH1Heading && !hasH1Heading) {
        showH1Warning();
      }
      setHasH1Heading(containsH1Heading);
      // const pendingLinks = hasPendingLinks(updatedPost);
      setContentStatus({ ...contentStatus, pendingLinks: false });
    } catch (err) {
      console.log("Failed to update content! Error: ", err);
    }
  };

  const createNewTopic = async (newTitleName) => {
    try {
      console.log("title: ", newTitleName);
      const { data } = await axiosInstance.post(`/api/admin/create-new-post`, {
        newTitle: newTitleName,
      });
      console.log(data);

      if (!checkTitleExists(titleOptions, data.createdPost._id)) {
        getExistingTitles(data.createdPost.title);
      }
      setSelectedTitle({
        value: data.createdPost.title,
        label: data.createdPost.title,
        id: data.createdPost._id,
      });
      setWorkingPost(data.createdPost._id);

      setaskConfirmation({
        active: false,
        title: "",
        heading: "",
        onClick: null,
      });
      setTagGroups([]);
      updatePostContent("");
      setNewTitleName("");
      setAuthor("");
      setIssues({});
      setArticleLabel({
        personalExperience: false,
        promotionalArticle: false,
        inAppropriate: false,
      });
      setAttractiveTitle("");
      setAttractiveSubtitle("");
      setSavedTitles({
        attractiveTitle: "",
        attractiveSubtitle: "",
      });
      navigate(
        `/admin/create-post/edit${
          data.createdPost._id ? `?postId=${data.createdPost._id}` : ""
        }`
      );
      dispatch(newAlert({ type: "success", message: "New topic created!" }));
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const getOptionTags = () => {
    axiosInstance
      .get(`/api/admin/get-available-tags-groups`)
      .then(({ data }) => {
        const tagOptions = data.tagGroups.map((item) => {
          return {
            value: item.tagGroupName,
            label: item.tagGroupName,
            id: item._id,
          };
        });
        // console.log("tags: ",tagOptions);
        setTagOptions(tagOptions);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const validPostDetails = () => {
    let error = "";
    if (!workingPost) {
      error = "Please select a post to save";
    } else if (tagGroups.length === 0) {
      error = "Please select tags before saving";
    } else if (!thumbnailImg) {
      error = "Please select thumbnail image before saving";
    } else if (!author) {
      error = "select author before saving";
    } else if (!hasTextContent(postContent)) {
      error = "Post cannot be empty!";
    } else if (!userData?._id) {
      error = "Invalid userId for lastSavedBy";
    } else if (getHeadings(postContent).length === 0) {
      error = "Outline cannot be empty";
    }
    // console.log("outline: ", outline, outline.length);
    // console.log("postContent: ", postContent, hasTextContent(postContent));

    if (error) {
      dispatch(newAlert({ type: "danger", message: error }));
      return false;
    }
    return true;
  };

  const postSavePublish = async (fPublish, publishDate, showAlerts = true) => {
    try {
      const outline = getHeadings(postContent);
      setOutline(getHeadings(postContent));
      if (savingPostData) {
        setShowModal(false);
        if (showAlerts)
          dispatch(
            newAlert({ type: "warning", message: "Post saving in progress!" })
          );
        return;
      }

      if (isFinalized && showAlerts) {
        if (!validPostDetails()) {
          return;
        }
      }
      if (showAlerts) setSavingPostData(true);
      // console.log("workingPost: ", workingPost, postContent);
      if (workingPost && postContent) {
        const tagsReferences = tagGroups.map((tag) => {
          return tag.id;
        });
        let newPostContent = postContent;
        if (showAlerts) {
          newPostContent = await getImagesS3Url();
          newPostContent = modifyHtml(newPostContent);
          // console.log("outline:", outline);
        }
        // console.log("userId: ", userData._id);
        const { data } = await axiosInstance.post(
          `/api/admin/save-post-details`,
          {
            postId: workingPost,
            postContent: newPostContent,
            postTags: tagsReferences,
            fPublish,
            publishDate,
            author: author?.id,
            attractiveTitle: attractiveTitle,
            representativeTitle: attractiveSubtitle,
            lastSavedBy: userData?._id,
            outline,
            fFinalized: isFinalized,
          }
        );
        setLastSavedAt(Date.parse(data.response.updatedAt));
        setSavedTitles({ attractiveTitle, attractiveSubtitle });
        if (showAlerts) {
          setContentStatus({ ...contentStatus, pendingLinks: false });
          if (!fPublish) {
            dispatch(
              newAlert({ type: "success", message: "Post saved successfully!" })
            );
          } else {
            dispatch(
              newAlert({
                type: "success",
                message: "Post saved and publish scheduled!",
              })
            );
            navigate(
              `/admin/create-post/preview${
                workingPost ? `?postId=${workingPost}` : ""
              }`
            );
          }
        }
      } else {
        if (!workingPost) {
          console.log("select a post first");

          if (showAlerts)
            dispatch(
              newAlert({ type: "danger", message: "Select a post Title!" })
            );
        } else {
          console.log("post content should not be empty!");

          if (showAlerts)
            dispatch(
              newAlert({
                type: "danger",
                message: "Post content should not be empty!",
              })
            );
        }
      }
    } catch (err) {
      console.log("Error: ", err);
      if (showAlerts)
        dispatch(
          newAlert({ type: "danger", message: "Failed to update post!" })
        );
    } finally {
      setSavingPostData(false);
    }
  };
  function checkTitleExists(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        return true;
      }
    }
    return false;
  }

  function getISTISOString() {
    const now = new Date();
    const offsetIST = 5.5 * 60; // IST offset is UTC+5:30

    const offsetMilliseconds = offsetIST * 60 * 1000;
    const istTime = new Date(now.getTime() + offsetMilliseconds);

    return istTime.toISOString().replace("Z", "");
  }
  const handleSaveAndPublish = () => {
    setDateTime(getISTISOString().slice(0, 16));
    if (!validPostDetails()) return;
    if (workingPost) setShowModal(true);
    else
      dispatch(newAlert({ type: "danger", message: "Select a post Title!" }));

    // console.log(showModal);
  };

  const getImagesS3Url = async () => {
    try {
      if (workingPost) {
        const imagesData = await extractImageSrc(postContent, workingPost);
        if (imagesData.length > 0) {
          const { data } = await axiosInstance.post(
            `/api/admin/get-s3ImageUrl`,
            { imagesData }
          );
          const { s3ImageUrls } = data;
          const newPostContent = replaceImageSrc(postContent, s3ImageUrls);
          updatePostContent(newPostContent);
          return newPostContent;
        } else {
          return postContent;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAuthorAvatarsForTagGroup = async (choice) => {
    setTagGroups(choice);
    const tagGroupId = choice[0]?.id;
    if (tagGroupId) {
      const { data } = await axiosInstance.get(
        `/api/admin/get-author-avatars-for-tagGroup/${tagGroupId}`
      );

      const authorOpt = data.authors.map((author) => {
        return {
          label: author.name,
          value: author.name,
          id: author._id,
          totalArticles: author.totalArticles,
        };
      });
      setAuthorOptions(authorOpt);
      setAuthor(authorOpt[0]);
      return authorOpt;
    } else {
      console.log("no tagGroupId to look for author avatar");
      return [];
    }
  };

  const getSelectedPost = async (articleId, choice) => {
    const type = choice.type;
    try {
      setIsPostLoading(true);
      if (type === "preview") {
        console.log("navigate to preview");
        navigate(
          `/admin/create-post/preview${articleId ? `?postId=${articleId}` : ""}`
        );
        return;
      }
      const { data } = await axiosInstance.get(
        `/api/admin/get-selected-post-details/${articleId}/${type}`
      );
      const postContents = data.selectedPost.post ? data.selectedPost.post : "";
      const tagGroups = data.selectedPost.tagGroup.map((item) => {
        return {
          label: item.tagGroupName,
          value: item.tagGroupName,
          id: item._id,
        };
      });
      const {
        issues,
        generatedArticle,
        mainArticle,
        promptChain,
        attractiveTitle,
        representativeTitle,
      } = data.selectedPost || {};
      setIssues(issues);
      setGeneratedArticle(generatedArticle);
      setMainArticle(mainArticle);
      setPromptChain(promptChain);
      setAttractiveTitle(attractiveTitle);
      setAttractiveSubtitle(representativeTitle);
      setSavedTitles({
        attractiveTitle: attractiveTitle,
        attractiveSubtitle: representativeTitle,
      });
      const personalExperience =
        data.selectedPost?.markedAs?.personalExperience?.fPersonalExperience;
      const promotionalArticle =
        data.selectedPost?.markedAs?.promotionalArticle?.fPromotionalArticle;
      const inAppropriate =
        data.selectedPost?.markedAs?.inAppropriate?.fInAppropriate;
      setArticleLabel({
        personalExperience,
        promotionalArticle,
        inAppropriate,
      });
      navigate(
        `/admin/create-post/edit${
          data.selectedPost._id ? `?postId=${data.selectedPost._id}` : ""
        }`
      );

      const authorId = data.selectedPost.author;
      if (authorId && data.selectedPost.tagGroup.length > 0) {
        const authorOpt = await getAuthorAvatarsForTagGroup(tagGroups);
        if (authorOpt) {
          const selectedAuthor = authorOpt.find((a) => a.id === authorId);
          setAuthor(selectedAuthor);
        }
      } else {
        setTagGroups([]);
        setAuthor(null);
      }

      setSelectedTitle({
        value: data.selectedPost.title,
        label: data.selectedPost.title,
        id: data.selectedPost._id,
      });
      setWorkingPost(data.selectedPost._id);
      setThumbnailImg(data.selectedPost.thumbnail);

      if (type === "import") {
        updatePostContent(postContents);
        getExistingTitles(choice.value);
        const postData = await axiosInstance.post(
          `/api/admin/save-post-details`,
          {
            postId: data.selectedPost._id,
            postContent: postContent,
            postTags: [],
            fPublish: false,
            lastSavedBy: userData?._id,
            outline,
          }
        );
      } else {
        updatePostContent(postContents);
        setLastSavedAt(Date.parse(data.selectedPost.updatedAt));
      }
    } catch (err) {
      console.log(err);
      dispatch(newAlert({ type: "danger", message: "Failed to load post!" }));
    } finally {
      setIsPostLoading(false);
    }
  };

  const handleModalSave = () => {
    if (!dateTime) {
      dispatch(
        newAlert({ type: "danger", message: "Invalid date time given!" })
      );
      return;
    }
    const istDateTime = new Date(dateTime);
    const utcDateTime = istDateTime.toISOString();
    console.log("IST DateTime:", istDateTime);
    console.log("UTC DateTime:", utcDateTime);
    postSavePublish(true, istDateTime);
    setShowModal(false);
  };

  const ImageLinksStatus = (element, tooltiptext) => {
    return (
      <span className="show-container">
        <span className={`post-tooltip ${isFixed ? "fixed" : ""}`}>
          <span className="showtooltip m-1">
            {element}
            <span
              className={`showtooltiptext ${
                !contentStatus.pendingLinks ? "text-success" : "text-warning"
              }`}
            >
              {tooltiptext}
            </span>
          </span>
        </span>
      </span>
    );
  };
  const [isFixed, setIsFixed] = useState(false);
  const postComponent = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const rect = postComponent.current?.getBoundingClientRect();
      if (rect) setIsFixed(rect.top <= 0 && rect.top + rect.height >= 0);
    };
    const debouncedHandleScroll = debounce(handleScroll, 40); // Adjust the debounce delay as needed

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const handlePostCancel = () => {
    setSelectedTitle([]);
    setWorkingPost("");
    setShowModal(false);
    setTagGroups([]);
    updatePostContent("");
    navigate(`/admin/create-post`);
  };
  const handleRegenerateArticle = () => {
    const regenerateArticleForPCRemovePost = async () => {
      // console.log("Regenerate article for PC");
      if (!mainArticle || !promptChain) {
        dispatch(
          newAlert({
            type: "warning",
            message:
              "Cannot regenerate post, missing mainArticle or promptChain!",
          })
        );
      } else {
        const { data } = await axiosInstance.post(
          `/api/admin/regenerate-article-for-pc-remove-post`,
          {
            mainArticle,
            promptChain,
            postId: workingPost,
            userId: userData?._id,
          }
        );
        // console.log({ data });
        if (data.status === "success") {
          dispatch(
            newAlert({
              type: "success",
              message: "Article generation restarted successfully!",
            })
          );
          navigate(`/admin/create-post`);
        }
      }
    };
    setaskConfirmation({
      active: true,
      title: `You will not be able to access create-post interface for this article till article is successfully regenerated`,
      heading: "Are you sure you want to regenerate this Article?",
      onClick: regenerateArticleForPCRemovePost,
    });
  };

  useEffect(() => {
    getOptionTags();
    if (postId) {
      getSelectedPost(postId, { type: "edit" });
    }
    setTimeout(() => {
      window.location = "#edit-post-header";
    }, 500);
  }, []);

  return (
    <>
      <Container
        style={{ marginTop: "30px", marginBottom: "80px", minHeight: "70vh" }}
      >
        <div className="d-flex" id="edit-post-header">
          <h2>Edit Post</h2>
          <button
            className="ms-auto add-post"
            onClick={() => {
              handlePostCancel();
            }}
          >
            <i className="fa-solid fa-plus"></i> New Post
          </button>
        </div>
        {askConfirmation.active && (
          <div className="">
            <div className="modal-background"></div>
            <div
              className="bg-light border rounded position-fixed top-50 start-50 translate-middle zindex-modal "
              style={{
                width: "70%",
                zIndex: 20,
                boxShadow: "1px 1px 6px gray",
              }}
            >
              <h4 className="text-center m-4">{askConfirmation.heading}</h4>
              <h6 className="text-center">
                {askConfirmation.title && <span>{askConfirmation.title}</span>}
              </h6>
              <div className="d-flex w-100 justify-content-center">
                <button
                  className="btn btn-success mx-4 my-2"
                  onClick={async () => {
                    try {
                      await askConfirmation.onClick();
                    } catch (error) {
                      const errMsg =
                        error.response?.data?.message || error.message;
                      // console.error(errMsg);
                      dispatch(
                        newAlert({
                          type: "danger",
                          message: `Error: ${errMsg}`,
                        })
                      );
                    } finally {
                      setaskConfirmation({
                        active: false,
                        title: "",
                        heading: "",
                        onClick: null,
                      });
                    }
                  }}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger mx-4 my-2"
                  onClick={() => {
                    setaskConfirmation({
                      active: false,
                      title: "",
                      heading: "",
                      onClick: null,
                    });
                    setNewTitleName("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <Form>
          <div className="parent-container">
            <div className="title-container">
              <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
                Title
              </div>
              <Select
                closeMenuOnSelect={true}
                placeholder={<div>Enter Title</div>}
                value={selectedTitle}
                onChange={async (choice) => {
                  try {
                    setSelectedTitle(choice);
                    //todo:  redirect to topic page
                    if (choice.value.startsWith("Create new Title:")) {
                      const newTitleNameEntered =
                        choice.label.split("Create new Title:")[1];
                      // console.log("newTitleNameEntered: ",newTitleNameEntered)
                      setNewTitleName(newTitleNameEntered);
                      const { data } = await axiosInstance.get(
                        `/api/admin/check-unique-title/${newTitleNameEntered}`
                      );
                      const { isPresent, titleId, type } = data;
                      if (!isPresent) {
                        setaskConfirmation({
                          active: true,
                          title: `New title Name: ${newTitleNameEntered}`,
                          heading: "Are you sure you want to create new Post?",
                          onClick: () => createNewTopic(newTitleNameEntered),
                        });
                      } else {
                        const getPost = async () => {
                          choice.type = type;
                          await getSelectedPost(titleId, choice);
                        };
                        setaskConfirmation({
                          active: true,
                          title: `New title Name: ${newTitleNameEntered}`,
                          heading:
                            "This Post already exists! do you want to open this post?",
                          onClick: getPost,
                        });
                      }
                    } else {
                      // console.log("postId: ", choice)
                      await getSelectedPost(choice.id, choice);
                    }
                  } catch (err) {
                    console.log("Error: ", err);
                  }
                }}
                onInputChange={(input) => {
                  if (input) getExistingTitles(input);
                }}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    width: "100%",
                  }),
                }}
                noOptionsMessage={() => "Start typing any title name"}
                options={titleOptions}
                getOptionLabel={(option) => {
                  return (
                    <div>
                      <div>
                        {`${option.label} ${
                          option?.type ? `(${option.type})` : ""
                        }`}
                      </div>
                      <div
                        className="d-flex justify-content-between text-muted"
                        style={{ fontSize: "11px" }}
                      >
                        <span>{`${option.promptChain || ""}`}</span>
                        <span>{`${option.updatedAt || ""}`}</span>
                      </div>
                    </div>
                  );
                }}
              />
              {isPostLoading && (
                <div
                  className="spinner-border text-primary title-load-spinner"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>

            {workingPost && (
              <div>
                <div className="attractive-title-container">
                  <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
                    Attractive Title
                  </div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter title"
                      aria-label="Attractive Title"
                      aria-describedby="basic-addon2"
                      value={attractiveTitle}
                      onChange={(e) => setAttractiveTitle(e.target.value)}
                    />
                    {attractiveTitle !== savedTitles.attractiveTitle && (
                      <InputGroup.Text
                        style={{
                          background: "none",
                          borderLeft: "2px solid white",
                          zIndex: "11",
                        }}
                        data-tooltip="not saved yet"
                        data-tooltip-location="bottom"
                      >
                        <i className="text-warning fa-solid fa-circle-exclamation"></i>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </div>

                <div className="attractive-subtitle-container">
                  <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
                    Representative Title
                  </div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter representative title"
                      aria-label="Attractive Subtitle"
                      aria-describedby="basic-addon2"
                      value={attractiveSubtitle}
                      onChange={(e) => setAttractiveSubtitle(e.target.value)}
                    />
                    {attractiveSubtitle !== savedTitles.attractiveSubtitle && (
                      <InputGroup.Text
                        style={{
                          background: "none",
                          borderLeft: "2px solid white",
                        }}
                        data-tooltip="not saved yet"
                        data-tooltip-location="bottom"
                      >
                        <i className="text-warning fa-solid fa-circle-exclamation"></i>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </div>

                <div className="tags-container">
                  <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
                    TagGroup
                  </div>
                  <Select
                    value={tagGroups}
                    placeholder="Select tags"
                    name="colors"
                    options={tagOptions}
                    onChange={(choice) => {
                      getAuthorAvatarsForTagGroup([choice]);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    noOptionsMessage={() => "no such tag available"}
                  />
                </div>

                <ThumbnailUploader
                  workingPost={workingPost}
                  thumbnailImg={thumbnailImg}
                  setThumbnailImg={setThumbnailImg}
                />

                <SelectAuthor
                  author={author}
                  setAuthor={setAuthor}
                  authorOptions={authorOptions}
                  setAuthorOptions={setAuthorOptions}
                />

                <ArticleIssues data={issues || {}} />
                <MarkReviewArticle
                  generatedArticle={generatedArticle}
                  mainArticle={mainArticle}
                  promptChain={promptChain}
                  articleLabel={articleLabel}
                  setArticleLabel={setArticleLabel}
                  userData={userData}
                />
                <ErrorBoundary>
                  <div ref={postComponent} className="editor-container">
                    <BlogTextEditor
                      data={postContent}
                      onChange={(content) => {
                        setPostContent(content);
                      }}
                      placeholder={"Write post content here"}
                      contentStatus={contentStatus}
                      setContentStatus={setContentStatus}
                      lastSavedAt={lastSavedAt}
                      fShowEditor={workingPost}
                      onAutoSave={async () =>
                        await postSavePublish(false, null, false)
                      }
                      hasH1Heading={hasH1Heading}
                      setHasH1Heading={setHasH1Heading}
                      showH1Warning={showH1Warning}
                    />
                  </div>
                </ErrorBoundary>

                <div className="">
                  <button
                    type="button"
                    className="mt-2 post-outline-btn"
                    onClick={() => {
                      setShowOutline(!showOutline);
                    }}
                  >
                    {showOutline ? (
                      <>
                        Hide outline{" "}
                        <i className="fa-solid fa-chevron-up ms-1"></i>
                      </>
                    ) : (
                      <>
                        Show outline
                        <i className="fa-solid fa-chevron-down ms-1"></i>
                      </>
                    )}
                  </button>
                </div>
                {postContent && (
                  <PostOutline
                    htmlString={postContent}
                    setOutline={setOutline}
                    showOutline={showOutline}
                    updateOutline={{
                      saved: contentStatus.saved,
                      selectedTitle,
                    }}
                  />
                )}

                <div className="d-flex">
                  <div className="form-check ms-auto mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={isFinalized}
                      onChange={(event) => {
                        setIsFinalized(event.target.checked);
                      }}
                      id="finalize-post"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Finalize post
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2">
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#E9244A",
                        border: "none",
                        height: "fit-content",
                      }}
                      type="button"
                      className="mt-2 me-3"
                      onClick={handlePostCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#0086ff",
                        border: "none",
                        height: "fit-content",
                      }}
                      type="button"
                      className="mt-2"
                      onClick={handleRegenerateArticle}
                    >
                      Regenerate Article
                    </Button>
                  </div>
                  <div>
                    {savingPostData && (
                      <div
                        className="spinner-border text-primary me-3"
                        style={{ marginBottom: "-12px" }}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    <Button
                      style={{ backgroundColor: "#0099FF", border: "none" }}
                      type="button"
                      className="mt-2"
                      onClick={() => postSavePublish(false, null, true)}
                    >
                      Save Post
                    </Button>
                    <Button
                      style={{ backgroundColor: "#0099FF", border: "none" }}
                      type="button"
                      className="mt-2 ms-2"
                      onClick={handleSaveAndPublish}
                    >
                      Save and Publish
                    </Button>

                    {showModal && (
                      <Card className="mt-2">
                        <Card.Header>Enter Publish Date and Time.</Card.Header>
                        <Card.Body>
                          <input
                            type="datetime-local"
                            value={dateTime}
                            className="local-datetime"
                            onChange={(e) => setDateTime(e.target.value)}
                          />
                        </Card.Body>
                        <Card.Footer>
                          <div className="d-flex justify-content-between">
                            <Button
                              variant="secondary"
                              onClick={() => setShowModal(false)}
                            >
                              Cancel
                            </Button>
                            <Button variant="primary" onClick={handleModalSave}>
                              Save
                            </Button>
                          </div>
                        </Card.Footer>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form>
      </Container>
    </>
  );
};
