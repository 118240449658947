import React from "react";
import styles from './Newsletter.module.css'

const Newsletter = () => {
  return (
    <div className="newsletter-section py-4 pb-5 px-sm-3">
      <div className="container">
        <div className={`${styles['newsletter-card']} shadow border rounded p-5 text-center d-flex flex-column align-items-center`}>
          <h3 className="fs-2 fw-bold">Join our newsletter</h3>
          <p className="w-75 text-center mb-3">
            Subscribe our newsletter to stay updated every moment
          </p>
          <input
            type="text"
            className={`${styles['enter-your-email']} form-control mb-3`}
            placeholder="Enter your email address"
            aria-label="Enter your email address"
            aria-describedby="button-addon2"
          />
          <button className={`${styles['submit-email']} text-uppercase border-0 px-5 py-3 rounded shadow`}>
            sign up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
