import React, { useState, useEffect } from "react";
import { Link, Navigate, useSearchParams,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GoogleLogo from "../../assets/images/google_logo.svg";
import FacebookLogo from "../../assets/images/facebook_logo.svg";
import LinkedinLogo from "../../assets/images/linkedin_logo.svg";
import {
  signIn,
  setError,
  changeEmail,
  setLoading,
  setFRedirectUserToVerifyEmailMessagePage
} from "../../slices/sessionSlice";
import styles from "./SignIn.module.css";

import Axios from "../../axios";
import Comp_GuestUserConversionModal from "../../components/GuestUserConversionModal/Comp_GuestUserConversionModal";
import { newAlert } from "../../slices/alertSlice";
const { getBackendDomain } = require("../../helpers/stubs.js");

const SignIn = ({ fApplicantPage }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalstate, setmodalstate] = useState("otpModal");
  const backEndURL = getBackendDomain();

  const [searchParams] = useSearchParams();
  const {
    error: signInError,
    sessionUser,
    loading,
    fRedirectUserToVerifyEmailMessagePage
  } = useSelector((state) => state.sessionSlice);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [pendingUser, setPendingUser] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let email = searchParams.get("email");
  // console.log({ email });

  useEffect(() => {
    if (fApplicantPage && email) {
      setFormField("email", email);
    }
  }, []);

  useEffect(() => {
    if (fRedirectUserToVerifyEmailMessagePage) {
      dispatch(setFRedirectUserToVerifyEmailMessagePage(false));
      navigate("/verify-email-message")
    }
  }, [fRedirectUserToVerifyEmailMessagePage]);

  useEffect(() => {
    if (isSubmitted) {
      const errors = validateForm(formValues);
      setFormErrors(errors);
    }
    const error = searchParams.get("error");
    // console.log("error", error);
    if (error) {
      let errorMessage = "Google Verification Failed";
      if (error.includes("facebook"))
        errorMessage = "Facebook Verification Failed";
      if (error.includes("linkedin"))
        errorMessage = "LinkedIn Verification Failed";
      dispatch(setError(errorMessage));
    }
  }, [formValues, isSubmitted]);

  const setFormField = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormField(name, value);
  };

  const handlePasswordVisibleToggle = () => {
    setPasswordVisible((previous) => !previous);
  };

  const validateForm = (formValues) => {
    const errors = {};
    const { email, password } = formValues;

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      errors.email = "Please enter a valid email";
    }
    if (!email) {
      errors.email = "Please enter your email";
    }
    if (!password) {
      errors.password = "Please enter your password";
    }

    return errors;
  };

  const handleFormSubmit = async (e) => {
    console.log("Submit");
    if (formValues.password.length < 6) {
      dispatch(
        newAlert({
          type: "warning",
          message: "Password should contain atleast 6 characters",
        })
      );
      return;
    }
    dispatch(setLoading(true));
    e.preventDefault();
    setIsSubmitted(true);
    const errors = validateForm(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      dispatch(setLoading(false));
      return;
    }
    dispatch(signIn(formValues));
  };

  const decideSignInForEmail = async () => {
    const reqBody = { email: formValues.email };
    const { data } = await Axios.post(
      "/api/public/check-email-status",
      reqBody
    );
    if (data.fPendingUserExist) {
      setPendingUser({
        _id: data.pendingUser,
        email: formValues.email,
      });
      setModalShow(true);
    }
  };

  // redirect on successful sign-in
  if (sessionUser?.user) {
    let redirectTo = searchParams.get("ref") || "/";
    if (fApplicantPage) {
      redirectTo = `/user/profile-page/${sessionUser.user?._id}/otp/${sessionUser.user?.naukriApplicantLoginOTP}`;
    }
    return <Navigate to={redirectTo} replace />;
  }

  return (
    <>
      {/* //#region otp modal */}
      {/* <Comp_GuestUserConversionModal
        show={modalShow}
        modalstate={modalstate}
        setmodalstate={setmodalstate}
        onHide={() => {
          setModalShow(false);
        }}
        pendingUser={pendingUser}
      /> */}
      {/* //#endregion */}

      <div className="signin p-5 shadow border rounded">
        <i className={`${styles["fa-bolt"]} fa-bolt fa-solid mb-3 fs-2`} />
        <h2 className="fw-bold">Sign In</h2>
        <p className="mb-4">
          Build marketing strategies by knowing your competitors better
        </p>
        {!fApplicantPage && (
          <div className="socials-sign d-flex flex-column mb-2 flex-lg-row justify-content-center text-center">
            <a
              className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`}
              href={`${backEndURL}/api/session/sign-in/google?redirectTo=/`}
            >
              <img
                src={GoogleLogo}
                className={`${styles["social-sign-logo"]}`}
                alt="google logo"
              />
              <span className="ms-2">Sign in with Google</span>
            </a>
            {/* <a
              className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`}
              href={`${backEndURL}/api/session/sign-in/facebook?redirectTo=/`}
            >
              <img
                src={FacebookLogo}
                className={`${styles["social-sign-logo"]}`}
                alt="google logo"
              />
              <span className="ms-2">Sign in with Facebook</span>
            </a>
            <a
              className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`}
              href={`${backEndURL}/api/session/sign-in/linked-in?redirectTo=/`}
            >
              <img
                src={LinkedinLogo}
                className={`${styles["social-sign-logo"]}`}
                alt="google logo"
              />
              <span className="ms-2">Sign in with Linkedin</span>
            </a> */}
          </div>
        )}
        <div className={`${styles["strike"]} mb-3`}>
          <span className={`${styles["strike-span"]}`}>
            {fApplicantPage ? "" : "or"} Sign In with email
          </span>
        </div>
        <form onSubmit={handleFormSubmit} noValidate>
          {signInError ? (
            <p
              className={`${styles["formError"]} font-semibold p-3 px-3 m-0 bg-danger text-white mb-3 rounded`}
            >
              <i className="fa-solid fa-circle-exclamation pe-2"></i>
              {signInError}
            </p>
          ) : null}

          <div className="mb-3">
            <label htmlFor="email" className="form-label font-semibold">
              Enter your email
            </label>
            <input
              type="email"
              className={`${styles["formInput"]} form-control ${
                formErrors.email ? "is-invalid" : ""
              }`}
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              onBlur={decideSignInForEmail}
            />
            <div className="invalid-feedback"> {formErrors.email} </div>
          </div>
          <div className="mb-3 position-relative">
            <label htmlFor="password" className="form-label font-semibold">
              Password
            </label>
            <input
              type={passwordVisible ? "text" : "password"}
              className={`${styles["formInput"]} form-control ${
                formErrors.password ? "is-invalid" : ""
              }`}
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <i
              className={`${styles["passwordVisible"]} fa-solid ${
                passwordVisible ? "fa-eye-slash" : "fa-eye"
              } position-absolute`}
              onClick={handlePasswordVisibleToggle}
            ></i>
            <div className="invalid-feedback">{formErrors.password}</div>
            <Link
              to={
                fApplicantPage
                  ? "/applicant/forgot-password"
                  : "/forgot-password"
              }
              className={`${styles["forgot-password"]} d-block text-end text-decoration-none mt-2`}
            >
              Forgot password?
            </Link>
          </div>

          <button
            type="submit"
            className={`${styles["sign-button"]} p-3 btn btn-primary w-100 shadow-sm mb-2 text-uppercase`}
            disabled={loading} // Disable the button when loading
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-lg" // Increase spinner size
                role="status"
                aria-hidden="true"
                style={{ width: "2rem", height: "2rem", color: "white" }} // Custom size for the spinner
              ></span>
            ) : (
              "Sign In"
            )}
          </button>

          {!fApplicantPage && (
            <p>
              Don't have an account?{" "}
              <span
                style={{ color: "#0D6EFD", cursor: "pointer" }}
                to="/sign-up"
                className="font-semibold"
                onClick={() => {
                  window.location.href = "/sign-up";
                }}
              >
                Sign Up
              </span>
            </p>
          )}
        </form>
      </div>
    </>
  );
};

export default SignIn;
