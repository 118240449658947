import { Button, Form, Row, Col } from "react-bootstrap";
import { deleteUserProfileDetails, saveEduDetails } from "../utility/userUtils";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import AskConfirmationModal from "../workComponents/subComp/AskConfirmationModal";
import { useState } from "react";

export const DegreeDetails = ({
  educationDetails,
  details,
  openEditMode,
  err,
}) => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { eduMessages } = useSelector((state) => {
    return state.workSlice.edit_profile_field_messages;
  });
  const [askConfirmation, setaskConfirmation] = useState(false);
  const { editMode, setEditMode } = openEditMode;
  const { degreeDetails, setDegreeDetails } = details;
  const { errors, setErrors } = err;

  function validateURL(url) {
    var res = url.match(
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\+~#=]{2,256}\.[a-z^@]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }
  const validateForm = () => {
    const newErrors = {};
    if (!degreeDetails.organizationName?.trim()) {
      newErrors.organizationName = "Organization name is required.";
    }
    if (!degreeDetails.degree?.trim()) {
      newErrors.degree = "Degree is required.";
    }
    if (!degreeDetails.major?.trim()) {
      newErrors.major = "Major is required.";
    }
    if (
      degreeDetails.organizationUrl &&
      !validateURL(degreeDetails.organizationUrl)
    ) {
      newErrors.organizationUrl = "Invalid URL.";
    }
    //   if (!degreeDetails.grade) {
    //     newErrors.grade = 'Grade is required.';
    //   }
    if (!degreeDetails.startYear) {
      newErrors.startYear = "Start Year is required.";
    }
    if (!degreeDetails.currStuding && !degreeDetails.endYear) {
      newErrors.endYear = "End Year is required.";
    }
    const currYear = new Date().getFullYear();
    if (
      degreeDetails.startYear > currYear ||
      degreeDetails.startYear < currYear - 80 ||
      (!degreeDetails.currStuding &&
        (degreeDetails.endYear > currYear ||
          degreeDetails.startYear >= degreeDetails.endYear))
    ) {
      newErrors.attendTime = "Invalid years selected.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (editMode.mode === "update") {
        saveEduDetails(degreeDetails, "update", userId)
          .then((eData) => {
            if (eData.status === 200) {
              let eduData = [...educationDetails.eduDetails];
              eduData[editMode.index] = degreeDetails;
              // console.log("eduData", eData);
              // console.log("eduData", eduData, editMode.index);
              educationDetails.setEduDetails(eduData);
              dispatch(
                newAlert({
                  message: "Education Details updated successfully",
                  type: "success",
                })
              );
            } else {
              dispatch(
                newAlert({
                  message: "Failed to update Education details",
                  type: "error",
                })
              );
            }
          })
          .finally(() => {
            setEditMode({ value: false, mode: "", index: 0 });
          });
      } else {
        saveEduDetails(degreeDetails, "add", userId)
          .then((eData) => {
            if (eData.status === 200) {
              educationDetails.setEduDetails([
                ...educationDetails.eduDetails,
                { ...degreeDetails, eduId: eData.data?.schoolDetailId },
              ]);
              dispatch(
                newAlert({
                  message: "Education details added successfully",
                  type: "success",
                })
              );
            } else {
              dispatch(
                newAlert({
                  message: "Failed to add Education details",
                  type: "error",
                })
              );
            }
          })
          .finally(() => {
            setEditMode({ value: false, mode: "", index: 0 });
          });
      }
    }
  };

  const deleteEducationDetails = async () => {
    // const index = editMode.index;
    const removeEduDetails = async () => {
      const { eduId } = degreeDetails;
      const status = await deleteUserProfileDetails(eduId, userId, "school");
      // const status = "success";
      console.log(status);
      if (status === "success") {
        let eduData = [...educationDetails.eduDetails];
        eduData.splice(editMode.index, 1);
        educationDetails.setEduDetails(eduData);
        setEditMode(false);
      }
    };
    setaskConfirmation({
      active: true,
      title: "",
      heading: "Are you sure you want to delete",
      onClick: removeEduDetails,
      type: "delete",
    });
  };

  return (
    <>
      <AskConfirmationModal
        askConfirmation={askConfirmation}
        setaskConfirmation={(data) => setaskConfirmation(data)}
      />
      <div>
        <Form onSubmit={handleSubmit} id="education-form">
          <Form.Control
            type="text"
            placeholder="Organization name"
            className="p-2"
            name="name"
            value={degreeDetails.organizationName}
            onChange={(e) =>
              setDegreeDetails({
                ...degreeDetails,
                organizationName: e.target.value,
              })
            }
          />
          {errors.organizationName && (
            <div className="text-danger">{errors.organizationName}</div>
          )}
          <Form.Control
            type="text"
            placeholder="Organization URL"
            className="mt-3 p-2"
            value={degreeDetails.organizationUrl}
            name="url"
            onChange={(e) =>
              setDegreeDetails({
                ...degreeDetails,
                organizationUrl: e.target.value,
              })
            }
          />
          {errors.organizationUrl && (
            <div className="text-danger">{errors.organizationUrl}</div>
          )}
          <Form.Control
            type="text"
            placeholder="Degree"
            className="mt-3 p-2"
            value={degreeDetails.degree}
            name="degree"
            onChange={(e) =>
              setDegreeDetails({ ...degreeDetails, degree: e.target.value })
            }
          />
          {errors.degree && <div className="text-danger">{errors.degree}</div>}

          <Row className="mt-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Major"
                className="p-2"
                value={degreeDetails.major}
                name="major"
                onChange={(e) =>
                  setDegreeDetails({ ...degreeDetails, major: e.target.value })
                }
              />
              {errors.major && (
                <div className="text-danger">{errors.major}</div>
              )}
            </Col>
            <Col>
              <Form.Control
                type="number"
                placeholder="Grade"
                className="p-2"
                value={degreeDetails.grade}
                name="grade"
                onChange={(e) =>
                  setDegreeDetails({ ...degreeDetails, grade: e.target.value })
                }
              />
              {errors.grade && (
                <div className="text-danger">{errors.grade}</div>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <hr />
      <div>
        <Row>
          <Col lg={4}>
            <AttendDuration details={details} errors={errors} />
          </Col>
          <Col lg={5}>
            <Form.Control
              as="textarea"
              className="no-resize h-100"
              placeholder="Education description"
              value={degreeDetails.description}
              name="description"
              rows={4}
              onChange={(e) => {
                e.target.value = e.target.value.slice(0, 300);
                setDegreeDetails({
                  ...degreeDetails,
                  description: e.target.value,
                });
              }}
            />
          </Col>
          <Col lg={3}>
            <SaveSection
              editMode={editMode}
              setEditMode={setEditMode}
              deleteEducationDetails={deleteEducationDetails}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const AttendDuration = ({ details, errors }) => {
  const { degreeDetails, setDegreeDetails } = details;
  return (
    <div>
      <div className="text-center fw-lite">Attended</div>
      <Row>
        <Col>
          <Form.Label className="mb-0 fw-lite">
            Start
            <Form.Control
              type="number"
              placeholder="Year"
              name="startYear"
              value={degreeDetails.startYear}
              onChange={(e) =>
                setDegreeDetails({
                  ...degreeDetails,
                  startYear: e.target.value,
                })
              }
            />
          </Form.Label>
          {errors.startYear && (
            <div className="text-danger">{errors.startYear}</div>
          )}
        </Col>
        <Col>
          <Form.Label className="mb-0 fw-lite">
            End
            <Form.Control
              type="number"
              placeholder="Year"
              disabled={degreeDetails.currStuding}
              value={degreeDetails.endYear}
              name="endYear"
              onChange={(e) =>
                setDegreeDetails({
                  ...degreeDetails,
                  endYear: e.target.value,
                })
              }
            />
          </Form.Label>
          {errors.endYear && (
            <div className="text-danger">{errors.endYear}</div>
          )}
        </Col>
      </Row>
      <Form.Group>
        {errors.attendTime && (
          <div className="text-danger">{errors.attendTime}</div>
        )}
        <div className="mt-2">
          <input
            className="form-check-input"
            value={degreeDetails.currStuding}
            name="currStuding"
            onChange={(e) => {
              setDegreeDetails({
                ...degreeDetails,
                currStuding: e.target.checked,
              });
            }}
            checked={degreeDetails.currStuding}
            type="checkbox"
            id="flexCheckDefault"
          />
          <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
            Currently Studying Here
          </label>
        </div>
      </Form.Group>
    </div>
  );
};

const SaveSection = ({ editMode, setEditMode, deleteEducationDetails }) => {
  // const saveDetails = () => {
  //   // Save education details logic
  // };

  const cancelDetails = () => {
    setEditMode(false);
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100 mt-2">
      <Button
        form="education-form"
        className="p-2"
        style={{
          backgroundColor: "#074A75",
          maxWidth: "160px",
          minHeight: "50px",
          border: "none",
        }}
        type="submit"
        variant="primary mb-2"
      >
        Save
      </Button>
      {/* <div> */}
      <Button
        variant="secondary"
        className="p-2"
        style={{
          backgroundColor: "#EF888C",
          maxWidth: "160px",
          minHeight: "50px",
          border: "none",
        }}
        onClick={cancelDetails}
      >
        Cancel
      </Button>
      {editMode.mode === "update" && (
        <span onClick={deleteEducationDetails}>
          <i className="far fa-trash-can work-edit delete-education-btn ms-1 col-4 cursor-pointor"></i>
        </span>
      )}
      {/* </div> */}
    </div>
  );
};

export default DegreeDetails;
