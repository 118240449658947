import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import EditCertificate from "./EditCertificate";
import { useDispatch } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import {
  deleteUserProfileDetails,
  getCertificationDetails,
  saveCertificationDetails,
} from "../utility/userUtils";
import AskConfirmationModal from "../workComponents/subComp/AskConfirmationModal";

const CertificateDetails = () => {
  const dispatch = useDispatch();
  const [certifications, setCertifications] = useState([]);
  const [askConfirmation, setaskConfirmation] = useState(false);
  const [editMode, setEditMode] = useState({
    value: false,
    mode: "",
    index: 0,
  });
  const initCertificateDetails = {
    name: "",
    issuedAt: {
      issuedMonth: "Jan",
      issuedYear: "",
    },
    issuedBy: "",
  };
  const [certificateDetails, setCertificateDetails] = useState(
    initCertificateDetails
  );

  const getCertificationsData = async () => {
    const { certificationDetails } = await getCertificationDetails();
    // console.log({ certificationDetails });
    setCertifications(certificationDetails || []);
  };

  const saveCertificationData = async ({
    mode,
    userId,
    certificationDetails,
  }) => {
    try {
      if (!["update", "add"].includes(mode)) {
        throw new Error(`Invalid mode provided: ${mode}`);
      }
      const pData = await saveCertificationDetails(
        certificationDetails,
        mode,
        userId
      );
      if (pData.status === "success") {
        const dbCertificationDetails = pData?.certificationDetails;
        let certificationData = [...certifications];
        // console.log({ dbCertificationDetails });
        if (mode === "update") {
          certificationData[editMode.index] = dbCertificationDetails;
          setCertifications(certificationData);
        } else {
          certificationData.push(dbCertificationDetails);
          setCertifications(certificationData);
        }
        dispatch(
          newAlert({
            message: "Certification Details updated successfully",
            type: "success",
          })
        );
      } else {
        throw new Error(`Failed to add Certification details`);
      }
    } catch (error) {
      console.log({ error });
      dispatch(
        newAlert({
          message: "Failed to add Certification details",
          type: "error",
        })
      );
    } finally {
      setEditMode({ value: false, mode: "", index: 0 });
    }
  };

  const deleteCertificationDetails = async (deleteId, userId) => {
    // const index = editMode.index;
    const removeCertificationsDetails = async () => {
      const status = await deleteUserProfileDetails(
        deleteId,
        userId,
        "certification"
      );
      console.log(status);
      if (status === "success") {
        let certificationData = [...certifications];
        // console.log(certifications, editMode);
        certificationData.splice(editMode.index, 1);
        setCertifications(certificationData);
        dispatch(
          newAlert({
            message: "Certification deleted successfully",
            type: "success",
          })
        );
        setEditMode({ value: false, mode: "" });
      }
    };
    setaskConfirmation({
      active: true,
      title: "",
      heading: `Are you sure you want to delete certification "${
        certifications[editMode.index].name
      }" from your profile`,
      onClick: removeCertificationsDetails,
      type: "delete",
    });
  };

  useEffect(() => {
    if (editMode.mode === "update") {
      setCertificateDetails(certifications[editMode.index]);
    } else {
      setCertificateDetails(initCertificateDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    getCertificationsData();
  }, []);

  return (
    <div className="pb-4" id="edit-certificates">
      <AskConfirmationModal
        askConfirmation={askConfirmation}
        setaskConfirmation={(data) => setaskConfirmation(data)}
      />
      <div className="d-flex justify-content-between mb-4 mt-5">
        <h3>Certifications</h3>
        <button
          className="add-details-btn"
          onClick={() => {
            setEditMode({ value: true, mode: "add" });
          }}
        >
          + Add Certification
        </button>
      </div>
      <div className="mb-4">
        {editMode.value === true && (
          <EditCertificate
            certificate={certificateDetails}
            setCertificate={setCertificateDetails}
            editMode={editMode}
            setEditMode={setEditMode}
            saveCertificationData={saveCertificationData}
            deleteCertificationDetails={deleteCertificationDetails}
          />
        )}
      </div>
      <Row>
        {certifications?.map((certificateInfo, index) => {
          return (
            <Col lg={6} md={11} xs={12} className="mb-4" key={index}>
              {!(editMode.value && editMode.index === index) && (
                <Certificate
                  certificate={certificateInfo}
                  setEditMode={setEditMode}
                  index={index}
                />
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
const Certificate = ({ certificate, setEditMode, index }) => {
  // const { setEditMode } = openEditMode;
  return (
    <Card className="px-4 py-3 bg-light" style={{ minHeight: "140px" }}>
      <div className="mt-2" style={{ fontSize: "20px", fontWeight: "500" }}>
        {certificate.name}
      </div>
      <div className="mt-1">{`${certificate.issuedAt?.issuedMonth || ''} ${certificate.issuedAt?.issuedYear || ''}`}
      {certificate?.issuedBy && `- ${certificate?.issuedBy}`}
      </div>
      <a
        href="#edit-certificates"
        onClick={() => {
          setEditMode({ value: true, mode: "update", index: index });
        }}
      >
        <i className="far fa-edit work-edit"></i>
      </a>
    </Card>
  );
};

export default CertificateDetails;
