import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./BookmarkArticles.css";
import Blog from "../../NewsFeed/Blog/Blog";

function BookmarkArticles({ bookmarkedArticles }) {
  return (
    <Row>
      {bookmarkedArticles?.map((bookmarkedArticle, index) => {
        if (bookmarkedArticle) {
          return (
            <div key={bookmarkedArticle.postId} className="my-3">
              <Blog
                postId={bookmarkedArticle?.postId}
                authorProfilePic={bookmarkedArticle?.authorProfilePic}
                author={bookmarkedArticle?.author}
                readTime={bookmarkedArticle?.readTime}
                url={bookmarkedArticle?.url}
                title={bookmarkedArticle?.title}
                key_learning={bookmarkedArticle?.key_learning}
                tags={bookmarkedArticle?.tags}
                articleImageUrl={bookmarkedArticle?.articleImageUrl}
                upvoteCount={bookmarkedArticle?.upvoteCount}
                isUpvoted={bookmarkedArticle?.fLiked || false}
                isDownvoted={bookmarkedArticle?.fDisliked || false}
                isBookmarked={bookmarkedArticle?.fBookmarked || false}
                objectName="bookmarkedArticles"
              />
            </div>
          );
        }
      })}
    </Row>
  );
}

export default BookmarkArticles;
