import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import "./CreatePost.css";

import { useLocation, useNavigate } from "react-router-dom";
import { convertGeneratedArticlesToHTML } from "../utilities";

export const CreatePost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get("postId");
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [newTitleName, setNewTitleName] = useState();
  const [askConfirmation, setaskConfirmation] = useState({
    active: false,
    title: false,
    content: "",
    onClick: null,
  });
  const dispatch = useDispatch();
  let timeoutId = null;

  const getExistingTitles = async (titleName) => {
    try {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        const { data } = await axiosInstance.get(
          `/api/admin/get-existing-title-by-title-name/${titleName}`
        );
        // console.log('matchedPost: ',data.matchedPost);
        const options = await data.matchedPost.map((doc) => {
          return {
            value: doc.title,
            label: doc.title,
            id: doc._id,
            type: doc.type,
            promptChain: doc.promptChain,
            updatedAt: doc.updatedAt,
          };
        });
        options.push({
          value: `Create new Title:${titleName}`,
          label: `Create new Title:${titleName}`,
          // id: "abc",
          // type: "create",
        });
        setTitleOptions(options);
        // console.log("Got title; ", options);
      }, 1000);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const createNewTopic = async (newTitleName) => {
    try {
      console.log("title: ", newTitleName);
      const { data } = await axiosInstance.post(`/api/admin/create-new-post`, {
        newTitle: newTitleName,
      });
      console.log(data);

      if (!checkTitleExists(titleOptions, data.createdPost._id)) {
        getExistingTitles(data.createdPost.title);
      }
      setSelectedTitle({
        value: data.createdPost.title,
        label: data.createdPost.title,
        id: data.createdPost._id,
      });

      setaskConfirmation({
        active: false,
        title: false,
        content: "",
        onClick: null,
      });
      setNewTitleName("");
      navigate(
        `/admin/create-post/edit${
          data.createdPost._id ? `?postId=${data.createdPost._id}` : ""
        }`
      );
      dispatch(newAlert({ type: "success", message: "New topic created!" }));
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  function checkTitleExists(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        return true;
      }
    }
    return false;
  }

  const getSelectedPost = async (articleId, choice) => {
    // console.log("choice: ", choice);
    const type = choice.type;
    try {
      if (type === "preview") {
        console.log("navigate to preview");
        navigate(
          `/admin/create-post/preview${articleId ? `?postId=${articleId}` : ""}`
        );
        return;
      } else if (type === "edit") {
        navigate(
          `/admin/create-post/edit${articleId ? `?postId=${articleId}` : ""}`
        );
        return;
      }
      const { data } = await axiosInstance.get(
        `/api/admin/get-selected-post-details/${articleId}/${type}`
      );
      const htmlArticle = convertGeneratedArticlesToHTML(
        data.selectedPost.post
      );
      await axiosInstance.post(`/api/admin/save-post-details`, {
        postId: data.selectedPost._id,
        postContent: htmlArticle,
        postTags: [],
        fPublish: false,
        lastSavedBy: data.selectedPost.lastSavedBy,
      });
      navigate(
        `/admin/create-post/edit${
          data.selectedPost._id ? `?postId=${data.selectedPost._id}` : ""
        }`
      );
      return;
    } catch (err) {
      console.log(err);
      dispatch(newAlert({ type: "danger", message: "Failed to load post!" }));
    }
  };

  const handlePostCancel = () => {
    setSelectedTitle([]);
  };

  useEffect(() => {
    if (postId) {
      getSelectedPost(postId, { type: "edit" });
    }
    // console.log(postId);
  }, []);

  // console.log(true);

  return (
    <>
      <Container
        style={{ marginTop: "30px", marginBottom: "80px", minHeight: "70vh" }}
      >
        <div className="d-flex">
          <h2>Create Post</h2>
        </div>
        {askConfirmation.active && (
          <div className="">
            <div className="modal-background"></div>
            <div
              className="bg-light border rounded position-fixed top-50 start-50 translate-middle zindex-modal "
              style={{
                width: "70%",
                zIndex: 20,
                boxShadow: "1px 1px 6px gray",
              }}
            >
              <h4 className="text-center m-4">{askConfirmation.content}</h4>
              <h6 className="text-center">
                {askConfirmation.title && (
                  <span>New title Name: {newTitleName}</span>
                )}
              </h6>
              <div className="d-flex w-100 justify-content-center">
                <button
                  className="btn btn-success mx-4 my-2"
                  onClick={askConfirmation.onClick}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger mx-4 my-2"
                  onClick={() => {
                    setaskConfirmation({
                      active: false,
                      title: false,
                      content: "",
                      onClick: null,
                    });
                    setNewTitleName("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <Form>
          <div className="parent-container">
            <div className="title-container">
              <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
                Title
              </div>
              <Select
                closeMenuOnSelect={true}
                placeholder={<div>Enter Title</div>}
                value={selectedTitle}
                onChange={async (choice) => {
                  try {
                    setSelectedTitle(choice);
                    //todo:  redirect to topic page
                    if (choice.value.startsWith("Create new Title:")) {
                      const newTitleNameEntered =
                        choice.label.split("Create new Title:")[1];
                      // console.log("newTitleNameEntered: ",newTitleNameEntered)
                      setNewTitleName(newTitleNameEntered);
                      const { data } = await axiosInstance.get(
                        `/api/admin/check-unique-title/${newTitleNameEntered}`
                      );
                      const { isPresent, titleId, type } = data;
                      if (!isPresent) {
                        setaskConfirmation({
                          active: true,
                          title: true,
                          content: "Are you sure you want to create new Post?",
                          onClick: () => createNewTopic(newTitleNameEntered),
                        });
                      } else {
                        const getPost = async () => {
                          choice.type = type;
                          await getSelectedPost(titleId, choice);
                          setaskConfirmation({
                            active: false,
                            title: false,
                            content: "",
                            onClick: null,
                          });
                        };
                        setaskConfirmation({
                          active: true,
                          title: false,
                          content:
                            "This Post already exists! do you want to open this post?",
                          onClick: getPost,
                        });
                      }
                    } else {
                      // console.log("postId: ", choice)
                      await getSelectedPost(choice.id, choice);
                    }
                  } catch (err) {
                    console.log("Error: ", err);
                  }
                }}
                onInputChange={(input) => {
                  if (input) getExistingTitles(input);
                }}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    width: "100%",
                  }),
                }}
                noOptionsMessage={() => "Start typing any title name"}
                options={titleOptions}
                getOptionLabel={(option) => {
                  return (
                    <div>
                      <div>
                        {`${option.label} ${
                          option?.type ? `(${option.type})` : ""
                        }`}
                      </div>
                      <div
                        className="d-flex justify-content-between text-muted"
                        style={{ fontSize: "11px" }}
                      >
                        <span>{`${option.promptChain || ""}`}</span>
                        <span>{`${option.updatedAt || ""}`}</span>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </Form>
      </Container>
    </>
  );
};
