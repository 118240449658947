import React from "react";

const Spinner = ({ style }) => {
  return (
    <div className={`spinner-border`} style={style} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Spinner;
