import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
const ImportQuestionsFromChats = ({
  isModalOpen = false,
  setModalStatus,
  indexInHeadingQuestions,
  chats,
}) => {
  const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(true);

  const closeModal = () => {
    setModalStatus(false);
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Import Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {chats.map((chat) => {
          return (
            <Accordion>
              <Accordion.Item eventKey={chat.gptId}>
                <Accordion.Header>
                  <div className="position-relative w-100">
                    <span
                      className=" position-absolute top-0 start-0 px-1"
                      style={{ fontSize: "8px" }}
                    >
                      {chat.gptId}
                    </span>
                    <p className="pt-2 px-1">{chat.gptName}</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {chat.questions?.map((question) => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          id={question.id}
                          name={question.id}
                          value={question.id}
                        />
                        <label className="px-3" for={question.id}>
                          {question.message.content.parts[0].substring(0, 30)}
                          ....
                        </label>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
        <Modal.Footer className="d-flex justify-content-between border-0 p-0 mt-4">
          <Button
            onClick={() => setModalStatus(false)}
            style={{
              width: "150px",
              backgroundColor: "#3FACCB",
              border: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isSubmitBtnEnabled}
            style={{
              width: "150px",
              backgroundColor: "#3FACCB",
              border: "none",
            }}
          >
            {isSubmitBtnEnabled ? (
              "Import"
            ) : (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ImportQuestionsFromChats;
