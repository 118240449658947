import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./LikedArticles.css";
import Blog from "../../NewsFeed/Blog/Blog";
function LikedArticles({ likedArticles }) {
  return (
    <Row>
      {likedArticles?.map((likedArticle, index) => {
        if (likedArticle) {
          return (
            <div key={likedArticle.postId} className="my-3">
              <Blog
                postId={likedArticle?.postId}
                authorProfilePic={likedArticle?.authorProfilePic}
                author={likedArticle?.author}
                readTime={likedArticle?.readTime}
                url={likedArticle?.url}
                title={likedArticle?.title}
                key_learning={likedArticle?.key_learning}
                tags={likedArticle?.tags}
                articleImageUrl={likedArticle?.articleImageUrl}
                upvoteCount={likedArticle?.upvoteCount}
                isUpvoted={likedArticle?.fLiked || false}
                isDownvoted={likedArticle?.fDisliked || false}
                isBookmarked={likedArticle?.fBookmarked || false}
                objectName="likedArticles"
              />
            </div>
          );
        }
      })}
    </Row>
  );
}

export default LikedArticles;
