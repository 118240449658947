import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./VerifyEmailMessagePage.css";
import { useNavigate } from "react-router-dom";
import emailVerificationIcon from "../../assets/images/emailVerificationicon.svg";
import { getCookie } from "../../helpers/stubs.js";
import {
  resendVerificationEmail,
  setSessionUserFromCookie,
  setFRedirectUserToEmailAlreadyVerifiedEmailPage
} from "../../slices/sessionSlice.js";

const EmailVerificationMessagePage = ({
  email,
  enableButtonAfterSeconds = 60,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(enableButtonAfterSeconds);
  const { sessionUser, error,fRedirectUserToEmailAlreadyVerifiedEmailPage } = useSelector((slice) => slice.sessionSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (fRedirectUserToEmailAlreadyVerifiedEmailPage) {
      dispatch(setFRedirectUserToEmailAlreadyVerifiedEmailPage(false));
      navigate("/email-already-verified");
    }
  }, [fRedirectUserToEmailAlreadyVerifiedEmailPage]);



  useEffect(() => {
    let timer;
    if (secondsLeft > 0) {
      timer = setInterval(() => {
        setSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [secondsLeft]);

  const handleResendClick = () => {
    setSecondsLeft(enableButtonAfterSeconds);
    if (!sessionUser || !sessionUser.pendingUser) {
      const getCookie_updateReduxStore = () => {
        const sessionCookie = getCookie("sc");
        if (sessionCookie) {
          const { sessionId, pendingUser, user } = JSON.parse(sessionCookie);
          if (!pendingUser && user) {
            navigate("/");
          }
          if (!pendingUser && !user) {
            navigate("/sign-in");
          }
          dispatch(setSessionUserFromCookie({ sessionId, pendingUser, user }));
          dispatch(
            resendVerificationEmail({
              pendingUserId: pendingUser._id,
            })
          );
        }
      };

      getCookie_updateReduxStore();
    } else {
      dispatch(
        resendVerificationEmail({ pendingUserId: sessionUser.pendingUser._id })
      );
    }
  };

  const buttonText =
    secondsLeft > 0 ? `Resend Email (${secondsLeft}s)` : "Resend Email";

  return (
    <div className="d-flex justify-content-center align-items-center main-container">
      <div className="email-verification-container">
        <div className="email-icon">
          <img src={emailVerificationIcon} alt="email verification icon" />
        </div>
        <h2 className="verify-email-heading">Verify your email</h2>
        <p className="verify-email-text">
          Welcome to{" "}
          <span style={{ color: "#272727", fontWeight: "500" }}>
            MarketingNet!
          </span>
        </p>
        <p className="verify-email-text">
          A verification link has been sent to your email
        </p>
        <div className="verify-email">{email}</div>
        <p className="verify-email-text">
          Please verify your email to continue. If you don't see the email,
          please check your spam folder.
        </p>
        <p className="verify-email-text mail-not-received">
          Did not recieve email?
        </p>
        <div className="d-flex justify-content-center">
          <button
            className="resend-button"
            disabled={secondsLeft > 0}
            onClick={handleResendClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationMessagePage;
