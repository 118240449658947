import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./educationComp.css";
import universitylogo from "../../../assets/profile-images/university_logo.png";

const SavedEducations = ({ educationDetails, openEditMode }) => {
  return (
    <Row>
      {educationDetails?.map((educationDetail, index) => (
        <SavedEducation
          key={index}
          educationDetail={educationDetail}
          openEditMode={openEditMode}
          index={index}
        />
      ))}
    </Row>
  );
};

const SavedEducation = ({ educationDetail, openEditMode, index }) => {
  const { setEditMode } = openEditMode;
  return (
    <Col lg={6} md={11} xs={12} className="mb-4">
      <Card className="p-4 bg-light" style={{ minHeight: "360px" }}>
        {/* {console.log(educationDetail)} */}
        <Row>
          <Col xs={4} xl={3}>
            <Image for="CollegeImage" className="img-fluid" />
          </Col>
          <Col
            xs={8}
            xl={9}
            className="ps-3 d-flex flex-column justify-content-around"
          >
            <OrganizationName value={educationDetail.organizationName} />
            <div className={`edu-subheader`}>
              <DegreeName value={educationDetail.degree} />
              <DegreeMajor value={educationDetail.major} />
            </div>
            <DegreeYears
              value={`${educationDetail.startYear || ''}${
                educationDetail.currStuding
                  ? "-Present"
                  : (educationDetail.endYear) ? ("-" + educationDetail.endYear) : ''
              }`}
            />
            <div className="mb-0 text-muted short-text">{educationDetail?.grade && `Grade: ${educationDetail?.grade}`}</div>
          </Col>
        </Row>
        <DegreeDescription value={educationDetail.description} />
        <a
          href="#edit-education"
          onClick={() => {
            setEditMode({ value: true, mode: "update", index: index });
          }}
        >
          <i className="far fa-edit work-edit"></i>
        </a>
      </Card>
    </Col>
  );
};

const Image = (props) => (
  <Card.Img
    src={universitylogo}
    alt="orglogo"
    style={{ width: "92px", height: "92px" }}
  />
);

const OrganizationName = ({ value }) => (
  <div className="edu-card-header">{value}</div>
);

const DegreeName = ({ value }) => <div className="mb-0 fw-lite">{value}</div>;

const DegreeMajor = ({ value }) => <div className="mb-0 fw-lite">{value}</div>;

const DegreeYears = ({ value }) => (
  <div className="mb-0 text-muted short-text">{value}</div>
);

const DegreeDescription = ({ value }) => <div className="mt-3 ">{value}</div>;

export default SavedEducations;
