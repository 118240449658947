import "./Blog.css";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import likesIcon from "../../../assets/images/like-icon.svg";
import savePostIcon from "../../../assets/images/save_post_icon.png";
import unsavePostIcon from "../../../assets/images/Bookmark active 2.svg";
import likesInactiveIcon from "../../../assets/images/like-inactive-icon.svg";
import dislikesIcon from "../../../assets/images/dislike-icon.svg";
import dislikesInactiveIcon from "../../../assets/images/dislike-inactive-icon.svg";
import {
  updateSavedCreatedPosts,
  updateVotesForCreatedPosts,
} from "../../../slices/newsFeedSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
function Blog({
  postId,
  authorProfilePic,
  author,
  readTime,
  url,
  title,
  key_learning,
  tags,
  articleImageUrl,
  upvoteCount,
  isUpvoted,
  isDownvoted,
  isBookmarked,
  showSignInModal,
  objectName,
}) {
  // console.log({ postId, isUpvoted, isDownvoted, isBookmarked });
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const [currentUpvoteCount, setCurrentUpvoteCount] = useState(upvoteCount);
  const [upvoteStatus, setUpvoteStatus] = useState(isUpvoted);
  const [downvoteStatus, setDownvoteStatus] = useState(isDownvoted);
  const [postSaveStatus, setPostSaveStatus] = useState(isBookmarked);
  const dispatch = useDispatch();
  const randomTagsColorsArray = [
    "#FCE2CE",
    "#FBCDCD",
    "#D9EAEA",
    "#D5D6EB",
    "#DFF0D6",
    "#FFFAC5",
  ];
  useEffect(() => {
    //set random colors for post tags
    const elements = document.querySelectorAll(".custom-post-tags");
    elements.forEach((element) => {
      element.style.backgroundColor =
        randomTagsColorsArray[
          Math.floor(Math.random() * randomTagsColorsArray?.length)
        ];
    });
  }, []);
  useEffect(() => {
    setCurrentUpvoteCount(upvoteCount);
  }, [upvoteCount]);
  useEffect(() => {
    setUpvoteStatus(isUpvoted);
  }, [isUpvoted]);
  useEffect(() => {
    setDownvoteStatus(isDownvoted);
  }, [isDownvoted]);
  useEffect(() => {
    setPostSaveStatus(isBookmarked);
  }, [isBookmarked]);
  const handleSaveUnsavePosts = async () => {
    if (sessionUser?.user) {
      const result = await dispatch(
        updateSavedCreatedPosts({
          postId,
          isSaved: postSaveStatus,
          objectName,
          query: "",
        })
      );
      if (result.meta.requestStatus === "fulfilled") {
        setPostSaveStatus(!postSaveStatus);
      }
    } else {
      showSignInModal();
    }
  };

  function showKeyLearnings(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let key_learning = tempElement.innerText;
    // let key_learning = htmlString?.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    key_learning =
      key_learning?.length <= 180
        ? key_learning
        : key_learning?.slice(0, 180) + "...";

    return key_learning;
  }
  const handleUpdateVotesForCreatedPost = async (currentVoteType) => {
    if (sessionUser?.user) {
      const result = await dispatch(
        updateVotesForCreatedPosts({
          postId,
          currentVoteType,
          isUpvoted: upvoteStatus,
          isDownvoted: downvoteStatus,
          objectName,
          query: "",
        })
      );
      if (result.meta.requestStatus === "fulfilled") {
        if (currentVoteType === "upvote") {
          setUpvoteStatus((prevUpvoteStatus) => !prevUpvoteStatus);
          setDownvoteStatus(false);
          if (result.payload.insertedVote || result.payload.updatedVoteType) {
            setCurrentUpvoteCount(
              (prevCurrentUpvoteCount) => prevCurrentUpvoteCount + 1
            );
          } else if (result.payload.deletedVote) {
            setCurrentUpvoteCount(
              (prevCurrentUpvoteCount) => prevCurrentUpvoteCount - 1
            );
          }
        } else if (currentVoteType === "downvote") {
          setDownvoteStatus((prevDownvoteStatus) => !prevDownvoteStatus);
          setUpvoteStatus(false);
          if (result.payload.updatedVoteType) {
            setCurrentUpvoteCount(
              (prevCurrentUpvoteCount) => prevCurrentUpvoteCount - 1
            );
          }
        }
      }
    } else {
      showSignInModal();
    }
  };
  return (
    <div className="ms-md-4 ms-2 mb-md-4 position-relative custom-blog-container">
      <Row style={{ overflowWrap: "break-word" }}>
        <Col md={8} xl={9} xs={9}>
          <div className="d-flex flex-column ps-md-2">
            <div className="d-flex align-items-center">
              <div className="me-sm-1 author-pic-container">
                <img
                  src={authorProfilePic}
                  alt="profile-pic"
                  className="img-fluid rounded-circle author-profile-pic"
                />
              </div>
              <div className="mx-lg-2 custom-post-author">{author}</div>
              <span>
                <i className="bi bi-dot"></i>
              </span>
              <div className="mx-lg-1 custom-post-readtime">{readTime}</div>
            </div>
            <div className="custom-post-title mt-md-3 mt-md-2 mt-1">
              <Link
                to={`/article-page/${postId}`}
                target="_blank"
                className="text-decoration-none"
                style={{ fontWeight: "600" }}
              >
                {title?.length <= 75 ? title : title?.slice(0, 75) + "..."}
              </Link>
            </div>
            <div className="my-2 custom-post-content d-none d-md-block">
              {showKeyLearnings(key_learning)}
            </div>
            <div className="mt-md-2 d-flex" style={{ flexWrap: "wrap" }}>
              {tags?.map((tag, index) => {
                return (
                  <div
                    key={index}
                    className="custom-post-tags"
                    style={{
                      padding: "1px 8px 1px 8px",
                      marginRight: "8px",
                    }}
                  >
                    <span className="post-tag-value">{tag}</span>
                  </div>
                );
              })}
            </div>
            <div className="mt-md-4 d-flex align-items-center">
              <button
                className="bg-transparent border-0"
                onClick={() => handleUpdateVotesForCreatedPost("upvote")}
              >
                {upvoteStatus ? (
                  <img
                    src={likesIcon}
                    alt="like-icon"
                    className="post-like-icon"
                  />
                ) : (
                  <img
                    src={likesInactiveIcon}
                    alt="like-inactive-icon"
                    className="post-like-icon"
                  />
                )}
              </button>
              <span
                className="mt-1 mx-md-1 post-upvote-count"
                style={{
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "500",
                }}
              >
                {currentUpvoteCount}
              </span>
              <button
                className="mt-1 bg-transparent border-0 downvote-btn"
                onClick={() => handleUpdateVotesForCreatedPost("downvote")}
              >
                {downvoteStatus ? (
                  <img
                    src={dislikesIcon}
                    alt="dislike-icon"
                    className="post-dislike-icon"
                  />
                ) : (
                  <img
                    src={dislikesInactiveIcon}
                    alt="dislike-inactive-icon"
                    className="post-dislike-icon"
                  />
                )}
              </button>
              <button
                className="mt-1 ms-auto bg-transparent border-0 save-post-btn"
                onClick={handleSaveUnsavePosts}
              >
                {!postSaveStatus ? (
                  <img
                    src={savePostIcon}
                    alt="save-post-icon"
                    className="save-post-icon"
                    style={{ opacity: 0.4, filter: "grayscale(1)" }}
                  />
                ) : (
                  <img
                    src={unsavePostIcon}
                    alt="unsaved-post-icon"
                    className="unsave-post-icon"
                    style={{ opacity: 1, filter: "grayscale(1)" }}
                  />
                )}
              </button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="custom-post-image-container">
        <img
          src={articleImageUrl}
          alt="article-image"
          className="w-100 h-100 custom-post-image"
          style={{ borderRadius: "13px" }}
        />
      </div>
    </div>
  );
}
export default Blog;
