import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axios";
import { newAlert } from "./alertSlice";

const internals = {
  mapArrayToObjectByKey: ({
    array,
    key,
    fCreateArrayOfValuesForSameKey = false,
  }) => {
    const resultObject = array.reduce((resultObject, arrayItem) => {
      const valueOfKey = arrayItem[key];
      if (fCreateArrayOfValuesForSameKey === true) {
        resultObject[valueOfKey] ||= [];
        resultObject[valueOfKey].push(arrayItem);
      } else {
        resultObject[valueOfKey] = arrayItem;
      }
      return resultObject;
    }, {});

    return resultObject;
  },
  mapFilteredTextToGroupFilteredTextAndStartInTextResponse: ({ array }) => {
    const groupedFilteredText = array.reduce((acc, curr) => {
      const { point, sentence, _id, startInTextResponse, question, response } =
        curr;
      const existingQuestion = acc.find((q) => q.question === question);
      if (existingQuestion) {
        existingQuestion[point ? "points" : "sentences"].push({
          [point ? "point" : "sentence"]: point ? point : sentence,
          _id,
          startInTextResponse,
          response,
        });
      } else {
        const newQuestion = {
          question,
          [point ? "points" : "sentences"]: [
            {
              [point ? "point" : "sentence"]: point ? point : sentence,
              _id,
              startInTextResponse,
              response,
            },
          ],
        };
        acc.push(newQuestion);
      }
      return acc;
    }, []);
    return groupedFilteredText;
  },
  getIndexToAddPeerQuestionAt: ({
    headingQuestions,
    questionRelations,
    parentIndexInHeadingQuestions,
  }) => {
    const parentObjectInHeadingQuestions =
      headingQuestions[parentIndexInHeadingQuestions];
    if (
      parentObjectInHeadingQuestions &&
      parentObjectInHeadingQuestions.question
    ) {
      const questionRelationsArray = Object.values(questionRelations);
      let parentQuestionId = parentObjectInHeadingQuestions.question;
      let indexAfterQuestionAllChildren =
        headingQuestions.findIndex((hq) => hq.question === parentQuestionId) +
        1;

      // getToMaxChildDepthForQuestion
      for (;;) {
        let potentialChildren = questionRelationsArray.find(
          (qr) => qr.parentQuestion === parentQuestionId
        );
        if (!potentialChildren || !potentialChildren.questions) break;
        let childrenQuestions = potentialChildren.questions;
        let lastChildQuestion = childrenQuestions[childrenQuestions.length - 1];
        indexAfterQuestionAllChildren =
          headingQuestions.findIndex(
            (hq) => hq.question === lastChildQuestion
          ) + 1;
        parentQuestionId = lastChildQuestion;
      }

      const result = {
        indexToAddPeerQuestionAt: indexAfterQuestionAllChildren,
      };
      if (parentObjectInHeadingQuestions.relation) {
        let potentialChildren = questionRelationsArray.find(
          (qr) => qr._id === parentObjectInHeadingQuestions.relation
        );
        let indexInQuestionRelations = potentialChildren?.questions?.findIndex(
          (qr) => qr === parentObjectInHeadingQuestions?.question
        );
        result["indexInQuestionRelations"] = indexInQuestionRelations + 1;
      }
      return result;
    } else {
      return { indexToAddPeerQuestionAt: parentIndexInHeadingQuestions + 1 };
    }
  },
  scrollToHeadingQuestionsElOnPage: (indexInHeadingQuestions) => {
    const id = `[data-heading-questions-index="${indexInHeadingQuestions}"]`;
    console.log(`scrolling to ${id}`);

    const yOffset = -100;
    const element = document.querySelector(id);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  },
  moveItems: (headingQuestions, eleMovedIndex, eleMovedCount, eleBelowEnd) => {
    // update position of headingQuestions moved
    const newHeadingQuestions = Array.from(headingQuestions);
    const itemsToMove = newHeadingQuestions.splice(
      eleMovedIndex,
      eleMovedCount
    );
    newHeadingQuestions.splice(eleBelowEnd, 0, ...itemsToMove);
    return newHeadingQuestions;
  },
  addQuestionsToLS: (createdQuestions) => {
    createdQuestions.forEach((createdQuestion) => {
      const questionKey = `QuestionsForInterface`;
      const questionObject = {
        topic: createdQuestion.topic,
        question: createdQuestion.question,
        questionId: createdQuestion._id,
        chatId: createdQuestion.chatId,
        createdAt: createdQuestion.createdAt,
        contextProvided: createdQuestion.contextProvided
          ? createdQuestion.contextProvided
          : "",
      };
      let valueInLS = JSON.parse(localStorage.getItem(questionKey));

      if (valueInLS == undefined) {
        valueInLS = JSON.stringify([questionObject]);
        localStorage.setItem(questionKey, valueInLS);
      } else {
        valueInLS.push(questionObject);
        localStorage.setItem(questionKey, JSON.stringify(valueInLS));
      }
    });
  },
  removeQuestionForApi: (qId) => {
    try {
      let questionsForApiString = localStorage.getItem("QuestionsForApi");
      let questionsForApi = JSON.parse(questionsForApiString);
      if (questionsForApi && questionsForApi.length > 0) {
        let newQuestionsForApi = questionsForApi.filter(
          (q) => q.questionId != qId
        );
        localStorage.setItem(
          "QuestionsForApi",
          JSON.stringify(newQuestionsForApi)
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
};

const chatGPTSliceInitialState = {
  questions: {},
  responses: {},
  topic: {
    _id: null,
    topic: null,
    headingQuestions: [],
    questionRelations: {},
    filteredPoints: {},
    filteredSentences: {},
  },
  fShowSelectionMenu: false,
};

export const addNewHeading = createAsyncThunk(
  "chatGPT/addNewHeading",
  async (payload, thunkAPI) => {
    try {
      const {
        headingName,
        headingLevel,
        indexInHeadingQuestions,
        closeModal,
        setIsSubmitBtnEnabled,
      } = payload;
      const state = thunkAPI.getState();
      const userId = state.sessionSlice.sessionUser.user._id;
      const topicId = state.chatGPTSlice.topic._id;

      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/add-heading/${topicId}`,
        {
          headingName,
          headingLevel,
          indexInHeadingQuestions: indexInHeadingQuestions + 1,
          userId,
        }
      );
      closeModal();
      thunkAPI.dispatch(
        newAlert({ type: "success", message: "New heading added" })
      );

      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;

      payload.setIsSubmitBtnEnabled(true);
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } });
    }
  }
);

// function readLocalStorageForResponse() {
//   const responseForms =
//     JSON.parse(localStorage.getItem("ResponseFormInterface")) || [];
//   console.log(responseForms);
//   if (responseForms.length === 0) {
//     return; // Nothing to send
//   }

//   const lastResponse = responseForms.pop();

//   localStorage.setItem("ResponseFormInterface", JSON.stringify(responseForms));
// }

//check for response in localStorage every 30 seconds and save in db
export const sendResponseFromLocalStorageToServer = createAsyncThunk(
  "chatGPT/sendResponseFromLocalStorageToServer",
  async (payload, thunkAPI) => {
    try {
      const responsesFromLS =
        JSON.parse(localStorage.getItem("ResponseFormInterface")) || [];
      // console.log(responsesFromLS);
      if (responsesFromLS.length === 0) {
        console.log("no responses In local storage");
        setTimeout(() => {
          thunkAPI.dispatch(sendResponseFromLocalStorageToServer());
        }, 10000);
        return {}; // Nothing to send
      }
      //save all at once
      // const state = thunkAPI.getState();
      // console.log(state.sessionSlice);

      const { data, status } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/save-response-from-local-storage`,
        {
          responsesFromLS,
        }
      );
      console.log("save response --> ", data);
      let allResponsesFromLS =
        JSON.parse(localStorage.getItem("ResponseFormInterface")) || [];
      if (status === 200) {
        //remove this
        if (allResponsesFromLS?.length !== responsesFromLS?.length) {
          let newResponsesFromLS = allResponsesFromLS.filter(
            (obj) =>
              !responsesFromLS.some(
                (o) =>
                  o.question === obj.question && o.questionId === obj.questionId
              )
          );
          console.log("newResponsesFromLS", newResponsesFromLS);
          localStorage.setItem(
            "ResponseFormInterface",
            JSON.stringify(newResponsesFromLS)
          );
        } else {
          localStorage.setItem("ResponseFormInterface", JSON.stringify([]));
        }
      }
      thunkAPI.dispatch(sendResponseFromLocalStorageToServer());

      return data;
    } catch (error) {
      // const { data } = error.response;
      console.log("latest one --->", error);
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      Promise.reject({ error: { message: error.message } });
    }
    // thunkAPI.dispatch(sendResponseFromLocalStorageToServer());
  }
);

export const readLocalStorage_askQuestionToApi = createAsyncThunk(
  "chatGPT/readLocalStorage_askQuestionToApi",
  async (payload, thunkAPI) => {
    try {
      console.log("here->readLocalStorage_askQuestionToApi");
      let questionsForApiString = localStorage.getItem("QuestionsForApi");
      let questionsForApi = JSON.parse(questionsForApiString);

      if (questionsForApiString && questionsForApi?.length > 0) {
        console.log("questionsForApi.length", questionsForApi.length);

        console.log(questionsForApi[0].questionId);
        thunkAPI.dispatch(
          getAiResponse({
            questionId: questionsForApi[0].questionId,
            question: questionsForApi[0].question,
            topicId: questionsForApi[0].topic,
            fGenerateNewResponse: false,
          })
        );
        thunkAPI.dispatch(readLocalStorage_askQuestionToApi());
      } else {
        const sleep = (time) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve();
            }, time);
          });
        };
        await sleep(10000);

        thunkAPI.dispatch(readLocalStorage_askQuestionToApi());
      }
    } catch (error) {
      console.log("readLocalStorage_askQuestionToApi error :", error);
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      thunkAPI.dispatch(readLocalStorage_askQuestionToApi());
      return Promise.reject({ error: { message: error.message } });
    }
  }
);

export const addNewQuestion = createAsyncThunk(
  "chatGPT/addNewQuestion",
  async (payload, thunkAPI) => {
    try {
      // #region Before New Question Modal
      const {
        question,
        questionAlias,
        indexInHeadingQuestions,
        closeModal,
        resetForm,
        level = 1,
        relation,
        askQuestionInChat,
      } = payload;
      console.log("askQuestionInChat:-", askQuestionInChat);
      const state = thunkAPI.getState();
      const userId = state.sessionSlice.sessionUser.user._id;
      const topicId = state.chatGPTSlice.topic._id;
      const { indexToAddPeerQuestionAt, indexInQuestionRelations } =
        internals.getIndexToAddPeerQuestionAt({
          headingQuestions: state.chatGPTSlice.topic.headingQuestions,
          questionRelations: state.chatGPTSlice.topic.questionRelations,
          parentIndexInHeadingQuestions: indexInHeadingQuestions,
        });
      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/add-question/${topicId}`,
        {
          question,
          questionAlias,
          indexInHeadingQuestions: indexToAddPeerQuestionAt,
          indexInQuestionRelations,
          userId,
          level,
          relation,
        }
      );
      console.log("question added in topicId", topicId);
      closeModal();
      resetForm();
      internals.scrollToHeadingQuestionsElOnPage(indexToAddPeerQuestionAt - 1);
      thunkAPI.dispatch(
        newAlert({ type: "success", message: "New question added" })
      );
      if (data?.createdQuestion) {
        console.log("get response for createdQuestion");
        const questionAndChatId = [
          {
            ...data?.createdQuestion,
            chatId: askQuestionInChat?.value,
            topic: topicId,
          },
        ];
        console.log("questionAndChatId---->");
        console.log(questionAndChatId);
        internals.addQuestionsToLS(questionAndChatId);
        // const getResponseFromCLientsGpt = new CustomEvent(
        //   "getResponseFromCLientsGpt",
        //   {
        //     detail: {
        //       question: data?.createdQuestion?.question,
        //     },
        //   }
        // );
        // window.dispatchEvent(getResponseFromCLientsGpt);
        // thunkAPI.dispatch(
        //   getAiResponse({
        //     question: data?.createdQuestion?.question,
        //     questionId: data?.createdQuestion?._id,
        //     topicId,
        //   })
        // );
        // setInterval(
        //   thunkAPI.dispatch(sendResponseFromLocalStorageToServer()),
        //   15 * 1000
        // );
      }
      return data;
      // #endregion
      // #region After New Question Modal
      // const {
      //   question,
      //   questionAlias,
      //   indexInHeadingQuestions,
      //   closeModal,
      //   resetForm,
      //   level = 1,
      //   relation,
      //   // askQuestionInChat,
      // } = payload.questionsList[0].questions[0];
      // const { callb } = payload;
      // const askQuestionInChat =
      //   payload.askQuestionInChat == "newChat"
      //     ? ""
      //     : payload.askQuestionInChat.value;
      // console.log("askQuestionInChat:-", askQuestionInChat);
      // const state = thunkAPI.getState();
      // const userId = state.sessionSlice.sessionUser.user._id;
      // const topicId = state.chatGPTSlice.topic._id;
      // const { indexToAddPeerQuestionAt, indexInQuestionRelations } =
      //   internals.getIndexToAddPeerQuestionAt({
      //     headingQuestions: state.chatGPTSlice.topic.headingQuestions,
      //     questionRelations: state.chatGPTSlice.topic.questionRelations,
      //     parentIndexInHeadingQuestions: indexInHeadingQuestions,
      //   });
      // let { data } = await axiosInstance.post(
      //   `/api/admin/chat-gpt-interface-v2/add-question/${topicId}`,
      //   {
      //     question,
      //     questionAlias,
      //     indexInHeadingQuestions: indexToAddPeerQuestionAt,
      //     indexInQuestionRelations,
      //     userId,
      //     level,
      //     relation,
      //   }
      // );
      // console.log("question added in topicId", topicId);
      // // closeModal();
      // // resetForm();
      // internals.scrollToHeadingQuestionsElOnPage(indexToAddPeerQuestionAt - 1);
      // thunkAPI.dispatch(
      //   newAlert({ type: "success", message: "New question added" })
      // );
      // if (data?.createdQuestion) {
      //   console.log("get response for createdQuestion", data.createdQuestion);
      //   const questionAndChatId = [
      //     {
      //       ...data?.createdQuestion,
      //       chatId: askQuestionInChat?.value,
      //       topic: topicId,
      //     },
      //   ];
      //   console.log("questionAndChatId---->");
      //   console.log(questionAndChatId);
      //   internals.addQuestionsToLS(questionAndChatId);
      // }
      // data.payload = payload;
      // data.callb = callb;
      // return data;

      // #endregion
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;

      payload.setIsSubmitBtnEnabled(true);
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } })();
    }
  }
);

export const addNewChildQuestions = createAsyncThunk(
  "chatGPT/addNewChildQuestions",
  async (payload, thunkAPI) => {
    try {
      const {
        indexInHeadingQuestions,
        relation,
        questionTemplate,
        questionAliasTemplate,
        childQuestions,
        parentQuestionId,
        closeModal,
        resetForm,
        level,
      } = payload;
      const state = thunkAPI.getState();
      const userId = state.sessionSlice.sessionUser.user._id;
      const topicId = state.chatGPTSlice.topic._id;

      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/add-child-questions/${topicId}`,
        {
          indexInHeadingQuestions: indexInHeadingQuestions + 1,
          relation,
          questionTemplate,
          questionAliasTemplate,
          childQuestions,
          parentQuestionId,
          userId,
          level: level + 1,
        }
      );
      closeModal();
      resetForm();
      internals.scrollToHeadingQuestionsElOnPage(indexInHeadingQuestions);

      thunkAPI.dispatch(
        newAlert({ type: "success", message: "New child question added" })
      );

      console.log(data.createdQuestions);
      data?.createdQuestions?.map((childQuestion) => {
        thunkAPI.dispatch(
          getAiResponse({
            question: childQuestion.question,
            questionId: childQuestion._id,
            topicId,
          })
        );
      });
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;

      payload.setIsSubmitBtnEnabled(true);
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } });
    }
  }
);

export const addNewQuestions = createAsyncThunk(
  "chatGPT/addNewQuestions",
  async (payload, thunkAPI) => {
    try {
      const { questionsList, askQuestionInChat, topicId, closeModal } = payload;
      const state = thunkAPI.getState();
      const userId = state.sessionSlice.sessionUser.user._id;
      // console.log(payload);

      let { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/add-new-questions/${topicId}`,
        {
          questionsList,
          askQuestionInChat:
            askQuestionInChat == "newChat" ? "" : askQuestionInChat.value,
          userId,
        }
      );
      thunkAPI.dispatch(
        newAlert({ type: "success", message: "New question added" })
      );
      closeModal();
      // console.log(data);
      if (data?.createdQuestions) {
        // console.log("++++++++++++++++++", data.createdQuestions);
        for (let i = 0; i < data.createdQuestions.length; i++) {
          data.createdQuestions[i].chatId = data.createdQuestions[i].chat;
          data.createdQuestions[i].topic = topicId;
          // console.log("++++++++++++++++++", data.createdQuestions[i]);
          internals.addQuestionsToLS([data.createdQuestions[i]]);
        }
      }
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;

      payload.setIsSubmitBtnEnabled(true);
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } })();
    }
  }
);

export const getTopic = createAsyncThunk(
  "chatGPT/getTopicResearchTillNowByTopicId",
  async (payload, thunkAPI) => {
    try {
      const { topicId } = payload;

      const { data } = await axiosInstance.get(
        `/api/admin/chat-gpt-interface-v2/get-topic/${topicId}`
      );
      console.log(data);

      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);

export const getAiResponse = createAsyncThunk(
  "chatGPT/getAiResponse",
  async (payload, thunkAPI) => {
    try {
      const {
        question,
        questionId,
        topicId,
        fGenerateNewResponse = false,
      } = payload;
      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/get-ai-response`,
        {
          question,
          questionId,
          topicId,
          fGenerateNewResponse,
        }
      );
      internals.removeQuestionForApi(questionId);
      thunkAPI.dispatch(readLocalStorage_askQuestionToApi());
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;

      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));

      return Promise.reject({
        error: { message: errorMessage },
      });
    }
  }
);

export const getResponse = createAsyncThunk(
  "chatGPT/getResponse",
  async (payload, thunkAPI) => {
    try {
      const { questionId } = payload;
      const { data } = await axiosInstance.get(
        `/api/admin/chat-gpt-interface-v2/get-response/${questionId}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);

export const updateFilteredPointsAndSentences = createAsyncThunk(
  "chatGPT/updateFilteredPointsAndSentences",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const createdBy = state.sessionSlice.sessionUser.user._id;
      console.log("updateFilteredPointsAndSentences");
      const payloadBody = {
        ...payload,
        element: { ...payload.element, createdBy },
      };
      if (payloadBody.operation !== "add") delete payloadBody.element;
      console.log(payload);
      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/update-filtered-points-sentences`,
        payloadBody
      );
      console.log(data);
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);

export const strikeOutQuestion = createAsyncThunk(
  "chatGPT/strikeOutQuestion",
  async (payload, thunkAPI) => {
    try {
      const { indexInHeadingQuestions, fStrikeout, headingQuestions } = payload;
      const state = thunkAPI.getState();
      const topicId = state.chatGPTSlice.topic._id;
      const { indexToAddPeerQuestionAt } =
        internals.getIndexToAddPeerQuestionAt({
          headingQuestions: state.chatGPTSlice.topic.headingQuestions,
          questionRelations: state.chatGPTSlice.topic.questionRelations,
          parentIndexInHeadingQuestions: indexInHeadingQuestions,
        });
      const headingQuestionsIdsToStrikeout = Object.values(headingQuestions)
        ?.slice(indexInHeadingQuestions, indexToAddPeerQuestionAt)
        ?.map((x) => x?._id);
      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/update-heading-questions`,
        {
          headingQuestionsIdsToStrikeout,
          topicId,
          fStrikeout,
        }
      );
      console.log("question striked", topicId);

      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } });
    }
  }
);

export const deleteHeading = createAsyncThunk(
  "chatGPT/deleteHeading",
  async (payload, thunkAPI) => {
    try {
      const { headingId } = payload;
      const state = thunkAPI.getState();
      const topicId = state.chatGPTSlice.topic._id;
      const { data } = await axiosInstance.put(
        `/api/admin/chat-gpt-interface-v2/delete-heading`,
        {
          headingId,
          topicId,
        }
      );
      console.log("heading deleted", headingId);
      thunkAPI.dispatch(
        newAlert({ type: "success", message: "Heading Deleted" })
      );
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } });
    }
  }
);

export const onDragEndLHS = createAsyncThunk(
  "chatGPT/onDragEndLHS",
  async (payload, thunkAPI) => {
    try {
      const { eleMovedIndex, eleBelowIndex } = payload;
      // console.log(payload);
      const state = thunkAPI.getState();
      const topicId = state.chatGPTSlice.topic._id;
      let questionRelationsQuestions = [];
      if (eleMovedIndex === eleBelowIndex) return;
      if (
        state.chatGPTSlice.topic.headingQuestions[eleMovedIndex]?.relation !==
        state.chatGPTSlice.topic.headingQuestions[eleBelowIndex]?.relation
      )
        return;

      //todo: update headingQuestions
      let {
        indexToAddPeerQuestionAt: eleMovedEnd,
        indexInQuestionRelations: eleMovedIndexInQuestionRelations,
      } = internals.getIndexToAddPeerQuestionAt({
        headingQuestions: state.chatGPTSlice.topic.headingQuestions,
        questionRelations: state.chatGPTSlice.topic.questionRelations,
        parentIndexInHeadingQuestions: eleMovedIndex,
      });
      let eleMovedCount = Math.abs(eleMovedEnd - eleMovedIndex);
      let {
        indexToAddPeerQuestionAt: eleBelowEnd,
        indexInQuestionRelations: eleBelowIndexInQuestionRelations,
      } = internals.getIndexToAddPeerQuestionAt({
        headingQuestions: state.chatGPTSlice.topic.headingQuestions,
        questionRelations: state.chatGPTSlice.topic.questionRelations,
        parentIndexInHeadingQuestions: eleBelowIndex,
      });

      if (
        state.chatGPTSlice.topic.headingQuestions[eleMovedIndex]?.relation !==
          undefined &&
        state.chatGPTSlice.topic.headingQuestions[eleBelowIndex]?.relation !==
          undefined
      ) {
        //todo: update questionRelations questions requence
        questionRelationsQuestions = Array.from(
          state.chatGPTSlice.topic.questionRelations[
            state.chatGPTSlice.topic.headingQuestions[eleMovedIndex]?.relation
          ]?.questions
        );
        const item = questionRelationsQuestions?.splice(
          eleMovedIndexInQuestionRelations - 1,
          1
        );
        questionRelationsQuestions?.splice(
          eleBelowIndexInQuestionRelations - 1,
          0,
          ...item
        );
        // console.log(questionRelationsQuestions);
        // console.log("process completed");
      }
      const eleBelowCount = Math.abs(eleBelowEnd - eleBelowIndex);
      if (eleMovedIndex < eleBelowIndex)
        eleBelowEnd = eleBelowEnd - eleMovedCount;
      if (eleBelowCount > 1) eleBelowEnd = eleBelowEnd - eleBelowCount + 1;
      // console.log("eleMovedCount ->", eleMovedCount);
      // console.log("eleMovedEnd ->", eleMovedEnd);
      // console.log("eleBelowCount ->", eleBelowCount);
      // console.log("eleBelowEnd ->", eleBelowEnd - 1);
      const updatedHeadingQuestions = internals.moveItems(
        state.chatGPTSlice.topic.headingQuestions,
        eleMovedIndex,
        eleMovedCount,
        eleBelowEnd - 1
      );

      const { data } = await axiosInstance.put(
        `/api/admin/chat-gpt-interface-v2/update-heading-questions-order-after-dnd`,
        {
          updatedHeadingQuestions,
          questionRelationsQuestions,
          relation:
            state.chatGPTSlice.topic.headingQuestions[eleMovedIndex]?.relation,
          topicId,
        }
      );
      // console.log(data);
      thunkAPI.dispatch(
        newAlert({ type: "success", message: "Question moved successfuly" })
      );
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } });
    }
  }
);

export const upsertChatMap = createAsyncThunk(
  "chatGPT/upsertChatMap",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const createdBy = state?.sessionSlice?.sessionUser?.user?._id;
      const { gptId, gptName, gptQuestionsMap, syncComplete, setSyncingChat } =
        payload;
      const { data, status } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/upsert-chat-map`,
        {
          gptId,
          gptName,
          gptQuestionsMap,
          createdBy,
        }
      );
      if (status === 200 && syncComplete) {
        setSyncingChat(false);
        thunkAPI.dispatch(
          newAlert({ type: "success", message: "Sync Successfull" })
        );
      }
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);

export const addAlias = createAsyncThunk(
  "chatGPT/addAlias",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const topicId = state.chatGPTSlice.topic._id;
      const { questionId, questionAlias } = payload;
      const { data } = await axiosInstance.post(
        `/api/admin/chat-gpt-interface-v2/add-alias`,
        {
          topicId,
          questionId,
          questionAlias,
        }
      );
      thunkAPI.dispatch(newAlert({ type: "success", message: "Alias added!" }));

      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;

      thunkAPI.dispatch(newAlert({ type: "danger", message: errorMessage }));
      return Promise.reject({ error: { message: errorMessage } });
    }
  }
);

export const regenerateResponse = (payload) => {
  const { questionId, question, topicId, contextProvided } = payload;
  const questionObject = {
    _id: questionId,
    question,
    topic: topicId,
    contextProvided,
  };

  internals.addQuestionsToLS([questionObject]);
};

export const chatGPTSlice = createSlice({
  name: "chatGPT",
  initialState: chatGPTSliceInitialState,
  reducers: {
    setHeadingQuestions: (state, action) => {
      const { payload } = action;
      state.topic.headingQuestions = payload;
    },
    setLoadingStateForQuestion: (state, action) => {
      const {
        payload: { questionId, loadingState },
      } = action;
      if (loadingState === true) {
        state.questions[questionId].isResponseLoading = true;
        state.questions[questionId].responseStatus = "pending";
      }
    },
    setFShowSelectionMenu: (state, action) => {
      const { payload } = action;
      state.fShowSelectionMenu = payload;
    },
  },
  extraReducers: {
    [addNewHeading.pending]: (state, payload) => {
      state.loading = true;
    },
    [addNewHeading.fulfilled]: (state, action) => {
      const { headingQuestions } = action.payload;

      state.topic.headingQuestions = headingQuestions;
    },
    [addNewHeading.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [addNewQuestion.pending]: (state, payload) => {
      state.loading = true;
    },
    [addNewQuestion.fulfilled]: (state, action) => {
      let {
        createdQuestion,
        headingQuestions,
        questionRelations,
        // payload,
        // callb,
      } = action.payload;
      console.log("created", createdQuestion);
      let newMappedQuestions = internals.mapArrayToObjectByKey({
        array: [createdQuestion],
        key: "_id",
      });
      //   set loading state for question
      Object.values(newMappedQuestions).forEach((question) => {
        question.isResponseLoading = true;
        question.responseStatus = "pending";
      });

      state.questions = {
        ...state.questions,
        ...newMappedQuestions,
      };
      state.topic.headingQuestions = headingQuestions;
      if (questionRelations) {
        const newMappedQuestionRelations = internals.mapArrayToObjectByKey({
          array: questionRelations,
          key: "_id",
        });
        console.log(newMappedQuestionRelations);
        state.topic.questionRelations = newMappedQuestionRelations;
      }
    },
    [addNewQuestion.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [addNewChildQuestions.pending]: (state, payload) => {
      state.loading = true;
    },
    [addNewChildQuestions.fulfilled]: (state, action) => {
      let { createdQuestions, createdQuestionRelations, headingQuestions } =
        action.payload;

      const newMappedQuestions = internals.mapArrayToObjectByKey({
        array: createdQuestions,
        key: "_id",
      });
      //   set loading state for question
      Object.values(newMappedQuestions).forEach((question) => {
        question.isResponseLoading = true;
        question.responseStatus = "pending";
      });
      const newMappedQuestionRelations = internals.mapArrayToObjectByKey({
        array: [createdQuestionRelations],
        key: "_id",
      });

      state.questions = {
        ...state.questions,
        ...newMappedQuestions,
      };
      state.topic.questionRelations = {
        ...state.topic.questionRelations,
        ...newMappedQuestionRelations,
      };
      state.topic.headingQuestions = headingQuestions;
    },
    [addNewChildQuestions.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [addNewQuestions.pending]: (state, action) => {
      state.loading = true;
    },
    [addNewQuestions.fulfilled]: (state, action) => {
      let { createdQuestions, questionRelations, headingQuestions } =
        action.payload;

      const newMappedQuestions = internals.mapArrayToObjectByKey({
        array: createdQuestions,
        key: "_id",
      });
      //   set loading state for question
      Object.values(newMappedQuestions).forEach((question) => {
        question.isResponseLoading = true;
        question.responseStatus = "pending";
      });
      const newMappedQuestionRelations = internals.mapArrayToObjectByKey({
        array: questionRelations,
        key: "_id",
      });

      state.questions = {
        ...state.questions,
        ...newMappedQuestions,
      };
      state.topic.questionRelations = newMappedQuestionRelations;

      state.topic.headingQuestions = headingQuestions;
    },
    [addNewQuestions.rejected]: (state, action) => {
      const { error } = action;
      state.error = error.message;
      state.loading = false;
    },

    [getTopic.pending]: (state, action) => {
      state.loading = true;
    },
    [getTopic.fulfilled]: (state, action) => {
      const topic = action.payload;

      //todo: map questions
      const newMappedQuestions = internals.mapArrayToObjectByKey({
        array: topic?.questions,
        key: "_id",
      });

      state.questions = {
        ...state.questions,
        ...newMappedQuestions,
      };
      state.topic._id = topic?._id;
      state.topic.topic = topic?.topic;
      state.topic.headingQuestions = topic?.headingQuestions;

      if (topic?.responses) {
        const newMappedResponses = internals.mapArrayToObjectByKey({
          array: topic?.responses,
          key: "question",
          fCreateArrayOfValuesForSameKey: true,
        });

        state.responses = newMappedResponses;
      }

      if (topic?.questionRelations) {
        const newMappedQuestionRelations = internals.mapArrayToObjectByKey({
          array: topic?.questionRelations,
          key: "_id",
        });
        // console.log(newMappedQuestionRelations);
        state.topic.questionRelations = newMappedQuestionRelations;
      }

      if (topic?.filteredPoints) {
        //todo: map filteredPoints
        const groupFilteredPointsAndStartInTextResponse =
          internals.mapFilteredTextToGroupFilteredTextAndStartInTextResponse({
            array: topic?.filteredPoints,
            groupKeyName: "points",
          });
        const newMappedFilterdPoints = internals.mapArrayToObjectByKey({
          array: groupFilteredPointsAndStartInTextResponse,
          key: "question",
        });
        state.topic.filteredPoints = newMappedFilterdPoints;
      }

      if (topic?.filteredSentences) {
        //todo: map filteredSentences
        const groupFilteredSentencesAndStartInTextResponse =
          internals.mapFilteredTextToGroupFilteredTextAndStartInTextResponse({
            array: topic?.filteredSentences,
            groupKeyName: "sentences",
          });
        // console.log("filteredSentences");
        // console.log(groupFilteredSentencesAndStartInTextResponse);
        const newMappedFilterdSentences = internals.mapArrayToObjectByKey({
          array: groupFilteredSentencesAndStartInTextResponse,
          key: "question",
        });
        // console.log(newMappedFilterdSentences);
        state.topic.filteredSentences = newMappedFilterdSentences;
      }

      window.history.pushState(
        {},
        "",
        `/admin/chatGPTInterfaceV2/${topic?.topic
          ?.split(/[\s,./-]+/)
          .slice(0, 20)
          .join(" ")
          .trim()
          .replace(/[^a-zA-Z0-9-]/g, "-")
          .split("-")
          .filter((x) => x != "")
          .join("-")}/${topic?._id}`
      );
    },
    [getTopic.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [getAiResponse.pending]: (state, action) => {},
    [getAiResponse.fulfilled]: (state, action) => {
      const { response, filteredPoints } = action.payload;

      const questionId = response.question;
      const responsesForQuestion = state.responses[questionId];

      if (responsesForQuestion?.length) {
        state.responses[questionId].push(response);
      } else {
        state.responses[questionId] = [response];
      }

      //   hide loading for question
      state.questions[questionId].isResponseLoading = false;
      state.questions[questionId].responseStatus = "completed";

      if (filteredPoints && filteredPoints.length) {
        //todo: map filteredPoints
        const groupFilteredPointsAndStartInTextResponse =
          internals.mapFilteredTextToGroupFilteredTextAndStartInTextResponse({
            array: filteredPoints,
            groupKeyName: "points",
          });
        const newMappedFilterdPoints = internals.mapArrayToObjectByKey({
          array: groupFilteredPointsAndStartInTextResponse,
          key: "question",
        });

        const filteredPointsForQuestion =
          newMappedFilterdPoints[questionId]?.points;

        const existingFilteredPointsForQuestion =
          state.topic.filteredPoints[questionId];

        if (existingFilteredPointsForQuestion) {
          state.topic.filteredPoints[questionId].points.push(
            ...filteredPointsForQuestion
          );
        } else {
          state.topic.filteredPoints[questionId] =
            newMappedFilterdPoints[questionId];
        }
      }
    },
    [getAiResponse.rejected]: (state, payload) => {
      const questionId = payload?.meta?.arg?.questionId;

      state.questions[questionId].isResponseLoading = false;
      state.questions[questionId].responseStatus = "failed";
    },

    [getResponse.pending]: (state, payload) => {
      state.loading = true;
    },
    [getResponse.fulfilled]: (state, action) => {
      const { response } = action.payload;

      const newMappedResponses = internals.mapArrayToObjectByKey({
        array: response,
        key: "question",
        fCreateArrayOfValuesForSameKey: true,
      });
      state.responses = {
        ...state.responses,
        ...newMappedResponses,
      };
    },
    [getResponse.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [updateFilteredPointsAndSentences.pending]: (state, payload) => {
      state.loading = true;
    },
    [updateFilteredPointsAndSentences.fulfilled]: (state, action) => {
      const topic = action.payload;

      if (topic?.filteredPoints) {
        //todo: map filteredPoints
        const groupFilteredPointsAndStartInTextResponse =
          internals.mapFilteredTextToGroupFilteredTextAndStartInTextResponse({
            array: topic?.filteredPoints,
            groupKeyName: "points",
          });
        const newMappedFilterdPoints = internals.mapArrayToObjectByKey({
          array: groupFilteredPointsAndStartInTextResponse,
          key: "question",
        });
        state.topic.filteredPoints = newMappedFilterdPoints;
      }

      if (topic?.filteredSentences) {
        //todo: map filteredSentences
        const groupFilteredSentencesAndStartInTextResponse =
          internals.mapFilteredTextToGroupFilteredTextAndStartInTextResponse({
            array: topic?.filteredSentences,
            groupKeyName: "sentences",
          });
        // console.log("filteredSentences");
        // console.log(groupFilteredSentencesAndStartInTextResponse);
        const newMappedFilterdSentences = internals.mapArrayToObjectByKey({
          array: groupFilteredSentencesAndStartInTextResponse,
          key: "question",
        });
        // console.log(newMappedFilterdSentences);
        state.topic.filteredSentences = newMappedFilterdSentences;
      }
    },
    [updateFilteredPointsAndSentences.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [strikeOutQuestion.pending]: (state, payload) => {
      state.loading = true;
    },
    [strikeOutQuestion.fulfilled]: (state, action) => {
      const { headingQuestions } = action.payload;

      state.topic.headingQuestions = headingQuestions;
    },
    [strikeOutQuestion.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [deleteHeading.pending]: (state, payload) => {
      state.loading = true;
    },
    [deleteHeading.fulfilled]: (state, action) => {
      const { headingQuestions } = action.payload;

      state.topic.headingQuestions = headingQuestions;
    },
    [deleteHeading.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [onDragEndLHS.pending]: (state, payload) => {
      state.loading = true;
    },
    [onDragEndLHS.fulfilled]: (state, action) => {
      const { headingQuestions, questionRelations } = action.payload;

      state.topic.headingQuestions = headingQuestions;
      if (questionRelations) {
        const newMappedQuestionRelations = internals.mapArrayToObjectByKey({
          array: questionRelations,
          key: "_id",
        });
        state.topic.questionRelations = newMappedQuestionRelations;
      }
    },
    [onDragEndLHS.rejected]: (state, payload) => {
      const { error } = payload;
      state.error = error.message;
      state.loading = false;
    },

    [sendResponseFromLocalStorageToServer.pending]: (state, payload) => {},
    [sendResponseFromLocalStorageToServer.fulfilled]: (state, action) => {
      // console.log("Fullfilled", action.payload);
      const { response, filteredPoints } = action.payload;
      if (response) {
        for (let i = 0; i < response?.length; i++) {
          const questionId = response[i].question;
          const responsesForQuestion = state.responses[questionId];

          if (responsesForQuestion?.length) {
            state.responses[questionId].push(response[i]);
          } else {
            state.responses[questionId] = [response[i]];
          }

          //   hide loading for question
          state.questions[questionId].isResponseLoading = false;
          state.questions[questionId].responseStatus = "completed";
        }
      }

      if (filteredPoints) {
        //todo: map filteredPoints
        const groupFilteredPointsAndStartInTextResponse =
          internals.mapFilteredTextToGroupFilteredTextAndStartInTextResponse({
            array: filteredPoints,
            groupKeyName: "points",
          });
        const newMappedFilterdPoints = internals.mapArrayToObjectByKey({
          array: groupFilteredPointsAndStartInTextResponse,
          key: "question",
        });
        if (state.topic.filteredPoints) {
          state.topic.filteredPoints = {
            ...state.topic.filteredPoints,
            ...newMappedFilterdPoints,
          };
        }
      }
    },
    [sendResponseFromLocalStorageToServer.rejected]: (state, payload) => {
      console.log("sendResponseFromLocalStorageToServer.rejected", payload);

      let questions = Object.keys(state.questions);
      for (let i = 0; i < questions?.length; i++) {
        state.questions[questions[i]].isResponseLoading = false;
      }
    },

    [addAlias.pending]: (state, action) => {
      state.loading = true;
    },
    [addAlias.fulfilled]: (state, action) => {
      const { headingQuestions } = action.payload;

      state.topic.headingQuestions = headingQuestions;
    },
    [addAlias.rejected]: (state, action) => {
      const { error } = action;
      console.log({ error });

      state.error = error.message;
      state.loading = false;
    },
  },
});

// Export Actions
export const {
  setHeadingQuestions,
  setLoadingStateForQuestion,
  setFShowSelectionMenu,
} = chatGPTSlice.actions;

// Export Reducer
export default chatGPTSlice.reducer;
