import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ChatGPTInterfaceV2.css";
import AddHeadingOrQuestion from "../../../components/ChatGPTInterfaceV2/AddHeadingOrQuestion/AddHeadingOrQuestion";
import {
  deleteHeading,
  getTopic,
  onDragEndLHS,
  sendResponseFromLocalStorageToServer,
} from "../../../slices/chatGPTSliceV2";
import FindCreateTopic from "../../../components/ChatGPTInterfaceV2/FindCreateTopic/FindCreateTopic";
import { useParams } from "react-router-dom";
import Question from "../../../components/ChatGPTInterfaceV2/Question/Question";
import Outline from "../../../components/ChatGPTInterfaceV2/ShowOutline/ShowOutline";
import Arrow from "../../../components/ChatGPTInterfaceV2/Arrow/Arrow";
import ImportQuestionModal from "../../../components/ChatGPTInterfaceV2/ImportQuestionModal/ImportQuestionModal";
import OpenChatGptPrompt from "../../../components/ChatGPTInterfaceV2/OpenChatGptPrompt/OpenChatGptPrompt";
import SyncBtn from "../../../components/ChatGPTInterfaceV2/SyncBtn/SyncBtn";

const Heading = ({
  heading,
  level,
  fStrikeout,
  indexInHeadingQuestions,
  headingQuestions,
}) => {
  const fontSize = `${26 - level}px`;
  const dispatch = useDispatch();

  const deleteHeadingElement = (indexInHeadingQuestions) => {
    if (indexInHeadingQuestions !== -1) {
      dispatch(
        deleteHeading({
          headingId: headingQuestions[indexInHeadingQuestions]._id,
        })
      );
    }
  };
  return (
    <p
      style={{
        marginLeft: `${15 * level}px`,
        fontSize,
        textDecoration: fStrikeout ? "line-through" : "none",
      }}
      className="highlight-heading position-relative"
    >
      {heading}
      <span
        className="deleteHeadingBtn position-absolute   badge bg-danger"
        onClick={() => deleteHeadingElement(indexInHeadingQuestions)}
        style={{
          cursor: "pointer",
          fontSize: "10px",
          width: "20px",
          right: "-20px",
          top: "-10px",
          margin: "0 0 10px 20px",
        }}
      >
        X
      </span>
    </p>
  );
};

const ChatGPTInterfaceV2 = () => {
  const { topicId } = useParams();
  const [outlineState, setOutlineState] = useState([]);
  const [showOutline, setShowOutline] = useState(false);
  const [eleMovedIndex, setEleMovedIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const dispatch = useDispatch();
  const topic = useSelector((state) => state.chatGPTSlice.topic);
  const questions = useSelector((state) => state.chatGPTSlice.questions);
  const sta = useSelector((state) => state.chatGPTSlice.responses);
  console.log(sta);
  const createOutline = (fShowStatements) => {
    let outline = [];
    // headingQuestions.forEach((hq) => {
    for (let i = 0; i < topic?.headingQuestions.length; i++) {
      let { relation, level, question, questionAlias, fStrikeout } =
        topic?.headingQuestions[i];
      if (level == undefined) {
        level = 0;
      }
      if (!fStrikeout && (relation || question)) {
        if (question) {
          const text = questions[`${question}`]?.question;
          outline.push({ text, level, question });
          //   let fT = filteredPoints.filter((fp) => fp.question === question);
          console.log(question);
          let fT = topic?.filteredPoints[question]?.points;
          console.log(fT);
          if (fShowStatements == true) {
            // let fS = topic?.filteredSentences.filter(
            //   (fp) => fp.question === question._id
            // );
            let fS = topic?.filteredSentences[question]?.sentences;
            console.log(fS);
            let combinedArr = undefined;
            if (fS == undefined) {
              if (fT !== undefined) {
                combinedArr = [...fT];
              }
            } else {
              if (fT !== undefined) {
                combinedArr = [...fT, ...fS];
              } else {
                combinedArr = [...fS];
              }
            }
            if (combinedArr !== undefined) {
              //ensuring filteredSentences come after filteredPoints for same start
              combinedArr.sort(
                (a, b) =>
                  a.startInTextResponse - b.startInTextResponse ||
                  (a.startInTextResponse === b.startInTextResponse
                    ? a in fT
                      ? -1
                      : 1
                    : 0)
              );
            }
            fT = combinedArr;
          }
          level++;
          if (fT !== undefined) {
            fT = fT.map((t) => ({ ...t, level, question }));
            console.log(fT);
            // outline.slice(outline.length, 0, ...fT);
            outline.push(...fT);
          }
        } else if (relation) {
          const text = topic?.questionRelations[`${relation}`]?.relation;
          outline.push({ relationText: text, level, relation });
        }
        // else outline.push({ text: relation, level });
      }
    }
    // });
    setOutlineState(outline);
  };

  useEffect(() => {
    if (topicId) {
      dispatch(getTopic({ topicId }));
      dispatch(sendResponseFromLocalStorageToServer());
    }
  }, [topicId]);

  const onEleDrop = (eleBelowIndex) => {
    dispatch(
      onDragEndLHS({
        eleMovedIndex,
        eleBelowIndex,
      })
    );
  };

  return (
    <div className=" chatgpt-main-container my-5 mx-2">
      <div className="chatgpt-layout w-100">
        {!topic?.topic ? (
          <FindCreateTopic />
        ) : (
          <div className="d-flex justify-content-between">
            <h4>{topic?.topic}</h4>

            <div
              className="d-flex align-items-center"
              id="import-question-btn-container"
            >
              <ImportQuestionModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </div>
        )}
        {topic?.topic && (
          <div
            className="d-flex w-100 justify-content-between"
            style={{ overflowX: "hidden" }}
          >
            <div id="sync-btn-container">
              <SyncBtn />
              <div className="d-flex gap-2">
                <AddHeadingOrQuestion fHasParentQuestion={false} />
                <p>Add Heading/Question</p>
              </div>
              {topic?.headingQuestions &&
                topic?.headingQuestions.length > 0 &&
                topic?.headingQuestions.map(
                  (
                    {
                      heading,
                      question,
                      questionAlias,
                      relation,
                      level,
                      _id,
                      fStrikeout,
                    },
                    i
                  ) => (
                    <div key={i} data-heading-questions-index={i}>
                      {heading && level && (
                        <div
                          className="heading-question-btn"
                          draggable="true"
                          onDragStart={() => setEleMovedIndex(i)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => onEleDrop(i)}
                        >
                          <i className="bi bi-grip-vertical"></i>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <AddHeadingOrQuestion
                              fHasParentQuestion={false}
                              indexInHeadingQuestions={i}
                              question={question}
                              level={level}
                              filteredPoints={topic?.filteredPoints[question]}
                              fStrikeout={fStrikeout}
                              headingQuestions={topic?.headingQuestions}
                            />
                            <Heading
                              heading={heading}
                              level={level}
                              fStrikeout={fStrikeout}
                              indexInHeadingQuestions={i}
                              headingQuestions={topic?.headingQuestions}
                            />
                          </div>
                        </div>
                      )}
                      {/* //todo: render relation */}
                      {relation && !question && level && (
                        <div
                          className="fw-dark text-muted d-flex align-center"
                          style={{ marginLeft: `${45}px` }}
                        >
                          <Arrow level={level} type={"relation"} />{" "}
                          <p style={{ color: "#800000", fontSize: "12px" }}>
                            {topic?.questionRelations[relation]?.relation}
                          </p>
                        </div>
                      )}
                      {/* //todo: render child questions */}
                      {relation && question && level && (
                        <div
                          style={{
                            // marginLeft: `${35 * (level - 1)}px`,
                            display: "flex",
                          }}
                          draggable="true"
                          onDragStart={(_) => setEleMovedIndex(i)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => onEleDrop(i)}
                        >
                          <i className="bi bi-grip-vertical"></i>
                          <Question
                            i={i}
                            question={question}
                            questionAlias={questionAlias}
                            _id={_id}
                            containerClassName={"child-relation-question"}
                            questionTypeClassName={"relation"}
                            showOutline={showOutline}
                            level={level}
                            relation={relation}
                            fStrikeout={fStrikeout}
                          />
                        </div>
                      )}
                      {/* //todo: render question */}
                      {!relation && question && level && (
                        <div
                          style={{ display: "flex" }}
                          draggable="true"
                          onDragStart={(_) => setEleMovedIndex(i)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => onEleDrop(i)}
                        >
                          <i className="bi bi-grip-vertical"></i>
                          <Question
                            i={i}
                            question={question}
                            questionAlias={questionAlias}
                            _id={_id}
                            containerClassName={"heading-question-btn"}
                            questionTypeClassName={"question-tag"}
                            showOutline={showOutline}
                            level={level}
                            fStrikeout={fStrikeout}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}

              <button
                className="btn btn-primary btn-sm m-1"
                style={{
                  backgroundColor: "#3FACCB",
                  border: "none",
                  fontSize: "10px",
                }}
                onClick={() => {
                  createOutline(true);
                  setShowOutline(true);
                }}
              >
                Show Outline With Statements
              </button>
              <button
                className="btn btn-primary btn-sm m-1"
                style={{
                  backgroundColor: "#3FACCB",
                  border: "none",
                  fontSize: "10px",
                }}
                onClick={() => {
                  createOutline(false);
                  setShowOutline(true);
                }}
              >
                Show Outline Without Statements
              </button>
              <br />
              <button
                className="btn btn-primary btn-sm m-1"
                style={{
                  backgroundColor: "#3FACCB",
                  border: "none",
                  fontSize: "10px",
                }}
                onClick={() => {
                  createOutline(false);
                  setShowOutline(true);
                }}
              >
                Show Outline
              </button>
              <button
                className="btn btn-primary btn-sm m-1"
                style={{
                  backgroundColor: "#3FACCB",
                  border: "none",
                  fontSize: "10px",
                }}
                onClick={() => setShowOutline(false)}
              >
                Hide Outline
              </button>
            </div>

            {showOutline && <Outline outlineState={outlineState} />}
          </div>
        )}
      </div>
      <OpenChatGptPrompt
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
      />
      <button
        id="chatGPT-prompt"
        style={{ display: "none" }}
        onClick={() => {
          setShowPrompt(true);
        }}
      >
        Open ChatGPT Prompt
      </button>
    </div>
  );
};

export default ChatGPTInterfaceV2;
