import React, { useEffect, useState } from "react";
import {
  checkFDaysPassedSinceSave,
  getStagesStatusForApplication,
} from "../../../components/ProfilePage/utility/userUtils";
import PaymentPreferencePage from "../../../components/ApplicationStatus/PaymentPreferencePage/PaymentPreferencePage";
import PaymentPreferenceSave from "../../../components/ApplicationStatus/PaymentPreferenceSave/PaymentPreferenceSave";
import Test1Completion from "../../../components/ApplicationStatus/Test1Completion/Test1Completion";
import CannotContinuePage from "../../../components/ApplicationStatus/CannotContinuePage/CannotContinuePage";
import Test2Completion from "../../../components/ApplicationStatus/Test2Completion/Test2Completion";
import ApplicantRejectionPage from "../../../components/ApplicationStatus/ApplicantRejectionPage/ApplicantRejectionPage";
import PageNotFound from "../PageNotFound/PageNotFound";
import { newAlert } from "../../../slices/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  redirectToTestPage,
  setUserJobStageHistory,
} from "../../../helpers/stubs";
import { Spinner } from "react-bootstrap";
import RejectedByAssessment from "../../../components/ApplicationStatus/RejectedByAssessment/RejectedByAssessment";
import Test3Preference from "../../../components/ApplicationStatus/Test3Preference/Test3Preference";
import Test3Completion from "../../../components/ApplicationStatus/Test3Completion/Test3Completion";
const ApplicationStatus = () => {
  const dispatch = useDispatch();
  const { email } = useParams();
  const [stageName, setStageName] = useState("");
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [fRedirectTestPage, setFRedirectTestPage] = useState(false);
  const { sessionUser } = useSelector((state) => state.sessionSlice);

  const setUserJobStageHistoryForStatus = async (status) => {
    switch (status) {
      case "rejectedForWork":
        await setUserJobStageHistory({
          name: "rejectedForWorkPrefPage",
          type: "page",
        });
        break;
      case "test1Completed":
        await setUserJobStageHistory({
          name: "test1CompletedPage",
          type: "page",
        });
        break;
      case "test2Completed":
        await setUserJobStageHistory({
          name: "test2CompletedPage",
          type: "page",
        });
        break;
      case "paymentPreference":
        await setUserJobStageHistory({ name: "paymentPrefPage", type: "page" });
        break;
      case "applicantRejected":
        await setUserJobStageHistory({
          name: "rejectedForTestOrPayPref",
          type: "page",
        });
        break;
      case "preferenceSaved":
        await setUserJobStageHistory({
          name: "weWillLetYouKnowPage",
          type: "page",
        });
        break;
      default:
        // console.log(stageName);
        break;
    }
  };
  const getStageNameBasedOnCurrentStatus = async () => {
    let fRedirect = false;
    try {
      setIsLoadingStatus(true);
      const { stagesData, fRejectedForWork, fRejectedForTest } =
        await getStagesStatusForApplication();
      const {
        stagesStatus,
        paymentPreference,
        fEligibleForTest2,
        fRejected,
        assessment,
        fApplicantRejected,
      } = stagesData || {};
      // console.log({ stagesData, stagesStatus });
      let tStageName = "";
      if (fRejected && (fRejectedForWork || fRejectedForTest)) {
        if (fRejectedForTest) {
          tStageName = "applicantRejected";
        } else if (fRejectedForWork) {
          tStageName = "rejectedForWork";
        }
      } else {
        if (stagesStatus?.test1?.fSaved) {
          tStageName = "test1Completed";
        }
        if (fEligibleForTest2) {
          tStageName = "paymentPreference";
        }
        if (stagesStatus?.paymentPreference?.fSaved) {
          if (paymentPreference === "money") {
            if (fApplicantRejected) {
              tStageName = "applicantRejected";
            } else {
              tStageName = "preferenceSaved";
            }
          } else if (
            paymentPreference === "lecture" &&
            !stagesStatus?.test2?.fSaved
          ) {
            setFRedirectTestPage(true);
            fRedirect = true;
          }
        }
        if (stagesStatus?.test2?.fSaved) {
          const test2SavedAt = stagesStatus?.test2?.savedAt;
          const daysPassedSinceTest = checkFDaysPassedSinceSave(
            test2SavedAt,
            4
          );
          if (["rejected", "blocked"].includes(assessment) || fRejected) {
            tStageName = "test2Rejected";
          } else {
            if (!daysPassedSinceTest) {
              tStageName = "test2Completed";
            } else {
              tStageName = "test3Preference";
            }
          }
        }
        if (
          stagesStatus?.test3Preference?.fSaved &&
          !stagesStatus?.test3?.fSaved
        ) {
          setFRedirectTestPage(true);
          fRedirect = true;
        }
        if (stagesStatus?.test3?.fSaved) {
          tStageName = "test3Completed";
        }
      }
      // console.log({ tStageName });
      setStageName(tStageName);
      await setUserJobStageHistoryForStatus(tStageName);
    } catch (e) {
      dispatch(newAlert({ type: "danger", message: "Something went wrong" }));
    } finally {
      if (!fRedirect) {
        setIsLoadingStatus(false);
      }
    }
  };

  const StatusPage = ({ stageName }) => {
    // console.log({ stageName }, "test1Completed" === stageName);
    switch (stageName) {
      case "rejectedForWork":
        return <CannotContinuePage />;
      case "test1Completed":
        return <Test1Completion />;
      case "paymentPreference":
        return <PaymentPreferencePage />;
      case "preferenceSaved":
        return <PaymentPreferenceSave />;
      case "applicantRejected":
        return <ApplicantRejectionPage />;
      case "test2Rejected":
        return <RejectedByAssessment />;
      case "test2Completed":
        return <Test2Completion />;
      case "test3Preference":
        return <Test3Preference email={email} />;
      case "test3Completed":
        return <Test3Completion />;
      default:
        return <PageNotFound />;
    }
  };

  useEffect(() => {
    getStageNameBasedOnCurrentStatus();
  }, []);

  useEffect(() => {
    if (sessionUser?.user) {
      if (fRedirectTestPage) {
        redirectToTestPage({ sessionUser, dispatch });
      }
    }
  }, [sessionUser, fRedirectTestPage]);

  useEffect(() => {
    if (sessionUser.user && !isLoadingStatus && stageName) {
      // console.log(
      //   email,
      //   sessionUser.user.email,
      //   sessionUser.user.email === email
      // );
      if (sessionUser.user.email !== email) {
        setStageName("404");
      }
    }
  }, [sessionUser, isLoadingStatus, stageName]);
  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      {!isLoadingStatus ? (
        <StatusPage stageName={stageName} />
      ) : (
        <div className="loader text-center pt-3">
          <Spinner variant="secondary" />
        </div>
      )}
    </div>
  );
};

export default ApplicationStatus;
