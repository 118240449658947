import React, { useState } from 'react'
import './SearchTermsReportOptions.css';
import axiosInstance from '../../../axios';
import { useDispatch } from 'react-redux';
import { newAlert } from '../../../slices/alertSlice';
// import report from '../../../dummy_search_term_analysis_data/searchTerm.json'

const SearchTermsReportOptions = ({ setSearchTermsReport, setLoading, setDisplay, setSearchTermFilter, setSortOrder, setSortValue, setSortBtn }) => {
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [currentTab, setCurrentTab] = useState("24H");
    const dispatch = useDispatch();

    const getSearchTermsAnalysisReport = async (toDate, timeFrame) => {
        setSearchTermFilter('')
        setSortOrder(0)
        setSortValue('')
        setSortBtn('')
        setDisplay(false);
        setLoading(true);
        const data = await axiosInstance.get(`/api/admin/get-search-terms-report/date=${toDate}&forlast=${timeFrame}`).catch((err) => {
            dispatch(newAlert({ type: "danger", message: "Bad Request!!" }))
        });
        if (data?.status === 200) {
            setLoading(false);
            if (!data?.data) {
                dispatch(newAlert({ type: "danger", message: "No Search Terms Available!!" }))
            } else {
                setSearchTermsReport(data.data); //final
                // setSearchTermsReport(report); // for testing
                setDisplay(true);
            }
        } else {
            setLoading(false);
            dispatch(newAlert({ type: "danger", message: "Bad Request!!" }))
        }
    }

    return (
        <div className='search-terms-options'>
            <div className='search-term-option-inputs'>
                <input type='date' value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                />
                <button
                    onClick={() => {
                        setCurrentTab("24H")
                        getSearchTermsAnalysisReport(toDate, "24H")
                    }
                    }
                    style={{ backgroundColor: 'rgb(63, 172, 203)' }}
                >Get Report</button>
            </div>
            <div className='search-term-option-tabs'>
                <button onClick={() => getSearchTermsAnalysisReport(toDate, "24H") && setCurrentTab("24H")} style={{ backgroundColor: currentTab === "24H" ? "rgb(63, 172, 203)" : "lightgrey" }} >
                    24 Hour
                </button>
                <button onClick={() => getSearchTermsAnalysisReport(toDate, "1W") && setCurrentTab("1W")} style={{ backgroundColor: currentTab === "1W" ? "rgb(63, 172, 203)" : "lightgrey" }} >
                    1 Week
                </button>
                <button onClick={() => getSearchTermsAnalysisReport(toDate, "1M") && setCurrentTab("1M")} style={{ backgroundColor: currentTab === "1M" ? "rgb(63, 172, 203)" : "lightgrey" }} >
                    1 Month
                </button>
            </div>
        </div>
    )
}

export default SearchTermsReportOptions