import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ImportQuestionModal = ({ isModalOpen = false, setIsModalOpen }) => {
  const [validated, setValidated] = useState(false);
  const [chatId, setChatId] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      className="import-question-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Questions with chatId</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group
            className="mb-4"
            md="4"
            controlId="extract-chat-with-chatId"
          >
            <Form.Label>ChatId</Form.Label>
            <Form.Control
              required
              as="textarea"
              placeholder=""
              style={{ height: "40px" }}
              value={chatId}
              onChange={(e) => setChatId(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              chatId is required
            </Form.Control.Feedback>
          </Form.Group>

          <Modal.Footer className="border-0 p-0 mt-4">
            <Button
              type="submit"
              id="chatId-input-box"
              style={{ width: "fitContent" }}
            >
              Import
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ImportQuestionModal;
