import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate,useNavigate,useSearchParams } from "react-router-dom";
import GoogleLogo from "../../assets/images/google_logo.svg";
import FacebookLogo from "../../assets/images/facebook_logo.svg";
import LinkedinLogo from "../../assets/images/linkedin_logo.svg";
import styles from "./SignUp.module.css";
import {
  signUp,
  updateEmailFromSignUp,
  setLoading,
} from "../../slices/sessionSlice";

import Axios from "../../axios";
import Comp_GuestUserConversionModal from "../../components/GuestUserConversionModal/Comp_GuestUserConversionModal";
import { alertSlice } from "../../slices/alertSlice";
import { getBackendDomain } from "../../helpers/stubs";

const SignUp = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
  });
  const backEndURL = getBackendDomain();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [modalShow, setModalShow] = useState(false);
  const [modalstate, setmodalstate] = useState("otpModal");
  const [pendingUser, setPendingUser] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { loading, pending, sessionUser } = useSelector((state) => state.sessionSlice);
  const setFormField = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    if (pending) {
      navigate("/verify-email-message");
    }
  }, [pending]);

  useEffect(() => {
    if (isSubmitted) {
      const errors = validateForm(formValues);
      setFormErrors(errors);
    }
  }, [formValues, isSubmitted]);

  const handleInputChange = (e) => {
    let { type, name, value } = e.target;

    if (type === "checkbox") value = !formValues.agreeToTerms;
    setFormField(name, value);
  };

  const validateForm = (formValues) => {
    const errors = {};
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      agreeToTerms,
    } = formValues;

    const emailRegex =
      /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;

    if (!firstName) errors.firstName = "Please enter your first name";
    if (!lastName) errors.lastName = "Please enter your last name";

    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) errors.email = "Please enter a valid email";
    if (!email) errors.email = "Please enter your email";

    if (password !== confirmPassword)
      errors.confirmPassword = "Passwords don't match";
    if (password.length < 8)
      errors.password = "Password should be minimum 8 characters";
    if (!password) errors.password = "Please enter a password";
    if (!confirmPassword)
      errors.confirmPassword = "Please confirm your password";

    if (!agreeToTerms) errors.agreeToTerms = "You must agree before proceeding";

    return errors;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    dispatch(setLoading(true));
    const errors = validateForm(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      dispatch(setLoading(false));
      return;
    }

    // sign-up logic here
    console.log("payload:", formValues);
    dispatch(signUp(formValues));
  };

  const decideSignUpForEmail = async () => {
    const reqBody = { email: formValues.email };
    const { data } = await Axios.post(
      "/api/public/check-email-status",
      reqBody
    );
    dispatch(updateEmailFromSignUp(formValues.email));
    if (data.fUserExist) {
      navigate("/sign-in");
    }
    if (data.fPendingUserExist) {
      setModalShow(true);
      setPendingUser({
        _id: data.pendingUser,
        email: formValues.email,
      });
    }
  };
  if (sessionUser?.user) {
    let redirectTo = searchParams.get("ref") || "/";
    
    return <Navigate to={redirectTo} replace />;
  }
  return (
    <>
      {/* //#region otp modal */}
      {/* <Comp_GuestUserConversionModal
        show={modalShow}
        modalstate={modalstate}
        setmodalstate={setmodalstate}
        onHide={() => {
          setModalShow(false);
        }}
        pendingUser={pendingUser}
      /> */}
      {/* //#endregion */}
      <div className="signup p-5 shadow border rounded">
        <i className={`${styles["fa-bolt"]} fa-bolt fa-solid mb-3 fs-2`} />
        <h2 className="fw-bold">Sign Up</h2>
        <p className="mb-4">
          Build marketing strategies by knowing your competitors better.
        </p>
        <div className="socials-sign d-flex flex-column mb-2 flex-lg-row justify-content-center text-center">
          <a
            className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`}
            href={`${backEndURL}/api/session/sign-in/google?redirectTo=/`}
          >
            <img
              src={GoogleLogo}
              className={`${styles["social-sign-logo"]}`}
              alt="google logo"
            />
            <span className="ms-2">Sign Up with Google</span>
          </a>
          {/* <a
            className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`}
            href={`${backEndURL}/api/session/sign-in/facebook?redirectTo=/`}
          >
            <img
              src={FacebookLogo}
              className={`${styles["social-sign-logo"]}`}
              alt="google logo"
            />
            <span className="ms-2">Sign Up with Facebook</span>
          </a>
          <a
            className={`${styles["social-sign"]} d-flex align-items-center justify-content-center`}
            href={`${backEndURL}/api/session/sign-in/linked-in?redirectTo=/`}
          >
            <img
              src={LinkedinLogo}
              className={`${styles["social-sign-logo"]}`}
              alt="google logo"
            />
            <span className="ms-2">Sign Up with Linkedin</span>
          </a> */}
        </div>
        <div className={`${styles["strike"]} mb-3`}>
          <span className={`${styles["strike-span"]}`}>
            or Sign Up with email
          </span>
        </div>
        <form onSubmit={handleFormSubmit} noValidate>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label font-semibold">
                  First Name
                </label>
                <input
                  type="text"
                  className={`form-control p-2 ${
                    formErrors.firstName ? "is-invalid" : ""
                  } `}
                  id="firstName"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                />
                <div className="invalid-feedback">{formErrors.firstName}</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label font-semibold">
                  Last Name
                </label>
                <input
                  type="text"
                  className={`form-control p-2 ${
                    formErrors.lastName ? "is-invalid" : ""
                  }`}
                  id="lastName"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                />
                <div className="invalid-feedback">{formErrors.lastName}</div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label font-semibold">
              Email
            </label>
            <input
              type="email"
              className={`form-control p-2 ${
                formErrors.email ? "is-invalid" : ""
              }`}
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              // onBlur={decideSignUpForEmail}
            />
            <div className="invalid-feedback">{formErrors.email}</div>
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label font-semibold">
              Password
            </label>
            <input
              type="password"
              className={`form-control p-2 ${
                formErrors.password ? "is-invalid" : ""
              }`}
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback">{formErrors.password}</div>
          </div>

          <div className="mb-3">
            <label
              htmlFor="confirmPassword"
              className="form-label font-semibold"
            >
              Confirm Password
            </label>
            <input
              type="password"
              className={`form-control p-2 ${
                formErrors.confirmPassword ? "is-invalid" : ""
              }`}
              id="confirmPassword"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback">
              {formErrors.confirmPassword}{" "}
            </div>
          </div>

          <div className="mb-4">
            <input
              type="checkbox"
              className={`form-check-input me-2 ${
                formErrors.agreeToTerms ? "is-invalid" : ""
              }`}
              id="termsAndCondition"
              name="agreeToTerms"
              onChange={handleInputChange}
              checked={formValues.agreeToTerms}
            />
            <label className="form-check-label" htmlFor="termsAndCondition">
              I agree to the{" "}
              <a href="https://mentorstudents.org/terms-and-conditions">
                terms of use{" "}
              </a>
              and{" "}
              <a href="https://mentorstudents.org/privacy-policy">
                privacy policy{" "}
              </a>
            </label>
            <div className="invalid-feedback">{formErrors.agreeToTerms} </div>
          </div>
          <button
            type="submit"
            className={`${styles["sign-button"]} btn btn-primary w-100 shadow-sm mb-2 text-uppercase`}
            disabled={loading} // Disable button when loading
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-lg" // Default color of the spinner
                role="status"
                aria-hidden="true"
                style={{ width: "2rem", height: "2rem", color: "white" }} // Custom size for the spinner
              ></span>
            ) : (
              "Sign Up"
            )}
          </button>

          <p>
            Already have an account?{" "}
            <span
              style={{ color: "#0D6EFD", cursor: "pointer" }}
              to="/sign-up"
              className="font-semibold"
              onClick={() => {
                window.location.href = "/sign-in";
              }}
            >
              Sign In
            </span>
          </p>
        </form>
      </div>
    </>
  );
};

export default SignUp;
