import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Dropdown } from "react-bootstrap";
import "./GenerateArticleStatus.css";
import { SplitView } from "../../../components/GenerateArticleStatus/SplitView/SplitView";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchArticleBatches,
  fetchArticlesBasedOnPcAndArticleBatch,
  fetchPcs,
  getResponsesWithSelectedFilters,
  getGeneratedArticle,
} from "../../../slices/generateArticleSlice";
import DOMPurify from "dompurify";
import Axios from "../../../axios";
import { newAlert } from "../../../slices/alertSlice";
import { useLocation } from "react-router-dom";
import PromptModal from "../../../components/GenerateArticleStatus/promptModal/PromptModal";
const { REACT_APP_PROMPT_MASTER_BACK_END_DOMAIN: backendURL } = process.env;

const leftRightContainerStyle = {
  width: "100%",
  borderRadius: "4px",
  backgroundColor: "#edf2fa",
  paddingBottom: "1rem",
  height: "100vh",
  overflowY: "scroll",
};

const LeftSide = ({
  mainArticle,
  setMainArticle,
  pc,
  setPc,
  runIndex,
  setRunIndex,
  mode,
  setMode,
  setUrl,
  setParsedHTML,
  setTitle,
  articleBatch,
  setArticleBatch,
  setSerpArticle,
}) => {
  //read query params
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const pcInUrl = params.get("pc");
  const articleBatchInUrl = params.get("articleBatch");
  const articleInUrl = params.get("article");
  const runIndexInUrl = params.get("runIndex");
  const modeInUrl = params.get("mode");
  const serpInUrl = params.get("serp");
  const [isHovered, setIsHovered] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(null);
  const [generatedArticleActiveKey, setGeneratedArticleActiveKey] = useState(1);

  const dispatch = useDispatch();
  const articleBatches = useSelector(
    (state) => state.generateArticleSlice.articleBatches
  );
  const pcs = useSelector((state) => state.generateArticleSlice.pcs);
  const articles = useSelector((state) => state.generateArticleSlice.articles);
  const promptsAndResponses = useSelector(
    (state) => state.generateArticleSlice.promptsAndResponses
  );

  const generatedArticle = useSelector(
    (state) => state.generateArticleSlice.generatedArticle
  );
  const serpArticles = generatedArticle?.serpArticles || [];
  // console.log("generatedArticle 👍👍", generatedArticle);
  const [maxRunIndex, setMaxRunIndex] = useState(0);
  const [activeKey, setActiveKey] = useState([
    ...promptsAndResponses.map((obj, index) => index),
  ]);
  const [resActiveKey, setResActiveKey] = useState([
    ...promptsAndResponses.map((obj, index) =>
      obj?.responses.map((resObj, j) => j)
    ),
  ]);
  const accordionRef = useRef(null);
  useEffect(() => {
    setActiveKey([...promptsAndResponses.map((obj, index) => index)]);
    setResActiveKey(
      [
        ...promptsAndResponses.map((obj, index) =>
          obj?.responses.map((resObj, j) => index + "-" + j)
        ),
      ].flat(1)
    );
  }, [promptsAndResponses]);

  useEffect(() => {
    dispatch(fetchPcs());
    dispatch(fetchArticleBatches());
  }, [dispatch]);
  const setQueryParam = (param, value) => {
    if (value) {
      params.set(param, value);
    } else {
      params.delete(param);
    }
  };
  useEffect(() => {
    if (pc?.name && articleBatch?.name) {
      dispatch(
        fetchArticlesBasedOnPcAndArticleBatch({
          pc: pc?.name,
          articleBatchName: articleBatch?.name,
        })
      );
    }
  }, [dispatch, pc, articleBatch]);

  const extractRunIndexesForSelectedArticleAndPc = (
    article,
    pc,
    articleBatch
  ) => {
    const runIndexes = articleBatch.promptChains
      .filter(
        (obj) =>
          obj?.pc === pc &&
          obj?.status === "completed" &&
          obj?.article === article
      )
      .map((obj) => obj.runIndex);
    return setMaxRunIndex(Math.max(...runIndexes));
  };

  useEffect(() => {
    if (pc?.name && articleBatch?.promptChains && mainArticle?._id) {
      extractRunIndexesForSelectedArticleAndPc(
        mainArticle._id,
        pc?.name,
        articleBatch
      );
      setUrl(mainArticle?.url);
      setParsedHTML(mainArticle?.parsedHTML);
      setTitle(mainArticle?.title);
      setSerpArticle({});
    }
  }, [pc, articleBatch, mainArticle]);

  useEffect(() => {
    if (pcInUrl || articleBatchInUrl) {
      pcs?.map((pc) => {
        if (pc?.name === pcInUrl) setPc(pc);
      });
      articleBatches?.map((ab) => {
        if (ab?.name === articleBatchInUrl) setArticleBatch(ab);
      });
    }
  }, [pcInUrl, articleBatchInUrl, pcs, articleBatches]);

  useEffect(() => {
    if (articleInUrl || runIndexInUrl || modeInUrl) {
      articles?.map((article) => {
        if (article?._id === articleInUrl) setMainArticle(article);
      });
      if (runIndexInUrl) setRunIndex(runIndexInUrl);
      if (modeInUrl) setMode(modeInUrl);
    }
  }, [articleInUrl, runIndexInUrl, modeInUrl, pcs, articleBatches, articles]);
  useEffect(() => {
    if (serpInUrl) {
      serpArticles?.map((serpObj, i) => {
        if (serpObj?.serpArticle === serpInUrl)
          setSerpArticle({
            serpArticleRef: serpInUrl,
            serpArticleUrl: serpObj?.url,
            index: i,
          });
      });
    }
  }, [serpArticles]);

  const renderRunIndexes = (maxRunIndex) => {
    const runIndexes = [];

    for (let i = 1; i <= maxRunIndex; i++) {
      runIndexes.push(
        <Dropdown.Item key={i} eventKey={i}>
          {i}
        </Dropdown.Item>
      );
    }

    return runIndexes;
  };
  useEffect(() => {
    if (pc?._id && articleBatch && mainArticle?._id && runIndex && mode) {
      dispatch(
        getResponsesWithSelectedFilters({
          pcRef: pc?._id,
          articleBatchRef: articleBatch?._id,
          articleRef: mainArticle?._id,
          runIndex,
          mode,
        })
      );
    }

    setQueryParam("pc", pc?.name);
    setQueryParam("articleBatch", articleBatch?.name);
    setQueryParam("article", mainArticle?._id);
    setQueryParam("runIndex", runIndex);
    setQueryParam("mode", mode);

    if (pc?.name || articleBatch?.name || mainArticle?._id || runIndex || mode)
      window.history.pushState({}, "", `?${params.toString()}`);
  }, [dispatch, pc, articleBatch, mainArticle, runIndex, mode]);

  const scrollToPrompt = (index) => {
    const promptTemplate = accordionRef.current.querySelector(
      `.accordion-item:nth-child(${index + 1})`
    );
    // console.log("➡️➡️➡️➡️➡️", promptTemplate);
    promptTemplate.scrollIntoView({ behavior: "smooth" });
    setActiveKey([index]);
    setResActiveKey([index + "-" + 0]);
    setGeneratedArticleActiveKey(0);
  };

  const copyResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        // Handle successful copying if needed
        console.log("Response copied to clipboard!");
        dispatch(
          newAlert({ type: "success", message: "Text copied successfully" })
        );
      })
      .catch((error) => {
        // Handle error if the copy operation fails
        console.error("Failed to copy response:", error);
      });
  };
  return (
    <div style={leftRightContainerStyle}>
      {/* left dropdown */}
      <div className="d-flex flex-row flex-wrap gap-1 p-2 w-100">
        {" "}
        {/* pcs */}
        <Dropdown onSelect={(eventKey) => setPc(JSON.parse(eventKey))}>
          <Dropdown.Toggle
            style={{ backgroundColor: "#3EACCA" }}
            id="dropdown-basic"
          >
            {pc?.name ? pc.name : "Select Pc"}
            <i className="bi bi-caret-down-fill"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {pcs?.map((pc) => (
              <Dropdown.Item key={pc._id} eventKey={JSON.stringify(pc)}>
                {pc.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {/* articleBatches */}
        {pc && (
          <Dropdown
            onSelect={(eventKey) => setArticleBatch(JSON.parse(eventKey))}
          >
            <Dropdown.Toggle
              style={{ backgroundColor: "#3EACCA" }}
              id="dropdown-basic"
            >
              {articleBatch?.name ? articleBatch.name : "Select Article Batch"}{" "}
              <i className="bi bi-caret-down-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {articleBatches?.map((ab) => (
                <Dropdown.Item key={ab._id} eventKey={JSON.stringify(ab)}>
                  {ab.name}
                </Dropdown.Item>
              ))}{" "}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {/* articles */}
        {articleBatch && (
          <Dropdown
            onSelect={(eventKey) => setMainArticle(JSON.parse(eventKey))}
          >
            <Dropdown.Toggle
              style={{ backgroundColor: "#3EACCA" }}
              id="dropdown-basic"
            >
              {mainArticle?.title
                ? mainArticle.title.substring(0, 20)
                : "Select Article"}{" "}
              <i className="bi bi-caret-down-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-article-titles">
              {articles?.map((article, index) => (
                <Dropdown.Item
                  key={article._id}
                  eventKey={JSON.stringify(article)}
                  bsPrefix="custom-dropdown-item dropdown-article-title"
                >
                  <span style={{ opacity: 0.4 }}>{index + 1}.</span>{" "}
                  {article?.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {/* runIndexes */}
        {mainArticle && (
          <Dropdown onSelect={(eventKey) => setRunIndex(eventKey)}>
            <Dropdown.Toggle
              style={{ backgroundColor: "#3EACCA" }}
              id="dropdown-basic"
            >
              {runIndex ? `runIndex: ${runIndex}` : "Select Run Index"}{" "}
              <i className="bi bi-caret-down-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderRunIndexes(maxRunIndex)}</Dropdown.Menu>
          </Dropdown>
        )}
        {/* mode */}
        {runIndex !== 0 && mainArticle && (
          <Dropdown onSelect={(eventKey) => setMode(eventKey)}>
            <Dropdown.Toggle
              style={{ backgroundColor: "#3EACCA" }}
              id="dropdown-basic"
            >
              {mode ? mode : "Mode"} <i className="bi bi-caret-down-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="interface">Interface</Dropdown.Item>
              <Dropdown.Item eventKey="api">API</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {/* outline, prompt and responses */}
      <div
        className="d-flex w-100"
        style={{ height: "92%", paddingLeft: "5%" }}
      >
        {/* Outline */}
        <h6
          className="vertical-outline"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Outline
          <i className="bi bi-arrow-down"></i>
        </h6>
        <div
          className={`rounded-2 p-2 m-2 mt-3 outline-container ${
            isHovered ? "expand" : ""
          }`}
          style={{ background: "#f9fbfd", border: "1px solid grey" }}
        >
          {promptsAndResponses?.map((obj, i) => (
            <p
              key={obj?._id}
              onClick={() => scrollToPrompt(i)}
              className="m-1 p-0 py-2"
            >
              <b>-</b> {obj?.promptTemplateName}
            </p>
          ))}
          <br />
          {generatedArticle && (
            <p
              className="m-1 p-0 py-2"
              onClick={() => {
                const generatedArticle = document
                  .getElementById("generated-article")
                  .querySelector(`.accordion-header`);
                // console.log("👍👍👍", generatedArticle);
                setActiveKey([]);
                setResActiveKey([]);
                setGeneratedArticleActiveKey(1);
                generatedArticle?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <b>-</b> Generated Article
            </p>
          )}
        </div>

        {/* prompts and response */}
        <div className="p-2 m-2 rounded-2 response-container">
          <Accordion
            ref={accordionRef}
            activeKey={activeKey}
            onSelect={(e) => setActiveKey(e)}
            alwaysOpen
          >
            {promptsAndResponses?.map((obj, i) => (
              <Accordion.Item key={obj?._id} eventKey={i}>
                <Accordion.Header>
                  <h6 className="m-0 p-0">{obj?.promptTemplateName}</h6>
                </Accordion.Header>
                <Accordion.Body className="responses-text">
                  <Accordion
                    activeKey={resActiveKey}
                    onSelect={setResActiveKey}
                    alwaysOpen
                  >
                    {obj?.responses?.map((resObj, j) => (
                      <Accordion.Item key={j} eventKey={i + "-" + j}>
                        <Accordion.Header>
                          {" "}
                          <h6 className="m-0 p-0">{resObj?.heading}</h6>
                        </Accordion.Header>
                        <Accordion.Body className="responses-text">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              width: "95%",
                              gap: "1rem",
                            }}
                          >
                            {" "}
                            <button
                              className="btns-inside-accordion"
                              onClick={() => setShowPromptModal(resObj?._id)}
                            >
                              Prompt
                            </button>{" "}
                            <button
                              className="btns-inside-accordion"
                              onClick={() => copyResponse(resObj?.response)}
                            >
                              <i className="bi bi-clipboard"></i> Copy
                            </button>
                          </div>
                          <br />
                          {resObj?.response}
                        </Accordion.Body>
                        <PromptModal
                          showPromptModal={showPromptModal}
                          setShowPromptModal={setShowPromptModal}
                          prompt={resObj?.prompt}
                          responseId={resObj?._id}
                        />
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <br />
          {generatedArticle && (
            <div className="mt-2" id="generated-article">
              <Accordion
                alwaysOpen
                activeKey={generatedArticleActiveKey}
                onSelect={setGeneratedArticleActiveKey}
              >
                <Accordion.Item eventKey={1}>
                  <Accordion.Header>
                    <h6>Generated Article</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "95%",
                        gap: "1rem",
                      }}
                    >
                      <button
                        className="btns-inside-accordion"
                        onClick={() =>
                          copyResponse(
                            `${generatedArticle?.generatedTitle}\n\n${generatedArticle?.articleString}`
                          )
                        }
                      >
                        <i className="bi bi-clipboard"></i> Copy
                      </button>
                    </div>
                    <h2>{generatedArticle?.generatedTitle}</h2>
                    {generatedArticle?.articleString}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <br />
            </div>
          )}
        </div>
      </div>

      {/*  */}
    </div>
  );
};

const RightSide = ({
  mainArticle,
  pc,
  runIndex,
  mode,
  url,
  setUrl,
  parsedHTML,
  setParsedHTML,
  title,
  setTitle,
  articleBatch,
  serpArticle,
  setSerpArticle,
}) => {
  const dispatch = useDispatch();

  const generatedArticle = useSelector(
    (state) => state.generateArticleSlice.generatedArticle
  );
  const serpArticles = generatedArticle?.serpArticles || [];
  const [urlPrefix, setUrlPrefix] = useState("Main Article - ");

  useEffect(() => {
    if (mainArticle?._id && pc?.name && runIndex && mode && articleBatch?._id) {
      dispatch(
        getGeneratedArticle({
          mainArticleRef: mainArticle._id,
          articleBatchRef: articleBatch?._id,
          pc: pc?.name,
          runIndex,
          mode,
        })
      );
    }
  }, [dispatch, mainArticle, articleBatch, pc, runIndex, mode]);

  const getSerpArticleUrlAndParsedHTMLFromArticleId = async ({
    serpArticleRef,
  }) => {
    try {
      const {
        data: { url, parsedHTML, title },
      } = await Axios.get(
        `${backendURL}/api/prompt-master/get-article-parsedHTML-from-articleId/${serpArticleRef}`
      );
      setUrl(url);
      setParsedHTML(parsedHTML);
      setTitle(title);
      setUrlPrefix(`Serp ${serpArticle?.index + 1} - `);
      const currentUrl = window.location.href;
      if (currentUrl.includes("&serp=")) {
        const newUrl =
          currentUrl.split("&serp=")[0] + `&serp=${serpArticleRef}`;
        window.history.pushState({}, "", newUrl);
      } else {
        const newUrl = currentUrl + `&serp=${serpArticleRef}`;
        window.history.pushState({}, "", newUrl);
      }

      return;
    } catch (error) {
      setSerpArticle({});
      const { data } = error.response;
      dispatch(newAlert({ type: "danger", message: data?.message }));
      return Promise.reject({ error: { message: data?.message } });
    }
  };

  useEffect(() => {
    if (serpArticle?.serpArticleRef)
      getSerpArticleUrlAndParsedHTMLFromArticleId({
        serpArticleRef: serpArticle?.serpArticleRef,
      });
  }, [serpArticle]);

  return (
    <div style={leftRightContainerStyle}>
      {/* //right dropdown */}

      <div className="d-flex flex-row flex-row flex-wrap gap-1 p-2">
        {mainArticle && (
          <Button
            onClick={() => {
              setUrl(mainArticle?.url);
              setParsedHTML(mainArticle?.parsedHTML);
              setTitle(mainArticle?.title);
              setUrlPrefix("Main Article - ");
              setSerpArticle({});
              window.history.pushState(
                {},
                "",
                window.location.href.split("&serp")[0]
              );
            }}
            style={{ backgroundColor: "#3EACCA", border: "none" }}
          >
            Select Main Article
          </Button>
        )}
        {serpArticles.length > 0 && (
          <Dropdown
            onSelect={(eventKey) => setSerpArticle(JSON.parse(eventKey))}
          >
            <Dropdown.Toggle
              style={{ backgroundColor: "#3EACCA" }}
              id="dropdown-basic"
            >
              {serpArticle?.serpArticleUrl
                ? serpArticle?.serpArticleUrl.substring(0, 30)
                : "Select Serp Article"}{" "}
              <i className="bi bi-caret-down-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {serpArticles?.map((serpObj, index) => (
                <Dropdown.Item
                  key={serpObj?._id}
                  eventKey={JSON.stringify({
                    serpArticleRef: serpObj?.serpArticle,
                    serpArticleUrl: serpObj?.url,
                    index,
                  })}
                  bsPrefix="custom-dropdown-item"
                >
                  {`${serpObj?.url} (${serpObj?.status})`}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <div className="m-3">
        {url && (
          <div>
            {urlPrefix}
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </div>
        )}
        <br />
        <br />
        {title && <h1>{title}</h1>}
        {parsedHTML && (
          <div
            className="parsed-html-container"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parsedHTML),
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

const GenerateArticleStatus = () => {
  const [mainArticle, setMainArticle] = useState(null);
  const [pc, setPc] = useState(null);
  const [runIndex, setRunIndex] = useState(0);
  const [mode, setMode] = useState("");
  const [url, setUrl] = useState("");
  const [parsedHTML, setParsedHTML] = useState("");
  const [title, setTitle] = useState("");
  const [articleBatch, setArticleBatch] = useState(null);
  const [serpArticle, setSerpArticle] = useState({});

  return (
    <div
      style={{
        display: "block",
        minHeight: "90vh",
        width: "98vw",
        height: "fit-content",
      }}
      className="m-2"
    >
      <SplitView
        left={
          <LeftSide
            mainArticle={mainArticle}
            setMainArticle={setMainArticle}
            pc={pc}
            setPc={setPc}
            runIndex={runIndex}
            setRunIndex={setRunIndex}
            mode={mode}
            setMode={setMode}
            setUrl={setUrl}
            setParsedHTML={setParsedHTML}
            setTitle={setTitle}
            articleBatch={articleBatch}
            setArticleBatch={setArticleBatch}
            setSerpArticle={setSerpArticle}
          />
        }
        right={
          <RightSide
            mainArticle={mainArticle}
            pc={pc}
            runIndex={runIndex}
            mode={mode}
            url={url}
            setUrl={setUrl}
            parsedHTML={parsedHTML}
            setParsedHTML={setParsedHTML}
            title={title}
            setTitle={setTitle}
            articleBatch={articleBatch}
            serpArticle={serpArticle}
            setSerpArticle={setSerpArticle}
          />
        }
      />
    </div>
  );
};

export default GenerateArticleStatus;
