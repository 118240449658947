import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axios";
import { newAlert } from "./alertSlice";

const { REACT_APP_PROMPT_MASTER_BACK_END_DOMAIN: backendURL } = process.env;

export const fetchArticleBatches = createAsyncThunk(
  "generateAricleStatus/fetchArticleBatches",
  async (thunkAPI) => {
    try {
      const { data } = await Axios.get(
        `${backendURL}/api/prompt-master/fetch-article-batches`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.message } });
    }
  }
);

export const fetchPcs = createAsyncThunk(
  "generateAricleStatus/fetchPcs",
  async (thunkAPI) => {
    try {
      const { data } = await Axios.get(
        `${backendURL}/api/prompt-master/fetch-pcs`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.message } });
    }
  }
);

export const fetchArticlesBasedOnPcAndArticleBatch = createAsyncThunk(
  "generateAricleStatus/fetchArticlesBasedOnPcAndArticleBatch",
  async (payload, thunkAPI) => {
    try {
      const { pc, articleBatchName } = payload;

      const { data } = await Axios.get(
        `${backendURL}/api/prompt-master/fetch-articles-based-on-pc-articlebatch/pc=${pc}&articleBatchName=${articleBatchName}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data?.message } });
    }
  }
);

export const getResponsesWithSelectedFilters = createAsyncThunk(
  "generateAricleStatus/getResponsesWithSelectedFilters",
  async (payload, thunkAPI) => {
    try {
      const { pcRef, articleRef, runIndex, mode, articleBatchRef } = payload;
      const { data } = await Axios.get(
        `${backendURL}/api/prompt-master/get-responses-with-selected-filters/pcRef=${pcRef}&articleRef=${articleRef}&runIndex=${runIndex}&mode=${mode}&articleBatchRef=${articleBatchRef}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data?.message } });
    }
  }
);

export const getGeneratedArticle = createAsyncThunk(
  "generateAricleStatus/getGeneratedArticle",
  async (payload, thunkAPI) => {
    try {
      const { mainArticleRef, runIndex, pc, mode, articleBatchRef } = payload;
      const { data } = await Axios.get(
        `${backendURL}/api/prompt-master/get-generated-article/mainArticleRef=${mainArticleRef}&runIndex=${runIndex}&pc=${pc}&mode=${mode}&articleBatchRef=${articleBatchRef}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data?.message } });
    }
  }
);

export const generateArticleSlice = createSlice({
  name: "generateArticleStatus",
  initialState: {
    articleBatches: [],
    articles: [],
    pcs: [],
    promptsAndResponses: [],
    generatedArticle: null,
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchArticleBatches.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchArticleBatches.fulfilled]: (state, { payload }) => {
      state.articleBatches = payload;
      state.loading = false;
    },
    [fetchArticleBatches.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },
    [fetchPcs.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchPcs.fulfilled]: (state, { payload }) => {
      state.pcs = payload;
      state.loading = false;
    },
    [fetchPcs.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },
    [fetchArticlesBasedOnPcAndArticleBatch.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchArticlesBasedOnPcAndArticleBatch.fulfilled]: (state, { payload }) => {
      state.articles = payload;
      state.loading = false;
    },
    [fetchArticlesBasedOnPcAndArticleBatch.rejected]: (state, { error }) => {
      state.error = error.message;
      state.articles = [];
      state.loading = false;
    },
    [getGeneratedArticle.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getGeneratedArticle.fulfilled]: (state, { payload }) => {
      state.generatedArticle = payload;
      state.loading = false;
    },
    [getGeneratedArticle.rejected]: (state, { error }) => {
      state.error = error.message;
      state.generatedArticle = null;
      state.loading = false;
    },
    [getResponsesWithSelectedFilters.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getResponsesWithSelectedFilters.fulfilled]: (state, { payload }) => {
      const promptsAndResponses = payload.reduce((acc, obj) => {
        const promptTemplateName = obj?.promptTemplate?.name;

        // Find the index of the existing object with the same promptTemplateName
        const index = acc.findIndex(
          (item) => item.promptTemplateName === promptTemplateName
        );
        let heading = "";
        if (index !== -1) {
          if (!obj?.inputData?.promptForArticleId)
            heading = obj?.additionalData?.responsePartNumber || 0;
          else if (
            obj?.inputData?.article === obj?.inputData?.promptForArticleId
          )
            heading = "Main: " + (obj?.additionalData?.responsePartNumber || 0);
          else
            heading = `Serp: 
            ${obj?.inputData?.serpArticles?.indexOf(
              obj?.inputData?.promptForArticleId
            )} - Response
           ${obj?.additionalData?.responsePartNumber || 0}`;
          // If an object with the same promptTemplateName exists, push the response to its array
          acc[index].responses.push({
            _id: obj._id,
            response: obj?.response?.response,
            heading,
            prompt: obj?.prompt,
            // promptForArticleId: obj?.inputData?.promptForArticleId,
          });
        } else {
          if (!obj?.inputData?.promptForArticleId)
            heading = obj?.additionalData?.responsePartNumber || 0;
          else if (
            obj?.inputData?.article === obj?.inputData?.promptForArticleId
          )
            heading = "Main: " + (obj?.additionalData?.responsePartNumber || 0);
          else
            heading = `Serp: 
              ${obj?.inputData?.serpArticles?.indexOf(
                obj?.inputData?.promptForArticleId
              )} - Response
             ${obj?.additionalData?.responsePartNumber || 0}`;
          // If no object with the same promptTemplateName exists, create a new object
          acc.push({
            _id: obj._id,
            promptTemplateName: promptTemplateName,
            // promptTemplateId: obj?.promptTemplate?._id,
            responses: [
              {
                _id: obj._id,
                response: obj?.response?.response,
                heading,
                prompt: obj?.prompt,
                // promptForArticleId: obj?.inputData?.promptForArticleId,
              },
            ], // Store the response in an array
          });
        }

        return acc;
      }, []);

      state.promptsAndResponses = promptsAndResponses;
      state.loading = false;
    },
    [getResponsesWithSelectedFilters.rejected]: (state, { error }) => {
      state.error = error.message;
      state.promptsAndResponses = [];
      state.loading = false;
    },
  },
});

export default generateArticleSlice.reducer;
