import React from "react";
import SignInPage from "../SignInPage/SignInPage";

const ApplicantSignIn = () => {
  return (
    <div>
      <SignInPage fApplicantPage={true} />
    </div>
  );
};

export default ApplicantSignIn;
