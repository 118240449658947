import React, { useEffect, useRef, useState } from "react";
// import dynamic from "next/dynamic";
// import { Spinner } from "react-bootstrap";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import { getFullDate, getTimeAgo } from "../NewsFeed/utilities";

const BlogTextEditor = ({
  data,
  onChange,
  placeholder,
  onAutoSave,
  hasH1Heading,
  contentStatus,
  setContentStatus,
  lastSavedAt,
  fShowEditor,
}) => {
  const [isFixed, setIsFixed] = useState(false);
  const blogComponent = useRef(null);
  const [lastSavedTime, setLastSavedTime] = useState(lastSavedAt);
  const lastChangeTime = useRef(Date.now());
  const intervalRef = useRef(null);
  const [imageLoadStatus, setImageLoadStatus] = useState("loading");
  const [imgSrc, setImgSrc] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(true);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

 useEffect(() => {
   const timer = setTimeout(() => {
      setContentStatus({ ...contentStatus, saved: true });
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const rect = blogComponent.current?.getBoundingClientRect();
      if (rect) setIsFixed(rect.top <= 0 && rect.top + rect.height >= 0);
    };
    const debouncedHandleScroll = debounce(handleScroll, 15); // Adjust the debounce delay as needed

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

   useEffect(() => {
    const checkTimeAgo = () => {
      const timeAgo = getTimeAgo(lastSavedAt);
      if (timeAgo === 'just now') {
        intervalRef.current = setInterval(() => {
          const currentTime = Date.now();
          if (currentTime - lastChangeTime.current >= 5000) {
            setLastSavedTime(currentTime);
            // setContentStatus({ ...contentStatus, saved: true });
          }
        }, 5000);
      }
    };
    checkTimeAgo();
    return () => {
      // Clear the interval when component unmounts or before running new effect
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [contentStatus, setContentStatus,setLastSavedTime]);

  useEffect(() => {
    const checkForAutoSave = () => {
      const currentTime = Date.now();
      if (currentTime - lastChangeTime.current >= 30000) { // 30 seconds
        setLastSavedTime(currentTime);
        // setContentStatus({ ...contentStatus, saved: true });
      }
    };
    const intervalId = setInterval(checkForAutoSave, 30000);
    return () => clearInterval(intervalId);
  }, [contentStatus, setContentStatus]);

  const handleEditorChange = (newData) => {
    lastChangeTime.current = Date.now();
    onChange(newData);
    setContentStatus({ ...contentStatus, saved: false });
  };

  useEffect(() => {
  const timeoutId = setTimeout(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    // Find the img tag
    const imgTag = doc.querySelector('img');

    if (imgTag) {
      const imgSrc = imgTag.getAttribute('src');
      setImgSrc(imgSrc);
      // Create a new Image object to check if the image loads
      const image = new Image();
      image.src = imgSrc;
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntriesByType('resource');
        const imgEntry = entries.find(entry => entry.name === imgSrc);
      });
      observer.observe({ entryTypes: ['resource'] });
      image.onload = () => {
        if (imgSrc.startsWith("https://marketing-net.s3"))
        {
          setImageLoadStatus('success');
          observer.disconnect();
        }
        else {
          setImageLoadStatus('error');
          observer.disconnect();
        }
      };

    } else {
      // If no img tag is found, consider it as success
      // if (!isFirstTime) {
        setImageLoadStatus('success');
      // }
      // setIsFirstTime(false);
    }
  },1000);

  return () => clearTimeout(timeoutId); // Cleanup the timeout if the component unmounts
}, [data]);


  const ImageLinksStatus = (element, tooltiptext) => {
    return (
      <span className="show-container">
        <span className={`blog-tooltip ${isFixed ? "fixed" : ""}`}>
          <span className="showtooltip m-1">
            {element}
            <span
              className={`showtooltiptext ${
                imageLoadStatus === "loading"
                  ? "text-info"
                  : imageLoadStatus === "success"
                  ? "text-success"
                  : "text-warning"
              }`}
            >
              {tooltiptext}
            </span>
          </span>
        </span>
      </span>
    );
  };


  if (!fShowEditor) {
    return <></>;
  }
  return (
    <div>

      <span className="blog-tooltip">
        {imageLoadStatus === "loading"
          ? ImageLinksStatus(
              <>
                <i className="fa fa-spinner fa-spin"></i>
              </>,
              "Image is loading"
            )
          : imageLoadStatus === "success"
          ? ImageLinksStatus(
              <>
                <i className="text-success fa-solid fa-circle-check"></i>
              </>,
              "All image links saved"
            )
          : ImageLinksStatus(
              <>
                <i className="text-warning fa-solid fa-circle-exclamation"></i>
              </>,
              "Unsaved image links"
            )}
      </span>
      <div ref={blogComponent}>
        <div className="d-flex text-muted text-small">
          {hasH1Heading && (
            <div>
              <i
                className="fa fa-exclamation-triangle text-warning ms-2 me-1"
                aria-hidden="true"
              ></i>
              contains h1 heading
            </div>
          )}
          <div
            className="text-muted ms-auto mt-1 me-1 mytooltip"
            style={{ fontSize: "small" }}
          >
            {contentStatus.saved ? (
              <span>
                last saved {getTimeAgo(lastSavedAt)}
              </span>
            ) : (
              <span>unsaved changes</span>
            )}
            <div className="tooltiptext">{getFullDate(lastSavedAt)}</div>
          </div>
        </div>
        <div fontSize="20px">
          <RichTextEditor
            data={data}
            onChange={handleEditorChange}
            placeholder={placeholder}
           onAutoSave={(data) => {
              onAutoSave(data);
              setContentStatus({ ...contentStatus, saved: true });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogTextEditor;
