import React, { useEffect, useState } from "react";
import ProfilePage from "../ProfilePage/ProfilePage";
import "./ApplicationInterface.css";
import {
  getStagesStatusForApplication,
  saveQuestionsForApplicant,
  updateStagesStatusForApplication,
} from "../../../components/ProfilePage/utility/userUtils";
import { Spinner } from "react-bootstrap";
import Questions from "../../../components/ApplicationComponents/Questions";
import StageIndicator from "../../../components/ApplicationComponents/StageIndicator";
import TestLink from "../../../components/ApplicationComponents/TestLink";
import { useDispatch, useSelector } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import AcknowledgeModal from "../../../components/ApplicationComponents/AcknowledgeModal";
import { getCookie, setUserJobStageHistory } from "../../../helpers/stubs";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../axios";
import PageNotFound from "../PageNotFound/PageNotFound";

const ApplicationInterface = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { userId, otp } = params;
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const [currentPage, setCurrentPage] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [fRedirectTestPage, setFRedirectTestPage] = useState(false);
  const [fRedirectApplicationStatus, setFRedirectApplicationStatus] =
    useState(false);
  const [fGettingStage, setfGettingStage] = useState(true);
  const [fApplicantPageNotFound, setfApplicantPageNotFound] = useState(false);
  const [askConfirmation, setaskConfirmation] = useState(false);
  const [fAccepted, setFAcceptedTermAndCondition] = useState(false);

  const fHandleOTPLogin = () => {
    const sessionCookie = getCookie("sc");
    const parsedJSONCookie = JSON.parse(sessionCookie);
    const { user } = parsedJSONCookie || {};
    const handlingOTP = !user && otp ? true : false;
    // console.log({ handlingOTP, otpParam });
    return handlingOTP;
  };
  const [handlingOTPLogin, setHandlingOTPLogin] = useState(fHandleOTPLogin());

  const handleOTPLoginForNaukriApplicant = async () => {
    if (fHandleOTPLogin()) {
      // console.log({ handlingOTPLogin, sessionUser });
      try {
        const { data } = await axiosInstance.post(
          `/api/public/validate-applicant-otp`,
          { userId, otp }
        );
        // dispatch(getSession);
        // navigate(`/user/profile-page/${userId}`);
        window.location.reload();
      } catch (error) {
        console.log(error);
        let errorMsg = error.response?.data?.message || error.message;
        if (errorMsg.includes("max logins exceeded for otp")) {
          navigate("/applicant/forgot-password?otpExceed=true");
        } else {
          // dispatch(newAlert({ type: "danger", message: errorMsg }));
          // if (errorMsg.includes("otp validation failed")) {
          setfApplicantPageNotFound(true);
        }
      } finally {
        setHandlingOTPLogin(false);
      }
    }
  };

  // onPreRender: Fetch data before component renders
  useEffect(() => {
    if (sessionUser.sessionId !== undefined) {
      handleOTPLoginForNaukriApplicant(params, otp);
    }
  }, [sessionUser.sessionId]);

  const nextPage = () => {
    if (currentPage < 2) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const redirectToPage = (pageNo) => {
    if (![1, 2, 3].includes(pageNo)) {
      return;
    }
    setCurrentPage(pageNo);
  };

  const savePageStatus = async () => {
    const stagesStatus = {};
    stagesStatus[`stage${currentPage}`] = { fSaved: true, savedAt: new Date() };
    await updateStagesStatusForApplication(stagesStatus);
    // console.log({ currentPage });
    if (currentPage < 2) {
      await setUserJobStageHistory({ name: "workPrefPage", type:"page" });
      nextPage();
    }
  };

  const saveCurrentStage = async () => {
    let fAnswerInRejectionMail = false;
    if (currentPage === 1) {
      setaskConfirmation({
        active: true,
        title: "",
        heading: `Have you checked all your details?`,
        onClick: savePageStatus,
        acceptBtnMsg: "I confirm my profile is completely updated",
        rejectBtnMsg: "let me take another look",
        newLine: true,
      });
    } else if (currentPage === 2) {
      for (const question of questions) {
        // console.log(question);
        if (question.answer?.trim() === "") {
          dispatch(
            newAlert({ type: "danger", message: "All questions are mandatory" })
          );
          return;
        } else if (question.answer === question.answerForRejectionMail) {
          fAnswerInRejectionMail = true;
        }
      }
      const questionsData = questions.map((question) => {
        return {
          question: question._id,
          answer: question.answer,
        };
      });
      await saveQuestionsForApplicant(questionsData, fAnswerInRejectionMail);
      if (!fAnswerInRejectionMail) {
        await savePageStatus();
        setFRedirectTestPage(true);
      } else {
        setFRedirectApplicationStatus(true);
      }
    }
  };

  const redirectToTestPage = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/user/get-applicant-test-url/${sessionUser.user?.email}`
      );
      // console.log({ data });
      if (data.status === "success") {
        // console.log(data.testUrl);
        window.location.replace(data.testUrl);
      }
    } catch (e) {
      dispatch(
        newAlert({
          type: "danger",
          message: "Unable to redirect to start page",
        })
      );
    }
  };

  const fLogin = () => {
    const sessionCookie = getCookie("sc");
    const parsedJSONCookie = JSON.parse(sessionCookie);
    const { user } = parsedJSONCookie || {};
    const fLogin = user ? true : false;
    // console.log({ fLogin });
    return fLogin;
  };

  function findFirstIncompleteStage(stagesStatus, paymentPreference) {
    const stageSequence = [
      "stage1",
      "stage2",
      "test1",
      "paymentPreference",
      "test2",
      "test3Preference",
      "test3",
    ];
    for (let stage of stageSequence) {
      if (!stagesStatus[stage]?.fSaved) {
        if (stage === "test2" && paymentPreference === "money") {
          return "paymentPrefMoney";
        }
        return stage;
      }
    }
    return "completed";
  }
  const getStagesStatus = async () => {
    let fRedirectToTestPage = false;
    if (fLogin()) {
      try {
        setfGettingStage(true);
        const data = await getStagesStatusForApplication();
        // console.log({ data });
        if (data.status === "success") {
          const { stagesData, fRejectedForWork, fRejectedForTest } = data;
          let currentStage = "stage1";
          const stagesForApplicationStatus = [
            "paymentPreference",
            "paymentPrefMoney",
            "showRejectionPage",
            "test3Preference",
            "completed",
          ];
          const stagesForTestPage = ["test1", "test2", "test3"];
          if (fRejectedForWork || fRejectedForTest) {
            currentStage = "showRejectionPage";
          } else if (stagesData?.stagesStatus) {
            const { stagesStatus, paymentPreference } = stagesData;
            currentStage = findFirstIncompleteStage(
              stagesStatus,
              paymentPreference
            );
          }
          // console.log({ currentStage });
          if (stagesForApplicationStatus.includes(currentStage)) {
            setFRedirectApplicationStatus(true);
          } else if (stagesForTestPage.includes(currentStage)) {
            setFRedirectTestPage(true);
            fRedirectToTestPage = true;
          } else {
            if (currentStage === "stage1") {
              await setUserJobStageHistory({
                name: "profilePage",
                type: "page",
              });
            }
            if (currentStage === "stage2") {
              await setUserJobStageHistory({
                name: "workPrefPage",
                type: "page",
              });
              redirectToPage(2);
            }
          }
        }
      } finally {
        if (!fRedirectToTestPage) {
          // console.log({ fRedirectToTestPage });
          setfGettingStage(false);
        }
      }
    } else {
      redirectToPage(1);
    }
  };
  useEffect(() => {
    getStagesStatus();
  }, []);

  useEffect(() => {
    if (sessionUser?.user) {
      if (fRedirectApplicationStatus) {
        navigate(`/user/application-status/${sessionUser?.user?.email}`);
      }
      if (fRedirectTestPage) {
        redirectToTestPage();
      }
    }
  }, [sessionUser, fRedirectApplicationStatus, fRedirectTestPage]);

  return (
    <>
      <AcknowledgeModal
        askConfirmation={askConfirmation}
        setaskConfirmation={setaskConfirmation}
      />
      <div
        style={{
          backgroundColor: "#F5F5F5",
          position: "absolute",
          width: "100%",
          minHeight: "100%",
        }}
      >
        {/* {console.log({ handlingOTPLogin, currentPage })} */}
        {(handlingOTPLogin && currentPage === 1) ||
        fRedirectApplicationStatus ||
        fGettingStage ? (
          <div className="profile-page-loader">
            <Spinner
              variant="secondary"
              size="md"
              // style={{ width: "48px", height: "48px", fontSize: "16px" }}
            />
          </div>
        ) : fApplicantPageNotFound ? (
          <div>
            <PageNotFound />
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center application-interface-container">
            <div className="page-container" style={{ maxWidth: "1600px" }}>
              {!fRedirectApplicationStatus && !fGettingStage && (
                <StageIndicator
                  currentPage={currentPage}
                  setaskConfirmation={setaskConfirmation}
                  saveCurrentStage={saveCurrentStage}
                  redirectToPage={redirectToPage}
                />
              )}
              {/* Content of current page */}
              {
                <div>
                  <div>
                    {currentPage === 1 && (
                      <>
                        <div className="top-section-cover"></div>
                        <div style={{ position: "relative" }}>
                          <ProfilePage
                            isApplicationInterface={true}
                            handlingOTPLogin={handlingOTPLogin}
                          />
                        </div>
                      </>
                    )}
                    {currentPage === 2 && (
                      <Questions
                        questions={questions}
                        setQuestions={setQuestions}
                      />
                    )}
                    {currentPage === 3 && <TestLink />}
                  </div>
                </div>
              }
            </div>
            {currentPage === 2 && (
              <div className="checkbox">
                <label style={{ fontWeight: "normal" }}>
                  <input
                    name="agreeToPolicy"
                    type="checkbox"
                    checked={fAccepted}
                    onClick={() => setFAcceptedTermAndCondition(!fAccepted)}
                  />{" "}
                  I agree to the{" "}
                  <a
                    href="https://mentorstudents.org/terms-and-conditions"
                    target="_blank"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://mentorstudents.org/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
            )}
            {!fRedirectApplicationStatus &&
              !fGettingStage &&
              !(currentPage === 3) && (
                <div
                  className="text-center mt-4"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <button
                    className="previous-application-btn btn btn-secondary me-2"
                    onClick={currentPage === 1 ? () => {} : prevPage}
                    style={{
                      padding: "10px 18px",
                      background:
                        currentPage === 1
                          ? "#CCCCCC"
                          : "linear-gradient(180deg, #7DB2D9 0%, #549ACF 100%)",
                      cursor: currentPage === 1 ? "not-allowed" : "pointer",
                      border: `2px solid ${
                        currentPage === 1 ? "#C4C4C4" : "#4690C7"
                      }`,
                    }}
                  >
                    Go Back
                  </button>
                  {!(currentPage === 3) && (
                    <button
                      className={`submit-application-btn btn ${
                        currentPage === 3 ? "d-none" : ""
                      }`}
                      onClick={saveCurrentStage}
                      disabled={
                        currentPage && currentPage === 2 ? !fAccepted : false
                      }
                      style={{
                        background:
                          "linear-gradient(180deg, #49B933 25.22%, #2EA917 81.65%)",
                        color: "white",
                        padding: "10px 24px",
                        border: `2px solid #2FAA17`,
                      }}
                    >
                      {"Save & Continue"}
                    </button>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicationInterface;
