import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axios";

// const { REACT_APP_BACK_END_DOMAIN: backendURL } = process.env;
import { getBackendDomain } from "../helpers/stubs";

const backendURL = getBackendDomain();
//#region getVotes
export const getVotes = createAsyncThunk("user/getVotes", async (thunkAPI) => {
  try {
    const { data } = await Axios.get(`${backendURL}/api/user/get-votes`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
});
//#endregion

//#region updateVotesForAd
export const updateVotesForAd = createAsyncThunk(
  "user/updateVotesForAd",
  async (
    { adId, currentVoteType, isUpvoted, isDownvoted, query },
    thunkAPI
  ) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/update-votes-for-ad?adId=${adId}&currentVoteType=${currentVoteType}&isUpvoted=${isUpvoted}&isDownvoted=${isDownvoted}&query=${query}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
//#endregion

export const votingsSlice = createSlice({
  name: "reportAds",
  initialState: {
    votes: [],
    loading: false,
    error: "",
  },
  reducers: {
    setVotes: (state, action) => {
      const { payload } = action;

      state.votes.push(payload);
    },
  },

  extraReducers: {
    [getVotes.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getVotes.fulfilled]: (state, { payload }) => {
      state.votes = payload.votes;
      state.loading = false;
    },
    [getVotes.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [updateVotesForAd.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateVotesForAd.fulfilled]: (state, { payload }) => {
      const { deletedVote, updatedVoteType, insertedVote } = payload;
      let modifiedPayload = {};
      if (deletedVote) {
        modifiedPayload = state.votes.filter((a) => a.ad !== deletedVote.ad);
      } else if (insertedVote) {
        modifiedPayload = [...state.votes, insertedVote];
      } else {
        modifiedPayload = state.votes.filter(
          (a) => a.ad !== updatedVoteType.ad
        );
        modifiedPayload = [...modifiedPayload, updatedVoteType];
      }
      state.votes = modifiedPayload;
      state.loading = false;
    },
    [updateVotesForAd.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },
  },
});

// Export Actions
export const { setVotes } = votingsSlice.actions;

// Export Reducer
export default votingsSlice.reducer;
