import React from "react";
import Response from "../Response/Response";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setFShowSelectionMenu } from "../../../slices/chatGPTSlice";

const ResponseContent = ({
  showResponse,
  setShowResponse,
  questionIndex,
  questionId,
  sourceQuestion,
  questionAlias,
  questionModal,
}) => {
  const dispatch = useDispatch();

  const handleSelectionMenuDismissOnClickOutside = (e) => {
    if (!e.target.parentNode.classList.contains("responseSelectionPopup")) {
      dispatch(setFShowSelectionMenu(false));
    }
  };
  return (
    <Modal
      show={questionIndex === showResponse || questionModal}
      onHide={() => setShowResponse(false)}
      size="xl"
    >
      <Modal.Header closeButton> </Modal.Header>
      <Modal.Body onMouseDown={handleSelectionMenuDismissOnClickOutside}>
        <Response
          questionId={questionId}
          sourceQuestion={sourceQuestion}
          questionAlias={questionAlias}
          questionModal={questionModal}
        />
      </Modal.Body>
      <Modal.Footer onMouseDown={handleSelectionMenuDismissOnClickOutside}>
        <Button variant="secondary" onClick={() => setShowResponse(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResponseContent;
