import React from "react";
import "./FbAdTypeCarousel.css";
import FbAdImageDetailsBelowPrimaryText from "../FbAdImageDetailsBelowPrimaryText/FbAdImageDetailsBelowPrimaryText";
import Carousel from "react-bootstrap/Carousel";

const FBCarousel = ({ adDetails }) => {
  console.log("FBCarousel", adDetails);
  return (
    <Carousel indicators={false} interval={null} variant={"dark"} wrap={false}>
      {adDetails.map((ad, adIndex) => {
        return (
          <Carousel.Item key={adIndex}>
            <FbAdImageDetailsBelowPrimaryText adDetails={ad} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default FBCarousel;
