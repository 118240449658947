import { configureStore } from "@reduxjs/toolkit";

//#region SLICES
import alertSlice from "./slices/alertSlice";
import adSlice from "./slices/adSlice";
import sessionSlice from "./slices/sessionSlice";
import savedAdsSlice from "./slices/savedAdsSlice";
import reportAdsSlice from "./slices/reportAdsSlice";
import votingsSlice from "./slices/votingsSlice";
import chatGPTSlice from "./slices/chatGPTSliceV2";
import newsFeedSlice from "./slices/newsFeedSlice";
import generateArticleSlice from "./slices/generateArticleSlice";
import workSlice from "./slices/profileSlices/workSlice";
import openTabSlice from "./slices/profileSlices/openTabSlice";
import courseOutlineSlice from "./slices/courseOutlineSlice";
//#endregion

//#region STORE
const store = configureStore({
  reducer: {
    alertSlice: alertSlice,
    adsSlice: adSlice,
    sessionSlice,
    savedAdsSlice: savedAdsSlice,
    reportAdsSlice: reportAdsSlice,
    votingsSlice: votingsSlice,
    chatGPTSlice: chatGPTSlice,
    newsFeedSlice: newsFeedSlice,
    generateArticleSlice: generateArticleSlice,
    workSlice,
    openTabSlice,
    courseOutlineSlice,
  },
});
//#endregion

export default store;
