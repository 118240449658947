import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import './SearchTermsReport.css';
import aSort from "../../../assets/images/a-sort.svg";
import dSort from "../../../assets/images/d-sort.svg";
import searchIcon from "../../../assets/images/search-icon.svg";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import axiosInstance from '../../../axios';
import { useDispatch } from 'react-redux';
import { newAlert } from '../../../slices/alertSlice';

const SearchTermsReport = ({ searchTermsReport, loading, display, setDisplay, searchTermFilter, setSearchTermFilter, sortOrder, setSortOrder, sortValue, setSortValue, sortBtn, setSortBtn }) => {
    const [page, setPage] = useState(1);
    const [dataPerPage] = useState(10);
    const [visibleReport, setVisibleReport] = useState([]);
    const [morePage, setMorePage] = useState(true);
    const [childrenSearchTerms, setChildrenSearchTerms] = useState([]);
    const [childSearchTerm, setChildSearchTerm] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modal, setModal] = useState(true);
    const [parentSearchTerm, setParentSearchTerm] = useState('');
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const [parentIndex, setParentIndex] = useState();
    const [childIndex, setChildIndex] = useState();
    const [newChildrenSearchTerms, setNewChildrenSearchTerms] = useState([]);

    const dispatch = useDispatch();

    //? total search count
    // const [totalSearchCount, setTotalSearchCount] = useState(0)
    // setTimeout(() => {
    //     setTotalSearchCount(
    //         searchTermsReport.reduce(
    //             (acc, cv) => acc + parseInt(cv.searchCount), 0
    //         )
    //     );
    // }, 2000);

    const style = { margin: '2px', borderRadius: '10px', padding: '2px', }

    useEffect(() => {
        //shalow copy
        let sortedReport = Array.from(searchTermsReport);
        if (!sortedReport.length) setDisplay(false);
        sortedReport.sort((a, b) => (a[sortValue] - b[sortValue]) * sortOrder);
        if (searchTermFilter) sortedReport = sortedReport.filter(x => new RegExp(searchTermFilter).test(x?.stemmedSearchTerm));
        //pagination
        const to = page * dataPerPage;
        const sortedReportCurrentPage = sortedReport.slice(0, to);
        if (page > sortedReport.length / dataPerPage) setMorePage(false);
        else setMorePage(true);
        //setvisible report
        if (!sortedReportCurrentPage.length) {
            setVisibleReport([]);
        } else setVisibleReport([...sortedReportCurrentPage]);
    }, [sortOrder, sortValue, page, searchTermFilter, searchTermsReport])

    const removeChildSearchTerm = async (parentIndex, childIndex) => {
        const newArray = Array.from(visibleReport);
        setDeleteConfirmationModalShow(false);
        const data = await axiosInstance.put(`/api/admin/remove-child-search-term/${visibleReport[parentIndex].childrenSearchTerms[childIndex].childSearchTermId}`)
        if (data.status === 200) {
            dispatch(newAlert({ type: "success", message: "Removed Successfully" }))
            newArray[parentIndex].childrenSearchTerms.splice(childIndex, 1);
            setVisibleReport(newArray);
        } else {
            dispatch(newAlert({ type: "danger", message: "Failed to Remove" }))
        }
    }

    const getChildrenSearchTerms = async (childSearchTerm) => {
        const { data } = await axiosInstance.get(`/api/admin/get-children-search-terms/${(childSearchTerm !== "") ? childSearchTerm : "a"}`)
        const options = await data?.childSearchTerm?.hits.hits.map(doc => {
            return ({ "value": doc?._id, 'label': doc?._source.stemmedSearchTerm })
        })
        // console.log('options=>', options)
        setChildrenSearchTerms(options)
    }

    const addChildrenSearchTerms = async (parentSearchTermId, newChildrenSearchTerms) => {
        const newArray = Array.from(visibleReport);
        const data = await axiosInstance.put(`/api/admin/add-children-search-terms`, {
            parentSearchTermId, newChildrenSearchTerms
        })
        if (data.status === 200) {
            dispatch(newAlert({ type: "success", message: "Children search term added" }))
            newChildrenSearchTerms.map(term => {
                newArray[parentIndex].childrenSearchTerms.push({ childSearchTermId: term.value, childSearchTerm: term.label })
            })
            setVisibleReport(newArray);
        } else {
            dispatch(newAlert({ type: "danger", message: "Failed to add children search terms" }))
        }
        setModalShow(false)
    }

    return (
        <>
            {
                display ? <div className='search-term-analysis-table'>

                    {/* //todo filter search Terms */}
                    <div className='search-terms-filter'>
                        <input
                            type='text' placeholder='search term filter...' value={searchTermFilter}
                            onChange={(e) => setSearchTermFilter(e.target.value)}
                        />
                        <img src={searchIcon} alt='searchIcon' />
                    </div>

                    {/* //todo search terms table */}
                    <Table striped bordered hover style={{ width: '72vw' }}>
                        <thead className='table-head'>
                            <tr>
                                <th>#</th>
                                <th style={{ width: '20%' }}>
                                    Parent Search Term
                                    <br />
                                    <span>{searchTermsReport.length} Total terms</span>
                                </th>
                                <th className='search-term-in-table'>
                                    Children Search Term
                                </th>
                                <th style={{ width: '100px' }} > Search Count <br />
                                    <div className='sort-tab'>
                                        <img src={aSort} alt='aSort'
                                            style={{ backgroundColor: sortBtn === "adsA" ? "rgb(63, 172, 203)" : "lightgrey", ...style }}
                                            onClick={() => {
                                                setSortValue("searchCount");
                                                setSortOrder(1);
                                                setSortBtn("adsA");
                                            }}
                                        />
                                        <img src={dSort} alt='dSort'
                                            style={{ backgroundColor: sortBtn === "adsD" ? "rgb(63, 172, 203)" : "lightgrey", ...style }}
                                            onClick={() => {
                                                setSortValue("searchCount");
                                                setSortOrder(-1);
                                                setSortBtn("adsD");
                                            }}
                                        />
                                    </div>
                                    {/* <br /> {totalSearchCount !== 0 && <span>Total Searches {totalSearchCount}</span>} */}
                                </th>
                                <th style={{ width: '100px' }} >Ads Count <br />
                                    <div className='sort-tab' >
                                        <img src={aSort} alt='aSort'
                                            style={{ backgroundColor: sortBtn === "searchA" ? "rgb(63, 172, 203)" : "lightgrey", ...style }}
                                            onClick={() => {
                                                setSortValue("adsCount")
                                                setSortOrder(1)
                                                setSortBtn("searchA")
                                            }}
                                        />
                                        <img src={dSort} alt='dSort'
                                            style={{ backgroundColor: sortBtn === "searchD" ? "rgb(63, 172, 203)" : "lightgrey", ...style }}
                                            onClick={() => {
                                                setSortValue("adsCount")
                                                setSortOrder(-1)
                                                setSortBtn("searchD")
                                            }}
                                        />
                                    </div>
                                </th>
                                <th style={{ width: '15%' }}>Customize Search <br /> Term Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                visibleReport && visibleReport.map((st, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: '30px' }}>{i + 1}</td>
                                            <td>
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey={i}>
                                                        <Accordion.Header>
                                                            '{st.stemmedSearchTerm}'
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <h6><u>Raw Search Terms</u></h6>
                                                        </Accordion.Body>
                                                        {st.rawSearchTerm && st.rawSearchTerm.map((rst, j) => (
                                                            <Accordion.Body key={j}>
                                                                {rst}
                                                            </Accordion.Body>
                                                        ))}
                                                    </Accordion.Item>
                                                </Accordion>
                                            </td>
                                            <td>
                                                <div
                                                    style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', flexWrap: 'wrap' }}
                                                >
                                                    <span
                                                        style={{
                                                            backgroundColor: 'rgb(63, 172, 203)',
                                                            padding: '2px 7px',
                                                            borderRadius: '3px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '0.5rem',
                                                            cursor: 'pointer'
                                                        }}
                                                        className='add-children-Search-term'
                                                        onClick={() => {
                                                            setModalShow(true)
                                                            setModal(true)
                                                            setParentSearchTerm(st.stemmedSearchTerm)
                                                            setParentIndex(i)
                                                            const options = st.childrenSearchTerms?.map(cst => {
                                                                return ({ 'value': cst?.childSearchTermId, 'label': cst?.childSearchTerm })
                                                            })
                                                            setChildrenSearchTerms(options)
                                                        }}
                                                    >
                                                        +
                                                    </span>
                                                    {st.childrenSearchTerms && st.childrenSearchTerms.map((cst, k) => {
                                                        if (k < 2) {
                                                            return (
                                                                <span key={k}
                                                                    style={{
                                                                        backgroundColor: 'lightgray',
                                                                        padding: '2px 5px',
                                                                        borderRadius: '3px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: '0.5rem'
                                                                    }}
                                                                >
                                                                    {cst?.childSearchTerm.length > 20 ? `'${cst?.childSearchTerm.substring(0, 20)}` + "...'" : `'${cst?.childSearchTerm}'`}
                                                                    <span
                                                                        className='remove-children-Search-term'
                                                                        onClick={() => {
                                                                            setDeleteConfirmationModalShow(true)
                                                                            setChildSearchTerm(cst?.childSearchTerm)
                                                                            setParentIndex(i)
                                                                            setChildIndex(k)
                                                                            setParentSearchTerm(st.stemmedSearchTerm)
                                                                        }}
                                                                    >x</span>
                                                                </span>
                                                            )
                                                        }
                                                    })}
                                                    <span
                                                        style={{
                                                            backgroundColor: 'rgb(63, 172, 203)',
                                                            padding: '2px 7px',
                                                            borderRadius: '3px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '0.5rem',
                                                            cursor: 'pointer'
                                                        }}
                                                        className='add-children-Search-term'
                                                        onClick={() => {
                                                            setModalShow(true)
                                                            setModal(false)
                                                            setParentIndex(i)
                                                            setParentSearchTerm(st.stemmedSearchTerm)
                                                            setChildrenSearchTerms(st.childrenSearchTerms)
                                                        }}
                                                    >
                                                        Show All
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{st.searchCount}</td>
                                            <td>{st.adsCount}</td>
                                            <td>
                                                <a href={`/admin/ads-by-search-term/${st.stemmedSearchTerm}/${st._id}`} target="_blank" rel="noreferrer">Customize Result</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>

                    {/* //todo Delete children search term confirmation modal */}
                    <Modal
                        show={deleteConfirmationModalShow}
                        onHide={() => setDeleteConfirmationModalShow(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body closeButton>
                            <strong>Are you sure you want to delete children Search Term '{childSearchTerm}' of '{parentSearchTerm}'? </strong>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setDeleteConfirmationModalShow(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => {
                                removeChildSearchTerm(parentIndex, childIndex)
                            }} >Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* //todo children search terms and add new children search terms modal */}
                    {
                        modal ? <Modal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Add Children Search Terms For '{parentSearchTerm}'
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={choice => { setNewChildrenSearchTerms(choice) }}
                                    onFocus={_ => getChildrenSearchTerms("")}
                                    onInputChange={input => { getChildrenSearchTerms(input) }}
                                    options={childrenSearchTerms}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => setModalShow(false)}>Cancel</Button>
                                <Button variant='success' onClick={() => addChildrenSearchTerms(visibleReport[parentIndex]._id, newChildrenSearchTerms)}>Add</Button>
                            </Modal.Footer>
                        </Modal> : <Modal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Children Search Terms for <u>'{parentSearchTerm}'</u>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', flexWrap: 'wrap' }}
                                >
                                    {childrenSearchTerms && childrenSearchTerms.map((cst, k) => {
                                        return (
                                            <span key={k}
                                                style={{
                                                    backgroundColor: 'lightgray',
                                                    padding: '2px 5px',
                                                    borderRadius: '3px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem'
                                                }}
                                            >
                                                '{cst?.childSearchTerm}'
                                                <span
                                                    className='remove-children-Search-term'
                                                    onClick={() => {
                                                        setDeleteConfirmationModalShow(true)
                                                        setChildSearchTerm(cst?.childSearchTerm)
                                                        setChildIndex(k)
                                                    }}
                                                >x</span>
                                            </span>
                                        )
                                    })}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => setModalShow(false)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    }

                    {/* //todo Show more search terms btn */}
                    <button
                        className='show-more-btn' onClick={() => {
                            if (!morePage) {
                                dispatch(newAlert({ type: "danger", message: "No More Search Terms" }))
                            } else {
                                setPage(page + 1);
                            }
                        }}
                    >Show More...</button>
                </div > : <div className='empty-box'>
                    {
                        loading && <div className='empty-box'> Loading... </div>
                    }
                </div>
            }

        </>
    )
}

export default SearchTermsReport