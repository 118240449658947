export const initTimebar = []
  
    //  dataList Reducer for managing dataList list
    export const timeBarReducer = (dataList, action) => {
      // console.log(dataList);
      // console.log(action.type, action.index, action.value);
      if (isNaN(action.index)) return dataList;
      let nSkills = [...dataList];
      let tdata = [...dataList];
      switch (action.type) {
        case "setList":
          return action.value;
        case "setData":
          tdata[action.index] = action.value;
          break;
        case "setDataValue":
          let { index, value } = action;
          tdata = updateSkillValue(index, value, tdata);
          // tdata.sort((a, b) => b.value - a.value);
          break;
        case "addData":
          nSkills.push(action.value);
          const { value: addedvalue } = action.value;
          // console.log({ nSkills });
          nSkills = updateSkillValue(nSkills.length - 1, addedvalue, nSkills);
          nSkills.sort((a, b) => b.value - a.value);
          return nSkills;
        case "removeData":
          nSkills.splice(action.index, 1);
          return nSkills;
        case "sortData":
          nSkills.sort((a, b) => b.value - a.value);
          return nSkills;
        default:
          return dataList;
      }
      // console.log('datalist: ',dataList);
      return tdata;
    };

    export const updateSkillValue = (
      index,
      newValue,
      skills,
      changeOtherSkills = true
    ) => {
      const updatedSkills = [...skills];

      // Update the skill value at the given index
      const data = { ...skills[index] };
      data.value = newValue;
      updatedSkills[index] = data;

      if (!changeOtherSkills) {
        return updatedSkills;
      }

      // Calculate the total sum of values
      const totalSum = updatedSkills.reduce(
        (sum, skill) => sum + parseFloat(skill.value),
        0
      );
      // Check if the sum exceeds 45.00
      if (totalSum > 45.0) {
        // Reduce the excess proportionally from all skills
        const hrsToReduce = totalSum - 45;
        const numbersToReduce = updatedSkills.map((skill, i) =>
          index !== i ? parseFloat(skill.value) : 0
        );
        // console.log({
        //   hrsToReduce,
        //   numbersToReduce,
        //   totalSum,
        //   newValue: parseFloat(newValue),
        // });
        const reductionPercent =
          (hrsToReduce / numbersToReduce.reduce((a, b) => a + b, 0)) * 100;
        updatedSkills.forEach((skill, i) => {
          if (i !== index) {
            const reduction = (skill.value * reductionPercent) / 100;
            const reducedValue = Math.abs(skill.value - reduction)
              .toFixed(3)
              .slice(0, -1);
            const data = { ...updatedSkills[i] };
            data.value = reducedValue;
            updatedSkills[i] = data;
            // console.log({
            //   data,
            // });
          }
        });
      }
      // console.log({ updatedSkills });
      return updatedSkills;
    };
