import React, { useState, useEffect, useRef } from "react";
import ArticleHeader from "../../../components/ArticleHeader/ArticleHeader";
import ArticleInteractions from "../../../components/ArticleInteractions/ArticleInteractions";
import Article from "../../../components/Article/Article";
import RecommendedArticles from "../../../components/RecommendedArticles/RecommendedArticles";
import ShowMoreRecommendation from "../../../components/ShowMoreRecommendation/ShowMoreRecommendation";
import ArticleDropdownTable from "../../../components/ArticleDropdownTable/ArticleDropdownTable";
import ArticleContentsTable from "../../../components/Article/ArticleContentsTable/ArticleContentsTable";
import ArticleTitle from "../../../components/Article/ArticleTitle/ArticleTitle";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios";
import { newAlert } from "../../../slices/alertSlice";
import ArticleTag from "../../../components/Article/ArticleTag/ArticleTag";
import AboutAuthor from "../../../components/ArticleHeader/AboutAuthor/AboutAuthor";
import { auto } from "@popperjs/core";
import { useParams } from "react-router";
import { removeNumbersFromStart } from "../../../helpers/stubs";
import {
  getSavedCreatedPosts,
  updateVotesForCreatedPosts,
} from "../../../slices/newsFeedSlice";
import ArticlePlaceholder from "./ArticlePlaceholder";

function ArticlePage() {
  // Function to show the alert
  //   const location = useLocation();
  //   const searchParams = new URLSearchParams(location.search);
  const { id: postId } = useParams();
  //   const postId = "64c8a6c21d8a93db4140c5cd";
  const dispatch = useDispatch();
  const savedPosts = useSelector((state) => state.newsFeedSlice.savedPosts);

  const containerRef = useRef(null);
  const [postData, setPostData] = useState({
    title: "",
    tagGroups: [],
    thumbnailSrc: "",
    post: "",
    tableOfContent: [],
  });
  const [authorData, setAuthorData] = useState({
    name: "",
    profilePicUrl: "",
    email: "",
    bio: "",
  });
  const [postInteraction, setPostInteraction] = useState({
    upvoteStatus: false,
    downvoteStatus: false,
    upvoteCount: 0,
  });
  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    bottom: 0,
  });

  const [isLoading, setIsLoading] = useState({
    article: true,
    author: true,
    engagement: true,
    saved: true,
  });
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    setIsHide(containerPosition.top < 200 && containerPosition.bottom > 400);
  }, [containerPosition]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { top, bottom } = containerRef.current.getBoundingClientRect();
        setContainerPosition({ top, bottom });
      }
    };

    // Call handleScroll when the component mounts to get the initial position
    handleScroll();

    // Attach the scroll event listener to recalculate position on scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getAuthorAvatarForTagGroup = async (tagGroupId, authorId) => {
    if (tagGroupId) {
      const { data } = await axiosInstance.get(
        `/api/user/get-author-avatars-for-tagGroup/${tagGroupId}`
      );
      //   console.log(data);
      const authorOpt = data.authors.find((author) => {
        return author._id === authorId;
      });
      return authorOpt;
    } else {
      console.log("no tagId to look for author avatar");
      return [];
    }
  };

  const currLoaded = (value) => {
    switch (value) {
      case "article":
        setIsLoading({
          article: false,
          author: true,
          engagement: true,
          saved: true,
        });
        break;
      case "author":
        setIsLoading({
          article: false,
          author: false,
          engagement: true,
          saved: true,
        });
        break;
      case "engagement":
        setIsLoading({
          article: false,
          author: false,
          engagement: false,
          saved: true,
        });
        break;
      default:
        setIsLoading({
          article: false,
          author: false,
          engagement: false,
          saved: false,
        });
        break;
    }
  };

  const getModifiedArticleHtml = (tableOfContent, postContents) => {
    const parseHtml = (html) =>
      new DOMParser().parseFromString(html, "text/html").body;

    const addIdsRecursively = (element, headingCounter = 1) => {
      const children = Array.from(element.children);

      for (const child of children) {
        if (child.tagName.startsWith("H")) {
          const headingId = `heading-${headingCounter}`;
          child.setAttribute("id", headingId);
          headingCounter++;
        }
        addIdsRecursively(child, headingCounter + 1);
      }
    };

    const parsedHtml = parseHtml(postContents);
    addIdsRecursively(parsedHtml);

    // console.log(parsedHtml.innerHTML);
    return parsedHtml.innerHTML;
  };

  // Simulating API call to load post content
  const loadPostContent = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/user/get-selected-post-details/${postId}/preview`
      );
      // console.log("selectedPost: ", data.selectedPost);
      const postContents = data.selectedPost.post ? data.selectedPost.post : "";
      const title = data.selectedPost.title;
      const thumbnailSrc = data.selectedPost.thumbnail;
      const tagGroups = data.selectedPost.tagGroup.map((item) => {
        return {
          value: item.tagGroupName,
          id: item._id,
        };
      });
      const tableOfContent = data.selectedPost.tableOfContent?.map((item) => {
        return {
          heading: removeNumbersFromStart(item.heading),
          level: item.level,
        };
      });

      const newPostContent = await getModifiedArticleHtml(
        tableOfContent,
        postContents
      );

      let postData = {
        title,
        tagGroups,
        thumbnailSrc,
        post: newPostContent,
        tableOfContent,
      };
      setPostData(postData);
      currLoaded("article");

      //   setLastSavedAt(Date.parse(data.selectedPost.updatedAt));
      const authorId = data.selectedPost.author;
      let author = await getAuthorAvatarForTagGroup(tagGroups[0].id, authorId);
      setAuthorData(author);
      // console.log("postData: ", postData);
      currLoaded("author");

      const votesData = await axiosInstance.post(
        `/api/user/get-votes-for-created-posts`,
        { postIds: [postId] }
      );
      const vote = votesData.data.votes[0];
      // console.log("votes: ", votesData.data.votes);

      const upvoteStatus = vote?.voteType === "upvote";
      const downvoteStatus = vote?.voteType === "downvote";
      const upvoteCount = data.selectedPost.engagement.upvotes;
      setPostInteraction({ upvoteStatus, downvoteStatus, upvoteCount });
      currLoaded("engagement");
      await dispatch(getSavedCreatedPosts());
    } catch (err) {
      console.log(err);
      dispatch(
        newAlert({ type: "danger", message: "Error while loading postData!" })
      );
      currLoaded("saved");
    } finally {
      setIsLoading({
        article: false,
        author: false,
        engagement: false,
        saved: false,
      });
    }
  };

  const updateVotesForPost = async (currentVoteType) => {
    // console.log("currentVoteType: ", currentVoteType);
    const result = await dispatch(
      updateVotesForCreatedPosts({
        postId,
        currentVoteType,
        isUpvoted: postInteraction.upvoteStatus,
        isDownvoted: postInteraction.downvoteStatus,
        query: "",
      })
    );
    // console.log("result: ", result);
    let { upvoteStatus, downvoteStatus, upvoteCount } = postInteraction;
    if (result.meta.requestStatus === "fulfilled") {
      if (currentVoteType === "upvote") {
        upvoteStatus = !upvoteStatus;
        downvoteStatus = false;
        if (result.payload.insertedVote || result.payload.updatedVoteType) {
          upvoteCount = upvoteCount + 1;
        } else if (result.payload.deletedVote) {
          upvoteCount = upvoteCount - 1;
        }
      } else if (currentVoteType === "downvote") {
        downvoteStatus = !downvoteStatus;
        upvoteStatus = false;
        if (result.payload.updatedVoteType) {
          upvoteCount = upvoteCount - 1;
        }
      }

      setPostInteraction({
        upvoteStatus,
        downvoteStatus,
        upvoteCount,
      });
    }
  };
  useEffect(() => {
    loadPostContent();
  }, []);

  return (
    <div className=" p-lg-0 ">
      {isLoading.article ? (
        <ArticlePlaceholder />
      ) : (
        <div className="mx-1">
          <div className="d-lg-flex mx-1">
            <div
              className="position-sticky dropmobstyle"
              style={{
                top: 50,
                right: 0,
                left: 10,
                height: "18%",
                width: "18%",
              }}
            >
              {isHide && (
                <ArticleDropdownTable
                  tableOfContent={postData.tableOfContent}
                />
              )}
              {/* <ArticleFloatingToc /> */}
            </div>

            <div className="px-lg-5">
              <div className="">
                {/* <div className="content min-vh-100 px-sm-3 py-sm-5"> */}
                <div className="content min-vh-100 px-lg-3 ">
                  <div className=" px-lg-5 mx-lg-auto mt-4 mt-md-5">
                    <div>
                      <ArticleHeader
                        author={authorData}
                        title={postData.title}
                        loadingAuthor={isLoading.author}
                      />
                      <ArticleInteractions
                        postInteraction={postInteraction}
                        postId={postId}
                        savedPosts={savedPosts}
                        updateVotesForPost={updateVotesForPost}
                        loadingEngagement={isLoading.engagement}
                        loadingsaved={isLoading.saved}
                      />
                      <div className="ms-3">
                        <ArticleTag tagGroups={postData.tagGroups} />
                      </div>
                      <ArticleTitle thumbnail={postData.thumbnailSrc} />
                    </div>
                    <div className="px-3 px-md-0">
                      <ArticleContentsTable
                        tableOfContent={postData.tableOfContent}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-lg-3">
                <div className="content min-vh-100 px-sm-3 ">
                  <div className=" px-lg-5 mx-lg-auto" ref={containerRef}>
                    <Article postContent={postData.post} />
                    <ArticleInteractions
                      postInteraction={postInteraction}
                      postId={postId}
                      savedPosts={savedPosts}
                      updateVotesForPost={updateVotesForPost}
                      loadingEngagement={isLoading.engagement}
                      loadingsaved={isLoading.saved}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Recommended div */}
          <div
            className=" px-3  pb-5 min-vh-50"
            style={{ backgroundColor: "#FAFAFA" }}
          >
            <div className="content  px-md-3 py-sm-3 py-md-1">
              <div className="px-md-5 mx-md-auto w-100">
                <AboutAuthor
                  author={authorData}
                  loadingAuthor={isLoading.author}
                />
                <RecommendedArticles />
              </div>
            </div>
            <hr style={{ color: "#959595" }} />
            <ShowMoreRecommendation />
          </div>

          {/* <div className="ps-4 px-md-5" style={{ backgroundColor: '#FAFAFA' }}> */}

          {/* </div> */}

          <style jsx>{`
            @media (max-width: 767px) {
              .dropmobstyle {
                width: "50%";
              }
              .hrStyle {
                width: 90%;
                margin: auto;
              }
            }
          `}</style>
          {/* Recommended div for mobile */}
        </div>
      )}
    </div>
  );
}

export default ArticlePage;
