import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axios";

// const { REACT_APP_BACK_END_DOMAIN: backendURL } = process.env;

import { getBackendDomain } from "../helpers/stubs";
const backendURL = getBackendDomain();

//#region getReportAds
export const getIrrelevantAds = createAsyncThunk(
  "user/getIrrelevantAds",
  async (thunkAPI) => {
    try {
      const { data } = await Axios.get(
        `${backendURL}/api/user/get-irrelevant-ads`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
//#endregion

//#region reportAds
export const reportAds = createAsyncThunk(
  "user/reportAds",
  async ({ adId, reportType, isIrrelevantAd, query }, thunkAPI) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/report-ads?adId=${adId}&reportType=${reportType}&isIrrelevantAd=${isIrrelevantAd}&query=${query}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
//#endregion

export const reportAdsSlice = createSlice({
  name: "reportAds",
  initialState: {
    reportAds: [],
    loading: false,
    error: "",
  },
  reducers: {
    setReportAds: (state, action) => {
      const { payload } = action;

      state.reportAds.push(payload);
    },
  },

  extraReducers: {
    [getIrrelevantAds.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getIrrelevantAds.fulfilled]: (state, { payload }) => {
      state.reportAds = payload.irrelevantAds;
      state.loading = false;
    },
    [getIrrelevantAds.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [reportAds.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [reportAds.fulfilled]: (state, { payload }) => {
      const { savedIrrelevantAdInDatabase, removedIrrelevantAdFromDatabase } =
        payload;
      let modifiedPayload = {};
      if (removedIrrelevantAdFromDatabase) {
        modifiedPayload = state.reportAds.filter(
          (a) => a.ad !== removedIrrelevantAdFromDatabase.ad
        );
      } else if (savedIrrelevantAdInDatabase) {
        modifiedPayload = [...state.reportAds, savedIrrelevantAdInDatabase];
      } else {
        modifiedPayload = [...state.reportAds];
      }
      state.reportAds = modifiedPayload;
      state.loading = false;
    },
    [reportAds.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },
  },
});

// Export Actions
export const { setReportAds } = reportAdsSlice.actions;

// Export Reducer
export default reportAdsSlice.reducer;
