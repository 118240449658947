import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import OrganizationCard from '../../../components/OrganizationCard/OrganizationCard'
import './AdPage.css'
import Alert from 'react-bootstrap/Alert';
import FacebookAd from '../../../components/Ad/FacebookAd/FacebookAd';
import GoogleSearchAd from '../../../components/Ad/GoogleSearchAd/GoogleSearchAd';
import DisplayAd from '../../../components/Ad/DisplayAd/DisplayAd';
// import adDetails from '../../../dummy_ad_details_data/ad_details.json';
import { adsForSearchTermPayloadMappingBeforeStoring } from '../../../slices/adSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedAds } from '../../../slices/savedAdsSlice';
import { getIrrelevantAds } from '../../../slices/reportAdsSlice';
import { getVotes } from '../../../slices/votingsSlice';
import upArrow from '../../../assets/images/up_arrow.png'

const AdPage = () => {
    const [show, setShow] = useState(false);
    const [ad, setAd] = useState();
    const { adHeadline, orgName, adId } = useParams();
    useEffect(() => {
        const getAd_organization_and_landingPage = async (adId) => {
            const data = await axiosInstance.get(`/api/public/get-ad/adId=${adId}`).catch(err => {
                setShow(true)
                setTimeout(() => setShow(false), 5000);
            })
            if (data?.status === 200) {
                let modifiedPayload = adsForSearchTermPayloadMappingBeforeStoring([data.data]);
                console.log('modified ad =>', modifiedPayload)
                if (modifiedPayload[0]?.type == 'searchAd') {
                    let landingPageS3UrlWithTitle = [];
                    let index = 1;
                    modifiedPayload[0]?.landingPage?.map(page => {
                        modifiedPayload[0]?.sitelinkExtension?.map(sle => {
                            if (sle.rawUrl == page.rawUrl) {
                                landingPageS3UrlWithTitle[index] = { landingPageScreenshotS3Url: page.landingPageScreenshotS3Url, title: sle.title, rawUrl: sle.rawUrl };
                                index++;
                            };
                        })
                        if (page.rawUrl == modifiedPayload[0].headline?.rawUrl) {
                            landingPageS3UrlWithTitle[0] = { landingPageScreenshotS3Url: page.landingPageScreenshotS3Url, title: modifiedPayload[0].headline?.title, rawUrl: page.rawUrl }
                        }
                    })
                    setAd({ ...modifiedPayload[0], landingPageS3UrlWithTitle })
                } else {
                    setAd(modifiedPayload[0])
                }
            }
            // )
            // if (data?.status === 200) {
            //     setAd(data.data)
            // } 
            else {
                setShow(true)
                setTimeout(() => setShow(false), 5000);
            }
        }
        getAd_organization_and_landingPage(adId);
    }, [adId])

    //ONLY FOR TESTING PURPOSE
    const savedAds = useSelector((state) => state.savedAdsSlice.savedAds);
    const reportAds = useSelector((state) => state.reportAdsSlice.reportAds);
    const votes = useSelector((state) => state.votingsSlice.votes);
    const query = "Test Query";
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSavedAds());
        dispatch(getIrrelevantAds());
        dispatch(getVotes());
    }, []);

    let isSaved = false;
    let isIrrelevantAd = false;
    let isUpvoted = false;
    let isDownvoted = false;

    if (savedAds && savedAds.find((e) => e.ad._id === ad._id)) {
        isSaved = true;
    }

    if (reportAds && reportAds.find((e) => e.ad === ad._id)) {
        isIrrelevantAd = true;
    }

    let checkVote = votes && votes.find((e) => e.ad === ad._id);
    if (checkVote && checkVote.voteType === "upvote") {
        isUpvoted = true;
    } else if (checkVote && checkVote.voteType === "downvote") {
        isDownvoted = true;
    }
    //ONLY FOR TESTING PURPOSE END
    // console.log('adpage ==>', ad)
    return (
        <div className='ad-page-container' id='up'>
            {
                ad &&
                <div className='ad-details-layout'>
                    {/* //? if display ad heigth<width/2 */}
                    <div className={(ad?.dimensions?.height < (ad?.dimensions?.width / 2)) ? 'display-ad-org-card' : 'ad-org-card'}>
                        <div className={ad.type === "displayAd" ? 'none' : 'ad-details-ad-card'} >
                            {ad.type === "fbAd" && <FacebookAd ad={ad} query={query} isSaved={isSaved} isIrrelevantAd={isIrrelevantAd} isUpvoted={isUpvoted} isDownvoted={isDownvoted} />}
                            {ad.type === "searchAd" && <GoogleSearchAd ad={ad} query={query} isSaved={isSaved} isIrrelevantAd={isIrrelevantAd} isUpvoted={isUpvoted} isDownvoted={isDownvoted} />}
                            {ad.type === "displayAd" && <DisplayAd ad={ad} query={query} isSaved={isSaved} isIrrelevantAd={isIrrelevantAd} isUpvoted={isUpvoted} isDownvoted={isDownvoted} />}
                        </div>
                        <OrganizationCard org={ad.organization[0]} dimensions={ad?.dimensions} />
                    </div>
                    {ad.type == 'searchAd' && ad.landingPageS3UrlWithTitle && ad.landingPageS3UrlWithTitle.map(page => (
                        <div id={page.title.split(" ").join("")}>
                            <p className='landing-page-headline'>{page?.title}</p>
                            <div className='landing-page-img card shadow'>
                                <img src={page?.landingPageScreenshotS3Url} alt='landingPage' />
                            </div>
                        </div>
                    ))}
                    {ad.type == 'fbAd' && ad.landingPage &&
                        <div>
                            <p className='landing-page-headline'>Landing Page</p>
                            <div className='landing-page-img card shadow'>
                                <img src={ad.landingPage[0]?.landingPageScreenshotS3Url} alt='landingPage' />
                            </div>
                        </div>
                    }
                </div>
            }
            <Alert key="success" variant="danger" show={show}
                style={{
                    display: "inline",
                    padding: "10px 25px",
                    position: "fixed",
                    fontSize: "large",
                    top: "15px",
                    right: "15px",
                }}
            >Error 404. Not Found
            </Alert>
            <a href={`${window.location.href.split('#')[0]}#up`}><img src={upArrow} alt='up-arrow' className='card shadow' style={{
                position: "fixed",
                height: '45px',
                right: '40px',
                bottom: '50px',
                borderRadius: '50%',
                padding: '7px'
            }} /></a>
        </div>
    )
}

export default AdPage
