import React from "react";
import "./article.css";

function Article({ postContent }) {
  const divStyles = {
    width: "680px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "-0.06px",
  };
  const headingStyles = {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "-0.06px",
    marginTop: "3.7rem",
    marginBottom: "1rem",
  };
  return (
    <div
      className="px-3 px-md-0 mx-lg-auto p-1 p-md-0 mt-4 mt-lg-5 media"
      style={divStyles}
    >
      <div
        className=" mb-5 article-container"
        dangerouslySetInnerHTML={{ __html: postContent }}
      ></div>

      {/* <style jsx>{` */}
      <style>{`
                @media (max-width: 767px) {
                    .media {
                        width: 457px;
                        max-width: 100%;
                    }
                    .media img {
                     max-width: 100%;
                    }
                }
            `}</style>
    </div>
  );
}

export default Article;
