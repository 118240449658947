import React, { useEffect, useState } from "react";
import articleImg from "../../../assets/articlepage-images/articleImg.svg";

function ArticleTitle({ thumbnail }) {
  const [imageWidth, setImageWidth] = useState(680);
  const [imageHeight, setImageHeight] = useState(446);
  const divStyles = {
    width: "680px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "-0.06px",
  };
  const thumbnailStyles = {
    width: `${imageWidth}px`,
    height: `${imageHeight}px`,
    objectFit: "cover",
  };
  const textContentStyles = {
    fontSize: "18px",
    lineHeight: "28px",
    color: "#292929",
  };

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = Math.min(window.innerWidth, 680);

      let containerHeight;
      if (containerWidth < 680) {
        // Reduce height by 20% if width is less than 680
        containerHeight = (containerWidth * (446 - imageHeight * 0.2)) / 680;
      } else {
        containerHeight = (containerWidth * 446) / 680;
      }

      setImageWidth(containerWidth);
      setImageHeight(containerHeight);
    };

    handleResize(); // Call it initially to set the image dimensions
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="px-3 px-md-0 mx-auto p-1 p-md-0 media" style={divStyles}>
      <img
        src={thumbnail}
        alt="articleImg"
        className=""
        style={thumbnailStyles}
      />
      <p className="mt-4 mb-3" style={textContentStyles}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.{" "}
      </p>
      <style jsx>{`
        @media (max-width: 767px) {
          .media {
            width: 457px;
            max-width: 100%;
          }
          .media img {
            max-width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

export default ArticleTitle;
