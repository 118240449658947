import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Axios from "../axios";
import AxiosMaster from "../axiosMaster";
import { newAlert } from "./alertSlice";
import { getBackendDomain } from "../helpers/stubs";
// const { REACT_APP_BACK_END_DOMAIN: backendURL } = process.env;

const backendURL = getBackendDomain();


export const adsForSearchTermPayloadMappingBeforeStoring = (payload) => {
  let modifiedPayload = [];

  payload.map((ad, i) => {
    let { components, ...modAd } = ad;
    let parentCompType = {};
    if (ad.type !== "searchAd") {
      modAd.adDetails = [];
    }
    if (ad.type === "searchAd") {
      modAd.sitelinkExtension = [];
      modAd.priceExtension = [];
      modAd.ratingExtension = [];
    }
    components.map((c) => {
      if (!c.parentComponent) {
        if (c._id) {
          parentCompType[c._id.toString()] = c.type;
        }
        rawRootCompToModAd(c, modAd, ad.type);
      } else {
        let pcType = parentCompType[c.parentComponent.toString()];
        rawChildCompToModAd(c, modAd, ad.type, pcType);
      }
    });
    modifiedPayload.push(modAd);
  });
  return modifiedPayload;
};

// function for storing rawRootComp To ModAd array
function rawRootCompToModAd(c, modAd, adType) {
  if (adType === "fbAd") {
    if (c.type === "primaryText") {
      modAd.primaryText = c.text;
    } else if (c.type === "displayUrl") {
      modAd.displayUrl = c.text;
    } else if (c.type === "image") {
      modAd.adDetails.push({
        _id: c._id.toString(),
        media: c.media,
      });
    }
  } else if (adType === "displayAd") {
    if (c.type === "image") {
      console.log("display type=image", c);
      modAd.adDetails.push({
        _id: c._id.toString(),
        media: c.media,
      });
    }
    modAd.dimensions = c?.additionalData?.dimensions;
  } else if (adType === "searchAd") {
    if (c.type === "headline") {
      modAd.headline = {
        title: c.text,
        normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        redirectedUrl: c.redirectedUrl,
        landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      };
    } else if (c.type === "description") {
      modAd.adDescription = c.text;
    } else if (c.type === "locationExtension") {
      modAd.locationExtension = c.text;
    } else if (c.type === "calloutExtension") {
      modAd.calloutExtension = JSON.parse(c.text);
    } else if (c.type === "callExtension") {
      modAd.callExtension = c.text;
    } else if (c.type === "displayUrl") {
      modAd.displayUrl = c.text;
    } else if (c.type === "sitelinkExtension") {
      modAd.sitelinkExtension.push({
        _id: c._id.toString(),
        title: c.text,
        normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        redirectedUrl: c.redirectedUrl,
        landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      });
    } else if (c.type === "priceExtension") {
      modAd.priceExtension.push({
        _id: c._id.toString(),
        title: c.text,
        normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        redirectedUrl: c.redirectedUrl,
        landingPage: c.landingPage,
        rawUrl: c.rawUrl,
        price: c?.additionalData?.price,
      });
    } else if (c.type === "ratingExtension") {
      modAd.ratingExtension.push({
        _id: c._id.toString(),
        title: c.text,
        normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        redirectedUrl: c.redirectedUrl,
        landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      });
    }
  }
}

// function for storing rawChildComp To ModAd array
function rawChildCompToModAd(c, modAd, adType, pcType) {
  if (adType === "fbAd") {
    let idx = modAd.adDetails.findIndex(
      (a) => a._id === c.parentComponent.toString()
    );
    if (c.type === "headline") {
      modAd.adDetails[idx].headline = {
        title: c.text,
        // normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        // redirectedUrl: c.redirectedUrl,
        // landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      };
    } else if (c.type === "description") {
      modAd.adDetails[idx].description = {
        title: c.text,
        // normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        // redirectedUrl: c.redirectedUrl,
        // landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      };
    } else if (c.type === "cta") {
      modAd.adDetails[idx].cta = {
        title: c.text,
        // normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        // redirectedUrl: c.redirectedUrl,
        // landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      };
    } else if (c.type === "html5") {
      modAd.adDetails[idx].media = c.media;
    }
  } else if (adType === "displayAd") {
    let idx = modAd.adDetails.findIndex(
      (a) => a._id === c.parentComponent.toString()
    );
    if (c.type === "cta") {
      modAd.adDetails[idx].cta = {
        title: c.text,
        // normalizedUrl: c.normalizedUrlWithSubFolderDomain,
        // redirectedUrl: c.redirectedUrl,
        // landingPage: c.landingPage,
        rawUrl: c.rawUrl,
      };
    }
  } else if (adType === "searchAd") {
    if (c.type === "description") {
      if (pcType === "sitelinkExtension") {
        let idx = modAd.sitelinkExtension.findIndex(
          (a) => a._id === c.parentComponent.toString()
        );
        modAd.sitelinkExtension[idx].description = JSON.parse(c.text);
      } else if (pcType === "priceExtension") {
        let idx = modAd.priceExtension.findIndex(
          (a) => a._id === c.parentComponent.toString()
        );
        modAd.priceExtension[idx].description = c.text;
      } else if (pcType === "ratingExtension") {
        let idx = modAd.ratingExtension.findIndex(
          (a) => a._id === c.parentComponent.toString()
        );
        modAd.ratingExtension[idx].description = JSON.parse(c.text);
      }
    }
  }
}

// Thunks
// export const getAdsForSearchTerm = createAsyncThunk("ads/getAdsForSearchTerm", async ({ encodedSearchTerm, searchType }, thunkAPI) => {
//   try {
//     // const {
//     //   data: { adsForSearchTerm }
//     // } = await axios.get(`${backendURL}/api/ads/get-ads-for-search-term?term=${encodedSearchTerm}&type=${searchType}`);
//     const {
//       data: { adsForSearchTerm },
//     } = await axios.get(`${backendURL}/api/ads/get-ads-for-search-term?term=${encodedSearchTerm}&type=${searchType}`);

//     return adsForSearchTerm;
//   } catch (error) {
//     throw new Error(error);
//   }
// });

export const getAdsForSearchTerm = createAsyncThunk(
  "ads/getAdsForSearchTerm",
  async (payload, thunkAPI) => {
    try {
      const {
        searchTerm,
        pageNumber,
        loadAdsTillPageNumber = 1,
        setLoader,
      } = payload;
      const { data } = await Axios.get(
        `/api/public/get-ads-for-search-term/${searchTerm}&pg=${pageNumber}&loadAdsTillPageNumber=${loadAdsTillPageNumber}`
      );
      // const { data } = await Axios.get(`/api/public/get-ads-for-search-term/${searchTerm}&pg=${pageNumber}`);
      const resultAds = data.hits.hits.map((hit) => {
        return { _id: hit._id, ...hit._source };
      });
      const gotAds = data.hits.hits.length > 0 ? true : false;
      if (!gotAds && pageNumber <= 1) {
        thunkAPI.dispatch(
          newAlert({ type: "danger", message: "Ads not available" })
        );
      }
      setLoader(false);
      return { resultAds, gotAds };
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);
export const getAdsForAdvancedSearch = createAsyncThunk(
  "ads/getAdsForAdvancedSearch",
  async (payload, thunkAPI) => {
    try {
      // const { searchTerm, adType, industryType, pageNumber, loadAdsTillPageNumber = 1 } = payload
      const {
        searchTerm,
        adType,
        pageNumber,
        loadAdsTillPageNumber = 1,
        setLoader,
      } = payload;
      // const { data } = await Axios.get(`api/public/get-ads-for-advanced-search/${searchTerm}&${adType}&${industryType}&pg=${pageNumber}&loadAdsTillPageNumber=${loadAdsTillPageNumber}`);
      const { data } = await Axios.get(
        `api/public/get-ads-for-advanced-search/${searchTerm}&${adType}&pg=${pageNumber}&loadAdsTillPageNumber=${loadAdsTillPageNumber}`
      );
      const resultAds = data.hits.hits.map((hit) => {
        return { _id: hit._id, ...hit._source };
      });
      const gotAds = data.hits.hits.length > 0 ? true : false;
      if (!gotAds && pageNumber <= 1) {
        thunkAPI.dispatch(
          newAlert({ type: "danger", message: "Ads not available" })
        );
      }
      setLoader(false);
      return { resultAds, gotAds };
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);
export const getAdsForPredefinedSearchTerm = createAsyncThunk(
  "ads/getAdsForPredefinedSearchTerm",
  async (payload, thunkAPI) => {
    try {
      const {
        searchTerm,
        adType = "All types",
        pageNumber,
        loadAdsTillPageNumber = 1,
        setLoader,
      } = payload;
      const { data } = await Axios.get(
        `api/public/get-ads-for-predefined-search-term`
      );
      const resultAds = data.map((ad) => {
        return { _id: ad._id, ...ad._source };
      });
      const gotAds = data.length > 0 ? true : false;
      if (!gotAds && pageNumber <= 1) {
        thunkAPI.dispatch(
          newAlert({ type: "danger", message: "Ads not available" })
        );
      }
      setLoader(false);
      return { resultAds, gotAds };
    } catch (error) {
      const { data } = error.response;
      thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
      return Promise.reject({ error: { message: data.error.message } });
    }
  }
);

// export const saveSearchTermToMaster = createAsyncThunk("ads/saveSearchTermToMaster", async (payload,thunkAPI)=>{
//   try{
//     const searchedAt= new Date()
//     const{searchTerm}=payload
//     const reqBody={searchTerm,searchedAt}
//     const {data}= await AxiosMaster.post(`api/search-terms/save-search-term-to-db`,reqBody)
//   }catch (error) {
//     const { data } = error.response;
//     thunkAPI.dispatch(newAlert({ type: "danger", message: "Server Error" }));
//     return Promise.reject({ error: { message: data.error.message } });
//   }
// })
export const adSlice = createSlice({
  name: "ads",
  initialState: {
    adsForSearchTerm: [],
    gotMoreAds: false,
    loading: false,
    error: "",
  },
  reducers: {
    setAdsForSearchTerm: (state, action) => {
      const { payload } = action;

      state.adsForSearchTerm.push(payload);
    },
    clearAdsArray: (state, action) => {
      state.adsForSearchTerm = [];
    },
  },
  extraReducers: {
    [getAdsForSearchTerm.fulfilled]: (state, { payload }) => {
      let modifiedPayload = adsForSearchTermPayloadMappingBeforeStoring(
        payload.resultAds
      );
      state.adsForSearchTerm = state.adsForSearchTerm.concat(modifiedPayload);
      // state.adsForSearchTerm = payload;
      state.gotMoreAds = payload.gotAds;
      state.loading = false;
    },
    [getAdsForSearchTerm.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAdsForSearchTerm.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
      state.gotMoreAds = false;
    },

    [getAdsForAdvancedSearch.fulfilled]: (state, { payload }) => {
      let modifiedPayload = adsForSearchTermPayloadMappingBeforeStoring(
        payload.resultAds
      );
      state.adsForSearchTerm = state.adsForSearchTerm.concat(modifiedPayload);
      // state.adsForSearchTerm = payload;
      state.gotMoreAds = payload.gotAds;
      state.loading = false;
    },
    [getAdsForAdvancedSearch.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAdsForAdvancedSearch.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
      state.gotMoreAds = false;
    },

    [getAdsForPredefinedSearchTerm.fulfilled]: (state, { payload }) => {
      let modifiedPayload = adsForSearchTermPayloadMappingBeforeStoring(
        payload.resultAds
      );
      state.adsForSearchTerm = state.adsForSearchTerm.concat(modifiedPayload);
      // state.adsForSearchTerm = payload;
      state.gotMoreAds = payload.gotAds;
      state.loading = false;
    },
    [getAdsForPredefinedSearchTerm.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAdsForPredefinedSearchTerm.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
      state.gotMoreAds = false;
    },
  },
});

// Export Actions
export const { setAdsForSearchTerm, clearAdsArray } = adSlice.actions;

// Export Reducer
export default adSlice.reducer;
