import { useLocation } from "react-router-dom";
import { excludeFooterFromRoutePattern } from "../../helpers/stubs";

export default function FooterWrapper({ children }) {
  const location = useLocation();

  // Extract the pathname
  const { pathname, search } = location;
  const fullPath = pathname + search;

  const fExcludePattern = excludeFooterFromRoutePattern(fullPath);
  if (fExcludePattern) {
    return <></>;
  }

  return <>{children}</>;
}
