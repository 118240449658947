import React from "react";
// import noAiTool from "../../../assets/profile-images/noAiToolChatgpt.svg";
// import noAiTool from "../../../assets/profile-images/noAiToolBot.svg";
import cloverLeafIcon from "../../../assets/profile-images/clover_leaf_red.png";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Test3Preference.css";
import { saveTestPrefForTest3 } from "../../ProfilePage/utility/userUtils";

function Test3Preference({ email }) {
  return (
    <div style={{ margin: "3rem 0" }}>
      <Container className="d-flex flex-column align-items-center">
        <div className="test3Preference">
          <Row style={{ textAlign: "center", marginTop: "20px" }}>
            <Col>
              <img
                src={cloverLeafIcon}
                alt="clover-leaf-icon"
                style={{ height: "64px", width: "64px" }}
              />
              <h3
                style={{
                  fontWeight: "bold",
                  margin: "1rem 0",
                }}
              >
                Use of AI tools detected.
              </h3>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            <div
              className="d-flex align-items-center flex-column"
              style={{
                color: "#585858",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "34px",
              }}
            >
              <p>
                We suspect you have used AI tools like Chatgpt during your test.
              </p>
              <p>
                At this time we are preferring candidates who did not use AI
                tools.
              </p>
              <p>
                If you have not used AI tools you could prove your skills by
                giving another test, but this time you will need to record your
                video as well.
              </p>
              <Button
                style={{
                  background:
                    "linear-gradient(180deg, #49B933 0%, #31AB19 100%)",
                  border: "2px solid #2EA616",
                  borderRadius: "4px",
                }}
                onClick={async () => {
                  const data = await saveTestPrefForTest3(email);
                  // console.log({ data });
                  if (data.status === "success") {
                    window.location.replace(data.testUrl);
                  }
                }}
              >
                Give another test
              </Button>
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <hr style={{ width: "622px" }}></hr>
            </div>
          </Row>
          <Row>
            <div
              style={{ marginTop: "22px", fontSize: "18px" }}
              className="d-flex justify-content-center"
            >
              <span>
                As promised, you will get to attend the live lecture on{" "}
              </span>
            </div>
          </Row>
          <Row>
            <div
              style={{ fontSize: "18px", marginTop: "2px" }}
              className="d-flex justify-content-center"
            >
              <span>AI–RAG for Chatbot.</span>
            </div>
          </Row>
          <Row>
            <div
              style={{ marginTop: "18px", fontSize: "18px" }}
              className="d-flex justify-content-center"
            >
              <span>Pick time option for the lecture here: &nbsp; </span>
              <span> </span>
              <a
                target="_blank"
                href="https://meet.zoho.com/2VcfBrY9JT"
                rel="noreferrer"
                style={{ textDecoration: "underline", color: "#0056D2" }}
              >
                Time options
              </a>
              <span>⏰</span>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Test3Preference;
