import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axios";

// const { REACT_APP_BACK_END_DOMAIN: backendURL } = process.env;

import { getBackendDomain } from "../helpers/stubs";

const backendURL = getBackendDomain();
//#region GetSavedAds
export const getSavedAds = createAsyncThunk(
  "user/getSavedAds",
  async (thunkAPI) => {
    try {
      const { data } = await Axios.get(`${backendURL}/api/user/get-saved-ads`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
//#endregion

//#region SaveUnsaveAds
export const saveUnsaveAds = createAsyncThunk(
  "user/saveUnsaveAds",
  async ({ adId, isSaved, query }, thunkAPI) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/save-unsave-ads?adId=${adId}&isSaved=${isSaved}&query=${query}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
//#endregion

export const savedAdsSlice = createSlice({
  name: "savedAds",
  initialState: {
    savedAds: [],
    loading: false,
    error: "",
  },
  reducers: {
    setSavedAds: (state, action) => {
      const { payload } = action;

      state.savedAds.push(payload);
    },
  },

  extraReducers: {
    [getSavedAds.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSavedAds.fulfilled]: (state, { payload }) => {
      state.savedAds = payload.user;
      state.loading = false;
    },
    [getSavedAds.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [saveUnsaveAds.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [saveUnsaveAds.fulfilled]: (state, { payload }) => {
      const { savedAd, removedAd } = payload;
      let modifiedPayload = {};

      if (removedAd) {
        modifiedPayload = state.savedAds.filter((a) => a.ad !== removedAd.ad);
      } else if (savedAd) {
        modifiedPayload = [...state.savedAds, savedAd];
      }

      state.savedAds = modifiedPayload;
      state.loading = false;
    },
    [saveUnsaveAds.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },
  },
});

// Export Actions
export const { setSavedAds } = savedAdsSlice.actions;

// Export Reducer
export default savedAdsSlice.reducer;
