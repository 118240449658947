import React, { useEffect, useState } from "react";
import greenCheckImg from "../../../assets/profile-images/green_check.svg";
import addIcon from "../../../assets/profile-images/add_icon.svg";
import SelectDropdown from "../workComponents/subComp/SelectDropdown";
import axiosInstance from "../../../axios";
import { useDispatch } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";
import { updateSkillValue } from "../workComponents/reducers/timeBarReducer";

// It is the timebar used to edit time spend on skills/tools/software
export const EditProjectSkills = ({
  timebarData,
  dataList,
  setDataList,
  showLessSkillHrsMsg,
  setShowLessSkillHrsMsg,
}) => {
  // console.log({dataList})
  const dispatch = useDispatch();
  const initTBData = { value: "0", name: { label: "" } };
  const [editTBData, setEditTBData] = useState(initTBData);
  const [hideTimebar, setHideTimebar] = useState(true);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  };
  const minValue = 0;
  const maxValue = 45;
  const step = 0.25;

  const getAvailableSkills = async (skillName) => {
    try {
      // console.log({ skillName });
      if (!skillName) {
        setSkillsOptions([]);
        return;
      }
      const { data } = await axiosInstance.get(
        `/api/user/get-approved-skills-by-name/${skillName}`
      );
      // console.log("matchedSkills: ", data.matchedSkills);
      const options = await data.matchedSkills.map((doc) => {
        return {
          value: skillName,
          label: doc.name,
          _id: doc._id,
        };
      });
      options.push({
        value: `Create new Skill:${skillName}`,
        label: `Create new Skill:${skillName}`,
      });
      setSkillsOptions(options);
      // console.log("Got title; ", options);
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  const updateSkillsData = (value, index) => {
    const newDataList = updateSkillValue(index, value, dataList, false);
    setDataList(newDataList);
  };
  const addSkillsData = (value) => {
    const newDataList = [...dataList, value];
    setDataList(newDataList);
  };

  const getSelectedSkill = async (choice) => {
    // console.log({ choice });
    try {
      if (choice.label.startsWith("Create new Skill:")) {
        const newSkillNameEntered = choice.label.split("Create new Skill:")[1];
        // console.log("newSkillNameEntered: ",newSkillNameEntered)
        const { data } = await axiosInstance.post(`/api/user/add-new-skill`, {
          skillName: newSkillNameEntered,
        });
        const selectedSkill = {
          label: data.selectedSkill.name,
          value: data.selectedSkill.name,
          _id: data.selectedSkill._id,
        };
        setEditTBData({ ...editTBData, name: selectedSkill });
      } else {
        setEditTBData({ ...editTBData, name: choice });
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  const removeEditTimebar = () => {
    setEditTBData(initTBData);
    setHideTimebar(true);
  };
  const addEditTimebar = () => {
    if (!hideTimebar && editTBData.name) {
      const value = {
        value: editTBData.value,
        name: editTBData.name,
      };
      const newSkillId = editTBData.name?._id;
      if (!newSkillId) {
        dispatch(
          newAlert({ type: "danger", message: "Please enter valid skill" })
        );
        return;
      }
      const dataIdList = dataList.map((data) => data.name?._id);
      if (dataIdList.includes(newSkillId)) {
        dispatch(
          newAlert({ type: "danger", message: "This skill already exists" })
        );
        return;
      }
      // console.log({ newSkillId, dataList });
      addSkillsData(value);
      setEditTBData(initTBData);
      // console.log([...dataList, editTBData])
    } else {
      setHideTimebar(false);
    }
  };
  const saveNumberInRange = (number, min, max) => {
    console.log(typeof number, number);
    number = number.replace(/^0/g, "");
    if (number === "") {
      return "0";
    }
    if (parseFloat(number) < parseFloat(min)) {
      return min;
    } else if (parseFloat(number) > parseFloat(max)) {
      return max;
    } else {
      return number;
    }
  };
  const removeTimebar = (index) => {
    // let updatedDataList = [...dataList]
    setDataList({ type: "removeData", index });
    // setDataList(datalist);
    reset();
  };
  const saveTimebar = (index) => {
    addEditTimebar();
    removeEditTimebar();
  };
  useEffect(() => {
    // console.log(dataList);
    const fHrsLessthan1 = dataList.some((skill) => skill.value < 1);
    setShowLessSkillHrsMsg({
      ...showLessSkillHrsMsg,
      lessSkillHrs: fHrsLessthan1,
    });
  }, [dataList]);

  return (
    <div className="mb-2 edit-timebar-section" key={seed}>
      {dataList && dataList.length === 0 && hideTimebar ? (
        <div className="text-center">No data added yet</div>
      ) : (
        <div></div>
      )}
      {/* <Labels /> */}
      {dataList?.map((timebarData, index) => {
        return (
          <div key={index}>
            <div className="row skill-timebar">
              <div className="col-lg-3 col-3 pe-0">
                <div className="whitetooltip fw-500 d-flex justify-content-end fs-responsive">
                  <span
                    data-tooltip={timebarData.name?.label}
                    data-tooltip-location="left"
                    className="text-line-epipsis"
                    // style={{ fontSize: "14px" }}
                  >
                    {timebarData.name?.label}
                  </span>
                </div>
              </div>
              <div
                className="col-lg-7 col-7"
                style={{ transform: "scaleX(1.06) translateX(6px)" }}
              >
                <div
                  className=""
                  style={{ width: "100%", position: "relative" }}
                >
                  <span className="slider-bg-limiter"></span>
                  <input
                    key={seed}
                    type="range"
                    min={minValue}
                    max={maxValue}
                    step={step}
                    value={parseFloat(timebarData.value).toFixed(2)}
                    onInput={(e) => {
                      const value = parseFloat(e.target.value).toFixed(2);
                      updateSkillsData(value, index);
                    }}
                    onMouseUp={() => {
                      // setDataList({ type: "sortData", index });
                      // reset();
                    }}
                    className={`slider slider0`}
                    id="myRange"
                    style={{
                      background: `linear-gradient(to right, #C3E1FF ${Number(
                        timebarData.value * 2.09
                      )}%, #F3F9FF 0%)`,
                      border: "2px solid #B0D0F0",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-2 px-sm-0 d-flex fs-responsive skill-bar-options">
                <input
                  type="number"
                  min={0}
                  max={45}
                  step={0.25}
                  value={parseFloat(timebarData.value).toFixed(2)}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value).toFixed(2);
                    updateSkillsData(value, index);
                  }}
                  // onBlur={() => {
                  //   setDataList({ type: "sortData", index });
                  //   reset();
                  // }}
                  className="skills-input-value col-3"
                />
                <span className="me-1 skill-value-type">hr</span>
                {/* timebarData.value * 25 */}
                <i
                  className="far fa-trash-can pt-sm-1 ms-sm-auto pe-sm-2 ps-sm-1 cursor-pointor skill-delete-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    removeTimebar(index);
                  }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        );
      })}
      <div className={`row ${hideTimebar ? "d-none" : ""}`} id="EditTimebar">
        <div className="col-lg-3 col-3 px-0 ps-sm-2" style={{ zIndex: 10 }}>
          <SelectDropdown
            selectedValue={editTBData.name}
            setSelectedValue={(choice) =>
              setEditTBData({ ...editTBData, name: choice })
            }
            placeholder="Add Skill"
            options={skillsOptions}
            noOptionsMessage={"no skills available"}
            removeDropdownIndicator={true}
            onInputChange={(input) => {
              getAvailableSkills(input);
            }}
            onValueChange={(choice) => {
              getSelectedSkill(choice);
            }}
          />
        </div>
        <div
          className="col-lg-7 col-7"
          style={{ transform: "scaleX(1.06) translateX(6px)" }}
        >
          <div className="" style={{ width: "100%", position: "relative" }}>
            <span className="slider-bg-limiter"></span>
            <input
              type="range"
              min={minValue}
              max={maxValue}
              step={step}
              value={editTBData.value}
              // onBlur={() => setDataList({ type: "sortData", index: 0 })}
              onInput={(e) =>
                setEditTBData({
                  ...editTBData,
                  value: parseFloat(e.target.value).toFixed(2),
                })
              }
              className={`slider slider0`}
              id="myRange"
              style={{
                background: `linear-gradient(to right, #C3E1FF ${Number(
                  editTBData.value * 2.09
                )}%, #F3F9FF 0%)`,
                border: "2px solid #B0D0F0",
              }}
            />
          </div>
        </div>
        <div className="col-lg-2 col-2 px-0 d-flex fs-responsive skill-bar-options{">
          <input
            type="number"
            min={minValue}
            max={maxValue}
            step={step}
            value={editTBData.value}
            onBlur={() => {
              setEditTBData({
                ...editTBData,
                value: parseFloat(editTBData.value).toFixed(2),
              });
            }}
            onChange={(e) =>
              setEditTBData({
                ...editTBData,
                value: saveNumberInRange(e.target.value, "0", "45.00"),
              })
            }
            className="skills-input-value col-3"
          />
          <span className="me-1 skill-value-type">hr</span>
          <i
            className="far fa-trash-can pt-sm-1 ms-auto pe-sm-2 ps-sm-1 cursor-pointor skill-delete-option"
            style={{ cursor: "pointer" }}
            onClick={removeEditTimebar}
            aria-hidden="true"
          ></i>

          {!hideTimebar && (
            <span onClick={saveTimebar} style={{ cursor: "pointer" }}>
              <img
                className="skill-save-option"
                src={greenCheckImg}
                alt="green_check"
              />
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-8 pe-0"></div>
        <div className="col-lg-7 col-11">
          <button className="add-skill-btn" onClick={addEditTimebar}>
            <img
              src={addIcon}
              alt="add skill"
              style={{ height: "13px", paddingBottom: "2px" }}
            />{" "}
            {timebarData.addText}
          </button>
        </div>
      </div>
    </div>
  );
};
