import { createSlice } from '@reduxjs/toolkit'

export const workSlice = createSlice({
  name: "workData",
  initialState: {
    workData: [],
    edit_profile_field_messages: {
      workMessages: {
        name: "Some message about name",
        position: "Some message about position",
        url: "Some message about url",
        startYear: "Some message about startYear",
        endYear: "Some message about endYear",
        startMonth: "Some message about startMonth",
        endMonth: "Some message about endMonth",
        currWorking: "Some message about currWorking",
        description: "Some message about description",
        workingHours: "Some message about workingHours",
        timePeriod: "Some message about timePeriod",
        skills: "Some message about skills",
        tools: "Some message about tools",
        contribution: "Some message about contribution",
      },
      eduMessages: {
        name: "Some message about name",
        url: "Some message about url",
        degree: "Some message about degree",
        major: "Some message about major",
        grade: "Some message about grade",
        startYear: "Some message about startYear",
        endYear: "Some message about endYear",
        currStudying: "Some message about currStudying",
        description: "Some message about description",
      },
    },
  },

  reducers: {
    setWorkDetails: (state, action) => {
      state.workData = action.payload.data;
    },
    addWorkDetails: (state, action) => {
      state.workData.push(action.payload.data);
      // state.workData.sort((a, b) => {
      //   return b.timePeriod.startYear - a.timePeriod.startYear;
      // });
    },
    updateWorkDetails: (state, action) => {
      let workData = state.workData;
      workData.splice(action.payload.index, 1, action.payload.data);
      workData.sort((a, b) => {
        return b.timePeriod.startYear - a.timePeriod.startYear;
      });
      state.workData = workData;
    },
    deleteWorkDetails: (state, action) => {
      let workData = state.workData;
      workData.splice(action.payload.index, 1);
      state.workData = workData;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setWorkDetails,
  addWorkDetails,
  updateWorkDetails,
  deleteWorkDetails,
} = workSlice.actions;

export default workSlice.reducer