import React from "react";
import tick from "../../../assets/profile-images/tick.svg";
import testSubmittedBackgroundImage from "../../../assets/profile-images/testSubmittedBackground.svg";
import { Col, Container, Row } from "react-bootstrap";
import "./Test3Completion.css";

function Test3Completion() {
  return (
    <div style={{ height: "100dvh" }}>
      <Container className="d-flex flex-column align-items-center">
        <div className="test3Completion">
          <Row style={{ textAlign: "center"}}>
            <Col>
              <img
                style={{ height: "182px", width: "357px", marginLeft: "110px" }}
                src={testSubmittedBackgroundImage}
                alt="testSubmitedSuccessfully"
              />
              <img
                style={{
                  height: "81px",
                  width: "88px",
                  position: "relative",
                  top: "-2px",
                  right: "230px",
                }}
                src={tick}
                alt="tick"
              />
              <h3
                style={{
                  fontWeight: "bold",
                }}
              >
                Thank you for submitting your attempt.
              </h3>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              textAlign: "center",
              marginTop: "27px",
              fontSize: "20px",
            }}
          >
            <div className="d-flex justify-content-center">
              <span>
                We'll let you know about the test results and interview in 4 - 5
                days.
              </span>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Test3Completion;
