import React from "react";
import codeimg from "../../../assets/images/code.svg";
import "./PageNotFound.css";
function PageNotFound() {

  return (
    <div className="pagenotfound-container  ">
      <div className="row">
        <div className="col-sm-4">
          <img src={codeimg} alt="codeimage" />
        </div>
        <div className="col-sm-8">
          <div className="heading">Oops!</div>
          <div className="error-body">
            <p className="message">
              We can't find the page you're looking for.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
