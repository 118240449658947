import React from "react";
import styles from "./GoogleSearchAd.module.css";
import SiteLinksExtension from "./SiteLinksExtension/SiteLinksExtension";
import LocationExtension from "./LocationExtension/LocationExtension";
import CallExtension from "./CallExtension/CallExtension";
import RatingExtension from "./RatingExtension/RatingExtension";
import PriceExtension from "./PriceExtension/PriceExtension";
import FeedbackButtons from "../FeedbackButtons/FeedbackButtons";
import { useEffect } from "react";

const GoogleSearchAd = ({ ad, isSaved, userId, isIrrelevantAd, isUpvoted, isDownvoted, query }) => {
  const {
    // headline: { title: headlineTitle, rawUrl: headlineUrl },
    headline,
    displayUrl,
    adDescription,
    calloutExtensions,
    sitelinkExtension,
    locationExtension,
    position,
    blockPosition,
    callExtension,
    ratingExtension,
    priceExtension,
  } = ad;

  const [isDisabled, setIsDisabled] = React.useState(isIrrelevantAd);
  const toggleDisabled = () => setIsDisabled(!isDisabled);
  return (
    <div className={`card shadow position-relative ${styles["ad-card"]}`}>
      <div className={`${styles["card-body"]} card-body p-sm-4`}>
        <div className="d-flex justify-content-between align-items-center">
          <p className={`${styles["ad-indicator"]} mb-1 d-flex align-items-center`} style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
            <span className={`${styles["ad-indicator"]} fw-bold`}>
              Ad<span className="mx-1">·</span>{" "}
            </span>
            <a href={displayUrl} className={`${styles["ad-indicator"]} text-decoration-none`} target="_blank" rel="noreferrer" onClick={e => e.preventDefault()}>
              {displayUrl?.substring(0, 20)}
              {displayUrl?.length > 20 ? "..." : ""}
            </a>
          </p>
          <FeedbackButtons
            adId={ad._id}
            upvoteCount={ad.upvoteCount}
            query={query}
            userId={userId}
            isDisabled={isDisabled}
            isSaved={isSaved}
            toggleDisabled={toggleDisabled}
            isIrrelevantAd={isIrrelevantAd}
            adType="searchAd"
            isUpvoted={isUpvoted}
            isDownvoted={isDownvoted}
          />
        </div>
        {/* <h4 style={{ margin: '5px', marginLeft: '15px' }} >{ad.rankingScore}</h4> //! for testing only */}
        <div className="d-flex justify-content-between align-items-start mb-1" style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          <a href={`${window.location.href.split('#')[0]}#${headline?.title?.split(" ").join("")}`} className={`${styles["headline"]} card-title fs-5 mb-1`}>
            {headline?.title}
          </a>
        </div>

        <p className={`${styles["ad-description"]} card-text mb-1`} style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          {adDescription}
        </p>

        {/* {calloutExtensions.length && (
          <div className="calloutExtensions row">
            {calloutExtensions.map((callout) => {
              return (
                <div className="col-6 col-sm-4 col-md-6" key={callout.link}>
                  <a
                    href={callout.link}
                    className={`${styles["callout"]} d-inline-block me-2 text-decoration-none`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {callout.title}
                  </a>
                </div>
              );
            })}
          </div>
        )} */}
        <div style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          {ratingExtension?.length ? <RatingExtension ratingExtension={ratingExtension} /> : null}

          {priceExtension?.length ? <PriceExtension priceExtension={priceExtension} /> : null}

          {sitelinkExtension?.length ? <SiteLinksExtension siteLinksExtension={sitelinkExtension} position={{ position, blockPosition }} landingPageS3UrlWithTitle={ad.landingPageS3UrlWithTitle} /> : null}

          {callExtension ? <CallExtension callExtension={callExtension} /> : null}

          {locationExtension ? <LocationExtension locationExtension={locationExtension} /> : null}
        </div>
      </div>
    </div>
  );
};

export default GoogleSearchAd;
