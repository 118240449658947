import React, { useState } from "react";
import "./ArticleIssues.css"; // Import your custom CSS file
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../axios";
import { Spinner } from "react-bootstrap";
import { newAlert } from "../../../../slices/alertSlice";
import { useDispatch } from "react-redux";

const MarkReviewArticle = ({
  generatedArticle,
  mainArticle,
  promptChain,
  articleLabel,
  setArticleLabel,
  userData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const updateGeneratedArticle = async (e) => {
    try {
      setIsLoading(true);
      const name = e.target.name;
      const value = e.target.checked;
      const setter = {};
      if (name === "personalExperience") {
        setter["markedAs.personalExperience"] = {
          fPersonalExperience: value,
          reviewedBy: userData?._id,
        };
      } else if (name === "promotionalArticle") {
        setter["markedAs.promotionalArticle"] = {
          fPromotionalArticle: value,
          reviewedBy: userData?._id,
        };
      } else if (name === "inAppropriate"){
        setter["markedAs.inAppropriate"] = {
          fInAppropriate: value,
          reviewedBy: userData?._id,
        };        
      }
      // console.log({ name, value });
      // console.log({ setter, generatedArticle });
      if (!generatedArticle) {
        throw new Error("generated article not found");
      }
      if (!userData?._id) {
        throw new Error("UserId not found");
      }
      const { data } = await axiosInstance.post(
        `/api/admin/update-generated-article`,
        { id: generatedArticle, setter }
      );
      // console.log({ data });
      if (data?.status === "success") {
        console.log("sucessfully updated generated article");
        setArticleLabel((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        throw new Error("Error updating generated article");
      }
    } catch (error) {
      // console.log(error);
      dispatch(
        newAlert({
          type: "danger",
          message: `Error: ${error.message}`,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-between mt-4">
      <div className="d-flex">
        <div className="form-check me-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="personal-experience"
            name="personalExperience"
            checked={articleLabel.personalExperience}
            onChange={updateGeneratedArticle}
          />
          <label className="form-check-label" htmlFor="personalExperience">
            Personal Experience
          </label>
        </div>
        <div className="form-check me-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="promotional-article"
            name="promotionalArticle"
            checked={articleLabel.promotionalArticle}
            onChange={updateGeneratedArticle}
          />
          <label className="form-check-label" htmlFor="promotionalProduct">
            Promotional Article
          </label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="promotional-article"
            name="inAppropriate"
            checked={articleLabel.inAppropriate}
            onChange={updateGeneratedArticle}
          />
          <label className="form-check-label" htmlFor="promotionalProduct">
            In-Appropriate
          </label>
        </div>
        {isLoading && (
          <p className="ms-2 ">
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </p>
        )}
      </div>
      <div>
        <button
          className="btn"
          style={{
            backgroundColor: "rgb(0, 153, 255)",
            color: "white",
          }}
          onClick={() =>
            navigate(
              `/admin/review-generated-articles-v2?pc=${promptChain}&key=mainArticle&id=${mainArticle}`
            )
          }
        >
          Review Generated Article
        </button>
      </div>
    </div>
  );
};

export default MarkReviewArticle;
