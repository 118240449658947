import React from "react";
import "./../../../../src/views/public/ArticlePage/ArticlePage.css";
import "./AboutAuthor.css";
function AboutAuthor({ author, loadingAuthor }) {
  const profilePicStyles = {
    height: "63px",
    width: "63px",
    borderRadius: "50%",
    maxWidth: "fit-content",
  };
  return (
    <div
      className="mt-4 pt-3 pt-md-5 mx-md-auto media "
      style={{ width: "100%", maxWidth: "680px" }}
    >
      <div className=" mb-md-5 mb-4 justify-content-between w-100">
        <div className="d-flex">
          <div className="me-1 py-2 authorImgDiv2 placeholder-glow">
            {loadingAuthor ? (
              <div
                className="placeholder"
                style={{
                  width: "72px",
                  height: "72px",
                  borderRadius: "50%",
                  maxWidth: "fit-content",
                }}
              ></div>
            ) : (
              <img
                src={author?.profilePicUrl}
                alt="Author"
                style={profilePicStyles}
              />
            )}
          </div>
          <div
            className="ms-2 mt-2 placeholder-glow"
            style={{ lineHeight: "1rem" }}
          >
            {loadingAuthor ? (
              <p
                className="placeholder mb-0"
                style={{ width: "90px", height: "24px" }}
              ></p>
            ) : (
              <p className="fontPara authorName2">{author?.name}</p>
            )}

            {loadingAuthor ? (
              <p className="placeholder-glow">
                <small
                  className="placeholder"
                  style={{ width: "218px", height: "20px" }}
                ></small>
              </p>
            ) : (
              <p>
                <span className=" text-secondary fontPara mobpara2">
                  {author?.email}
                </span>
                {/* <span className="text-muted ">&nbsp;·&nbsp;Contributor at </span>
              <span
                style={{ fontFamily: "Helvetica Neue, sans-serif" }}
                className="comName2"
              >
                INC.COM{" "}
              </span> */}
              </p>
            )}
          </div>
        </div>
        {loadingAuthor ? (
          <div className="placeholder-glow">
            <p
              className="placeholder mb-0 mt-2"
              style={{ width: "75%", height: "18px" }}
            ></p>
          </div>
        ) : (
          <div className="mt-1 fontPara author-bio">{author.bio}</div>
        )}
      </div>
      <hr style={{ color: "#959595" }} />
    </div>
  );
}

export default AboutAuthor;
