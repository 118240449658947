import React from "react";
import Select from "react-select";

const SelectAuthor = ({
  author,
  setAuthor,
  authorOptions,
  setAuthorOptions,
}) => {
  return (
    <div>
      <div className="author-container">
        <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
          Author
        </div>
        <Select
          value={author}
          placeholder="Select Author"
          name="colors"
          options={authorOptions}
          onChange={setAuthor}
          classNamePrefix="select"
          noOptionsMessage={() => "no author available"}
          getOptionLabel={(option) => {
            return `${option.label} ${option?.totalArticles
              ? ` (total articles: ${option.totalArticles})`
              : ""
              }`;
          }}
        />
      </div>
    </div>
  );
};

export default SelectAuthor;
