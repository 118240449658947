import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { clearOldAlerts, clearAlert } from "../../slices/alertSlice";

function AlertWrapper() {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alertSlice.alerts);
  const [intervalId, setIntervalId] = useState({});

  useEffect(() => {
    const intervalIdGen = setInterval(() => {
      if (alerts.length) {
        dispatch(clearOldAlerts());
      }
    });
    setIntervalId(intervalIdGen);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [alerts]);

  return (
    <div>
      {alerts.map((alert) => {
        const { id, type, message } = alert;
        const onClose = () => dispatch(clearAlert({ id }));
        return (
          <Alert key={id} variant={type} onClose={onClose} dismissible>
          {message ? (typeof message === 'string' ? message : JSON.stringify(message)): null} 
          </Alert>
        );
      })}
    </div>
  );
}

export default AlertWrapper;
