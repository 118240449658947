import React, { useEffect, useState } from "react";
import { getQuestionsForApplication } from "../ProfilePage/utility/userUtils";

const Questions = ({ questions, setQuestions }) => {
  const loadQuestions = async () => {
    const response = await getQuestionsForApplication();
    // console.log({ questions });
    setQuestions(response.questions);
  };

  const setAnswerForIndex = (index, answer) => {
    let modifiedQuestions = [...questions];
    modifiedQuestions[index].answer = answer;
    setQuestions(modifiedQuestions);
  };
  useEffect(() => {
    loadQuestions();
  }, []);
  return (
    <div style={{ marginTop: "110px", fontWeight: "550" }}>
      {/* {console.log(questions)} */}
      {questions?.length > 0 &&
        questions?.map((questionData, index) => (
          <div key={index} className="align-items-center d-flex flex-column">
            <div className="text-center applicant-question">
              {`${index + 1}.`} {questionData.question}
            </div>
            <div className="my-2 text-center applicant-options">
              {questionData.options.map((option) => (
                <button
                  className={`mx-2 question-option-btn`}
                  style={
                    questionData.answer === option
                      ? {
                          backgroundColor: "#D3EBFD",
                          color: "#0F76B3",
                          border: "1px solid #3DA9E8",
                        }
                      : {}
                  }
                  onClick={() => {
                    setAnswerForIndex(index, option);
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Questions;
