import React from "react";
// import logo from "../../assets/images/MarketingNetLogo.jpg";
// import newLogo from "../../assets/images/MarketingNet_Logo_2.svg";
import newLogoWhite from "../../assets/images/MarketingNet_Logo_white.svg";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className="py-5 px-sm-4 footer">
      <div className="container">
        <div className="d-flex flex-column mb-4 flex-lg-row justify-content-between">
          <div className={`${styles["company-info"]} mb-3`}>
            <div className="d-flex align-items-center mb-3">
              <img
                src={newLogoWhite}
                alt="Company Logo"
                className={`${styles["company-logo"]} me-2`}
              />
              {/* <h3 className="text-white fs-2 m-0">MarketingNet</h3> */}
            </div>
            <p className={`${styles["company-desc"]}`}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis
              blanditiis tenetur soluta minus nesciunt. Officia eligendi dolorum
              inventore et quo impedit!
            </p>
            <div className="company-socials d-flex mb-3">
              <a href="https://fb.com">
                <i className="fa-brands fa-facebook fs-1 me-4 text-white" />
              </a>
              <a href="https://twitter.com">
                <i className="fa-brands fa-twitter fs-1 me-4 text-white" />
              </a>
              <a href="https://linkedin.com">
                <i className="fa-brands fa-linkedin fs-1 me-4 text-white" />
              </a>
            </div>
          </div>
          <div className="pages d-flex flex-column mb-4">
            <a className={`${styles["page-link"]}`} href="/">
              Home
            </a>
            <a className={`${styles["page-link"]}`} href="/">
              About Us
            </a>
            <a className={`${styles["page-link"]}`} href="/">
              Services
            </a>
            <a className={`${styles["page-link"]}`} href="/">
              Features
            </a>
            <a className={`${styles["page-link"]}`} href="/">
              Pages
            </a>
            <a className={`${styles["page-link"]}`} href="/">
              Contact Us
            </a>
          </div>
          <div className="company-contact d-flex flex-column">
            <div className={`${styles["contact-item"]} d-flex`}>
              <div className="contact-logo">
                <i className="fa-solid fa-location-dot fs-2 text-white me-3" />
              </div>
              <p>Address</p>
            </div>
            <div className={`${styles["contact-item"]} d-flex`}>
              <div className="contact-logo">
                <i className="fa-solid fa-mobile-screen fs-2 text-white me-3" />
              </div>
              <p>9999999999</p>
            </div>
            <div className={`${styles["contact-item"]} d-flex`}>
              <div className="contact-logo">
                <i className="fa-solid fa-envelope fs-2 text-white me-3" />
              </div>
              <p>info@hiringnet.com</p>
            </div>
          </div>
        </div>
        <p className="copyright text-center text-white">
          © 2022 HiringNet | All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
