import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import DegreeDetails from "./DegreeDetails";
import { getEduDetails } from "../utility/userUtils";
import { useParams } from "react-router-dom";

const EducationDetails = ({
  educationDetails,
  openEditMode,
  setLoadingEduDetails,
}) => {
  const { editMode, setEditMode } = openEditMode;
  const { userId } = useParams();
  const openEditEducationDetails = () => {
    setEditMode({ value: true, mode: "add", index: 0 });
  };
  const initDegreeDetails = {
    eduId: 0,
    organizationName: "",
    organizationUrl: "",
    degree: "",
    major: "",
    grade: "",
    startYear: "",
    endYear: "",
    description: "",
    currStuding: false,
  };
  const [degreeDetails, setDegreeDetails] = useState(initDegreeDetails);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (editMode.mode === "update") {
      // console.log("educationDetails ", educationDetails);
      setDegreeDetails(educationDetails.eduDetails[editMode.index]);
    } else {
      setDegreeDetails(initDegreeDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    setLoadingEduDetails(true);
    getEduDetails(userId)
      .then((eData) => {
        let sortedEduDetails = eData?.sort((a, b) => {
          if (b.startYear === a.startYear) {
            if (b.currStuding) {
              return -1;
            } else {
              return b.endYear - a.endYear;
            }
          }
          return b.startYear - a.startYear;
        });
        // console.log("edata: ", sortedEduDetails);
        if (eData) {
          educationDetails.setEduDetails(sortedEduDetails);
        }
      })
      .finally(() => {
        setLoadingEduDetails(false);
      });
  }, []);

  // console.log('DegreeDetails ',degreeDetails);
  // console.log('editMode ',editMode);
  return (
    <div>
      <div className="d-flex justify-content-between mb-4 mt-5">
        <h3>Education Details</h3>
        <button className="add-details-btn" onClick={openEditEducationDetails}>
          + Add Degree
        </button>
      </div>

      {editMode.value && (
        <Card className="p-4 mb-4 bg-light" id="edit-education">
          <div>
            <DegreeDetails
              openEditMode={openEditMode}
              educationDetails={educationDetails}
              details={{ degreeDetails, setDegreeDetails }}
              err={{ errors, setErrors }}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default EducationDetails;
