import React from "react";
import styles from "./CallExtension.module.css";

const CallExtension = ({ callExtension }) => {
  return (
    <div className={`d-flex align-items-center mt-1`}>
      <i className={`${styles["call-icon"]} fa-solid fa-phone`}></i>
      <span className={`${styles["call-number"]}`}>{callExtension}</span>
    </div>
  );
};

export default CallExtension;
