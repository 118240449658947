import React, { useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import logo from "../../assets/images/MarketingNetLogo.jpg";
// import newLogo from "../../assets/images/MarketingNet_Logo_1.svg";
import "./Navbar.css";
import newLogoWhite from "../../assets/images/MarketingNet_Logo_white.svg";
import userProfileIcon from "../../assets/images/user-profile-icon.svg";
import { signOut } from "../../slices/sessionSlice";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  // const sessionUser = {
  //   user: {
  //     firstName: "User",
  //     lastName: "Name",
  //     email: "user@gmail.com",
  //   },
  // };
  const navigate = useNavigate();
  const navbarTogglerRef = useRef(null);

  const toggleNavbarMenu = () => {
    navbarTogglerRef.current?.click();
    setOpenAccountMenu(false);
    // console.trace("navbarToggled");
  };
  const handleSignout = () => {
    toggleNavbarMenu();
    dispatch(signOut({ navigate }));
  };

  const handleProfile = () => {
    toggleNavbarMenu();
    navigate(`/user/profile-page/${sessionUser?.user?._id}`);
  };

  return (
    <nav
      className={`${styles.navbar} navbar navbar-expand-lg text-light justify-content-md-between`}
    >
      <a className="navbar-brand d-flex align-items-center  ms-sm-3" href="/">
        <img
          src={newLogoWhite}
          className={`${styles["navbar-brand-img"]} navbar-logo-img`}
          alt="companyLogo"
        />
        {/* <h4 className={`${styles["brand-name"]} brand-name text-light m-0 ms-2`}>MarketingNet</h4> */}
      </a>
      <button
        ref={navbarTogglerRef}
        onClick={() => setOpenAccountMenu(false)}
        className={`${styles["navbar-toggler"]} navbar-toggler  me-sm-3`}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span
          className={`${styles["navbar-toggler-icon"]} navbar-toggler-icon`}
        />
      </button>
      <div className="navbar-collapse collapse" id="navbarTogglerDemo02">
        <div className="d-lg-flex w-100">
          <ul className={`navbar-nav mx-xl-auto custom-navlist`}>
            <li className={`nav-item px-xl-4 px-3 me-auto`}>
              <NavLink
                to="/"
                exact
                className={`nav-link text-black`}
                activeClassName="active"
              >
                Home
              </NavLink>
            </li>
            <li className={`nav-item px-xl-4 px-3 me-auto`}>
              <NavLink to="/about-us" exact className={`nav-link text-black`}>
                About Us
              </NavLink>
            </li>
            <li className={`nav-item px-xl-4 px-3 me-auto`}>
              <NavLink to="/services" exact className={`nav-link text-black`}>
                Services
              </NavLink>
            </li>
            <li className={`nav-item px-xl-4 px-3 me-auto`}>
              <NavLink to="/blog" exact className={`nav-link text-black`}>
                Blog
              </NavLink>
            </li>
            <li className={`nav-item px-xl-4 px-3 me-auto`}>
              <NavLink to="/courses" exact className={`nav-link text-black`}>
                Courses
              </NavLink>
            </li>
            <li className={`nav-item px-xl-4 px-3 d-lg-none me-auto`}>
              <a className={`nav-link text-black`} href="/">
                Contact
              </a>
            </li>

            {sessionUser?.user ? (
              <li className="nav-item dropdown px-xl-4 px-2 d-lg-none">
                <span
                  className="nav-link dropdown-toggle"
                  onClick={() => {
                    setOpenAccountMenu(!openAccountMenu);
                  }}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Account{" "}
                  <i
                    className={`fa-solid fa-chevron-${
                      openAccountMenu ? "up" : "down"
                    }`}
                  ></i>
                </span>
                <ul className="dropdown-menu" style={{ marginBottom: "5px" }}>
                  <li className={`nav-item px-xl-4 px-3 me-auto`}>
                    <span className={`nav-link`} onClick={handleProfile}>
                      <i className="fa-regular fa-user me-2"></i>
                      Profile
                    </span>
                  </li>
                  <li className={`nav-item px-xl-4 px-3 me-auto`}>
                    <span
                      className={`nav-link text-danger`}
                      onClick={handleSignout}
                    >
                      <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>
                      Sign Out
                    </span>
                  </li>
                </ul>
              </li>
            ) : (
              <div
                className={`${styles["custom-signin-button"]} custom-signin-button mx-3 d-lg-none`}
                style={{ marginBottom: "10px" }}
              >
                <Link
                  to="/sign-in?ref=/"
                  className={`text-decoration-none ${styles["custom-signin-text"]} custom-signin-text`}
                  onClick={toggleNavbarMenu}
                >
                  Sign In
                </Link>
              </div>
            )}
          </ul>
        </div>
      </div>
      <div className="d-lg-flex d-none align-items-center">
        <div className={`nav-item mx-2`}>
          <a className={`nav-link text-black`} href="/">
            Contact
          </a>
        </div>
        {sessionUser?.user ? (
          <div className="nav-item dropdown">
            <button
              className={`${styles["loggedInUserFullName"]} nav-link dropdown-toggle d-flex align-items-center fs-6`}
              href="#"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="mx-3" style={{ width: "50px", height: "50px" }}>
                <img src={userProfileIcon} alt="user-profile-icon" />
              </div>
              {/* <span>
                {sessionUser?.user?.firstName
                  ? `${sessionUser?.user?.firstName} ${
                      sessionUser?.user?.lastName
                        ? sessionUser?.user?.lastName
                        : ""
                    }`
                  : sessionUser?.user?.email}
              </span> */}
            </button>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              {/* start-here */}
              {/* <li>
                    <span className="dropdown-item" role="button">
                      Option 1
                    </span>
                  </li> */}

              {/* <li>
                    <hr className="dropdown-divider" />
                  </li> */}
              {/* ends here */}
              <li onClick={handleProfile}>
                <span className="dropdown-item " role="button">
                  <i className="fa-regular fa-user me-2"></i>
                  <span>Profile</span>
                </span>
              </li>
              <li onClick={handleSignout}>
                <span className="dropdown-item text-danger" role="button">
                  <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>
                  <span>Sign Out</span>
                </span>
              </li>
            </ul>
          </div>
        ) : (
          <div
            className={`${styles["custom-signin-button"]} custom-signin-button mx-3`}
          >
          {/* <Link
              to="/sign-in?ref=/"
              className={`text-decoration-none ${styles["custom-signin-text"]} custom-signin-text`}
          >
            Sign In
          </Link> */}
            <div
              to="/sign-in?ref=/"
              className={`text-decoration-none ${styles["custom-signin-text"]} custom-signin-text`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/sign-in?ref=/";
              }}
            >
              Sign In
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
