import React from "react";
import Spinner from "react-bootstrap/esm/Spinner";

function GeneratingResponseLoader() {
  return (
    <p className="ms-2 ">
      <Spinner animation="border" role="status" size="sm">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </p>
  );
}

export default GeneratingResponseLoader;
