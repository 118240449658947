import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { newAlert } from "../../../slices/alertSlice";

const PromptModal = React.memo(
  ({ showPromptModal, closePromptModal, prompt, title }) => {
    const textRef = useRef(null);
    const dispatch = useDispatch();
    const copyPrompt = () => {
      if (textRef.current) {
        textRef.current.select();
        navigator.clipboard
          .writeText(textRef.current.value)
          .then(() => {
            // Handle successful copying if needed
            console.log("Prompt copied to clipboard!");
            dispatch(
              newAlert({
                type: "success",
                message: "Prompt copied successfully",
              })
            );
          })
          .catch((error) => {
            // Handle error if the copy operation fails
            console.error("Failed to copy prompt:", error.message);
          });
      }
    };

    return (
      <Modal show={showPromptModal} onHide={() => closePromptModal()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title || "Prompt"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            ref={textRef}
            value={prompt}
            readOnly
            style={{ width: "100%", height: "70vh", resize: "none" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closePromptModal()}>
            Close
          </Button>
          <Button variant="primary" onClick={copyPrompt}>
            <i className="bi bi-clipboard"></i> Copy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default PromptModal;
