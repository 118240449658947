import React from "react";
import AuthorDetails from "./AuthorDetails/AuthorDetails";
import "./../../../src/views/public/ArticlePage/ArticlePage.css";
import "./ArticleHeader.css";
function ArticleHeader({ author, title, loadingAuthor }) {
  return (
    // <div className='mx-auto' style={{ width: '680px' }}>
    <div
      className="mx-auto px-3 px-md-0 p-1 p-md-0 media "
      style={{ width: "100%", maxWidth: "680px" }}
    >
      <div className="mt-2 mb-4">
        <h1 className=" h1">{title}</h1>
      </div>
      <div>
        <AuthorDetails author={author} loadingAuthor={loadingAuthor} />
      </div>
    </div>
  );
}

export default ArticleHeader;
