import React, { useState } from "react";
import { processFilename } from "../../utilities";
import axiosInstance from "../../../../axios";
import { useDispatch } from "react-redux";
import { newAlert } from "../../../../slices/alertSlice";

const ImageUploader = ({ workingPost, thumbnailImg, setThumbnailImg }) => {
  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");
  const [openTab, setOpenTab] = useState(false);
  const [preview, setPreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("local");
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setURL("");
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreview("");
    }
  };

  const handleURLChange = (e) => {
    const inputURL = e.target.value;
    setURL(inputURL);
    setFile(null);
    // if (isValidURL(inputURL)) console.log("Valid url");
    // else console.log("Invalid url");
    if (inputURL) {
      setPreview(inputURL);
    } else {
      setPreview("");
    }
  };

  // useEffect(() => {
  //   console.log("isImageLoaded: ", isImageLoaded);
  // }, [isImageLoaded]);

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      let imgSrc = preview;
      const uniqueImageName = processFilename(
        workingPost,
        `_${Date.now()}`,
        imgSrc
      );
      const imageData = { imgSrc, uniqueImageName };

      const { data } = await axiosInstance.post(
        `/api/admin/upsert-post-thumbnail`,
        {
          postId: workingPost,
          imageData,
        }
      );
      const { s3ImageUrl } = data;

      setThumbnailImg(s3ImageUrl);
      setFile(null);
      setURL("");
      setPreview("");
      setOpenTab(false);
    } catch (err) {
      console.log("Error uploading thumbnail : " + err);
      dispatch(
        newAlert({
          type: "danger",
          message: "Error while uploading thumbnail!",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const LoadImage = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return <></>;
  };

  return (
    <div>
      <label
        htmlFor="edit-post-thumbnail"
        className=" text-muted text-small mt-3"
      >
        Thumbnail
      </label>
      <div
        id="edit-post-thumbnail"
        className="edit-thumbnail"
        onClick={() => {
          setOpenTab(true);
        }}
      >
        <i className="fa-regular fa-image"></i>
        <span className="">
          {" "}
          {!thumbnailImg ? "Add Thumbnail" : "Edit Thumbnail"}
        </span>
      </div>

      {openTab && (
        <div className="mymodel-container">
          <div className="mymodal">
            <div className="mymodal-header">
              <div className="text-end">
                <button
                  type="button"
                  className="mymodal-close-button"
                  onClick={() => setOpenTab(false)}
                >
                  &times;
                </button>
              </div>
              <div className="mymodal-tabs d-flex">
                <button
                  type="button"
                  className={`flex-fill-3 mx-2 ${
                    activeTab === "local" ? "active-btn-tab" : ""
                  }`}
                  onClick={() => handleTabChange("local")}
                >
                  File Upload
                </button>
                <button
                  type="button"
                  className={`flex-fill mx-2 ${
                    activeTab === "url" ? "active-btn-tab" : ""
                  }`}
                  onClick={() => handleTabChange("url")}
                >
                  URL
                </button>
              </div>
            </div>
            <div className="mymodal-content m-2">
              {activeTab === "local" ? (
                <div>
                  <label htmlFor="file">Upload Image:</label>
                  <input
                    type="file"
                    id="file"
                    style={{ width: "100%" }}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              ) : (
                <div>
                  <label htmlFor="url">Enter URL:</label>
                  <input
                    type="text"
                    id="url"
                    style={{ width: "100%" }}
                    value={url}
                    onChange={handleURLChange}
                  />
                </div>
              )}
              {preview && (
                <>
                  <div className="preview-image-container">
                    <img
                      src={preview}
                      onLoad={() => setIsImageLoaded(true)}
                      onError={() => setIsImageLoaded(false)}
                      alt="thumbnail-preview"
                      className="w-100 h-100 custom-post-image"
                      style={{ borderRadius: "13px" }}
                    />
                  </div>
                </>
              )}
              <div className="text-end mt-2">
                <span>
                  {isLoading && (
                    <div
                      className="spinner-border text-primary small-spinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleUpload}
                    disabled={!isImageLoaded || (!url && !file)}
                  >
                    Upload
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {thumbnailImg && (
        <>
          <div className="thumbnail-image-container mt-1">
            <img
              src={thumbnailImg}
              alt="thumbnail-img"
              className="w-100 h-100 custom-post-image"
              style={{ borderRadius: "13px" }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageUploader;
