import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import videoPlayImage from "../../../assets/profile-images/videoPlay.svg";
import rupeeImage from "../../../assets/profile-images/ruppeIcon.svg";
import "./PaymentPreferencePage.css";
import { updateStagesStatusForApplication } from "../../ProfilePage/utility/userUtils";
import { newAlert } from "../../../slices/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios";
import { redirectToTestPage } from "../../../helpers/stubs";

function PaymentPreferencePage() {
  const { sessionUser } = useSelector((state) => state.sessionSlice);
  const dispatch = useDispatch();
  // console.log(location, location.state.testLink);

  
  const savePaymentPreference = async (paymentPreference) => {
    try {
      const stagesStatus = {};
      stagesStatus[`paymentPreference`] = { fSaved: true, savedAt: new Date() };
      await updateStagesStatusForApplication(stagesStatus, paymentPreference);
      if (paymentPreference === "lecture") {
        await redirectToTestPage({ sessionUser, dispatch });
      } else {
        window.location.reload();
      }
    } catch (error) {
      let errorMsg = error.response?.data?.message || error.message;
      if (errorMsg === "stagesStatus already saved") {
        dispatch(
          newAlert({
            type: "danger",
            message: "Preference already saved",
          })
        );
      } else {
        dispatch(newAlert({ type: "danger", message: errorMsg }));
      }
    }
  };
  return (
    <div
    style={{height: "100dvh"}}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="paymentPreferencePage">
        <div className="paymentPreferenceSubheading">
          <p>As a next step, you have to take the technical test.</p>
          <p>After the technical test there will be a Zoom interview.</p>
          <br />
          <p>You will need to spend at least an hour on the test.</p>
          <p>
            We value your time and want to offer the following two options as an
            honorarium:
          </p>
        </div>
        <div className="buttons d-flex">
          <button
            style={{
              backgroundColor: "transparent",
              border: "3px solid rgba(255, 193, 7, 0.3)",
              borderRadius: "0.25rem",
              color: "#000000",
            }}
            className="money-button"
            onClick={() => {
              savePaymentPreference("money");
            }}
          >
            Get paid Rs. 250/-
          </button>
          <button
            style={{
              backgroundColor: "rgba(25, 135, 84, 0.1)",
              border: "3px solid rgba(83, 165, 81, 0.3)",
              borderRadius: "0.25rem",
              color: "#000000",
            }}
            className="lecture-button"
            onClick={() => {
              savePaymentPreference("lecture");
            }}
          >
            Attend a lecture of AI
          </button>
        </div>
        <div className="paymentPreferenceLecture" style={{ color: "#585858" }}>
          <p>Lecture topic: </p>
          <p>AI--RAG for Chatbot</p>
          <br />
          <p>Lecture by:</p>
          <p>
            Our Founder,{" "}
            <a
              href="https://eccles.utah.edu/team/rohit-aggarwal/"
              style={{ color: "#585858", textDecoration: "underline" }}
              target="_blank"
            >
              Dr. Rohit Aggarwal
            </a>
            , Professor of Artificial Intelligence (AI) at Tier 1 Research
            school in USA
          </p>
          <br/>
          <br/>
        </div>
        <div className="paymentPreferenceBottom" style={{ color: "#585858" }}>
          <p>Note:</p>
          <p>
            You'll have an opportunity to ask questions during this live
            lecture.
          </p>
          <p>
            This is a one time lecture. We are not a training institute, and
            please don't send us a course/training request.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentPreferencePage;
