import axios from "axios";

// const { REACT_APP_BACK_END_DOMAIN: backEndURL } = process.env;

const { getBackendDomain } = require("../src/helpers/stubs");

const backEndURL = getBackendDomain();

const axiosConfig = {
  baseURL: backEndURL,
  signal: new AbortController().signal,
  withCredentials: true,
};

const axiosInstance = axios.create(axiosConfig);

export default axiosInstance;
