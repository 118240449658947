import React from 'react'

function ConfirmationModal({ askConfirmation, setAskConfirmation, message, updateState }) {
    return (
      <div className="">
        <div className="modal-background"></div>
        <div
          className="bg-light border rounded position-fixed top-50 start-50 translate-middle zindex-modal "
          style={{
            width: "70%",
            zIndex: 20,
            boxShadow: "1px 1px 6px gray",
          }}
        >
          <h4 className="text-center m-4">{askConfirmation.content}</h4>
          <h6 className="text-center">
            {askConfirmation.title && <span>{message}</span>}
          </h6>
          <div className="d-flex w-100 justify-content-center">
            <button
              className={`btn ${
                askConfirmation.type === "delete" ? "btn-danger" : "btn-success"
              } mx-4 my-2`}
              onClick={askConfirmation.onClick}
            >
              {askConfirmation.type === "delete" ? "Delete" : "Yes"}
            </button>
            <button
              className={`btn ${
                askConfirmation.type === "delete"
                  ? "btn-secondary"
                  : "btn-danger"
              } mx-4 my-2`}
              onClick={() => {
                setAskConfirmation({
                  active: false,
                  title: false,
                  content: "",
                  onClick: null,
                });
                updateState("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
}

export default ConfirmationModal