import React from "react";
import "./EmailAlreadyVerified.css";

const EmailAlreadyVerified = () => {
  return (
    <div className="d-flex justify-content-center align-items-center main-container">
      <div className="email-verified-container">
        <h2 className="email-verified-heading">Link Already Used</h2>
        <p className="email-verified-text text-1">
          This link has already been used to verify your email and is no longer
          valid.
        </p>
        <p className="email-verified-text text-2">
          If you need further assistance or believe this is an error, please
          contact our support team.
        </p>
      </div>
    </div>
  );
};

export default EmailAlreadyVerified;
