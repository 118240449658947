import React, { useState } from "react";
import { Button } from "react-bootstrap";
import articleImg from "../../../assets/articlepage-images/articleImg.svg";
import author from "../../../assets/articlepage-images/author.svg";
import likeImg from "./../../../assets/articlepage-images/Like icon.svg";
import likeNotImg from "./../../../assets/articlepage-images/Like not active.svg";
import dislikeImg from "./../../../assets/articlepage-images/Dislike icon.svg";
import dislikeNotImg from "./../../../assets/articlepage-images/Dislike not active.svg";
import "./RecommendedArticleCard.css";
import BlogCard from "../BlogCard/BlogCard";

const dummyData = [
  {
    id: 1,
    articleImg: "url_to_article_image_1",
    authorImg: "url_to_author_image_1",
    authorName: "Harry Meadows",
    title:
      "Prompting with Purpose: How Strategic Narrative Will Change the Way You Use...",
    content:
      "Traditional brainstorming methods often fail due to people's hesitation to...",
    readTime: "11 min read",
  },
  // Add more dummy data objects for additional articles as needed
];
function RecommendedArticleCard() {
  const [isLike, setIsLike] = useState(false);
  const [isDisLike, setIsDisLike] = useState(false);
  const [likeCount, setLikeCount] = useState(227);
  const toggleLike = () => {
    setIsLike(!isLike);
    setIsDisLike(false);
  };

  const toggleDisLike = () => {
    setIsDisLike(!isDisLike);
    setIsLike(false);
  };

  const cardHeadingStyles = {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
  };
  const cardTextStyles = {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#757575",
  };
  const cardReadStyles = {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#757575",
  };

  const card = {
    position: "relative",
    width: "300px",
    height: "175px",
    borderRadius: "20px",
    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)",
    transition: "0.3s",
    // padding: "20px 50px",
    background: "#fff",
  };

  const content = {
    position: "relative",
    width: "70%",
    height: "100%",
    transform: "translateY(-140px) translateX(-40px)",
  };
  const imgBox = {
    transform: "translateX(230px) translateY(30px)",
    // boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)";
  };
  // if (window.innerWidth <= 576) {
  //     card.width = "350px";
  //     imgBox.transform = "translateX(280px) translateY(40px)"; // Adjust the width as per your requirements
  // }
  const labels = {
    fontSize: "0.5em",
    textWrap: "nowrap",
  };
  return (
    <div className="">
      {/* Desktop view */}
      <div
        className="card border-light d-none d-md-block"
        style={{ width: "20rem" }}
      >
        <img className="card-img-top " src={articleImg} alt="RecommArticle" />
        <div
          className="card-body px-0 py-3 "
          style={{ backgroundColor: "#FAFAFA" }}
        >
          <img
            src={author}
            alt="Author"
            className="m-1 "
            width={20}
            height={20}
          />
          <span style={{ fontSize: "12px" }}>Jessica Stillman</span>
          <h5 className="card-title mt-3" style={cardHeadingStyles}>
            A 3-Step Guide to Using ChatGPT for Brainstorming (ChatGPT)
          </h5>
          <p className="card-text text-secondary" style={cardTextStyles}>
            Traditional brainstorming methods often fail due to people's
            hesitation to{" "}
          </p>
          <small className="text-secondary">8 min read</small>
          <div className="d-flex align-items-baseline">
            <button
              onClick={toggleLike}
              id="likebtn"
              className="text-dark border border-0 text-decoration-none bg-transparent "
            >
              <img
                src={isLike ? likeImg : likeNotImg}
                alt="Like"
                className="like mb-1"
                width={19}
              />
            </button>
            <b style={{ fontSize: "13px" }}>{likeCount}</b> &nbsp;
            <Button
              variant="link"
              onClick={toggleDisLike}
              className="text-secondary "
            >
              <img
                src={isDisLike ? dislikeImg : dislikeNotImg}
                alt="Like"
                width={18}
              />
            </Button>
            {/* <Button variant="link" onClick={toggleDisLike} className='text-secondary '>
                            <img src={isDisLike ? dislikeImg : dislikeNotImg} alt="Like" width={16} />
                        </Button> */}
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div className="d-block d-md-none recommended-blog-mobile">
        <BlogCard
          authorProfilePic={author}
          author={dummyData[0].authorName}
          readTime={dummyData[0].readTime}
          url={"http://example.com"}
          title={dummyData[0].title}
          key_learning={dummyData[0].content}
          tags={["b2b-marketing", "marketing-strategies"]}
          articleImageUrl={articleImg}
          upvoteCount={255}
          isUpvoted={false}
          isDownvoted={false}
        />
      </div>
    </div>
    /* <div>
           {dummyData.map((article) => (
               <div key={article.id} className="card border-light" style={{ width: '20rem' }}>
                   <img className="" src={article.articleImg} alt="Card image cap" />
                   <div className="card-body p-0 py-3 " style={{ backgroundColor: '#FAFAFA' }}>
                       <img src={article.authorImg} alt="Author" className="m-1" width={25} height={25} />
                       <span style={{ fontSize: '0.8rem' }}>{article.authorName}</span>
                       <h5 className="card-title mt-3" style={cardHeadingStyles}>
                           {article.title}
                       </h5>
                       <p className="card-text text-secondary" style={cardTextStyles}>
                           {article.content}
                       </p>
                       <small className="text-secondary">{article.readTime}</small>
                   </div>
               </div>
           ))}
       </div>  */
  );
}

export default RecommendedArticleCard;
