import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Select from "react-select";
import Axios from "../../../axios";
import { useEffect } from "react";
const SelectChatToAskQuestion = ({
  askQuestionInChat,
  setAskQuestionInChat,
}) => {
  const [chatOptions, setChatOptions] = useState([]);
  const userId = useSelector(
    (state) => state.sessionSlice.sessionUser.user._id
  );
  const getAllGptChatIdsOfUser = async (userId) => {
    const { data } = await Axios.get(
      `/api/admin/chat-gpt-interface-v2/get-chats-of-user/${userId}`
    );
    const chats = data.map((chat) => {
      return { label: chat.gptId + "-" + chat.gptName, value: chat.gptId };
    });
    console.log(chats);
    setChatOptions(chats);
    // setAskQuestionInChat(chats[0]);
  };
  useEffect(() => {
    getAllGptChatIdsOfUser(userId);
  }, [userId]);
  return (
    <>
      <Form.Group className="mb-4" md="4" controlId="validationCustom01">
        <Form.Label
          inline="true"
          className="mr-1 text-maroon py-0"
          style={{ fontWeight: "500", fontSize: "14px" }}
          column
          sm="2"
        >
          Ask questions in chat:
        </Form.Label>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="option1"
            // checked
            onClick={() => setAskQuestionInChat(chatOptions[0])}
          />
          <label className="form-check-label" for="inlineRadio1">
            Existing chat
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="option2"
            onClick={() => setAskQuestionInChat("newChat")}
          />
          <label className="form-check-label" for="inlineRadio2">
            New chat
          </label>
        </div>
      </Form.Group>
      {askQuestionInChat != "newChat" && (
        <Select
          placeholder={<span>{askQuestionInChat.label}</span>}
          onChange={(choice) => {
            setAskQuestionInChat(choice);
          }}
          options={chatOptions}
          onFocus={() => {}}
          onKeyDown={(e) => {
            {
            }
          }}
        />
      )}
    </>
  );
};

export default SelectChatToAskQuestion;
