import { Navigate } from "react-router-dom";
import { getCookie } from "../../helpers/stubs";

const PrivateRoute = ({ adminRole, children }) => {
  const sessionCookie = getCookie("sc");
  // console.log('session cookie: ', sessionCookie);

  const { user } = JSON.parse(sessionCookie);
  // console.log('user: ', user);

  if ((!user?.adminRole)) {
    return <Navigate to="/" />;
  }

  // console.log('adminRole: ', user.adminRole);

  return (
    <>{user && user.adminRole === adminRole ? children : <Navigate to="/" />}</>
  );
};

export default PrivateRoute;
