import React from "react";
import { useDispatch } from "react-redux";
import { newAlert } from "../../slices/alertSlice";

const AcknowledgeModal = ({ askConfirmation, setaskConfirmation }) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    setaskConfirmation({
      active: false,
      title: "",
      heading: "",
      onClick: null,
      newLine: false,
    });
  };
  return (
    <div>
      {askConfirmation.active && (
        <div>
          <div className="modal-background"></div>
          <div
            className="bg-light border acknowledge-modal-body"
            style={{
              width: "70%",
              zIndex: 40,
              boxShadow: "1px 1px 6px gray",
              acceptBtnColor: "",
              rejectBtnColor: "",
              minHeight: "300px",
            }}
          >
            <h3 className="text-center m-4">{askConfirmation.heading}</h3>
            <div className="text-center">
              {askConfirmation.title && <span>{askConfirmation.title}</span>}
            </div>
            <div
              className={`d-flex ${
                askConfirmation.newLine ? "flex-column" : ""
              } justify-content-center`}
              style={
                askConfirmation.newLine
                  ? { maxWidth: "500px", margin: "2px auto" }
                  : {}
              }
            >
              <button
                className={`btn ${
                  askConfirmation.acceptBtnColor
                    ? askConfirmation.acceptBtnColor
                    : "btn-success"
                } mx-4 my-2`}
                onClick={async () => {
                  try {
                    await askConfirmation.onClick();
                  } catch (error) {
                    const errMsg =
                      error.response?.data?.message || error.message;
                    // console.error(errMsg);
                    dispatch(
                      newAlert({
                        type: "danger",
                        message: `Error: ${errMsg}`,
                      })
                    );
                  } finally {
                    setaskConfirmation({
                      active: false,
                      title: "",
                      heading: "",
                      onClick: null,
                    });
                  }
                }}
              >
                {askConfirmation.acceptBtnMsg
                  ? askConfirmation.acceptBtnMsg
                  : "Yes"}
              </button>
              <button
                className={`btn ${
                  askConfirmation.rejectBtnColor
                    ? askConfirmation.rejectBtnColor
                    : "btn-secondary"
                } mx-4 my-2`}
                onClick={closeModal}
              >
                {askConfirmation.rejectBtnMsg
                  ? askConfirmation.rejectBtnMsg
                  : "Cancel"}
              </button>
            </div>
            <i
              class="fas fa-window-close"
              style={{
                position: "absolute",
                top: "-1px",
                right: 0,
                color: "red",
                fontSize: "30px",
                cursor: "pointer",
              }}
              onClick={closeModal}
            ></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcknowledgeModal;
