import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axios";

import { getBackendDomain } from "../helpers/stubs";
const backendURL = getBackendDomain();
// const { REACT_APP_BACK_END_DOMAIN: backendURL } = process.env;

export const getArticles = createAsyncThunk(
  "newsFeed/getArticles",
  async (thunkAPI) => {
    try {
      const { data } = await Axios.get(`${backendURL}/api/public/get-articles`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getVotesForCreatedPosts = createAsyncThunk(
  "newsFeed/getVotesForCreatedPosts",
  async ({ postIds }, thunkAPI) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/get-votes-for-created-posts`,
        { postIds }
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateVotesForCreatedPosts = createAsyncThunk(
  "newsFeed/updateVotesForCreatedPosts",
  async (
    { postId, currentVoteType, isUpvoted, isDownvoted, query },
    thunkAPI
  ) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/update-votes-for-created-posts?postId=${postId}&currentVoteType=${currentVoteType}&isUpvoted=${isUpvoted}&isDownvoted=${isDownvoted}&query=${query}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getSavedCreatedPosts = createAsyncThunk(
  "newsFeed/getSavedCreatedPosts",
  async ({ postIds }, thunkAPI) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/get-saved-posts`,
        { postIds }
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateSavedCreatedPosts = createAsyncThunk(
  "newsFeed/updateSavedCreatedPosts",
  async ({ postId, isSaved, query }, thunkAPI) => {
    try {
      const { data } = await Axios.post(
        `${backendURL}/api/user/save-unsave-posts?postId=${postId}&isSaved=${isSaved}&query=${query}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getBookmarkedArticles = createAsyncThunk(
  "newsFeed/getBookmarkedArticles",
  async ({ currentPage, bookmarkedArticlesPerPage }, thunkAPI) => {
    try {
      const { data } = await Axios.get(
        `${backendURL}/api/user/get-bookmarked-articles/${currentPage}/${bookmarkedArticlesPerPage}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const getLikedArticles = createAsyncThunk(
  "newsFeed/getLikedArticles",
  async ({ currentPage, likedArticlesPerPage }, thunkAPI) => {
    try {
      const { data } = await Axios.get(
        `${backendURL}/api/user/get-liked-articles/${currentPage}/${likedArticlesPerPage}`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const newsFeedSlice = createSlice({
  name: "newsFeed",
  initialState: {
    articles: {
      fArticlesFetched: false,
      fBookmarksFetched: false,
      fVotesFetched: false,
    },
    bookmarkedArticles: {},
    likedArticles: {},
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getArticles.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getArticles.fulfilled]: (state, { payload }) => {
      state.articles = {
        ...state.articles,
        data: payload.data,
        totalArticles: payload.totalArticles,
      };
      state.articles.fArticlesFetched = true;
      state.loading = false;
    },
    [getArticles.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [getBookmarkedArticles.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getBookmarkedArticles.fulfilled]: (state, { payload }) => {
      state.bookmarkedArticles = {
        ...state.bookmarkedArticles,
        data: payload.data,
        totalBookmarkedArticles: payload.totalBookmarkedArticles,
      };
      state.loading = false;
    },
    [getBookmarkedArticles.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [getLikedArticles.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getLikedArticles.fulfilled]: (state, { payload }) => {
      state.likedArticles = {
        ...state.likedArticles,
        data: payload.data,
        totalLikedArticles: payload.totalLikedArticles,
      };
      state.loading = false;
    },
    [getLikedArticles.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [getVotesForCreatedPosts.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getVotesForCreatedPosts.fulfilled]: (state, { payload }) => {
      const { votes } = payload;
      const updatedArticles = state.articles.data.map((article) => {
        const vote = votes.find((voteObj) => voteObj.post === article.postId);
        const fLiked = vote ? vote.voteType === "upvote" : false;
        const fDisliked = vote ? vote.voteType === "downvote" : false;
        return { ...article, fLiked, fDisliked };
      });
      state.articles.data = [...updatedArticles];
      state.articles.fVotesFetched = true;
      state.loading = false;
    },
    [getVotesForCreatedPosts.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [updateVotesForCreatedPosts.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateVotesForCreatedPosts.fulfilled]: (state, { payload, meta }) => {
      const { fVoteUpdated, fVoteDeleted, fVoteInserted } = payload;
      const { currentVoteType } = meta.arg;
      let modifiedPayload = {};
      const { postId, objectName } = meta.arg;
      let articles = state[objectName]?.data;
      const articleIndex = articles?.findIndex(
        (article) => article.postId === postId
      );
      if (
        articleIndex !== undefined &&
        articleIndex !== -1 &&
        objectName !== "likedArticles"
      ) {
        const updatedArticle = { ...articles[articleIndex] };
        if (fVoteInserted) {
          updatedArticle.fLiked = currentVoteType === "upvote";
          updatedArticle.fDisliked = currentVoteType === "downvote";
          updatedArticle.upvoteCount += currentVoteType === "upvote" ? 1 : 0;
        } else if (fVoteUpdated) {
          updatedArticle.fLiked = currentVoteType === "upvote";
          updatedArticle.fDisliked = currentVoteType === "downvote";
          updatedArticle.upvoteCount += currentVoteType === "upvote" ? 1 : -1;
        } else if (fVoteDeleted) {
          updatedArticle.fLiked = false;
          updatedArticle.fDisliked = false;
          updatedArticle.upvoteCount -= currentVoteType === "upvote" ? 1 : 0;
        }
        articles[articleIndex] = updatedArticle;
      } else if (
        articleIndex !== undefined &&
        articleIndex !== -1 &&
        objectName === "likedArticles"
      ) {
        articles.splice(articleIndex, 1);
        state.likedArticles.totalLikedArticles -= 1;
      }
      modifiedPayload = { ...state.articles, data: articles };
      state.articles = modifiedPayload;
      state.loading = false;
    },
    [updateVotesForCreatedPosts.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [getSavedCreatedPosts.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSavedCreatedPosts.fulfilled]: (state, { payload }) => {
      const { savedPosts } = payload;
      const updatedArticles = state.articles.data.map((article) => {
        const savedPost = savedPosts.find(
          (savedPostObj) => savedPostObj.post === article.postId
        );
        const fBookmarked = savedPost ? true : false;
        return { ...article, fBookmarked };
      });
      state.articles.data = [...updatedArticles];
      state.articles.fBookmarksFetched = true;
      state.loading = false;
    },
    [getSavedCreatedPosts.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },

    [updateSavedCreatedPosts.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateSavedCreatedPosts.fulfilled]: (state, { payload, meta }) => {
      const { fBookmarkRemoved, fBookmarkAdded } = payload;
      const { postId, objectName } = meta.arg;
      let articles = state[objectName]?.data;
      const articleIndex = articles?.findIndex(
        (article) => article.postId === postId
      );
      if (
        articleIndex !== undefined &&
        articleIndex !== -1 &&
        (fBookmarkAdded || fBookmarkRemoved)
      ) {
        if (objectName === "bookmarkedArticles") {
          articles.splice(articleIndex, 1);
          state.bookmarkedArticles.totalBookmarkedArticles -= 1;
        } else {
          const updatedArticle = { ...articles[articleIndex] };
          updatedArticle.fBookmarked = fBookmarkAdded ? true : false;
          articles[articleIndex] = updatedArticle;
        }
        state[objectName] = { ...state[objectName], data: articles };
      }
      state.loading = false;
    },
    [updateSavedCreatedPosts.rejected]: (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    },
  },
});

export default newsFeedSlice.reducer;
