import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { getHeadings } from "../../utilities";

const PostOutline = ({
  htmlString,
  setOutline,
  showOutline,
  updateOutline,
}) => {
  const outline = getHeadings(htmlString);

  useEffect(() => {
    // console.log("Outline: ", outline);
    setOutline(outline);
    // console.log("Outline:- ", outlineStr);
  }, [updateOutline.saved, updateOutline.selectedTitle]);

  if (!showOutline) {
    return null;
  }

  return (
    <div>
      <Card className="mt-2 post-outline">
        <Card.Header>Outline</Card.Header>
        <Card.Body>
          {outline.map((heading, index) => (
            <div
              key={index}
              style={{ marginLeft: `${(heading.level - 1) * 28}px` }}
            >
              {heading.heading}
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PostOutline;
