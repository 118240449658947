import React, { useState } from "react";
import EditEducation from "../educationComp/EditEducation";
import SavedEducations from "../educationComp/SavedEducation";
import { Spinner } from "react-bootstrap";

export const EducationDetails = ({ educationDetails }) => {
  const { eduDetails, setEduDetails } = educationDetails;
  const [loadingEduDetails, setLoadingEduDetails] = useState(false);
  const [editMode, setEditMode] = useState({
    value: false,
    mode: "",
    index: 0,
  });
  return (
    <div id="edu-details" className="mb-5">
      <EditEducation
        educationDetails={{ eduDetails, setEduDetails }}
        openEditMode={{ editMode, setEditMode }}
        setLoadingEduDetails={setLoadingEduDetails}
      />
      {!loadingEduDetails ? (
        <SavedEducations
          educationDetails={eduDetails}
          openEditMode={{ editMode, setEditMode }}
        />
      ) : (
        <div className="text-center">
          <Spinner variant="secondary" />
        </div>
      )}
    </div>
  );
};
