import React, { useEffect, useState } from "react";
import { getWorkDetails } from "../utility/userUtils";
import { ExperienceList } from "../workComponents/ExperienceList";
import { EditWorkData } from "../workComponents/EditWorkData";
import { useDispatch } from "react-redux";
import { setWorkDetails } from "../../../slices/profileSlices/workSlice";
import { openEditExp } from "../../../slices/profileSlices/openTabSlice";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export const WorkDetails = () => {
  const { userId } = useParams();
  const [loadingWorkDetails, setLoadingWorkDetails] = useState(false);
  const dispatch = useDispatch();

  // fetch workdetails from backend when page loades
  const fetchWorkData = () => {
    setLoadingWorkDetails(true);
    getWorkDetails(userId)
      .then((data) => {
        // console.log(data[0]);
        if (data && data.length > 0) {
          // this is for temopary purose to sort data new experience data will be sorted before storing
          data[0].skills?.sort((a, b) => {
            return b.value - a.value;
          });
          // data[0].tools.sort((a, b) => {
          //   return b.value - a.value;
          // });
          data.sort((a, b) => {
            return b.timePeriod.startYear - a.timePeriod.startYear;
          });
          dispatch(setWorkDetails({ data }));
        }
      })
      .finally(() => {
        setLoadingWorkDetails(false);
      });
  };
  useEffect(() => {
    fetchWorkData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="work-details" className="work-details-margin">
      <div className="d-flex justify-content-between mb-2">
        <h3>Work Details </h3>
        <button
          onClick={() =>
            dispatch(openEditExp({ value1: true, mode: "add", index: 0 }))
          }
          className="add-details-btn"
        >
          + Add Experience
        </button>
      </div>
      <EditWorkData />
      {!loadingWorkDetails ? (
        <ExperienceList />
      ) : (
        <div className="text-center">
          <Spinner variant="secondary" />
        </div>
      )}
    </div>
  );
};
