/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import style from "./FeedbackButtons.module.css";
import Flag from "./Flag/Flag";
import SaveUnsaveAd from "./SaveUnsaveAd/SaveUsaveAd";
import VotingForAd from "./VotingForAd/VotingForAd";

const FeedbackButtons = ({ adId, upvoteCount, query, userId, isIrrelevantAd, isDisabled, toggleDisabled, isUpvoted, isDownvoted, isSaved, adType }) => {
  return (
    <div className={`w-75 d-flex align-items-center justify-content-end text-dark ps-2 exclude-buttons`}>
      <div className="d-flex justify-content-between align-items-center">
        <div style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          <VotingForAd adId={adId} upvoteCount={upvoteCount} query={query} isUpvoted={isUpvoted} isDownvoted={isDownvoted} userId={userId} />
        </div>

        <div className={style.savedAdButtonContainer} style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}>
          <SaveUnsaveAd adId={adId} userId={userId} saved={isSaved} query={query} />
        </div>
        <div className={style.irrelevantAdButtonContainer} style={{ pointerEvents: "auto", opacity: 1 }}>
          <Flag adId={adId} userId={userId} query={query} isIrrelevantAd={isIrrelevantAd} toggleDisabled={toggleDisabled} adType={adType} />
        </div>
      </div>
    </div>
  );
};

export default FeedbackButtons;
