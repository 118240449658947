import React, { useEffect, useRef, useState } from "react";
import tocIcon from "../../assets/images/toc floating icon.svg";
import mobTocIcon from "../../assets/images/mob toc icon.svg";
import tocArwIcon from "../../assets/images/toc arw btn.svg";
import "./ArticleDropdownTable.css";
import tocCloseIcon from "../../assets/images/close toc btn.svg";
import downArrow from "../../assets/images/down arrow.svg";

function truncateTitle(title) {
  const maxLength = 24;
  if (title?.length <= maxLength) {
    return title;
  }
  return title.substr(0, maxLength) + "...";
}

const handleHeadingClick = (headingId) => {
  const headingElement = document.getElementById(headingId);
  if (headingElement) {
    headingElement.scrollIntoView({ behavior: "smooth" });
  }
};

function ArticleDropdownTable({ tableOfContent }) {
  const [dropdownData, setDropdownData] = useState([]);

  useEffect(() => {
    const mainHeadings = tableOfContent[0]?.level;
    const subheadings = mainHeadings + 1;
    let dropdownData = [];
    let subData = null;
    for (let i = 0; i < tableOfContent.length; i++) {
      const data = tableOfContent[i];
      if (data.level === mainHeadings) {
        if (subData !== null) {
          dropdownData.push(subData);
        }
        subData = {
          title: { text: data.heading, headingId: `heading-${i + 1}` },
          items: [],
        };
      } else if (data.level > mainHeadings && data.level === subheadings) {
        subData.items.push({
          text: data.heading,
          headingId: `heading-${i + 1}`,
        });
      }
    }
    if (subData) {
      dropdownData.push(subData);
    }
    // console.log(dropdownData);
    setDropdownData(dropdownData);
  }, []);

  let [showIcon, setShowIcon] = useState(true);
  let [hide, setHide] = useState(true);
  // let hide=true;
  function hideAccordion() {
    setHide(!hide);
    setShowIcon(!showIcon);
  }
  const containerRef = useRef(null);
  useEffect(() => {
    function handleOutsideClick(event) {
      // Check if the click target is outside the container
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setHide(true);
        setShowIcon(true);
        document.getElementById("tableOfContents").classList.remove("show");
      }
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div ref={containerRef}>
      {/* Desktop view */}
      <div className="mt-5">
        <img
          className={hide ? "d-md-none" : "d-none"}
          onClick={hideAccordion}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#tableOfContents"
          aria-expanded={hide}
          aria-controls="tableOfContents"
          src={mobTocIcon}
          style={{
            transformOrigin: "top ",
            margin: "15px",
            position: "absolute",
            top: "28vh",
            borderRadius: "5px",
            left: "-26px",
          }}
        />
        <div
          className={hide ? "accordion accordion90" : "accordion accordion0"}
          id="tableOfContentsAccordion"
        >
          <div
            className={`accordion-item d-inline-block  bg-transparent bgShadow side-dropdown side-toc-dropdown ${
              hide ? "hideShadow" : ""
            }`}
            style={{
              position: "absolute",
              right: hide ? "25%" : "auto",
            }}
          >
            <div className="accordion-header" id="tableOfContentsHeading ">
              <div
                className={
                  (hide && "d-none") +
                  " tocButton d-md-block accordion-button table-of-contents-btn collapsed hideArrow bgtoc text-nowrap float-toc-heading " +
                  (!hide ? "tocButton bgtoc" : "")
                }
                onClick={hideAccordion}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tableOfContents"
                aria-expanded={hide}
                aria-controls="tableOfContents"
              >
                {!hide && (
                  <>
                    <img src={downArrow} className="toc-down-arrow" />
                    &nbsp;&nbsp;
                  </>
                )}
                <b className=" tocStyles">Table of Contents</b> &emsp;
                {
                  // Show the default arrow icon only when the table of contents is hidden
                  hide && (
                    <img
                      src={tocIcon}
                      alt="toc-icon"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  )
                }
                &emsp;&emsp;
                {
                  // Show the custom arrow icon when the table of contents is open
                  hide && (
                    <img
                      src={tocArwIcon}
                      alt="toc-icon"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  )
                }
                {/* {
                                    hide &&
                                    <img src={tocIcon} alt='toc-icon' style={{ transform: "rotate(90deg)" }} />

                                } */}
                {
                  // Show the close icon only when the table of contents is open
                  !hide && (
                    <img
                      src={tocCloseIcon}
                      alt="toc-icon"
                      className="mb-1 toc-close-icon"
                    />
                  )
                }
                {/* {
                                    hide &&
                                    <img src={tocArwIcon} alt='toc-icon' style={{ transform: "rotate(90deg)" }} />

                                } */}
                {/* {
                                    !hide &&
                                    <img src={tocCloseIcon} alt='toc-icon' className='mb-1 ' />

                                } */}
              </div>
            </div>
            <div
              id="tableOfContents"
              className="accordion-collapse collapse side-dropdown"
              aria-labelledby="tableOfContentsHeading"
              data-bs-parent="#tableOfContentsAccordion"
              style={{ maxHeight: "calc(70vh - 60px)" }}
            >
              <div className="accordion-body p-0">
                <div className="accordion" id="accordionExample">
                  {dropdownData.map((item, index) => (
                    <div className="accordion-item " key={index}>
                      <h2 className="accordion-header " id={`heading${index}`}>
                        <button
                          className="accordion-button collapsed contentStyles fw-bold"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                          onClick={() =>
                            handleHeadingClick(item.title.headingId)
                          }
                        >
                          {truncateTitle(item.title.text)}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-group">
                            {item.items.map((subItem, subIndex) => (
                              <button
                                className="subHeadBtn"
                                key={subIndex}
                                onClick={() =>
                                  handleHeadingClick(subItem.headingId)
                                }
                              >
                                <li
                                  className="list-group-item subContentStyles text-start toc-subheadings"
                                  key={subIndex}
                                >
                                  {truncateTitle(subItem.text)}
                                </li>
                              </button>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}

      {/* <div className={(scrollPercentage > 30) ? "mt-5 d-md-none" : "mt-5 d-none"} style={{ width: '268px' }}>
                <div className={hide ? "accordion accordion90" : "accordion accordion0"} id="tableOfContentsAccordion">
                    <div className="accordion-item d-inline-block  bg-transparent ">
                        <h2 className="accordion-header  " id="tableOfContentsHeading ">
                            <div className={(hide && "d-none text-nowrap") + " d-md-block accordion-button table-of-contents-btn collapsed hideArrow bgtoc " + (!hide ? "tocButton bgtoc" : "")} onClick={hideAccordion} type="button" data-bs-toggle="collapse"
                                data-bs-target="#tableOfContents" aria-expanded={hide} aria-controls="tableOfContents">
                                <b className=' tocStyles text-nowrap' >Table of Contents</b> &emsp;
                                {
                                    hide &&
                                    <img src={tocIcon} alt='toc-icon' style={{ transform: "rotate(90deg)" }} />

                                }
                                &emsp;
                                {
                                    hide &&
                                    <img src={tocArwIcon} alt='toc-icon' style={{ transform: "rotate(90deg)" }} />

                                }
                                {
                                    !hide &&
                                    <img src={tocCloseIcon} alt='toc-icon' className='mb-1 ' />

                                }
                            </div>
                            <img className={hide ? "d-md-none" : 'd-none'} onClick={hideAccordion} type="button" data-bs-toggle="collapse"
                                data-bs-target="#tableOfContents" aria-expanded={hide} aria-controls="tableOfContents" src={mobTocIcon} style={{ transform: "rotate(90deg)", transformOrigin: 'top ', margin: '15px' }} />
                        </h2>
                        <div id="tableOfContents" className="accordion-collapse collapse " aria-labelledby="tableOfContentsHeading" data-bs-parent="#tableOfContentsAccordion">
                            <div className="accordion-body p-0">
                                <div className="accordion" id="accordionExample">
                                    {dropdownData.map((item, index) => (
                                        <div className="accordion-item " key={index} >
                                            <h2 className="accordion-header " id={`heading${index}`} >
                                                <button className="accordion-button collapsed contentStyles" type="button" data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                    {truncateTitle(item.title)}
                                                </button>
                                            </h2>
                                            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`}
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body p-0">
                                                    <ul className="list-group">
                                                        {item.items.map((subItem, subIndex) => (
                                                            <button className='subHeadBtn'>
                                                                <li className="list-group-item ps-5 subContentStyles" key={subIndex}>{truncateTitle(subItem)}</li>
                                                            </button>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}

export default ArticleDropdownTable;
