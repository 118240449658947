import React, { useState } from 'react'
import './OrganizationCard.css'

const OrganizationCard = ({ org, dimensions }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [linesToShow] = useState(4);

  return (
    <div className={(dimensions?.height < (dimensions?.width / 2)) ? 'display-org-card' : 'org-card'}>
      <div className='org-card-img-name'>
        {org.logoUrl && <img src={org.logoUrl} />}
        <h3>{org.name}</h3>
      </div>
      <p className='org-description'>
        {org.description && (showFullText ? org.description : org?.description?.split(". ").slice(0, linesToShow).join(". ") + ".")}
        {org?.description?.split(". ").length > linesToShow && (
          <span onClick={() => setShowFullText(!showFullText)} style={{ marginLeft: '10px', color: 'blue', cursor: 'pointer' }}>
            {showFullText ? "Read Less" : "Read More"}
          </span>
        )}</p>
      {org.type && <p><span className='fw-semibold'>Company Type:</span> {org.type}</p>}
      {org.location && <p><span className='fw-semibold'>Location:</span> {org.location}</p>}
      {/* {org.website && <span>Website: <a href={org.website} target='_blank' rel="noreferrer"> {org.website}</a></span>} */}
      {org.website && <span><span className='fw-semibold'>Website</span>:  <p className='fst-italic'>{org.website}</p></span>}
      {org.employeesReported && <p style={{ marginTop: '15px' }}> <span className='fw-semibold'>Employees: </span>{org.employeesReported}</p>}
    </div>
  )
}

export default OrganizationCard