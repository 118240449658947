import { useEffect, useRef, useState } from "react";
import companylogo from "../../../assets/profile-images/companylogo.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { loadUserData, updateUserData } from "../utility/userUtils";
import { useParams } from "react-router-dom";
import { newAlert } from "../../../slices/alertSlice";
import { useDispatch } from "react-redux";
import universitylogo from "../../../assets/profile-images/university_logo.png";

const modalBody = (
  userInfo,
  userDescription,
  setUserDescription,
  editDescription,
  latestEducation,
  setInputError
) => {
  return (
    <div>
      {editDescription.name && (
        <div>
          {/* <span>Name</span> */}
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              const value = e.target.value?.trim();
              if (value === "") {
                setInputError("Your name cannot be empty");
              } else {
                setInputError("");
              }
              setUserDescription({ ...userDescription, name: value });
            }}
            placeholder="Your Name"
            defaultValue={userInfo.name}
          />
        </div>
      )}
      {editDescription.position && (
        <div>
          {/* <span>Position</span> */}
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setInputError("Your position cannot be empty");
              } else {
                setInputError("");
              }
              setUserDescription({
                ...userDescription,
                position: value,
              });
            }}
            placeholder="Add Position"
            defaultValue={userInfo.position}
          />
        </div>
      )}
      {editDescription.education && (
        <div>
          {/* <span>Education</span> */}
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setInputError("Your Education cannot be empty");
              } else {
                setInputError("");
              }
              setUserDescription({
                ...userDescription,
                education: value,
              });
            }}
            placeholder="Highest Education"
            defaultValue={
              <span>
                {latestEducation?.degree} -{" "}
                {/* <img
                  src={universitylogo}
                  alt="uni logo"
                  style={{ height: "20px" }}
                />{" "} */}
                {latestEducation?.institution}
              </span>
            }
          />
        </div>
      )}
      {editDescription.portfolio && (
        <div>
          <div className="text-center mb-2">Portfolio</div>
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              const value = {
                link:
                  userDescription.portfolio?.link || userInfo.portfolio?.link,
                text: e.target.value,
              };
              if (value.text === "") {
                setInputError("Your portfolio text cannot be empty");
              } else {
                setInputError("");
              }
              setUserDescription({
                ...userDescription,
                portfolio: value,
              });
            }}
            placeholder="Add Portfolio Text"
            defaultValue={userInfo.portfolio?.text}
          />
          <input
            type="text"
            className="form-control mt-3"
            onChange={(e) => {
              const value = {
                text:
                  userDescription.portfolio?.text || userInfo.portfolio?.text,
                link: e.target.value,
              };
              if (value.link === "") {
                setInputError("Your portfolio link cannot be empty");
              } else {
                setInputError("");
              }
              setUserDescription({
                ...userDescription,
                portfolio: value,
              });
            }}
            placeholder="Add Portfolio link"
            defaultValue={userInfo.portfolio?.link}
          />
        </div>
      )}
      {editDescription.description && (
        <div>
          <span>Description</span>
          <textarea
            className="form-control no-resize"
            onChange={(e) => {
              e.target.value = e.target.value.slice(0, 200);
              const value = e.target.value;
              if (value === "") {
                setInputError("Your description cannot be empty");
              } else {
                setInputError("");
              }
              setUserDescription({
                ...userDescription,
                description: value,
              });
            }}
            style={{ height: "100px" }}
            placeholder="Bio/Description"
            defaultValue={userInfo.description}
          />
        </div>
      )}
    </div>
  );
};

const ShowModal = ({
  bodyContent,
  onModalSubmit,
  onModalClose = false,
  handleModal,
  inputError,
}) => {
  const handleClose = () => {
    if (onModalClose) onModalClose();
    handleModal.setShowModal(false);
  };
  const handleSubmit = () => {
    if (inputError) {
      return;
    }
    onModalSubmit();
    onModalClose();
    handleModal.setShowModal(false);
  };

  return (
    <Modal
      show={handleModal.showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="profile-modal-bg">
          {bodyContent}
          <div style={{ fontSize: "14px", color: "red", marginTop: "4px" }}>
            {inputError}
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              style={{ backgroundColor: "rgb(239, 136, 140)", border: "none" }}
              className="me-2"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ backgroundColor: "rgb(7, 74, 117)", border: "none" }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const onModalSubmit = async (
  userDescription,
  userInfo,
  setUserInfo,
  editDescription,
  setEditDescription,
  dispatch,
  userId
) => {
  // console.log(userDescription, editDescription);
  const selectedFields = Object.fromEntries(
    Object.entries(userDescription).filter(([key]) => {
      return editDescription[key] === true;
    })
  );
  // console.log("selected fields", selectedFields);
  await updateUserData(userId, selectedFields).then((res) => {
    if (res && res.status === 200) {
      if (!editDescription.education)
        dispatch(newAlert({ type: "success", message: "Updated User Data" }));
      // console.log({ ...userInfo, ...userDescription });
      setUserInfo({ ...userInfo, ...userDescription });
    } else {
      dispatch(
        newAlert({ type: "danger", message: "Failed to update user Data" })
      );
    }
    setEditDescription({
      name: false,
      position: false,
      education: false,
      description: false,
    });
  });
  // loadUserData(setUserInfo)
  // console.log(udata);
};
const UserDescription = ({
  userInfo,
  setUserInfo,
  loadingUserData,
  latestEducation,
}) => {
  const [height, setHeight] = useState(0);
  const descriptionBgRef = useRef(null);
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    const calculateDistance = () => {
      if (descriptionBgRef.current) {
        // const computedStyle = window.getComputedStyle(descriptionBgRef.current);

        const { offsetTop, offsetHeight } = descriptionBgRef.current;
        const bottomPosition = (offsetTop + offsetHeight) * 1.1;
        // console.log({ offsetTop, offsetHeight });
        setHeight(bottomPosition);
      }
    };

    calculateDistance();
    return () => {};
  }, []);
  const [editDescription, setEditDescription] = useState({
    name: false,
    position: false,
    education: false,
    description: false,
  });
  const [userDescription, setUserDescription] = useState({
    name: "",
    position: "",
    education: "",
    description: "",
  });
  const [showModal, setShowModal] = useState(false);
  const { userId } = useParams();
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     console.log("latestEducation: ", latestEducation);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [latestEducation]);

  const openModal = (editDescription) => {
    setShowModal(true);
    setEditDescription(editDescription);
  };
  const PlaceholderText = ({ value, placeholder, placeHolderStyles }) => {
    return (
      <span className="placeholder-glow">
        <span
          className={`${!value ? "text-muted" : ""} ${
            loadingUserData ? "placeholder" : ""
          }`}
          style={
            loadingUserData
              ? { borderRadius: "10px", opacity: "0.3", ...placeHolderStyles }
              : {}
          }
        >
          {value ? value : loadingUserData ? "" : placeholder}
        </span>
      </span>
    );
  };

  const PlaceholderLink = ({ text, link, placeholder, placeHolderStyles }) => {
    return (
      <span className="placeholder-glow">
        <a
          href={link}
          rel="noreferrer"
          target="_blank"
          className={`ms-1 ${!text ? "text-muted" : ""} ${
            loadingUserData ? "placeholder" : ""
          }`}
          style={
            loadingUserData
              ? { borderRadius: "10px", opacity: "0.3", ...placeHolderStyles }
              : { color: "black", textDecoration: "underline" }
          }
        >
          {text ? text : loadingUserData ? "" : placeholder}
        </a>
      </span>
    );
  };

  useEffect(() => {
    // console.log(userInfo);
    setUserDescription({
      name: userInfo.name,
      position: userInfo.position,
      education: userInfo.education,
      description: userInfo.description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  return (
    <>
      <div className="pb-md-5 pb-4 mt-4 mt-md-5" ref={descriptionBgRef}>
        <ShowModal
          bodyContent={modalBody(
            userInfo,
            userDescription,
            setUserDescription,
            editDescription,
            latestEducation,
            setInputError
          )}
          onModalSubmit={() => {
            onModalSubmit(
              userDescription,
              userInfo,
              setUserInfo,
              editDescription,
              setEditDescription,
              dispatch,
              userId
            );
          }}
          handleModal={{ showModal, setShowModal }}
          onModalClose={() => {
            setInputError("");
            setEditDescription({
              Name: false,
              Position: false,
              Education: false,
              Description: false,
            });
          }}
          inputError={inputError}
        />

        <div
          className="description-bg"
          style={{
            height,
            // borderRadius: isApplicationInterface ? "62px 62px 0px 0px" : 0,
          }}
        ></div>
        <div className="d-flex" style={{ height: "90px" }}>
          <img
            src={companylogo}
            // style={{ maxHeight: "80px" }}
            alt="companylogo"
            className="orgpic-work"
          />
          <div className="ms-3 d-flex flex-column justify-content-around">
            <div>
              <span className="h3 fw-bold">
                <PlaceholderText
                  value={userInfo.name}
                  placeholder={"Your Name"}
                />
              </span>
              <i
                className={`far fa-edit ms-2 ${
                  loadingUserData ? "d-none" : ""
                }`}
                onClick={() => openModal({ ...editDescription, name: true })}
              ></i>
            </div>
            <div>
              <span>
                <PlaceholderText
                  value={userInfo.position}
                  placeholder={"Add Position"}
                />
              </span>
              <i
                className={`far fa-edit ms-2 ${
                  loadingUserData ? "d-none" : ""
                }`}
                onClick={() =>
                  openModal({ ...editDescription, position: true })
                }
              ></i>
            </div>
            <div>
              <span
                data-tooltip={"This is as per your latest education details"}
                data-tooltip-location="right"
              >
                <PlaceholderText
                  value={
                    userInfo.education || latestEducation?.degree ? (
                      <span>
                        {latestEducation?.degree} -{" "}
                        {/* <img
                          src={universitylogo}
                          alt="uni logo"
                          style={{ height: "20px" }}
                        />{" "} */}
                        {latestEducation?.institution}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  placeholder={"Add Education"}
                />
              </span>
              {/* <i
                className={`far fa-edit ms-2 ${loadingUserData?"d-none":""}`}
                onClick={() =>
                  openModal({ ...editDescription, education: true })
                }
              ></i> */}
            </div>
          </div>
        </div>

        <div className="">
          <i
            className="fa-solid fa-link"
            style={{
              transform: "rotate(-16deg) scaleY(0.7)",
              "-webkit-text-stroke": "0.4px",
            }}
          ></i>
          <span className="">
            <PlaceholderLink
              text={userInfo.portfolio?.text}
              link={userInfo.portfolio?.link}
              placeholder={"Add Portfolio link"}
              // placeHolderStyles={{ width: "650px", height: "45px" }}
            />
          </span>
          <i
            className={`far fa-edit ps-0 ms-2 ${
              loadingUserData ? "d-none" : ""
            }`}
            onClick={() => openModal({ ...editDescription, portfolio: true })}
          ></i>
        </div>
        <div className="d-flex mt-3" style={{ maxWidth: "650px" }}>
          <span className="">
            <PlaceholderText
              value={userInfo.description}
              placeholder={"Add Bio/Description"}
              placeHolderStyles={{ width: "650px", height: "45px" }}
            />
          </span>
          <i
            className={`far fa-edit ps-0 ms-2 ${
              loadingUserData ? "d-none" : ""
            }`}
            onClick={() => openModal({ ...editDescription, description: true })}
          ></i>
        </div>
      </div>
    </>
  );
};

export default UserDescription;
