import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeEditExp } from "../../../slices/profileSlices/openTabSlice";
import { Col, Row } from "react-bootstrap";
import { timeDifference } from "./subComp/WorkPeriod";

const MonthOptions = () => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
    <option value="" disabled></option>
      {month.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })}
    </>
  );
};
export const WorkExperience = ({ saveJobDetails, localData }) => {
  const dispatch = useDispatch();
  const { workMessages } = useSelector((state) => {
    return state.workSlice.edit_profile_field_messages;
  });

  const {
    orgDetails,
    setOrgDetails,
    timePeriod,
    setTimePeriod,
    currWorking,
    setCurrentlyWorking,
    workDescribe,
    setWorkDescribe,
  } = localData;
  const [errors, setErrors] = useState({});
  const setOrgInfo = (e) => {
    const name = e.target.name;
    let data;
    if (name === "name") {
      data = { ...orgDetails, name: e.target.value };
    } else if (name === "position") {
      data = { ...orgDetails, position: e.target.value };
    } else if (name === "url") {
      data = { ...orgDetails, url: e.target.value };
    }
    if (data) {
      setOrgDetails(data);
    }
  };
  const setTimeInfo = (e) => {
    const name = e.target.name;
    let data;
    if (name === "startMonth") {
      data = { ...timePeriod, startMonth: e.target.value };
    } else if (name === "endMonth") {
      data = { ...timePeriod, endMonth: e.target.value };
    } else if (name === "startYear") {
      data = { ...timePeriod, startYear: e.target.value };
    } else if (name === "endYear") {
      data = { ...timePeriod, endYear: e.target.value };
    }
    if (data) {
      // console.log({ data });
      setTimePeriod(data);
    }
  };

  const closeExpDetails = () => {
    dispatch(closeEditExp());
  };

  function validateURL(url) {
    var res = url.match(
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\+~#=]{2,256}\.[a-z^@]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }
  const SubmitWorkDetails = (e) => {
    e.preventDefault();
    // Perform validation before submitting the form
    const newErrors = {};
    if (!orgDetails.name?.trim()) {
      newErrors.orgInfo = {
        ...newErrors.orgInfo,
        name: "Organization name is required.",
      };
    }
    if (!orgDetails.position?.trim()) {
      newErrors.orgInfo = {
        ...newErrors.orgInfo,
        position: "Organization position is required.",
      };
    }
    if (orgDetails.url && !validateURL(orgDetails.url)) {
      newErrors.orgInfo = {
        ...newErrors.orgInfo,
        url: "Invalid URL.",
      };
    }
    if (!workDescribe.workDescription?.trim()) {
      newErrors.workDescription = "Work description is required.";
    }
    if (!timePeriod.startMonth || !timePeriod.startYear) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        start: "Start month & year is required.",
      };
    } else if (
      !currWorking &&
      (timePeriod.endMonth === "" || timePeriod.endYear === "")
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        end: "End month & year is required.",
      };
    } else if (!workDescribe.workingHours) {
      newErrors.workingHours = "Working hours is required.";
    } else if (workDescribe.workingHours <= 0) {
      newErrors.workingHours = "Working hours is invalid.";
    } else if (
      timePeriod.endMonth === timePeriod.startMonth &&
      timePeriod.endYear === timePeriod.startYear
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        all: "There must be at least one month of experience",
      };
    }
    const [yearDiff] = timeDifference(timePeriod, currWorking);
    const currYear = new Date().getFullYear();
    // const currMonth= new Date().getMonth();
    const yearExceed =
      (timePeriod.endYear > currYear && !currWorking) ||
      timePeriod.startYear > currYear;
    // const monthLess = yearDiff===0 && (endMonth-startMonth)<0;
    // const monthExceed = yearDiff===0 && timePeriod.startYear===currYear && (endMonth>currMonth || startMonth>currMonth);
    // console.log(yearDiff<0,yearDiff>100,yearExceed,monthLess );
    if (
      ((currWorking && timePeriod.startYear) ||
        (timePeriod.startYear && timePeriod.endYear)) &&
      (yearDiff < 0 ||
        yearDiff > 100 ||
        yearExceed ||
        timePeriod.startYear < currYear - 80)
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        all: "Invalid Time period given",
      };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      saveJobDetails();
    }
  };

  return (
    <div className="card p-4 bg-light">
      <div>
        <div>
          <Row>
            <Col md={8}>
              <input
                type="text"
                className="form-control input-h-50 me-1 mb-2"
                value={orgDetails.name}
                name="name"
                placeholder="Organization Name"
                onChange={(e) => {
                  setOrgInfo(e);
                }}
              />
              {errors.orgInfo && errors.orgInfo.name && (
                <span className="text-danger">{errors.orgInfo.name}</span>
              )}
            </Col>
            <Col md={4}>
              <input
                type="text"
                className="form-control input-h-50"
                placeholder="Position"
                name="position"
                value={orgDetails.position}
                onChange={(e) => {
                  setOrgInfo(e);
                }}
              />
              {errors.orgInfo && errors.orgInfo.position && (
                <span className="text-danger">{errors.orgInfo.position}</span>
              )}
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <input
            type="text"
            className="form-control input-h-50 org-url mb-2"
            placeholder="Organization URL"
            name="url"
            value={orgDetails.url}
            onChange={(e) => {
              setOrgInfo(e);
            }}
          />
          {errors.orgInfo && errors.orgInfo.url && (
            <span className="text-danger">{errors.orgInfo.url}</span>
          )}
        </div>
      </div>
      <hr />
      <div className="fw-bold text-center">Time period</div>
      <div className="row">
        <div className="row col-lg-4 col-md-6">
          <span className="col-12">Start</span>
          <div className="col-12 d-flex">
            <select
              className="form-select me-2"
              name="startMonth"
              value={timePeriod.startMonth}
              onChange={(e) => setTimeInfo(e)}
            >
              <MonthOptions />
            </select>
            <input
              type="number"
              value={timePeriod.startYear}
              name="startYear"
              className="form-control input-h-50"
              placeholder="Year"
              onChange={(e) => setTimeInfo(e)}
            />
          </div>
          {errors.timePeriod && errors.timePeriod.start && (
            <span className="text-danger">{errors.timePeriod.start}</span>
          )}
        </div>

        <div className="row col-lg-4 col-md-6">
          <span className="col-12">End</span>
          <div className="col-12 d-flex">
            <select
              className="form-select me-2"
              value={timePeriod.endMonth || ''}
              name="endMonth"
              onChange={(e) => setTimeInfo(e)}
              disabled={currWorking ? true : false}
            >
              <MonthOptions />
            </select>
            <input
              type="number"
              className="form-control input-h-50"
              placeholder="Year"
              disabled={currWorking ? true : false}
              value={timePeriod.endYear}
              name="endYear"
              onChange={(e) => setTimeInfo(e)}
            />
          </div>
          {errors.timePeriod && errors.timePeriod.end && (
            <span className="text-danger">{errors.timePeriod.end}</span>
          )}
        </div>
        <div className="form-check col-lg-4 col-md-6 mt-4 ms-2">
          <input
            className="form-check-input"
            value={currWorking}
            onChange={(e) => setCurrentlyWorking(e.target.checked)}
            checked={currWorking}
            type="checkbox"
            name="currWorking"
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Currently working
          </label>
        </div>
        {errors.timePeriod && errors.timePeriod.all && (
          <div className="text-danger text-center">{errors.timePeriod.all}</div>
        )}
      </div>

      <hr className="my-4" />
      <div className="row">
        <div className="col-md-6 col-11">
          <textarea
            className="form-control input-h-50 no-resize mb-2"
            name="workDescription"
            placeholder="description"
            value={workDescribe.workDescription}
            style={{ height: "100px" }}
            onChange={(e) =>
              setWorkDescribe({
                ...workDescribe,
                workDescription: e.target.value,
              })
            }
          />
          {errors.workDescription && (
            <span className="text-danger">{errors.workDescription}</span>
          )}
        </div>
        <div className="col-md-6 col-11 d-flex flex-column justify-content-between">
          <div>
            <input
              type="number"
              min="0"
              className="form-control input-h-50"
              placeholder="Working Hours/Week"
              name="workingHours"
              value={workDescribe.workingHours}
              onChange={(e) =>
                setWorkDescribe({
                  ...workDescribe,
                  workingHours: e.target.value,
                })
              }
            />
            {errors.workingHours && (
              <span className="text-danger">{errors.workingHours}</span>
            )}
          </div>
          <div className="ms-auto mt-2">
            <button
              className="btn btn-success me-2 p-2"
              style={{
                backgroundColor: "#074A75",
                minWidth: "85px",
                border: "none",
              }}
              onClick={(e) => SubmitWorkDetails(e)}
            >
              Save
            </button>
            <button
              onClick={() => closeExpDetails()}
              style={{
                backgroundColor: "#EF888C",
                minWidth: "85px",
                border: "none",
              }}
              className="btn btn-secondary p-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
