import React from 'react'
import { useDispatch } from 'react-redux';
import Select from "react-select";
import { Form } from "react-bootstrap"
import axiosInstance from "../../axios.js";
import { newAlert } from '../../slices/alertSlice.js';
import { useNavigate } from 'react-router-dom';


function CreateCourseSearch({
  setCourseOptions,
  courseOptions,
  setNewCourseName,
  setAskConfirmation,
  selectedCourse,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(courseOptions, "courseOptions")

  const createNewCourse = async (name) => {
    try {
      const { data } = await axiosInstance.post(`/api/admin/create-course`, {
        name,
      });
      //   console.log(data);
      if (data && data.course) {
        setNewCourseName("");
        dispatch(newAlert({ type: "success", message: "New Course created!" }));
        navigate(`/admin/create-course?courseId=${data.course._id}`);
        window.location.reload();
        setAskConfirmation({
          active: false,
          title: false,
          content: "",
          onClick: null,
        });
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const getExistingCourses = async (courseName) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/admin/get-existing-courses-by-course-name/${courseName}`
      );
      let fCourseAlreadyExist = false;
      const options = await data.matchedCourses.map((course) => {
        if (!fCourseAlreadyExist)
          fCourseAlreadyExist = course.name.trim() === courseName.trim();
        return {
          value: course.name,
          label: course.name + " ( edit )",
          _id: course._id,
          updatedAt: course.updatedAt,
          type: course.type,
          sections: course.sections,
        };
      });
      if (!fCourseAlreadyExist) {
        options.push({
          value: "xpZQ",
          label: `Create new course : ${courseName}`,
        });
      }
      //   console.log("optinos", options);
      setCourseOptions(options);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  return (
    <Form>
      <div style={{ marginBottom: "10px" }} className="parent-container">
        <div className="title-container">
          <div className="text-muted mt-3" style={{ fontSize: "14px" }}>
            Course
          </div>
          <Select
            closeMenuOnSelect={true}
            placeholder={<div>Enter Course Name</div>}
            value={selectedCourse}
            onChange={async (choice) => {
              try {
                // console.log("choice", choice);
                let courseName = choice;
                const suffix = " ( edit )";
                if (
                  courseName.label.indexOf(suffix) ===
                  courseName.label.length - suffix.length
                ) {
                  courseName.label = choice.label.slice(0, -suffix.length);
                }
                if (choice.value === "xpZQ") {
                  const newCourseNameEntered = choice.label.split(
                    "Create new course : "
                  )[1];
                  setNewCourseName(newCourseNameEntered);
                  const { data } = await axiosInstance.get(
                    `/api/admin/check-unique-course/${newCourseNameEntered}`
                  );
                  const { isPresent, courseId } = data;
                  //   console.log("checkuniquecourse", data);
                  if (!isPresent) {
                    setAskConfirmation({
                      active: true,
                      title: true,
                      content: "Are you sure you want to create new Course?",
                      onClick: () => createNewCourse(newCourseNameEntered),
                    });
                  } else {
                    const getCourse = async () => {
                      setAskConfirmation({
                        active: false,
                        title: false,
                        content: "",
                        onClick: null,
                      });
                      navigate(`/admin/create-course?courseId=${courseId}`);
                    };
                    setAskConfirmation({
                      active: true,
                      title: false,
                      content:
                        "This Course already exists! do you want to edit this Course?",
                      onClick: getCourse,
                    });
                  }
                } else {
                  navigate(`/admin/create-course?courseId=${choice._id}`);
                }
              } catch (err) {
                console.log("Error: ", err);
              }
            }}
            onInputChange={(input) => {
              if (input) getExistingCourses(input);
              else if (input === "")
                setCourseOptions([]);
            }}
            styles={{
              control: (styles) => ({
                ...styles,
                width: "100%",
              }),
            }}
            noOptionsMessage={() => "Start typing course name"}
            options={courseOptions}
          />
        </div>
      </div>
    </Form>
  );
}

export default CreateCourseSearch;