import React, { useState } from "react";
import newLogoWhite from "../../assets/images/Mentorstudents_logo_application.svg";
import styles from "./ApplicationNavbar.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ApplicationNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userData = useSelector((state) => state.sessionSlice.sessionUser.user);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <a
        className="navbar-brand d-flex align-items-center ms-sm-3"
        href="https://mentorstudents.org"
      >
        <img src={newLogoWhite} className="navbar-logo-img" alt="companyLogo" />
      </a>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a
              className="nav-link mx-3"
              href="https://mentorstudents.org/about-us"
            >
              About us
            </a>
          </li>
          <li className="nav-item dropdown">
            <button
              className="nav-link dropdown-toggle d-flex align-items-center fs-6 mx-3 px-0"
              type="button"
              id="navbarDropdown"
              onClick={toggleDropdown}
            >
              <div className="">
                {userData?._id ? (
                  `${userData?.firstName || ""} ${userData?.lastName || ""}`
                ) : (
                  <Link to="/applicant/sign-in" style={{ color: "#777777" }}>
                    SignIn
                  </Link>
                )}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default ApplicationNavbar;
