import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import testSubmittedBackgroundImage from "../../../assets/profile-images/testSubmittedBackground.svg";
import tick from "../../../assets/profile-images/tick.svg";
import "./Test2Completion.css";

function Test2Completion() {
  return (
    <div style={{ height: "100dvh" }}>
      <Container className="d-flex flex-column align-items-center">
        <div className="test2Completion">
          <Row style={{ textAlign: "center", marginTop: "20px" }}>
            <Col>
              <img
                style={{ height: "182px", width: "357px", marginLeft: "110px" }}
                src={testSubmittedBackgroundImage}
                alt="testSubmitedSuccessfully"
              />
              <img
                style={{
                  height: "81px",
                  width: "88px",
                  position: "relative",
                  top: "-2px",
                  right: "230px",
                }}
                src={tick}
                alt="tick"
              />
              <h3
                style={{
                  fontWeight: "bold",
                }}
              >
                Thank you for submitting your attempt.
              </h3>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              textAlign: "center",
              marginTop: "27px",
              fontSize: "20px",
            }}
          >
            <div className="d-flex justify-content-center">
              <span>
                We'll let you know about the test results and interview in 4 - 5
                days.
              </span>
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <hr style={{ width: "622px" }}></hr>
            </div>
          </Row>
          <Row>
            <div
              style={{ marginTop: "22px", fontSize: "18px" }}
              className="d-flex justify-content-center"
            >
              <span>
                As promised, you will get to attend the live lecture on{" "}
              </span>
            </div>
          </Row>
          <Row>
            <div
              style={{ fontSize: "18px", marginTop: "2px" }}
              className="d-flex justify-content-center"
            >
              <span>AI–RAG for Chatbot.</span>
            </div>
          </Row>
          <Row>
            <div
              style={{ marginTop: "18px", fontSize: "18px" }}
              className="d-flex justify-content-center"
            >
              <span>Pick time option for the lecture here: &nbsp; </span>
              <span> </span>
              <a
                target="_blank"
                href="https://meet.zoho.com/2VcfBrY9JT"
                rel="noreferrer"
                style={{ textDecoration: "underline", color: "#0056D2" }}
              >
                Time options
              </a>
              <span>⏰</span>
            </div>
          </Row>
          <Row
            style={{
              textAlign: "center",
              marginTop: "10px",
              position: "relative",
              top: "-30px",
              justifyContent: "center",
            }}
          >
            {/* <Button
          style={{ width: "100px" }}
          variant="danger"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </Button> */}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Test2Completion;
