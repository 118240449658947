import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { timeDifference } from "../workComponents/subComp/WorkPeriod";
import { useParams } from "react-router-dom";

const MonthOptions = () => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <option value="" disabled></option>
      {month.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })}
    </>
  );
};

const EditProject = ({
  project,
  setProject,
  editMode,
  setEditMode,
  saveProjectDetails,
  deleteProjectDetails,
  errors,
  setErrors,
  fProjectWithSkills,
}) => {
  const { userId } = useParams();
  const { title, timePeriod, currWorking, description } = project;
  // console.log({ timePeriod });
  const setOrgInfo = (e) => {
    const name = e.target.name;
    let data;
    if (name === "title") {
      data = { ...project, title: e.target.value };
    }
    if (data) {
      setProject(data);
    }
  };
  const setTimeInfo = (e) => {
    const name = e.target.name;
    let timePeriod = project.timePeriod || {};
    let data;
    if (name === "startMonth") {
      data = { ...timePeriod, startMonth: e.target.value };
    } else if (name === "endMonth") {
      data = { ...timePeriod, endMonth: e.target.value };
    } else if (name === "startYear") {
      data = { ...timePeriod, startYear: e.target.value };
    } else if (name === "endYear") {
      data = { ...timePeriod, endYear: e.target.value };
    }
    // console.log({ data });
    if (data) {
      setProject({ ...project, timePeriod: data });
    }
  };

  const closeExpDetails = () => {
    setEditMode({ value: false, mode: "" });
  };

  const SubmitProjectDetails = (e) => {
    e.preventDefault();
    // console.log({ project });

    let timePeriod = project.timePeriod || {};
    // Perform validation before submitting the form
    const newErrors = {};
    if (!project.title?.trim()) {
      newErrors.title = "Project title is required.";
    }
    if (!description?.trim()) {
      newErrors.description = "Work description is required.";
    }
    if (!timePeriod.startMonth || !timePeriod.startYear) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        start: "Start month & year is required.",
      };
    } else if (
      !currWorking &&
      (timePeriod.endMonth === "" || timePeriod.endYear === "")
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        end: "End month & year is required.",
      };
    } else if (
      timePeriod.endMonth === timePeriod.startMonth &&
      timePeriod.endYear === timePeriod.startYear
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        all: "There must be at least one month of experience",
      };
    }
    const [yearDiff] = timeDifference(timePeriod, currWorking);
    const currYear = new Date().getFullYear();
    const yearExceed =
      (timePeriod.endYear > currYear && !currWorking) ||
      timePeriod.startYear > currYear;
    if (
      ((currWorking && timePeriod.startYear) ||
        (timePeriod.startYear && timePeriod.endYear)) &&
      (yearDiff < 0 ||
        yearDiff > 100 ||
        yearExceed ||
        timePeriod.startYear < currYear - 80)
    ) {
      newErrors.timePeriod = {
        ...newErrors.timePeriod,
        all: "Invalid Time period given",
      };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors("");
      // console.log({ mode: editMode.mode, userId, project });
      saveProjectDetails({
        mode: editMode.mode,
        userId,
        projectDetails: project,
      });
    }
  };

  return (
    <div className={`${fProjectWithSkills ? "" : "card bg-light"} px-4 pt-4`}>
      <div>
        <div>
          <Row>
            <Col>
              <input
                type="text"
                className="form-control input-h-50 me-1 mb-2"
                value={project.title}
                name="title"
                placeholder="Project Title"
                onChange={(e) => {
                  setOrgInfo(e);
                }}
              />
              {errors && errors.title && (
                <span className="text-danger">{errors.title}</span>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <hr />
      <div className="fw-bold text-center">Time period</div>
      <div className="row">
        <div
          className={`row ${
            fProjectWithSkills ? "col-lg-6" : "col-lg-4"
          } col-md-6`}
        >
          <span className="col-12">Start</span>
          <div className="col-12 d-flex time-period-input">
            <select
              className="form-select me-2"
              name="startMonth"
              value={project.timePeriod?.startMonth || ''}
              onChange={(e) => setTimeInfo(e)}
            >
              <MonthOptions />
            </select>
            <input
              type="number"
              value={project.timePeriod?.startYear}
              name="startYear"
              className="form-control input-h-50"
              placeholder="Year"
              onChange={(e) => setTimeInfo(e)}
            />
          </div>
          {errors.timePeriod && errors.timePeriod.start && (
            <span className="text-danger">{errors.timePeriod.start}</span>
          )}
        </div>

        <div
          className={`row ${
            fProjectWithSkills ? "col-lg-6" : "col-lg-4"
          } col-md-6`}
        >
          <span className="col-12">End</span>
          <div className="col-12 d-flex time-period-input">
            <select
              className="form-select me-2"
              value={project.timePeriod?.endMonth || ''}
              name="endMonth"
              onChange={(e) => setTimeInfo(e)}
              disabled={currWorking ? true : false}
            >
              <MonthOptions />
            </select>
            <input
              type="number"
              className="form-control input-h-50"
              placeholder="Year"
              disabled={currWorking ? true : false}
              value={project.timePeriod?.endYear}
              name="endYear"
              onChange={(e) => setTimeInfo(e)}
            />
          </div>
          {errors.timePeriod && errors.timePeriod.end && (
            <span className="text-danger">{errors.timePeriod.end}</span>
          )}
        </div>
        <div
          className={`form-check ${
            fProjectWithSkills ? "col-lg-12" : "col-lg-4"
          } col-md-6 mt-4 ms-2`}
        >
          <input
            className="form-check-input"
            value={currWorking}
            onChange={(e) =>
              setProject({ ...project, currWorking: e.target.checked })
            }
            checked={currWorking}
            type="checkbox"
            name="currWorking"
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Currently working
          </label>
        </div>
        {errors.timePeriod && errors.timePeriod.all && (
          <div className="text-danger text-center">{errors.timePeriod.all}</div>
        )}
      </div>

      <hr className="my-4" />
      <div className="row">
        <div
          className={`${fProjectWithSkills ? "col-md-12" : "col-md-9"} col-11`}
        >
          <textarea
            className="form-control input-h-50 no-resize mb-2"
            name="description"
            placeholder="description"
            value={description}
            style={{ height: "100%" }}
            rows={5}
            onChange={(e) =>
              setProject({
                ...project,
                description: e.target.value,
              })
            }
          />
          {errors.description && (
            <span className="text-danger">{errors.description}</span>
          )}
        </div>
        {!fProjectWithSkills && (
          <div className="col-md-2 col-11 d-flex flex-column justify-content-around">
            {/* <div className="ms-auto mt-2"> */}
            <button
              className="btn btn-success p-2"
              style={{
                backgroundColor: "#074A75",
                minWidth: "85px",
                border: "none",
              }}
              onClick={(e) => SubmitProjectDetails(e)}
            >
              Save
            </button>
            <button
              onClick={() => closeExpDetails()}
              style={{
                backgroundColor: "#EF888C",
                minWidth: "85px",
                border: "none",
              }}
              className="btn btn-secondary p-2"
            >
              Cancel
            </button>
            {/* </div> */}
          </div>
        )}
        {!fProjectWithSkills && editMode.mode !== "add" && (
          <span
            className="col-1"
            onClick={async () => {
              await deleteProjectDetails(project._id, userId);
            }}
            style={{ marginTop: "auto", marginBottom: "10px" }}
          >
            <i className="far fa-trash-can delete-proj-btn cursor-pointor"></i>
          </span>
        )}
      </div>
    </div>
  );
};

export default EditProject;
