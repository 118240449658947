import React from "react";
import "./DisplayAdFormatCarousel.css";
import DisplayAdFormatImage from "../DisplayAdFormatImage/DisplayAdFormatImage";
import Carousel from "react-bootstrap/Carousel";

function DisplayAdFormatCarousel({ adDetails }) {
  return (
    <Carousel indicators={false} interval={null} variant={"dark"} wrap={false}>
      {adDetails.map((ad, adIndex) => {
        return (
          <Carousel.Item key={adIndex}>
            <DisplayAdFormatImage adDetails={ad} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default DisplayAdFormatCarousel;
