import React from "react";
import "./../../../../src/views/public/ArticlePage/ArticlePage.css";
import "./AuthorDetails.css";
function AuthorDetails({ author, loadingAuthor }) {
  return (
    <div className="d-flex mb-1">
      <div className="me-2 mt-1 authorImgDiv placeholder-glow">
        {loadingAuthor ? (
          <div
            className="placeholder"
            style={{ width: "52px", height: "52px", borderRadius: "50%" }}
          ></div>
        ) : (
          <img
            src={author?.profilePicUrl}
            alt="Author"
            width={44}
            height={44}
          />
        )}
      </div>
      <div className="">
        {loadingAuthor ? (
          <p
            className="placeholder mb-0"
            style={{ width: "90px", height: "20px" }}
          ></p>
        ) : (
          <p className="mb-0 fontPara authorName">{author?.name}</p>
        )}

        {loadingAuthor ? (
          <p className="placeholder-glow">
            <small
              className="placeholder"
              style={{ width: "218px", height: "18px" }}
            ></small>
          </p>
        ) : (
          <p>
            <small className=" text-secondary fontPara mobpara">
              Contributor at{" "}
            </small>

            <span
              style={{ fontFamily: "Helvetica Neue, sans-serif" }}
              className="comName"
            >
              INC.COM{" "}
            </span>
            <small className="text-secondary fontPara mobpara">
              {" "}
              &nbsp;.&nbsp; 9 min read
            </small>
          </p>
        )}
      </div>
    </div>
  );
}

export default AuthorDetails;
