import React from "react";
import pageRejectedIcon from "../../../assets/profile-images/page_rejected.svg";
import { Container } from "react-bootstrap";
import "./ApplicantRejectionPage.css";

const ApplicantRejectionPage = () => {
  return (
    <div>
      <Container className="d-flex flex-column align-items-center">
        <div className="application-rejection-page">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ margin: "80px 10px", fontSize: "24px" }}
          >
            <img
              src={pageRejectedIcon}
              alt="rejection-page-icon"
              className="mb-5 rejection-page-icon"
            />
            <div className="rejection-message">
              <div className="text-center">
                We were fortunate to receive interest from many talented
                professionals such as yourself.
                <br />
                We decided to persue other candidates at this time.
                <br />
                We hope we can reach back to you in future
                <br />
                <br />
                Best!
                <br />
                MentorStudents.org team
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ApplicantRejectionPage;
