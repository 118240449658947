import React, { useState } from "react";
import { Alert, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Axios from "../../../axios";
import { newAlert } from "../../../slices/alertSlice";
import { Link, useLocation } from "react-router-dom";
import EmailSent from "../../../assets/images/emailSent.svg";
import "./ForgotPasswordPage.css";

const ForgotPasswordPage = ({ fApplicantPage }) => {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [linkSent, setLinkSent] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let otpExceed = searchParams.get("otpExceed");
  const [fOTPExceed, setOTPExceed] = useState(otpExceed);

  const validateForm = () => {
    const errors = {};
    const emailRegex =
      /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      errors.email = "Please enter a valid email";
    } else {
      delete errors[email];
    }
    if (!email) {
      errors.email = "Please enter your email";
    } else {
      delete errors[email];
    }
    return errors;
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log();
    Axios.post("/api/public/forgot-password", { email, fApplicantPage })
      .then((res) => {
        if (res.status == 200) {
          setShowAlert(true);
          setLinkSent(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        console.log(err.message);
        if (err.response) {
          dispatch(
            newAlert({ type: "danger", message: err.response.data.message })
          );
        } else {
          dispatch(newAlert({ type: "danger", message: err.message }));
        }
      });
  };

  if (linkSent) {
    return (
      <>
        <Container className="d-flex align-items-center justify-content-center">
          <div className="forgot-password-page">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                style={{ height: "92px", width: "92px" }}
                src={EmailSent}
                alt="img"
              />
              <h3 style={{ marginTop: "35px" }}>Check Your Email</h3>
              <span style={{ marginTop: "30px" }}>
                Reset password link sent to{" "}
              </span>
              <br />
              <span>{email}</span>
              <span style={{ marginTop: "36px" }}>
                If you do not see reset password mail within{" "}
              </span>
              <br />
              <span>2 minutes, please check your spam folder.</span>
            </div>
          </div>
        </Container>
      </>
    );
  }

  return (
    <>
      {/* {showAlert && (
        <Alert
          variant="success"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <p className="text-center">Reset password link sent to {email}</p>
        </Alert>
      )} */}
      {fOTPExceed && (
        <div className="text-center d-flex align-items-center justify-content-center mt-5 p-auto">
          <div className="opt-exceed">
            <span>
              You exceeded your attempts.
              <br />
              Please use forgot password below.
            </span>
            {/* <i
            className="fa fa-close mt-1"
            style={{
              position: "absolute",
              right: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              setOTPExceed(false);
            }}
          ></i> */}
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className={`d-flex justify-content-center align-items-center ${
            !fOTPExceed ? "my-5" : "my-2"
          } forgot-password-container`}
        >
          <Form
            className="w-100"
            style={{ "max-width": "450px", textAlign: "center" }}
          >
            <Form.Label>
              <h3 style={{ fontSize: "28px" }}>Forgot Password</h3>
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {/* <Form.Label>Email</Form.Label> */}
              <span style={{ fontSize: "14px" }}>
                Enter your email address and we
                <br></br>
                will send you instructions to reset
                <br></br>
                your password.
              </span>
              <Form.Control
                style={{ marginTop: "20px" }}
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            {formErrors.email && (
              <p className="text-danger" style={{ "font-size": "0.75rem" }}>
                {formErrors.email}
              </p>
            )}
            <Button
              style={{
                backgroundColor: "#3FACCB",
                width: "100%",
                marginTop: "10px",
              }}
              onClick={(e) => handleForgotPassword(e)}
            >
              Continue
            </Button>
            {fApplicantPage && (
              <div style={{ fontSize: "13px", marginTop: "20px" }}>
                Already created password?{" "}
                <Link to="/applicant/sign-in">Sign-in</Link> here
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
