import React, { useState } from "react";
import styles from "./SaveUnsaveAd.module.css";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveUnsaveAds } from "../../../../slices/savedAdsSlice";

const SaveUnsaveAd = ({ saved, adId, userId, query }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isSaved, setIsSaved] = useState(saved);
  const dispatch = useDispatch();

  async function handleSaveUnsaveAd() {
    setIsOpenPopup(false);
    const result = await dispatch(saveUnsaveAds({ adId, isSaved, userId, query }));
    if (result.meta.requestStatus === "fulfilled") {
      setIsSaved(!isSaved);
    }
  }

  return (
    <div >
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Save this Ad</Tooltip>}>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="Tooltip on top"
          onClick={() => {
            if (isSaved) {
              setIsOpenPopup(true);
            } else {
              handleSaveUnsaveAd();
            }
          }}
        >
          <i className={isSaved ? "fa-solid fa-bookmark text-info" : "fa-regular fa-bookmark"} />
        </div>
      </OverlayTrigger>
      {isOpenPopup && (
        <Modal show={isOpenPopup}>
          <Modal.Header>⚠️ WARNING ⚠️</Modal.Header>
          <Modal.Body>Do You Want To Remove This Ad From your Saved Ads ?</Modal.Body>
          <Modal.Footer>
            <button className={styles.confirmationDialogBox_buttonsContainer_buttons} onClick={handleSaveUnsaveAd}>
              Remove
            </button>
            <button className={styles.confirmationDialogBox_buttonsContainer_buttons} onClick={() => setIsOpenPopup(false)}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default SaveUnsaveAd;
