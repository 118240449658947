import React from "react";
import { useDispatch } from "react-redux";
import { newAlert } from "../../../../slices/alertSlice";

const AskConfirmationModal = ({ askConfirmation, setaskConfirmation }) => {
  const dispatch = useDispatch();
  const deleteModal = askConfirmation.type === "delete";
  return (
    <div>
      {askConfirmation.active && (
        <div className="">
          <div className="modal-background"></div>
          <div className="bg-light border rounded position-fixed askconfirmation-modal">
            <h4 className="text-center m-4">{askConfirmation.heading}</h4>
            <h6 className="text-center">
              {askConfirmation.title && <span>{askConfirmation.title}</span>}
            </h6>
            <div className="d-flex w-100 justify-content-center">
              <button
                className={`btn ${
                  deleteModal ? "btn-danger" : "btn-success"
                } mx-4 my-2`}
                onClick={async () => {
                  try {
                    await askConfirmation.onClick();
                  } catch (error) {
                    const errMsg =
                      error.response?.data?.message || error.message;
                    // console.error(errMsg);
                    dispatch(
                      newAlert({
                        type: "danger",
                        message: `Error: ${errMsg}`,
                      })
                    );
                  } finally {
                    setaskConfirmation({
                      active: false,
                      title: "",
                      heading: "",
                      onClick: null,
                    });
                  }
                }}
              >
                {deleteModal ? "delete" : "Yes"}
              </button>
              <button
                className={`btn ${
                  deleteModal ? "btn-secondary" : "btn-danger"
                } mx-4 my-2`}
                onClick={() => {
                  setaskConfirmation({
                    active: false,
                    title: "",
                    heading: "",
                    onClick: null,
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AskConfirmationModal;
